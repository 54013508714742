import React from 'react';
import { Strings } from '../../locale';
import ChatPage from './ChatPage';
import { formatMessage } from '../../locale';
import { Icon } from 'antd';
import { testSelectors } from '../../constants/test-id';
import { routePaths } from '../../constants/route-paths';

export const routeChat = {
    title: formatMessage(Strings.navigation.main.chat),
    path: routePaths.chat,
    icon: <Icon type="inbox" />,
    component: ChatPage,
    testId: testSelectors.nav.chatItem
};
