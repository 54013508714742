import {
    ItemTypes,
    FormItemChoice
} from '../../../../../types/indicator/state-indicator.type';

export type QuestionnaireFormItem =
    | QuestionnaireFormSliderItem
    | QuestionnaireFormRadioGroupItem
    | QuestionnaireFormTextItem;

export type QuestionnaireFormSliderItem = {
    code: string;
    type: ItemTypes;
    value: number;
    report_min: number;
    report_max: number;
    steps: number;
    index: number;
    isValid: boolean;
};

export type QuestionnaireFormRadioGroupItem = {
    code: string;
    type: ItemTypes;
    value: string | undefined;
    choices?: FormItemChoice[];
    index: number;
    isValid: boolean;
};

export type QuestionnaireFormTextItem = {
    code: string;
    type: ItemTypes;
    index: number;
    isValid: true;
};

export function isQuestionnaireFormSliderItem(
    item: QuestionnaireFormItem
): item is QuestionnaireFormSliderItem {
    return item.type === ItemTypes.SLIDER || item.type === ItemTypes.STEPPER;
}

export function isQuestionnaireFormRadioGroupItem(
    item: QuestionnaireFormItem
): item is QuestionnaireFormRadioGroupItem {
    return item.type === ItemTypes.SELECTION;
}

export function isQuestionnaireFormTextItem(
    item: QuestionnaireFormItem
): item is QuestionnaireFormTextItem {
    return item.type === ItemTypes.TEXT;
}
