import { formatMessage as t, Strings } from '../../../../locale';
import { FormValuesType } from './form.type';
import { FormikErrors } from 'formik';

export const validate = async (values: FormValuesType) => {
    const errors: FormikErrors<FormValuesType> = {};

    if (!isFieldDefined(values.firstName)) {
        errors.firstName = t(
            Strings.screen.profile.validation.firstNameRequired
        );
    }
    if (!isFieldDefined(values.familyName)) {
        errors.familyName = t(
            Strings.screen.profile.validation.familyNameRequired
        );
    }

    const formattedPhoneNumber = formatPhoneNumber(values.phoneNumber);
    validatePhoneNumber(formattedPhoneNumber, errors);

    return errors;
};

const formatPhoneNumber = (phoneNumber: string) => {
    let formattedPhoneNumber = phoneNumber.replace(/-|\s/g, '');

    if (formattedPhoneNumber.startsWith('0')) {
        formattedPhoneNumber = '+46' + formattedPhoneNumber.substring(1);
    }

    return formattedPhoneNumber;
};

const validatePhoneNumber = (
    phoneNumber: string,
    errors: FormikErrors<FormValuesType>
) => {
    if (phoneNumber.length > 0 && !isPhoneNumberFormatCorrect(phoneNumber)) {
        errors.phoneNumber = t(
            Strings.screen.profile.validation.phoneNumberFormat
        );
    }

    return errors;
};

const isFieldDefined = (name: string | null): boolean => {
    if (name == null) {
        return false;
    }

    return name.trim().length > 0;
};

const isPhoneNumberFormatCorrect = (phoneNumber: string | null): boolean => {
    const phoneNumberRegex = /^\+46[0-9]{9}$/i;

    return !!phoneNumber && phoneNumberRegex.test(phoneNumber);
};
