import { styled } from '../../../../theme/styled';

export const StyledButtonContainer = styled.div`
    display: flex;
    padding: 10px 20px;
    background: #fff;
    border-top: 1px solid #e8e8e8;

    @media (orientation: portrait) and (max-width: 320px) and (max-height: 568px) {
        // Fix footer height on iOS 13.5.1 (iPhone 5s)
        height: 118px;
    }

    //Override NeutralModalButton and PrimaryModalButton. This should probably be the future implementation for the buttons.
    &&& {
        button {
            flex-grow: 1;
            width: auto;
            margin-left: 10px;
            margin-right: 10px;
        }
    }
`;
