import { defineMessages } from 'react-intl';

export const heatmapChoices = defineMessages({
    few: {
        defaultMessage: '{min}-{max} cigarettes',
        id: 'indicator.smoke.range-choices.1'
    },
    more: {
        defaultMessage: '{min} cigarettes or more',
        id: 'indicator.smoke.range-choices.5'
    }
});
