import { defineMessages } from 'react-intl';

export const breathlessness = defineMessages({
    title: {
        defaultMessage: 'Breathlessness',
        id: 'indicator.cat.breathlessness.title'
    },
    maxValueDescription: {
        defaultMessage:
            'When I walk up a hill or one flight of stairs I am very breathless',
        id: 'indicator.cat.breathlessness.max-text'
    },
    minValueDescription: {
        defaultMessage:
            'When I walk up a hill or one flight of stairs I am not breathless',
        id: 'indicator.cat.breathlessness.min-text'
    }
});
