import { defineMessages } from 'react-intl';

const values = defineMessages({
    noReportedValues: {
        id: 'values.no_reported_values',
        defaultMessage: 'No reported values'
    },
    noReportedValuesThisMonth: {
        id: 'values.no_reported_values_this_month',
        defaultMessage: 'No reported values this month'
    },
    report: {
        id: 'values.report',
        defaultMessage:
            '{isMulti, plural, =0 {Report value} other {Report values}}'
    },
    sum: {
        id: 'value.sum',
        defaultMessage: 'Sum {sum}'
    },
    score: {
        id: 'value.score',
        defaultMessage: 'Score {score}'
    }
});

export default {
    main: values
};
