import { publicAxios } from '../../../services/http-client/public-http-client';
import { EidAuthMethods } from './authentication.enums';
import { AuthTokens } from './authentication.types';

export const postLogin = (params: any) => {
    return publicAxios
        .post('authentication/login', { ...params, role: 'patient' })
        .then(({ data }: { data: AuthTokens }) => data)
        .catch((error) => {
            console.error('Error in postLogin\n', error);
            throw error;
        });
};

export const eidLogin = (
    authMethod: EidAuthMethods,
    qr?: true
): Promise<void> => {
    const callbackUrl =
        window.location.href.split('#')[0] + '#/?authmethod=' + authMethod;

    return publicAxios
        .post('authentication/eid/login', {
            callbackUrl,
            authMethod,
            qr
        })
        .then(({ data }) => {
            window.location.href = data.redirectUrl;
        });
};

export const eidGetSession = (
    sessionId: string,
    authMethod: EidAuthMethods
): Promise<AuthTokens> =>
    publicAxios
        .get(
            `authentication/eid/session/${sessionId}/authmethod/${authMethod}/role/patient`
        )
        .then(({ data }: { data: AuthTokens }) => data);
