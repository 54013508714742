import React, { Component } from 'react';
import {
    formatMessage as t,
    formatMessageById as ti,
    Strings
} from '../../../locale';

import symbols from '../../../static/images/symbols';

import AnimateHeight from 'react-animate-height';
import { styled } from '../../../theme/styled';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import MessageList from './ConversationMessageList';
import SendMessageForm from './ConversationSendMessageForm';
import { Header } from '../../../components/layout/Header';
import { ContentLayout } from '../../../components/layout/ContentLayout';
import { Loading } from '../../../components/loading/Loading';
import {
    CONVERSATION_FETCH,
    CONVERSATION_FETCH_OR_CREATE,
    MESSAGE_POST
} from '../../../redux/domains/chat/chat.constants';
import {
    fetchMessages,
    fetchOrCreateConversation,
    sendMessage,
    setCurrentConversationId,
    startMessageTimer,
    stopMessageTimer
} from '../../../redux/domains/chat/chat.actions';
import { setMessageNotificationsAsConfirmed } from '../../../redux/domains/notifications/notifications.actions';
import { createLoadingSelector } from '../../../redux/domains/requests/requests.selectors';
import {
    getConversationId,
    getMessages
} from '../../../redux/domains/chat/chat.selectors';

import { ConversationInfoButton } from './ConversationInfoButton';
import { ConversationStickyButton } from './ConversationStickyButton';

import { CustomImage } from '../../../components/custom-image/CustomImage';
import { profileLight } from '../../../theme/profile/profiles';
import { MarkdownFromDescriptor } from '../../../locale/utils/functions/format-markdown';

type Props = {
    match: any;
    actions: any;
    user: any;
    conversationId: string;
    medicGroups: any;
    messages: any;
    initialLoading: any;
    postMessageLoading: any;
    userChatSettingHandler: any;
};

interface State {
    showFirstTimeNotificationModal: boolean;
    expandStickyNotification: boolean;
}

class ConversationPage extends Component<Props, State> {
    private formRef: any;

    constructor(props: any) {
        super(props);

        this.state = {
            showFirstTimeNotificationModal: true,
            expandStickyNotification: false
        };
    }

    componentDidMount() {
        const {
            actions: {
                fetchOrCreateConversation,
                setMessageNotificationsAsConfirmed,
                startMessageTimer
            }
        } = this.props;
        const { groupId } = this.props.match.params;

        if (groupId) {
            fetchOrCreateConversation(groupId).then(
                (conversationId: string) => {
                    startMessageTimer(conversationId, groupId);
                    setMessageNotificationsAsConfirmed(conversationId);
                }
            );
        }

        this.expandStickyAndSetLocalStorageOnFirstTime();
    }

    componentWillUnmount = () => {
        const { actions, conversationId } = this.props;
        actions.stopMessageTimer(conversationId);

        actions.setCurrentConversationId('');
        actions.setMessageNotificationsAsConfirmed(conversationId);
    };

    expandStickyAndSetLocalStorageOnFirstTime = () => {
        if (!this.props.userChatSettingHandler.isEnabled()) {
            this.setStickyExpandStatus();
            this.props.userChatSettingHandler.setStatus(true);
        }
    };

    setStickyExpandStatus = () => {
        this.setState({
            expandStickyNotification: !this.state.expandStickyNotification
        });
    };

    getStickyNotificationButtonText = () => {
        if (this.state.expandStickyNotification) {
            return ti(Strings.chat.notForEmergency.closeInfo.id);
        } else {
            return ti(Strings.chat.notForEmergency.showInfo.id);
        }
    };

    getShowHideButton = () => {
        return (
            <ConversationStickyButton onClick={this.setStickyExpandStatus}>
                <StyledStickyButtonTextWrapper>
                    <StyledCustomImage alt="i" src={symbols.info} />
                    {this.getStickyNotificationButtonText()}
                </StyledStickyButtonTextWrapper>
            </ConversationStickyButton>
        );
    };

    getStickyMessage = () => {
        return (
            <StyledStickyWrapper>
                <StyledStickyRow>
                    {this.getShowHideButton()}
                    <AnimateHeight
                        delay={0}
                        duration={250}
                        height={
                            this.state.expandStickyNotification ? 'auto' : 0
                        }
                        animateOpacity={true}
                        style={{
                            backgroundColor:
                                // When converting to functional component, change to
                                // "ThemeProfile" hook instead of profileLight
                                profileLight.chat.emergency.sticky.background
                        }}
                    >
                        <StyledStickyText>
                            <MarkdownFromDescriptor
                                messageDescriptor={
                                    Strings.chat.notForEmergency.stickyBody
                                }
                            />
                        </StyledStickyText>
                    </AnimateHeight>
                    {this.state.expandStickyNotification ? (
                        <ConversationInfoButton
                            size="large"
                            onClick={this.setStickyExpandStatus}
                        >
                            {t(Strings.ui.buttons.main.ok)}
                        </ConversationInfoButton>
                    ) : null}
                </StyledStickyRow>
            </StyledStickyWrapper>
        );
    };

    render() {
        const {
            user,
            medicGroups,
            messages,
            initialLoading,
            postMessageLoading
        } = this.props;
        const { subjectId } = user;
        const multipleMedicGroups =
            Object.entries(medicGroups.byId).filter((group: any) =>
                group[1].tags.includes('medic')
            ).length > 1;

        return (
            <>
                <Header
                    title={t(Strings.navigation.main.messagesTab)}
                    backButtonPath={multipleMedicGroups ? '/chat' : null}
                />
                <ContentLayout>
                    <StyledMedicGroupName>
                        {medicGroups.byId[this.props.match.params.groupId].name}
                    </StyledMedicGroupName>
                    {this.getStickyMessage()}
                    {initialLoading ? (
                        <Loading />
                    ) : (
                        <>
                            <MessageList
                                userId={subjectId}
                                messages={messages}
                            />
                            <SendMessageForm
                                onSubmit={this.handleSubmit}
                                wrappedComponentRef={(form: any) =>
                                    (this.formRef = form)
                                }
                                loading={postMessageLoading}
                            />
                        </>
                    )}
                </ContentLayout>
            </>
        );
    }

    handleSubmit = (e: any) => {
        e.preventDefault();
        const {
            actions: { sendMessage },
            conversationId
        } = this.props;

        const { form } = this.formRef.props;
        const { groupId } = this.props.match.params;

        form.validateFields((err: any, values: any) => {
            if (!err) {
                const body = {
                    content: values['lp-message-content']
                };
                sendMessage(groupId, conversationId, body);
                form.resetFields();
            }
        });
    };
}

const loadingSelector = createLoadingSelector([
    CONVERSATION_FETCH_OR_CREATE,
    CONVERSATION_FETCH
]);

const postMessageLoadingSelector = createLoadingSelector([MESSAGE_POST]);

const mapStateToProps = (state: any, props: any) => {
    return {
        user: state.user,
        conversationId: getConversationId(state, props),
        messages: getMessages(state, props),
        initialLoading: loadingSelector(state),
        postMessageLoading: postMessageLoadingSelector(state)
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        actions: bindActionCreators(
            {
                fetchOrCreateConversation,
                fetchMessages,
                sendMessage,
                startMessageTimer,
                stopMessageTimer,
                setMessageNotificationsAsConfirmed,
                setCurrentConversationId
            },
            dispatch
        )
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConversationPage);

const StyledMedicGroupName = styled.div`
    width: 100%;
    height: 30px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 5px;
    font-size: 14px;
    text-align: center;
    border-bottom: 1px solid rgba(232, 233, 232, 0.7);
`;

const StyledCustomImage = styled(CustomImage)`
    margin: auto;
    margin-bottom: -2px;
    margin-left: 0px;
    margin-right: 5px;
    height: 1rem;
    width: auto;
    fill: ${(props) => props.theme.chat.emergency.sticky.buttonColor};
`;

const StyledStickyWrapper = styled.div`
    background-color: ${(props) =>
        props.theme.chat.emergency.sticky.background};
`;

const StyledStickyRow = styled.div`
    background-color: ${(props) =>
        props.theme.chat.emergency.sticky.background};
    position: absolute;
    top: 50px;
    width: 100%;
    z-index: 100;
    margin: auto;
    margin-top: 30px;
`;

const StyledStickyButtonTextWrapper = styled.div`
    .CustomImage {
        margin-left: 0px;
        margin-right: 4px;
    }
`;

const StyledStickyText = styled.div`
    background-color: ${(props) =>
        props.theme.chat.emergency.sticky.background};
    color: ${(props) => props.theme.chat.emergency.sticky.textColor};
    padding: 3rem;
    padding-top: 2rem;
    padding-bottom: 1rem;
`;
