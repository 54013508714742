import { HTTPClient } from '../../../services/http-client/http-client';
import { conditionsNormalize } from './conditions.normalize';

export const getConditions = (subjectId: any) => {
    const params = {
        expand: 'indicators',
        subjectIds: subjectId
    };

    return HTTPClient.get('conditions', {
        params
    }).then(conditionsNormalize);
};
