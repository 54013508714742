import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { useReduxSelector } from '../../hooks/use-redux-selector';
import { fetchVideoMeetings } from '../../redux/domains/video/video.actions';
import { GroupListPage } from './group-list/GroupListPage';
import ConversationPage from './conversation/ConversationPage';
import { UserSettingHandler } from '../../models';
import { UserSettingKeys } from '../../models/user-settings/user-setting-keys';

type Props = {
    medicGroups: any;
    subjectId: string;
};

const ChatPage = () => {
    const medicGroups = useReduxSelector((state) => state.groups.medicGroups);
    const subjectId = useReduxSelector((state) => state.user.subjectId);
    const chatProps: Props = {
        medicGroups,
        subjectId
    };

    const { activeSessions } = useReduxSelector((state) => state.video);
    const videoMeetings = useReduxSelector((state) => state.chat.videoMeetings);
    const dispatch = useDispatch();

    // Fetch active video meetings when in initial state
    useEffect(
        function () {
            if (
                __APP_CONFIG__.FEATURE_VIDEO_MEETING &&
                activeSessions == null
            ) {
                dispatch(fetchVideoMeetings());
            }
        },
        [activeSessions, dispatch]
    );

    // Fetch active video meetings when a new video meeting message has arrived
    useEffect(
        function () {
            if (
                Object.values(videoMeetings).some(
                    ({ active }) => active == null
                )
            ) {
                dispatch(fetchVideoMeetings());
            }
        },
        [videoMeetings, dispatch]
    );

    const userChatSettingsHandler = new UserSettingHandler(
        subjectId,
        'message',
        UserSettingKeys.messageNotForEmergency,
        false,
        false
    );

    return (
        <Switch>
            <Route
                exact
                path="/chat/:groupId"
                render={(props) => (
                    <ConversationPage
                        {...chatProps}
                        {...props}
                        userChatSettingHandler={userChatSettingsHandler}
                    />
                )}
            />
            <Route exact path="/chat" render={(_) => <GroupListPage />} />
        </Switch>
    );
};

export default ChatPage;
