import { general } from './general';
import { status } from './status';
import { blood } from './blood';
import { color } from './color';
import { heatmap } from './heatmap';

export default {
    general,
    status,
    blood,
    color,
    heatmap
};
