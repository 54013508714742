import base from './base';
import heatmap from './chart/heatmap';
import graph from './chart/graph';
import ui from './ui';
import theme from './profile';
import { getRGBA } from './functions';

const colors = {
    base,
    chart: {
        heatmap,
        graph
    },
    ui
};

// leaving for backwards compatibility after renaming to colors
const color = {
    base,
    heatmap: heatmap,
    graph
};

export {
    colors as default,
    color,
    colors,
    theme,
    getRGBA,
    base,
    heatmap,
    graph,
    ui
};
