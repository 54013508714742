import { HTTPClient } from '../../../services/http-client/http-client';
import { notificationsNormalize } from './notifications.normalize';

export interface NormalizedObjects<T> {
    [id: string]: T;
}

export type Notification = {
    id: string;
    meta: any;
    notificationType:
        | 'watchover'
        | 'message'
        | 'reminder'
        | 'report_reminder'
        | 'feedback';
    receiver: string;
    sender: string;
    senderMeta: any;
    sentDate: number;
    tags: ['sent', 'notified', 'confirmed'];
    title: string;
};

export const getNotifications = (
    params: any
): Promise<NormalizedObjects<Notification>> =>
    HTTPClient.get('notifications', { params })
        .then(notificationsNormalize)
        .catch((error) => {
            console.error('Error in getNotifications\n', error);
            throw error;
        });

export const putNotifications = (data: any) =>
    HTTPClient.put('notifications', data)
        .then(notificationsNormalize)
        .catch((error) => {
            console.error('Error in putNotifications\n', error);
            throw error;
        });
