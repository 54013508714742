import graphColors from './graph-colors';

const baseUrl = (name) => {
    return 'ui/icon/comment/comment-icon-' + name + '.svg';
};

const defaultObj = {
    name: graphColors.blue.name,
    commentAssetUrl: baseUrl(graphColors.blue.name),
    default: graphColors.blue.primary
};

const bloodPressure = {
    name: graphColors.blue.name,
    commentAssetUrl: baseUrl(graphColors.blue.name),
    systolic: graphColors.blue.primary,
    diastolic: graphColors.blue.primaryDark
};

const bmi = {
    name: graphColors.black.name,
    commentAssetUrl: baseUrl(graphColors.black.name),
    default: graphColors.black.primary,
    bmi: graphColors.black.primary,
    weight: graphColors.orange.primary
};

const bodyWeight = {
    name: graphColors.red.name,
    commentAssetUrl: baseUrl(graphColors.red.name),
    default: graphColors.red.primary
};

const bodyTemp = {
    name: graphColors.red.name,
    commentAssetUrl: baseUrl(graphColors.red.name),
    default: graphColors.red.primary
};

const cat = {
    name: graphColors.green.name,
    commentAssetUrl: baseUrl(graphColors.green.name),
    default: graphColors.green.primaryDark,
    sum: graphColors.green.primaryDark
};

const ehfscbs = {
    name: graphColors.yellow.name,
    commentAssetUrl: baseUrl(graphColors.yellow.name),
    default: graphColors.yellow.primary,
    sum: graphColors.yellow.primary
};

const oxygenSaturation = {
    name: graphColors.orange.name,
    commentAssetUrl: baseUrl(graphColors.orange.name),
    default: graphColors.orange.primary
};

const painCi = {
    name: graphColors.blue.name,
    commentAssetUrl: baseUrl(graphColors.blue.name),
    rest: graphColors.blue.primary,
    active: graphColors.blue.primaryDark
};

const pulse = {
    name: graphColors.orange.name,
    commentAssetUrl: baseUrl(graphColors.orange.name),
    default: graphColors.orange.primary
};

const pumpFlowLvad = {
    name: graphColors.green.name,
    commentAssetUrl: baseUrl(graphColors.green.name),
    default: graphColors.green.forest
};

const pumpPowerLvad = {
    name: graphColors.green.name,
    commentAssetUrl: baseUrl(graphColors.green.name),
    default: graphColors.green.forestDarker
};

const pumpPulseIndexLvad = {
    name: graphColors.green.name,
    commentAssetUrl: baseUrl(graphColors.green.name),
    default: graphColors.green.forestDarkest
};

const pumpSpeedLvad = {
    name: graphColors.green.name,
    commentAssetUrl: baseUrl(graphColors.green.name),
    default: graphColors.green.forestBright
};

const vas = {
    default: graphColors.purple.primary,
    commentAssetUrl: baseUrl(graphColors.purple.name),
    name: graphColors.purple.name
};

const waistCircumference = {
    name: graphColors.green.name,
    commentAssetUrl: baseUrl(graphColors.green.name),
    default: graphColors.green.primary
};

const graphSeries = {
    defaultObj,
    bloodPressure,
    bmi,
    bodyWeight,
    bodyTemp,
    cat,
    ehfscbs,
    oxygenSaturation,
    painCi,
    pulse,
    pumpFlowLvad,
    pumpPowerLvad,
    pumpPulseIndexLvad,
    pumpSpeedLvad,
    vas,
    waistCircumference
};

export default graphSeries;
