import { Property } from '../../types/indicator/indicator.type';
import {
    IndicatorRecommendations,
    RecommendationsLimits
} from '../../types/indicator/indicator-recommendations.type';
import { FetchedIndicator } from '../../types/indicator/fetched-indicator.type';

export function getEnabledPropertiesForRecommendations(props: {
    indicator: FetchedIndicator;
    recommendations?: IndicatorRecommendations;
}): RecommendationsLimits<string[]> & { properties: string[] } {
    const limits = props.recommendations?.limits;
    const enabled = limits?.enabled || false;

    const properties =
        props.indicator.properties &&
        (Object.keys(props.indicator.properties) as Property[]);

    return { enabled, properties: limits?.properties || properties };
}
