import { defineMessages } from 'react-intl';

const error = defineMessages({
    noImage: {
        id: 'app.error.no-image',
        defaultMessage: 'No image'
    },
    report: {
        id: 'error.indicator.report',
        defaultMessage:
            'Report failed. Please try again in a few minutes. If the error persists please contact the support.'
    },
    reportOutsideInterval: {
        id: 'error.indicator.report-outside-interval',
        defaultMessage: 'Reported value is outside accepted interval.'
    },
    invalidCredentials: {
        id: 'error.invalid.credentials',
        defaultMessage: 'Could not log in - invalid username or password'
    },
    accountDeactivated: {
        id: 'error.account.deactivated',
        defaultMessage:
            'Could not log in - Account is deactivated, please contact your health care provider'
    }
});

const general = defineMessages({
    message: {
        id: 'error.general.message',
        defaultMessage: 'Something went wrong'
    },
    description: {
        id: 'error.general.description',
        defaultMessage: 'Try again later or please contact support'
    }
});

const http = {
    error400: defineMessages({
        message: {
            id: 'error.400.message',
            defaultMessage: 'Bad request'
        }
    }),
    error403: defineMessages({
        message: {
            id: 'error.403.message',
            defaultMessage: 'Forbidden action'
        },
        description: {
            id: 'error.403.description',
            defaultMessage:
                "You don't have permission to perform this operation"
        }
    })
};

export default {
    error,
    general,
    http
};
