import { HTTPClient } from '../../../services/http-client/http-client';
import { parseValue, valuesNormalize } from './values.normalize';

export const getValues = (params: any) =>
    HTTPClient.get('values', { params }).then(valuesNormalize);

export const getHistoryValues = (params: any) =>
    HTTPClient.get('values', { params }).then((data) => {
        if (!data.length) return [];

        return data[0].values.map(parseValue);
    });

export const deleteValue = (valueId: string, params: { subjectId: string }) =>
    HTTPClient.delete(`values/${valueId}`, { params });
