const black = {
    primary: '#000000',
    primaryBright: '#232323',
    primaryBrightAlt2: '#2a2a2a',

    primaryExtraBright: '#3F3F3F',
    primaryExtraBright2: '#383838',
    primaryExtraBright3: '#2f374a'
};

const white = {
    primary: '#fff',
    primaryCream: '#f3f1f1',
    secondary: '#e9e9e9'
};
const green = {
    primary: '#76DCA0',
    primaryDark: '#00A851',
    secondary: '#87FF85'
};
const lightGreen = {
    primary: '#cfffc5'
};
const blue = {
    primaryDark: '#5a63c2',
    oxfordBlue: {
        primary: '#2f374a'
    }
};
const yellow = {
    light: '#E5E26E',
    primary: '#EFEA17',
    pearlLusta: {
        primary: '#D6C453'
    },
    secondary: '#E6E712', //'#FFFF00'
    dark: '#B7A533'
};

const orange = {
    primary: '#ED8517'
};
const brown = {
    primary: '#A0522D'
};

const red = {
    primary: '#E8462C',
    secondary: '#ff0000'
};

const gray = {
    primary: '#8C8C8C',
    primaryExtraBright: '#EBE8E8',
    primaryDark: '#bcb9b9',
    secondary: '#999999',
    secondaryBright: '#ccc',
    secondaryDark: '#545454'
};

const purpule = {
    primary: '#8b67ce',
    primaryBright: '#7c57c1'
};

const base = {
    black,
    brown,
    blue,
    gray,
    green,
    lightGreen,
    orange,
    purpule,
    red,
    white,
    yellow
};

export default base;
