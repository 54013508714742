import { RouteType } from '../../routes/route.type';
import { profileRoute } from './pages/profile/route';
import { recommendationsRoute } from './pages/recommendations/route';
import { achievementsRoute } from './pages/achievements/route';
import { messageOfTheDayRoute } from './pages/message-of-the-day/route';
import { supportRoute } from './pages/support/route';
import { exportRoute } from './pages/export/route';
import { settingsRoute } from './pages/settings/route';
import { aboutRoute } from './pages/about/route';

export const otherRoutes: RouteType[] = [
    profileRoute,
    recommendationsRoute,
    achievementsRoute,
    messageOfTheDayRoute,
    supportRoute,
    exportRoute,
    settingsRoute,
    aboutRoute
];
