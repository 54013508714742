import { ChartEnum } from '../../../types/indicator/chart.types';
import { IndicatorPropertyRules } from '../../../types/indicator/indicator-rules.type';
import { colors } from '../../../theme/colors/common-colors';

export const rules: IndicatorPropertyRules<'systolic' | 'diastolic'> = {
    neutralReportResult: false,
    invertReportResponse: false,
    hideValueWhenReporting: false,
    step: 1,
    chartTypes: [ChartEnum.Spline],
    lineColor: colors.chart.graph.obj.bloodPressure,
    chartOptions: {
        hidePlotBand: true
    }
};

export default rules;
