import appDetails from './constant';
import constant from './constant';
import { achievements } from './achievements';
import { chat } from './chat';
import { chart } from './chart';
import { conditions } from '../../conditions/messages';
import { common } from './common';
import { communication } from './communication';
import { error } from './error';
import { form } from './form';
import { instructions } from './instructions';
import { labels } from './labels';
import { navigation } from './navigation';
import { notification } from './notification';
import { other } from './other';
import { placeholder } from './placeholder';
import { recommendation } from './recommendation';
import { screen } from './screen';
import { time } from './time';
import { ui } from './ui';
import { userSettings } from './user-settings';
import { validation } from './validation';
import { values } from './values';
import { video } from './video';

const Strings = {
    achievements,
    appDetails,
    chat,
    chart,
    conditions,
    common,
    communication,
    error,
    form,
    instructions,
    labels,
    navigation,
    notification,
    other,
    placeholder,
    recommendation,
    screen,
    time,
    ui,
    userSettings,
    validation,
    values,
    video,
    constant
};

export { Strings as default };
