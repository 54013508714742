import { Dispatch } from 'redux';
import { AppState } from '../../../redux/store/root-reducer';
import { getConditions } from './conditions.api';
import { CONDITIONS_FETCH, CONDITIONS_SELECT } from './conditions.constants';
import { ConditionCode } from './condtions.types';

export const fetchConditions =
    () => (dispatch: Dispatch<any>, getState: () => AppState) => {
        const { subjectId } = getState().user;

        return dispatch({
            type: CONDITIONS_FETCH,
            payload: getConditions(subjectId)
        });
    };

export const selectCondition = (code: ConditionCode) => ({
    type: CONDITIONS_SELECT,
    payload: code
});

export const previousCondition =
    () => (dispatch: Dispatch<any>, getState: () => AppState) => {
        const { conditions } = getState();
        const currentIndex = conditions.allCodes.indexOf(conditions.selected);

        if (currentIndex === 0) {
            return dispatch(
                selectCondition(
                    conditions.allCodes[conditions.allCodes.length - 1]
                )
            );
        }
        return dispatch(selectCondition(conditions.allCodes[currentIndex - 1]));
    };

export const nextCondition =
    () => (dispatch: Dispatch<any>, getState: () => AppState) => {
        const { conditions } = getState();
        const currentIndex = conditions.allCodes.indexOf(conditions.selected);

        if (currentIndex >= conditions.allCodes.length - 1) {
            return dispatch(selectCondition(conditions.allCodes[0]));
        }
        return dispatch(selectCondition(conditions.allCodes[currentIndex + 1]));
    };
