import { AppState } from '../../../redux/store/root-reducer';
import { Reminder } from './reminders.types';

export function getReminderKeysForCondition(
    reminders: Record<string, Reminder | undefined>,
    conditionId: string
) {
    return Object.keys(reminders).filter(
        (key) =>
            reminders[key]?.conditionIds != null &&
            reminders[key]?.conditionIds.includes(conditionId)
    );
}

export function getRemindersForSelectedCondition(state: AppState) {
    const reminders = state.reminders.byIndicatorId;
    const selectedConditionId =
        state.conditions.byCode[state.conditions?.selected].id;

    return getReminderKeysForCondition(reminders, selectedConditionId).reduce(
        (acc: Record<string, Reminder | undefined>, curr: string) => {
            acc[curr] = reminders[curr];
            return acc;
        },
        {}
    );
}
