import { defineMessages } from 'react-intl';

export const form = defineMessages({
    description: {
        id: 'export.form.description',
        defaultMessage: 'Choose which reported values to export'
    },
    downloadButton: {
        id: 'export.form.download',
        defaultMessage: 'Download as text file'
    }
});

export const meta = defineMessages({
    dateRange: {
        id: 'export.meta.date-range',
        defaultMessage: '{from} to {to}'
    },
    careUnit: {
        id: 'export.meta.care-unit',
        defaultMessage: 'Care unit'
    },
    condition: {
        id: 'export.meta.condition',
        defaultMessage: 'Condition'
    },
    indicator: {
        id: 'export.meta.indicator',
        defaultMessage: 'Parameter'
    }
});

export const columns = defineMessages({
    date: {
        id: 'export.column.date',
        defaultMessage: 'Date (YYYY-MM-DD)'
    },
    time: {
        id: 'export.column.time',
        defaultMessage: 'Time (HH:MM:SS)'
    },
    comment: {
        id: 'export.column.comment',
        defaultMessage: 'Comment'
    }
});
