export const VALUES_FETCH = 'VALUES_FETCH';
export const VALUES_FETCH_FULFILLED = 'VALUES_FETCH_FULFILLED';
export const VALUES_FETCH_PENDING = 'VALUES_FETCH_PENDING';
export const VALUE_DELETE = 'VALUE_DELETE';
export const VALUE_DELETE_PENDING = 'VALUE_DELETE_PENDING';
export const VALUE_DELETE_REJECTED = 'VALUE_DELETE_REJECTED';
export const VALUE_DELETE_FULFILLED = 'VALUE_DELETE_FULFILLED';
export const HISTORY_VALUES_FETCH = 'HISTORY_VALUES_FETCH';
export const HISTORY_VALUES_FETCH_PENDING = 'HISTORY_VALUES_FETCH_PENDING';
export const HISTORY_VALUES_FETCH_FULFILLED = 'HISTORY_VALUES_FETCH_FULFILLED';
export const HISTORY_VALUES_FETCH_REJECTED = 'HISTORY_VALUES_FETCH_REJECTED';
