import { defineMessages } from 'react-intl';

export const general = defineMessages({
    title: {
        defaultMessage: 'Self-care behaviour',
        id: 'indicator.ehfscbs.title'
    },
    titleLong: {
        defaultMessage: 'Self-care behaviour',
        id: 'indicator.ehfscbs.title-long'
    },
    description: {
        defaultMessage:
            'This form makes some statements about your own care. It shall be completed once a month. In the history you can see the last time you completed it. Please indicate your agreement to these statements in the form.',
        id: 'indicator.ehfscbs.description'
    },
    helpText: {
        defaultMessage:
            'Please indicate your agreement with each statement from 1 (fully agree) to 5 (do not agree at all).',
        id: 'indicator.ehfscbs.help-text'
    },
    sum: {
        defaultMessage: 'Score',
        id: 'indicator.ehfscbs.sum.title'
    },
    value: {
        defaultMessage: 'Score {sum, number, ::precision-integer}',
        id: 'indicator.ehfscbs.value'
    }
});
