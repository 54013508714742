import React from 'react';
import { styled } from '../../../theme/styled';

const ConversationStickyButton = ({ onClick, children }: any) => (
    <StyledMessageStickyButton onClick={onClick}>
        {children}
    </StyledMessageStickyButton>
);

const StyledMessageStickyButton = styled.div`
    background-color: ${(props) =>
        props.theme.chat.emergency.sticky.background}; // For IE11
    border-width: 0px;

    color: ${(props) => props.theme.chat.emergency.sticky.buttonColor};
    margin-bottom: 1px;
    cursor: pointer;
    text-align: center;
`;

export { ConversationStickyButton };
