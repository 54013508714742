import { defineMessages } from 'react-intl';

const heatmap = defineMessages({
    noReport: {
        id: 'chart.heatmap.legend.no-report',
        defaultMessage: 'No report'
    }
});

export default {
    heatmap: heatmap
};
