import React from 'react';
import { formatMessage } from '../../../../locale';
import { ProfilePage } from './ProfilePage';
import { StyledIcon } from '../../../../components/list/StyledIcon';
import { testSelectors } from '../../../../constants/test-id';
import { profile } from './message-descriptors';
import { RouteType } from '../../../../routes/route.type';
import { routePaths } from '../../../../constants/route-paths';

export const profileRoute: RouteType = {
    component: ProfilePage,
    title: formatMessage(profile.header.title),
    path: routePaths.profile,
    icon: <StyledIcon type="user" />,
    testId: testSelectors.nav.profileItem
};
