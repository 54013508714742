import { defineMessages } from 'react-intl';

const label = defineMessages({
    email: {
        id: 'labels.label.email',
        defaultMessage: 'E-mail'
    }
});

export const labels = { label };
