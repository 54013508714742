import { PatientResponseType } from './user.types.responses';

export const formatPersonalId = (personalId?: string) => {
    if (personalId == null) return '';

    personalId = personalId.replace('-', '');

    const length = personalId.length,
        position = length - 4;

    return personalId.length === 10 || personalId.length === 12
        ? personalId.substring(0, position) +
              '-' +
              personalId.substring(position, length)
        : personalId;
};

export function getConfigurations(data: PatientResponseType) {
    return data.conditions.reduce((acc: any, curr: any) => {
        acc[curr.code] = getIndicatorConfigurations(
            curr.indicatorConfigurations
        );
        return acc;
    }, {});
}

function getIndicatorConfigurations(indicators: any) {
    return indicators.reduce((acc: any, curr: any) => {
        acc[curr.indicatorCode] = curr.configuration;
        return acc;
    }, {});
}
