import { prnmed } from '../shared-indicators/prnmed/prnmed';
import { dizziness } from '../shared-indicators/dizziness/dizziness';
import { swelling } from '../shared-indicators/swelling/swelling';
import { bodyWeight } from '../shared-indicators/body-weight/body-weight';
import { bloodPressure } from '../shared-indicators/blood-pressure/blood-pressure';
import { pulse } from '../shared-indicators/pulse/pulse';
import { breathlessness } from '../shared-indicators/breathlessness/breathlessness';
import { tiredness } from '../shared-indicators/tiredness/tiredness';
import { waistCircumference } from '../shared-indicators/waist-circumference/waist-circumference';
import { vas } from '../shared-indicators/vas/vas';
import { physact } from '../shared-indicators/physact/physact';
import { ehfscbs } from './indicators/ehfscbs/ehfscbs';
import { eq5d3l } from '../shared-indicators/eq5d3l/eq5d3l';
import { formatMessage, Strings } from '../../locale';

const code = 'LPHF_REG_SKANE';
const publicCode = 'lphf';

const indicators = [
    prnmed,
    dizziness,
    swelling,
    bodyWeight,
    bloodPressure,
    pulse,
    breathlessness,
    tiredness,
    waistCircumference,
    vas,
    physact,
    ehfscbs,
    eq5d3l
];

const condition = {
    [code]: {
        code,
        indicators,
        publicCode,
        name: formatMessage(Strings.conditions.rs.hf.name),
        nameLong: formatMessage(Strings.conditions.rs.hf.nameLong)
    }
};

export default condition;
