import React from 'react';
import SVG from 'react-inlinesvg';
import { Props as SVGProps } from 'react-inlinesvg';

export const CustomSVG = (props: SVGProps): JSX.Element => {
    const loader: React.ReactNode = (
        <svg
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            width="512"
            height="512"
            viewBox="0 0 512 512"
        />
    );
    return <SVG loader={loader} {...props} />;
};
