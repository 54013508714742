import { defineMessages } from 'react-intl';

const placeholder = defineMessages({
    username: {
        id: 'placeholder.username',
        defaultMessage: 'Username'
    },
    password: {
        id: 'placeholder.password',
        defaultMessage: 'Password'
    },
    personalIdentityNumber: {
        id: 'placeholder.personal-identity-number',
        defaultMessage: 'yyyymmddnnnn'
    },
    comment: {
        id: 'placeholder.comment',
        defaultMessage: 'Comment'
    },
    commentHelpText: {
        id: 'placeholder.comment-help-text',
        defaultMessage:
            'Comment regarding the value.  \n If you have questions to your health care provider, please use the message function.'
    },
    oldPassword: {
        id: 'placeholder.enter.old.password',
        defaultMessage: 'Enter your old password'
    },
    newPassword: {
        id: 'placeholder.enter.new.password',
        defaultMessage: 'Enter your new password'
    },
    confirmPassword: {
        id: 'placeholder.confirm.new.password',
        defaultMessage: 'Confirm your new password'
    }
});

export default {
    placeholder
};
