import { defineMessages } from 'react-intl';

export const general = defineMessages({
    title: {
        defaultMessage: 'Exercises',
        id: 'indicator.exercises-hs.title'
    },
    description: {
        defaultMessage:
            'How many times have you done your exercises today? Report the total number of times at the end of the day.',
        id: 'indicator.exercises-hs.description'
    },
    value: {
        defaultMessage: '{value}',
        id: 'indicator.exercises-hs.value'
    }
});
