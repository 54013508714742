import { bloodPressure } from '../shared-indicators/blood-pressure/blood-pressure';
import { waistCircumference } from '../shared-indicators/waist-circumference/waist-circumference';
import { vas } from '../shared-indicators/vas/vas';
import { swelling } from '../shared-indicators/swelling/swelling';
import { smoke } from '../shared-indicators/smoke/smoke';
import { spinalCordInjury01 } from './messages/messages';
import { formatMessage } from '../../locale';
import { bodyWeight } from '../shared-indicators/body-weight/body-weight';
import { oxygenSaturation } from './indicators/oxygen-saturation/oxygen-saturation';
import { bodyTemp } from '../shared-indicators/body-temp/body-temp';

const code = 'LP_2_0_SPINAL_CORD_INJURY_01';
const publicCode = 'SPINAL_CORD_INJURY';

const indicators = [
    bloodPressure,
    bodyTemp,
    waistCircumference,
    bodyWeight,
    vas,
    swelling,
    smoke,
    oxygenSaturation
];

const condition = {
    [code]: {
        code,
        indicators,
        publicCode,
        name: formatMessage(spinalCordInjury01.name),
        nameLong: formatMessage(spinalCordInjury01.nameLong)
    }
};

export default condition;
