import { defineMessages } from 'react-intl';

export const general = defineMessages({
    title: {
        defaultMessage: 'Pump self test',
        id: 'indicator.pump-self-test-lvad.title'
    },
    question: {
        defaultMessage: "Was the pump's self-test successful?",
        id: 'indicator.pump-self-test-lvad.question'
    },
    value: {
        defaultMessage:
            '{value, select, true {Self-test ok} false {Self-test not ok}}',
        id: 'indicator.pump-self-test-lvad.value'
    }
});
