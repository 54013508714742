import { defineMessages } from 'react-intl';

export const general = defineMessages({
    title: {
        defaultMessage: 'Use of orthosis during the night',
        id: 'indicator.orthosis-night-hs.title'
    },
    question: {
        defaultMessage: 'Did you use orthosis last night?',
        id: 'indicator.orthosis-night-hs.question'
    },
    value: {
        defaultMessage:
            '{value, select, 1 {Yes, all night} 2 {Part of the night} 3 {No}}',
        id: 'indicator.orthosis-night-hs.value'
    }
});
