import { heatmap } from './messages/heatmap';
import { ChartEnum } from '../../../types/indicator/chart.types';
import {
    IndicatorHeatmapRules,
    IndicatorRules
} from '../../../types/indicator/indicator-rules.type';
import { colors } from '../../../theme/colors/common-colors';

type PhlegmValue = {
    status: number;
    color?: number;
    blood?: boolean;
};

export const rules: IndicatorRules & IndicatorHeatmapRules<PhlegmValue> = {
    neutralReportResult: true,
    invertReportResponse: false,
    hideValueWhenReporting: false,
    step: 1,
    chartTypes: [ChartEnum.Heatmap],
    toHeatmapValue: ({ color = 0, blood = false }) => (blood ? 5 : color + 1),
    heatmapPieces: [
        {
            value: 1,
            color: colors.chart.heatmap.purple10,
            text: heatmap.none
        },
        {
            value: 2,
            color: colors.chart.heatmap.purple30,
            text: heatmap.colorless
        },
        {
            value: 3,
            color: colors.chart.heatmap.purple50,
            text: heatmap.yellow
        },
        {
            value: 4,
            color: colors.chart.heatmap.purple60,
            text: heatmap.green
        },
        {
            value: 5,
            color: colors.chart.heatmap.purple80,
            text: heatmap.withBlood
        }
    ]
};

export default rules;
