export const normalizeConversations = (conversationList: any) => {
    const conversations = conversationList.reduce((acc: any, curr: any) => {
        const tag = curr.tags.find((tag: any) => tag.key === 'groupId');
        if (tag) {
            acc[tag.value] = curr;
        }
        return acc;
    }, {});

    const allIds = Object.keys(conversations);

    return { conversationList, conversations, allIds };
};
