import { useSelector } from 'react-redux';
import { getCondition } from '../redux/domains/conditions/conditions.utils';
import { getSelectedConditionCode } from '../redux/domains/conditions/conditions.selectors';

export const useCondition = () => {
    const code = useSelector(getSelectedConditionCode);
    if (code) {
        return getCondition(code);
    }
    throw new Error('No selected condition');
};
