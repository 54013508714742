import { applyMiddleware, compose, createStore } from 'redux';
import { rootReducer } from './root-reducer';
import thunk from 'redux-thunk';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import promise from 'redux-promise-middleware';
import reduxLocalstorageJwtMiddleware from './middlewares/redux-localstorage-jwt-middleware';
import reduxTimerMiddleware from './middlewares/redux-timer-middleware';
import timerMiddleware from 'redux-timer-middleware';
import { composeWithDevTools } from 'redux-devtools-extension';

function configureStoreDev() {
    const middlewares = [
        reduxImmutableStateInvariant(),
        thunk,
        promise,
        reduxLocalstorageJwtMiddleware(),
        reduxTimerMiddleware(),
        timerMiddleware
        // logger
    ];

    const store = createStore(
        rootReducer,
        {},
        composeWithDevTools(applyMiddleware(...middlewares))
    );

    return store;
}

function configureStoreProd() {
    const middlewares = [
        thunk,
        promise,
        reduxLocalstorageJwtMiddleware(),
        reduxTimerMiddleware(),
        timerMiddleware
    ];

    return createStore(
        rootReducer,
        {},
        compose(applyMiddleware(...middlewares))
    );
}

export const configureStore =
    process.env.NODE_ENV === 'production'
        ? configureStoreProd
        : configureStoreDev;
