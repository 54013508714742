import { formatMessage as t } from '../../locale';
import { getCamelCase } from '../../utils/functions/index';
import AchievementHelper from './achievement-support/achievement-helper';
import { getAchievementCalculation } from './achievement-support/achievement-calculation';
import {
    AchievementImageType,
    AchievementLocaleType,
    AchievementResponseType
} from './achievement-support/achievement-model-types';
import achievement_images from '../../static/images/achievements/index';
import {
    achievementDefaults,
    achievementLocale
} from './achievement-support/achievement-constants';
import { getAchievementByCategoryType } from './get-achievement-message';
import React from 'react';
import { MarkdownFromDescriptor } from '../../locale/utils/functions/format-markdown';

export class AchievementModel {
    private _achievementHelper = new AchievementHelper();

    private _category = achievementDefaults.defaultCategory;
    private _categoryType = achievementDefaults.defaultCategoryType;

    private _currentValue = 0;
    private _milestonesArray: Array<Array<number>>;
    private _minorArray: Array<number>;
    private _achievementLocale?: AchievementLocaleType;

    private _achievedMajor = false;
    private _achievedMinor = false;
    private _majorIndex = 0;
    private _minorIndex = 0;
    private _untilNextLevel = 0;
    private _milestoneType = achievementDefaults.defaultMilestoneType;

    // Modal START
    private _showAchievementModal = false;
    private _currentIndex = 0; // needed for modal image

    getValues(): AchievementResponseType {
        return {
            category: this.getCategory(),
            type: this.getCategoryType(),
            sum: {
                value: this.getCurrentValue(),
                targets: this._milestonesArray
            }
        };
    }

    getCurrentIndex() {
        return this._currentIndex;
        // return this._majorIndex;
    }

    setCurrentIndex(index: number) {
        this._currentIndex = index;
        // this._majorIndex = index;
    }

    resetAchievement() {
        this._showAchievementModal = false;
        // this._majorIndex = 0;
        this._currentIndex = 0;
    }

    handleOnOkAchievement() {
        this.resetAchievement();
    }

    getAchievementModalDetails() {
        const details = this.getModalDetails();
        const details_ = {
            title: details.title,
            body: details.body,
            avatarUrl: this._getModalImg(this._majorIndex).src,
            milestoneType: details.milestoneType,
            handleOnOkAchievement: this.handleOnOkAchievement,
            showAchievementModal: this._showAchievementModal
        };
        return details_;
    }

    // Modal END

    constructor(
        category = achievementDefaults.defaultCategory,
        type = achievementDefaults.defaultCategoryType,
        value = 0,
        targets: Array<Array<number>> = []
    ) {
        this._category = category;
        this._categoryType = type;

        this._currentValue = value;
        this._milestonesArray = targets;
        this._minorArray = targets[0];

        this._initValues();
    }

    private _initValues() {
        this.setLocaleObj();
        this._calculateIfAchieved();
        this._calculatedLevel();
    }

    private _setMajorAchieved(index: number) {
        this._achievedMajor = true;
        this._achievedMinor = false;
        this._majorIndex = index;
        this._minorIndex = 0;
        this._milestoneType = achievementDefaults.milestoneMajor;
    }

    private _setMinorAchieved(index: number) {
        this._achievedMajor = false;
        this._achievedMinor = true;
        this._majorIndex = achievementDefaults.initInt;
        this._minorIndex = index;
        this._milestoneType = achievementDefaults.milestoneMinor;
    }

    private _resetAchievement() {
        this._achievedMajor = false;
        this._achievedMinor = false;
        this._majorIndex = achievementDefaults.initInt;
        this._minorIndex = achievementDefaults.initInt;
        this._milestoneType = achievementDefaults.defaultMilestoneType;
    }

    private _calculateIfAchieved() {
        this._resetAchievement();

        this._milestonesArray.forEach(
            (minorArray: Array<number>, majorIndex: number) => {
                minorArray.forEach((minorValue: number, minorIndex: number) => {
                    if (
                        minorValue >
                        this._achievementHelper.getLastInList(
                            this._achievementHelper.getLastInList(
                                this._milestonesArray
                            )
                        )
                    ) {
                        this._resetAchievement();
                        return;
                    }
                    if (minorValue === this.getCurrentValue()) {
                        if (
                            minorValue ===
                            this._achievementHelper.getLastInList(minorArray)
                        ) {
                            this._setMajorAchieved(majorIndex);
                            return;
                        }
                        this._setMinorAchieved(minorIndex);
                        return;
                    }
                });
            }
        );
    }

    private _isFirstTarget() {
        if (
            this._milestonesArray[0] &&
            this._milestonesArray[0][0] === this.getCurrentValue()
        ) {
            return true;
        }
        return false;
    }

    private _isMinorTarget() {
        if (this._achievedMinor && !this._achievedMajor) {
            return true;
        }
        return false;
    }

    private _getCamelStrings() {
        const category = getCamelCase({ string: this._category });
        const type = getCamelCase({ string: this._categoryType });

        return { category, type };
    }

    private _calculatedLevel() {
        return this._milestonesArray.find((minors, majorIndex: number) => {
            return minors.some((minorStone: number) => {
                if (this.getCurrentValue() <= minorStone) {
                    this._majorIndex = majorIndex;
                    this._minorArray = minors;
                    this._untilNextLevel = minorStone - this.getCurrentValue();
                }
                return this.getCurrentValue() <= minorStone;
            });
        });
    }

    private _getMilestonesArray(): Array<Array<number>> {
        return this._milestonesArray;
    }

    private _trySetLocaleObj() {
        const categoryType = this._getCamelStrings();
        let _achievementLocale = achievementLocale;

        if (
            this._achievementHelper.stringsAreValid(
                categoryType.category,
                categoryType.type
            )
        ) {
            try {
                _achievementLocale = getAchievementByCategoryType(
                    categoryType.category,
                    categoryType.type
                );
            } catch (error) {
                console.warn(
                    'Achievement type does not exist',
                    this._category,
                    this._categoryType
                );
            }
        }

        return _achievementLocale;
    }

    private _getProgressText(): string {
        const calc = getAchievementCalculation(
            this._currentValue,
            this._majorIndex,
            this._milestonesArray,
            this._minorArray
        );

        let progressText = `${calc.current}/${calc.max}`;

        // milestoneIsCompleted
        if (calc.milestoneCompleted && !calc.achievementCompleted) {
            progressText = `${calc.current}/${calc.max}`;
        }

        // achievementIsCompleted
        if (calc.milestoneCompleted && calc.achievementCompleted) {
            progressText = '';
        }

        // achievementIsFinished
        if (calc.achievementFinished) {
            progressText = ``;
        }

        return progressText;
    }

    private _getEncourageText(): string {
        const calc = getAchievementCalculation(
            this._currentValue,
            this._majorIndex,
            this._milestonesArray,
            this._minorArray
        );
        const _achievementLocale = this.getLocaleObj();

        const num = { num: this._untilNextLevel || 0 };
        let encourageText = t(_achievementLocale.encourage.untilCompleted, num);

        if (this._untilNextLevel === 0) {
            encourageText = t(
                _achievementLocale.encourage.minorMilestoneCompleted
            );
        }

        // milestoneIsCompleted
        if (calc.milestoneCompleted && !calc.achievementCompleted) {
            encourageText = t(_achievementLocale.encourage.milestoneCompleted);
        }

        // achievementIsCompleted
        if (calc.milestoneCompleted && calc.achievementCompleted) {
            encourageText = t(
                _achievementLocale.encourage.achievementCompleted
            );
        }

        // achievementIsFinished
        if (calc.achievementFinished) {
            encourageText = t(
                _achievementLocale.encourage.achievementCompleted
            );
        }
        return encourageText;
    }

    private _getPercentageText(): number {
        const calc = getAchievementCalculation(
            this._currentValue,
            this._majorIndex,
            this._milestonesArray,
            this._minorArray
        );
        return calc.percent;
    }

    private _getMajorAchievementImage(index: number): any {
        const achievementsImg: any = achievement_images;
        return achievementsImg.reportCounter.totalCounter[`milestone${index}`]
            .src;
    }

    private _getMinorAchievementImage(
        index: number = this.getMajorIndex()
    ): any {
        const achievementsImg: any = achievement_images;
        return achievementsImg.reportCounter.totalCounter[`milestone${index}`][
            `minor0`
        ].src;
    }

    private _getDetailsTitleText(): string {
        return t(this.getLocaleObj().details.title);
    }

    private _getDetailsBodyText(): string {
        if (this._isFirstTarget()) {
            return t(this.getLocaleObj().encourage.first);
        }

        if (this._isMinorTarget()) {
            return t(this.getLocaleObj().encourage.minorMilestoneCompleted);
        }

        return t(this.getLocaleObj().details.body);
    }

    private getLocaleObj(): AchievementLocaleType {
        if (this._achievementLocale == null) {
            console.warn('NO LOCALE OBJ');
            this._achievementLocale = this._trySetLocaleObj();
        }
        return this._achievementLocale;
    }

    private _getMinorMilestones(): Array<number> {
        return this._minorArray;
    }

    private _isAchieved(index: number): boolean {
        return this.getCurrentValue() >= index;
    }

    private _getMilestoneType(): string {
        return this._milestoneType;
    }

    private _getInfoText(): {
        title: JSX.Element | null;
        body: JSX.Element | null;
    } {
        const localeObj = this.getLocaleObj();

        return {
            title: (
                <MarkdownFromDescriptor
                    messageDescriptor={localeObj.info.title}
                    paragraph="div"
                />
            ),
            body: (
                <MarkdownFromDescriptor
                    messageDescriptor={localeObj.info.body}
                />
            )
        };
    }

    private _getMajorMilestones(): Array<number> {
        return this._getMilestonesArray().map((majors) => {
            return this._achievementHelper.getLastInList(majors);
        });
    }

    private _getAchievedMajor() {
        return this._achievedMajor;
    }

    private _getAchievedMinor() {
        return this._achievedMinor;
    }

    getCurrentValue() {
        return this._currentValue;
    }

    setLocaleObj(achievementLocale?: AchievementLocaleType) {
        if (achievementLocale != null) {
            this._achievementLocale = achievementLocale;
            return;
        }
        this._achievementLocale = this._trySetLocaleObj();
    }

    getMajorIndex(): number {
        return this._majorIndex;
    }

    setResponseValues(value: AchievementResponseType) {
        if (value != null) {
            this._category = value.category;
            this._categoryType = value.type;
            this._currentValue = value.sum.value;
            this._milestonesArray = value.sum.targets;
        } else {
            this._category = achievementDefaults.defaultCategory;
            this._categoryType = achievementDefaults.defaultCategoryType;
            this._currentValue = 0;
            this._milestonesArray = [];
        }

        this._initValues();
    }

    _getModalImg(index: number = this.getMajorIndex()): AchievementImageType {
        if (this._milestoneType === achievementDefaults.milestoneMinor) {
            return this._achievementHelper.getMinorImagePath(
                this._category,
                this._categoryType,
                index
            );
        }
        return this._achievementHelper.getMajorImagePath(
            this._category,
            this._categoryType,
            index
        );
    }

    getModalDetails() {
        return {
            title: this._getDetailsTitleText(),
            body: this._getDetailsBodyText(),
            milestoneType: this._getMilestoneType()
        };
    }

    getCardText(): {
        title: string;
        info: { title: JSX.Element | null; body: JSX.Element | null };
    } {
        return {
            title: t(this.getLocaleObj().milestone.title),
            info: this._getInfoText()
        };
    }

    getCardContentText() {
        return {
            encourage: this._getEncourageText(),
            percentage: this._getPercentageText(),
            progress: this._getProgressText()
        };
    }

    getMajorMilestonesData() {
        const majorStones = this._getMajorMilestones();
        if (majorStones != null) {
            return majorStones.map((majorStone, idx) => {
                return {
                    index: idx,
                    stone: majorStone,
                    isMinor: false,
                    isAchieved: this._isAchieved(majorStone),
                    img: this._getMajorAchievementImage(idx)
                };
            });
        }
        return [];
    }

    getMinorMilestonesData() {
        const minorStones = this._getMinorMilestones();
        if (minorStones != null) {
            return minorStones.map((minorStone, idx) => {
                return {
                    index: idx,
                    stone: minorStone,
                    isMinor: true,
                    isAchieved: this._isAchieved(minorStone),
                    img: this._getMinorAchievementImage(this.getMajorIndex())
                };
            });
        }
        return [];
    }

    getCategory() {
        return this._category;
    }

    getCategoryType() {
        return this._categoryType;
    }

    anyAchieved(): boolean {
        return this._getAchievedMajor() || this._getAchievedMinor();
    }
}
