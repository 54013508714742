import { FC } from 'react';
import {
    FetchedIndicator,
    FetchedIndicatorProperties
} from './fetched-indicator.type';

export type ReportTile = {
    form: FC<FormProps>;
    type: TileType;
    feedback: FeedbackType;
};

export type FormProps = {
    indicator: FetchedIndicator | FetchedIndicatorProperties;
};

export enum TileType {
    Hidden,
    Dimming,
    NoDimming
}

export enum FeedbackType {
    Checkmark,
    None,
    Smiley,
    SmileyInverted
}
