import {
    USER_FETCH,
    USER_UPDATE,
    USER_SETTINGS_UPDATE
} from './user.constants';
import {
    FetchUserAction,
    UpdateUserAction,
    UpdateUserSettingsAction,
    UserUpdateType
} from './user.types';
import { getUser, putUser, putSettings } from './user.api';
import { UserSettingsType } from './user.settings.types';

export const fetchUser = (subjectId: string): FetchUserAction => ({
    type: USER_FETCH,
    payload: getUser(subjectId)
});

export const updateUser = (
    subjectId: string,
    data: UserUpdateType
): UpdateUserAction => ({
    type: USER_UPDATE,
    payload: putUser(subjectId, data)
});

export const updateUserSettings = (
    subjectId: string,
    settings: UserSettingsType
): UpdateUserSettingsAction => ({
    type: USER_SETTINGS_UPDATE,
    payload: putSettings(subjectId, settings)
});
