import { defineMessages } from 'react-intl';

export const aoRS = defineMessages({
    name: {
        defaultMessage: 'Aortic Dissection',
        id: 'conditions.LP_2_0_AO_RS.name'
    },
    nameLong: {
        defaultMessage: 'Aortic Dissection',
        id: 'conditions.LP_2_0_AO_RS.name-long'
    }
});
