import { defineMessages } from 'react-intl';

export const general = defineMessages({
    title: {
        defaultMessage: 'Medication',
        id: 'indicator.medication-ci.title'
    },
    question: {
        defaultMessage: 'Have you taken your prescribed medication?',
        id: 'indicator.medication-ci.question'
    },
    value: {
        defaultMessage:
            'Medication to prevent high blood lipid: {antiLipoproteins}\nAnticoagulant: {anticoagulant}',
        id: 'indicator.medication-ci.value'
    }
});
