import React from 'react';
import Button, { ButtonProps } from 'antd/lib/button';
import { styled } from '../../../theme/styled';

export const ButtonLarge = (props: ButtonProps) => {
    return (
        <Button
            {...props}
            style={{ fontSize: '16px', ...props.style }}
            size={'large'}
        >
            {props.children}
        </Button>
    );
};

export const ButtonLargePrimary = (props: ButtonProps) => {
    return (
        <ButtonLarge {...props} type={'primary'}>
            {props.children}
        </ButtonLarge>
    );
};

export const ButtonLargeRound = (props: ButtonProps) => (
    <StyledButtonLargeRound shape="circle" size="large" {...props} />
);

const StyledButtonLargeRound = styled(ButtonLarge)`
    // Center the icon
    && > i {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;
