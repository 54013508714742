import { defineMessages } from 'react-intl';

export const color = defineMessages({
    value: {
        defaultMessage: '{value, select, 1 {No color} 2 {Yellow} 3 {Green}}',
        id: 'indicator.phlegm.color.value'
    },
    question: {
        defaultMessage: 'Phlegm color',
        id: 'indicator.phlegm.color.description'
    },
    valueNoColor: {
        defaultMessage: 'No color',
        id: 'indicator.phlegm.color.choices.1'
    },
    valueYellow: {
        defaultMessage: 'Yellow',
        id: 'indicator.phlegm.color.choices.2'
    },
    valueGreen: {
        defaultMessage: 'Green',
        id: 'indicator.phlegm.color.choices.3'
    }
});
