import { bloodPressure } from '../../shared-indicators/blood-pressure/blood-pressure';
import { pulse } from '../../shared-indicators/pulse/pulse';
import { bodyWeight } from '../../shared-indicators/body-weight/body-weight';
import { vas } from '../../shared-indicators/vas/vas';
import { physact } from '../../shared-indicators/physact/physact';
import { dizziness } from '../../shared-indicators/dizziness/dizziness';
import { swelling } from '../../shared-indicators/swelling/swelling';
import { tiredness } from '../../shared-indicators/tiredness/tiredness';
import { breathlessness } from '../../shared-indicators/breathlessness/breathlessness';
import { smoke } from '../../shared-indicators/smoke/smoke';
import { formatMessage } from '../../../locale';
import { hypertension01 } from './messages/messages';

const code = 'LP_2_0_HYPERTENSION_01';
const publicCode = 'HYPERTENSION';

const indicators = [
    bloodPressure,
    pulse,
    dizziness,
    bodyWeight,
    swelling,
    tiredness,
    breathlessness,
    physact,
    vas,
    smoke
];

const condition = {
    [code]: {
        code,
        indicators,
        publicCode,
        name: formatMessage(hypertension01.name),
        nameLong: formatMessage(hypertension01.nameLong)
    }
};

export default condition;
