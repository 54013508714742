import { AppState } from '../../../redux/store/root-reducer';
import {
    HISTORY_VALUES_FETCH,
    VALUES_FETCH,
    VALUE_DELETE
} from './values.constants';
import { deleteValue, getHistoryValues, getValues } from './values.api';

export const fetchValues =
    (params: any) => (dispatch: any, getState: () => AppState) => {
        const { subjectId } = getState().user;
        params = { ...params, subjectId };

        return dispatch({
            type: VALUES_FETCH,
            payload: getValues(params)
        });
    };

export const fetchHistoryValues =
    (params: any) => (dispatch: any, getState: () => AppState) => {
        const { subjectId } = getState().user;
        const conditionCode = getState().conditions.selected;
        params = { ...params, subjectId, aggregateBy: 'subject' };

        return dispatch({
            type: HISTORY_VALUES_FETCH,
            payload: getHistoryValues(params),
            meta: { conditionCode, limit: params.limit }
        });
    };

export const removeValue =
    (params: any, valueId: string, indicatorId: string) =>
    (dispatch: any, getState: () => AppState) => {
        const { subjectId } = getState().user;

        return dispatch({
            type: VALUE_DELETE,
            payload: deleteValue(valueId, {
                subjectId
            }),
            meta: {
                valueId,
                indicatorId
            }
        });
    };

export const fetchLatestValuesForEachIndicator = () =>
    fetchValues({ limit: 1 });
