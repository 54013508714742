import { IndicatorProperties } from '../../../types/indicator/indicator.type';
import indicatorRules from './blood-pressure-rules';
import messages from './messages';
import icon from './images/blood-pressure.svg';
import { TileType, FeedbackType } from '../../../types/indicator/report.type';
import { MultiSliderForm } from '../../../app/report/forms/slider/MultiSliderForm';

const properties = {
    systolic: 'systolic',
    diastolic: 'diastolic'
};

export const bloodPressure: IndicatorProperties<keyof typeof properties> = {
    code: 'BLOOD-PRESSURE',
    properties,
    icon,
    messages,
    hiddenHistory: false,
    hiddenStatistics: false,
    recommendations: {
        limits: {
            enabled: true
        },
        reminder: {
            moreOften: true,
            enabled: true
        },
        info: {
            enabled: false
        }
    },
    rules: indicatorRules,
    tile: {
        report: {
            form: MultiSliderForm,
            type: TileType.Dimming,
            feedback: FeedbackType.Smiley
        }
    }
};
