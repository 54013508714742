import { defineMessages } from 'react-intl';

export const limitation = defineMessages({
    title: {
        defaultMessage: 'Limitation',
        id: 'indicator.cat.limitation.title'
    },
    maxValueDescription: {
        defaultMessage: 'I am very limited doing activities at home',
        id: 'indicator.cat.limitation.max-text'
    },
    minValueDescription: {
        defaultMessage: 'I am not limited doing any activities at home',
        id: 'indicator.cat.limitation.min-text'
    }
});
