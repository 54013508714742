import { styled } from '../../../../theme/styled';

export const StyledText = styled.div`
    ul {
        text-align: left;
        margin-bottom: 0;
    }
    p {
        margin-bottom: 0;
    }
`;

export const gradient =
    'linear-gradient(175deg, rgba(170,209,255,0.8) 0%, rgba(210,229,251,1) 21%, rgba(234,244,255,1) 100%)';
