import { defineMessages } from 'react-intl';
import { AchievementLocaleType } from '../../../../../models/achievement/achievement-support/achievement-model-types';

const info = defineMessages({
    title: {
        id: `achievements.report-counter.total-counter.info.title`,
        defaultMessage: `**Report Counter**  \nHow to`,
        description: 'Milestone Title'
    },
    body: {
        id: `achievements.report-counter.total-counter.info.body`,
        defaultMessage: `The Report Counter is counting your report activity.  \nThe more you report the higher your score gets.\n\n**Goals**  \nThere are four levels to achieve.  \nEach level have part goals.\n\nThe progress of each level is represented by small coins, and when a coin is achieved you will unlock an achievement card.\n\nWhen all coins are filled you can revisit and view the card at any time.`,
        description: 'Milestone Title'
    }
});

const milestone = defineMessages({
    title: {
        id: `achievements.report-counter.total-counter.milestone.title`,
        defaultMessage: `Report Counter`,
        description: 'Milestone Title'
    }
});

const details = defineMessages({
    title: {
        id: `achievements.report-counter.total-counter.details.title`,
        defaultMessage: `Report Counter`,
        description: 'Details title'
    },
    body: {
        id: `achievements.report-counter.total-counter.details.body`,
        defaultMessage: `Congratulations, you have reached a minor level! Your commitment is important!`,
        description: 'Details body'
    }
});

const encourage = defineMessages({
    untilCompleted: {
        id: `achievements.report-counter.total-counter.milestone.encourage.until-completed`,
        defaultMessage:
            '{num, plural, one {Report one more time to reach the next level.} other {Report {num} times more to reach the next level.}}',
        description: 'Milestone Title'
    },
    milestoneCompleted: {
        id: `achievements.report-counter.total-counter.milestone.encourage.milestone-completed`,
        defaultMessage: `You have reached the next level. Keep reporting! You make a differance!`,
        description: 'Milestone keep going'
    },
    minorMilestoneCompleted: {
        id: 'achievements.report-counter.total-counter.milestone.encourage.minor-milestone-completed',
        defaultMessage: 'Level progress - Good work!'
    },
    achievementCompleted: {
        id: `achievements.report-counter.total-counter.milestone.encourage.achievement-completed`,
        defaultMessage:
            'Congratulations, you have completed the final level! Good work!',
        description: 'Milestone keep going'
    },
    first: {
        id: `achievements.report-counter.total-counter.milestone.encourage.first`,
        defaultMessage:
            'Thank you for reporting. Your effort is important and your commitment makes a difference. \n\nYou are receiving this message because you have been diligent and have reported several times, for more information navigate to the achievement page.',
        description: 'Milestone keep going'
    }
});

const totalCounter: AchievementLocaleType = {
    milestone,
    details,
    encourage,
    info
};

export { totalCounter };
