import { actionTypes as authenticationActionTypes } from '../authentication/authentication.constants';
import * as userActionTypes from './user.constants';
import { UserState } from './user.types';
import { getUserSettings } from './user.api';

const initialState: UserState = {
    subjectId: '',
    username: '',
    first_name: '',
    family_name: '',
    email: '',
    phone_number: '',
    personal_id: '',
    configurations: {},
    settings: {}
};

export function userReducer(state = initialState, action: any): UserState {
    switch (action.type) {
        case authenticationActionTypes.AUTHENTICATION_LOGIN_FULFILLED:
            return {
                ...state,
                subjectId: action.payload.uuid
            };
        case authenticationActionTypes.AUTHENTICATION_CHECK_FULFILLED: {
            return {
                ...state,
                subjectId: action.payload.externalId
            };
        }
        case authenticationActionTypes.AUTHENTICATION_LOGOUT_FULFILLED:
            return initialState;
        case userActionTypes.USER_FETCH_FULFILLED: {
            const {
                name: first_name,
                externalId: subjectId,
                ...rest
            } = action.payload;
            return { ...state, first_name, subjectId, ...rest };
        }
        case userActionTypes.USER_UPDATE_FULFILLED: {
            const {
                name: first_name,
                externalId: subjectId,
                ...rest
            } = action.payload;
            return { ...state, first_name, subjectId, ...rest };
        }
        case userActionTypes.USER_SETTINGS_UPDATE_FULFILLED: {
            return {
                ...state,
                settings: getUserSettings(action.payload.settings)
            };
        }
        default:
            return state;
    }
}
