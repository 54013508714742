import {
    VIDEO_MEETINGS_FETCH,
    VIDEO_MEETING_JOIN,
    VIDEO_MEETING_LEAVE
} from './video.constants';
import {
    getActiveVideoSessions,
    createVideoConnection,
    deleteVideoSession
} from './video.api';
import { AppState } from '../../../redux/store/root-reducer';
import { VideoState } from './video.reducer';
import { VideoSession } from '@lifepod-legacy/openvidu-frontend/dist/api/video-api';

export const fetchVideoMeetings =
    () => (dispatch: any, getState: () => AppState) => {
        const subjectId: string = getState().user.subjectId;

        return dispatch({
            type: VIDEO_MEETINGS_FETCH,
            payload: getActiveVideoSessions(subjectId)
        });
    };

export const joinVideoMeeting = (session: VideoSession) => (dispatch: any) =>
    dispatch({
        type: VIDEO_MEETING_JOIN,
        payload: createVideoConnection(session)
    });

export const leaveVideoMeeting =
    (sessionId: string, othersHaveLeft: boolean) =>
    (dispatch: any, getState: () => AppState) => {
        const { activeSessions = [] }: VideoState = getState().video;
        const session = activeSessions.find(
            (session) => session.id === sessionId
        );

        const deleted =
            othersHaveLeft && session && deleteVideoSession(session);

        dispatch({
            type: VIDEO_MEETING_LEAVE,
            payload: Promise.resolve(deleted)
        });
    };
