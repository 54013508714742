import { defineMessages } from 'react-intl';

export const legend = defineMessages({
    na: {
        defaultMessage: 'I do not have this medication',
        id: 'indicator.medication-ci.legend.na'
    },
    no: {
        defaultMessage: 'Medicines have not been taken',
        id: 'indicator.medication-ci.legend.no'
    },
    yes: {
        defaultMessage: 'Medicines have been taken',
        id: 'indicator.medication-ci.legend.yes'
    }
});
