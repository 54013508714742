import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { LoginMethods } from './LoginMethods';
import { routePaths } from '../../constants/route-paths';
import { LoginEmail } from './LoginEmail';
import { styled } from '../../theme/styled';
import { appLogos } from '../../constants/app-logos';
import { CustomImage } from '../../components/custom-image/CustomImage';
import { EidAuthMethods } from '../../redux/domains/authentication/authentication.enums';
import { authenticateWithSessionId } from '../../redux/domains/authentication/authentication.actions';

export const AuthenticationPage = (): JSX.Element => {
    const dispatch = useDispatch();
    const params = new URLSearchParams(useLocation().search);
    const sessionId = params.get('grandidsession');
    const authMethod = params.get('authmethod');

    useEffect(() => {
        if (sessionId && authMethod) {
            if (!isAuthMethod(authMethod)) {
                throw Error('Invalid auth method (' + authMethod + ')');
            }
            dispatch(authenticateWithSessionId(sessionId, authMethod));
        }
    }, [dispatch, sessionId, authMethod]);

    return (
        <StyledContainer>
            <AuthHeader />
            <Switch>
                <Route
                    path={`${routePaths.signin}`}
                    exact
                    render={() => <LoginMethods />}
                />
                <Route
                    path={`${routePaths.signinEmail}`}
                    exact
                    render={() => <LoginEmail />}
                />
                <Route
                    render={() => <Redirect to={`${routePaths.signin}`} />}
                />
            </Switch>
        </StyledContainer>
    );
};

function isAuthMethod(authMethod: string): authMethod is EidAuthMethods {
    return Object.values(EidAuthMethods).includes(authMethod as EidAuthMethods);
}

const AuthHeader = () => (
    <StyledLogo>
        <StyledImgContainer>
            <StyledCustomImage src={appLogos.authLogo.src} alt="LifePod Logo" />
        </StyledImgContainer>
    </StyledLogo>
);

const StyledContainer = styled.div`
    width: 100vw;
    max-height: 100%;
    overflow: auto;
    padding: 20px;
    background: ${(props) => props.theme.appBackground};

    @media (orientation: landscape) and (min-width: 1024px) and (min-height: 640px) {
        height: 80vh;
        width: 30vw;
        margin: 0 auto;
        background: ${(props) => props.theme.appContainerBackground};
        border: 1px solid #ccc;
    }
`;

const StyledLogo = styled.div`
    height: 150px;
    width: 150px;
    margin: auto;
    margin-bottom: 20px;
`;

const StyledImgContainer = styled.div`
    height: 100%;
    width: 100%;
    padding: 15px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.23);
    border-radius: 20px;
    background-color: white;
`;

const StyledCustomImage = styled(CustomImage)`
    && {
        width: 100%;
        height: 100%;
    }
`;
