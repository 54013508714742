import React from 'react';
import { useDispatch } from 'react-redux';
import { useSwipeable } from 'react-swipeable';
import { styled } from '../../theme/styled';
import {
    nextCondition,
    previousCondition
} from '../../redux/domains/conditions/conditions.actions';

type SwipeProps = {
    children: React.ReactElement | React.ReactElement[];
};

const swipeConfig = {
    delta: 10, // min distance(px) before a swipe starts
    preventDefaultTouchmoveEvent: false, // call e.preventDefault *See Details*
    trackTouch: true, // track touch input
    trackMouse: false, // track mouse input
    rotationAngle: 0 // set a rotation angle
};

export const ContentSwipeLayout = (props: SwipeProps) => {
    const dispatch = useDispatch();
    const handlers = useSwipeable({
        onSwipedLeft: () => {
            dispatch(nextCondition());
        },
        onSwipedRight: () => {
            dispatch(previousCondition());
        },
        ...swipeConfig
    });

    return (
        <StyledContentLayoutSwipe {...handlers}>
            {props.children}
        </StyledContentLayoutSwipe>
    );
};

const StyledContentLayoutSwipe = styled.div`
    margin: 100px 0 50px 0px;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    /* https://webkit.org/blog/7929/designing-websites-for-iphone-x/ */
    height: calc(100% - 150px - env(safe-area-inset-bottom));

    // Give space for notification-bar:
    .notification-bar ~ & {
        margin-top: 0;
        height: calc(100% - 150px);
    }
`;
