import afRs from './atrial-fibrillation/af-rs';
import aoRS from './aortic-dissection/ao-rs';
import ciMau from './intermittent-claudication/ci-mau';
import copdRs from './chronic-obstructive-pulmonary-disease/copd-rs';
import demoDiabetes from './demo-diabetes/diabetes';
import demoKf from './demo-kidney-failure/demo-kf-rs';
import fcRs from './fake-condition/fc-rs';
import hsRs from './hand-surgery/hs-rs';
import hfRs from './heart-failure/hf-rs';
import hyperCap from './hypertension/capio/hypertension-capio';
import hypertension01 from './hypertension/01/hypertension-01';
import kfRs from './kidney-failure/kf-rs';
import lvadRs from './left-ventricular-assist-device/lvad-rs';
import padRs from './peripheral-artery-disease/pad-rs';
import spinalCordInjury01 from './spinal-cord-injury/spinal-cord-injury';

export const conditions = {
    ...afRs,
    ...aoRS,
    ...ciMau,
    ...copdRs,
    ...demoDiabetes,
    ...demoKf,
    ...fcRs,
    ...hsRs,
    ...hfRs,
    ...hyperCap,
    ...hypertension01,
    ...kfRs,
    ...lvadRs,
    ...padRs,
    ...spinalCordInjury01
};
