import { defineMessages } from 'react-intl';

const validation = defineMessages({
    general: {
        id: 'validation.required.general',
        defaultMessage: '(required)'
    },
    option: {
        id: 'validation.required.option',
        defaultMessage: 'Please choose an option'
    },
    username: {
        id: 'validation.required.username',
        defaultMessage: 'Please enter your username address'
    },
    password: {
        id: 'validation.required.password',
        defaultMessage: 'Please enter your password'
    },
    date: {
        id: 'validation.required.date',
        defaultMessage: 'Date is required'
    }
});

export default {
    validation
};
