import { defineMessages } from 'react-intl';

const main = defineMessages({
    title: {
        id: 'app.screen.statistics.tab.title',
        defaultMessage: 'Statistics',
        description: 'tab title'
    }
});

export default {
    main
};
