import React from 'react';
import { formatMessage } from '../../../../locale';
import { AchievementsPage } from './AchievementsPage';
import { StyledIcon } from '../../../../components/list/StyledIcon';
import { testSelectors } from '../../../../constants/test-id';
import { achievements } from './message-descriptors';
import { RouteType } from '../../../../routes/route.type';
import { routePaths } from '../../../../constants/route-paths';

export const achievementsRoute: RouteType = {
    component: AchievementsPage,
    title: formatMessage(achievements.header.title),
    path: routePaths.achievements,
    icon: <StyledIcon type="rise" />,
    testId: testSelectors.nav.achievementsItem
};
