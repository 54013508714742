export function removeTokensFromLocalStorage() {
    window.localStorage.removeItem('idToken');
    window.localStorage.removeItem('refreshToken');
    window.localStorage.removeItem('uuid');
}

export function addTokensToLocalStorage({
    idToken,
    refreshToken,
    uuid
}: {
    idToken: any;
    refreshToken: any;
    uuid: any;
}) {
    window.localStorage.setItem('idToken', idToken);
    window.localStorage.setItem('refreshToken', refreshToken);
    window.localStorage.setItem('uuid', uuid);
}

export const addTokenToLocalStorage = (key: any, token: any) => {
    window.localStorage.setItem(key, token);
};

export const getTokenFromLocalStorage = (key: any) => {
    return window.localStorage.getItem(key);
};
