import { defineMessages } from 'react-intl';

export const medicationCheck = defineMessages({
    title: {
        defaultMessage: 'Medication check',
        id: 'indicator.ehfscbs.medication-check.title'
    },
    description: {
        defaultMessage: 'I take my medication as prescribed',
        id: 'indicator.ehfscbs.medication-check.description'
    },
    maxValueDescription: {
        defaultMessage: 'I do not agree at all',
        id: 'indicator.ehfscbs.medication-check.max-text'
    },
    minValueDescription: {
        defaultMessage: 'I fully agree',
        id: 'indicator.ehfscbs.medication-check.min-text'
    }
});
