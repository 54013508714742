import { defineMessages } from 'react-intl';

export const spinalCordInjury01 = defineMessages({
    name: {
        defaultMessage: 'Spinal Cord Injury',
        id: 'conditions.spinal-cord-injury-01.name'
    },
    nameLong: {
        defaultMessage: 'Spinal Cord Injury',
        id: 'conditions.spinal-cord-injury-01.name-long'
    }
});
