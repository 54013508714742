import { defineMessages } from 'react-intl';

const main = defineMessages({
    report: {
        id: 'navigation.report',
        defaultMessage: 'Report'
    },
    myData: {
        id: 'navigation.my-data.title',
        defaultMessage: 'My Data'
    },
    statistics: {
        id: 'navigation.my-data.statistics',
        defaultMessage: 'Statistics'
    },
    history: {
        id: 'navigation.my-data.history',
        defaultMessage: 'History'
    },
    messagesTab: {
        id: 'navigation.messages',
        defaultMessage: 'Messages'
    },
    other: {
        id: 'navigation.other.title',
        defaultMessage: 'Other'
    },
    chat: {
        id: 'navigation.chat',
        defaultMessage: 'Messages'
    },
    notifications: {
        id: 'navigation.notifications',
        defaultMessage: 'Notifications'
    }
});

export default {
    main
};
