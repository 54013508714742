import { defineMessages } from 'react-intl';

export const blood = defineMessages({
    value: {
        defaultMessage: '{value, select, true {Yes} false {No}}',
        id: 'indicator.phlegm.blood.value'
    },
    question: {
        defaultMessage: 'Phlegm with blood (Elements of red or brown)',
        id: 'indicator.phlegm.blood.description'
    },
    valueChoicesTrue: {
        defaultMessage: 'Yes',
        id: 'indicator.phlegm.blood.choices.true'
    },
    valueChoicesFalse: {
        defaultMessage: 'No',
        id: 'indicator.phlegm.blood.choices.false'
    }
});
