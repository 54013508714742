import { TileType } from '../../../types/indicator/report.type';
import { ConditionsKey } from '../../../conditions/condition.type';
import { conditions } from '../../../conditions/index';
import { Condition } from '../../../conditions/condition.type';
import { IndicatorByCode } from '../../../types/indicator/state-indicator.type';
import { IndicatorCode, Tile } from './condtions.types';

function createIndicatorObject(indicator: any): IndicatorByCode {
    return {
        id: indicator.id,
        code: indicator.code,
        category: indicator.category,
        data: indicator.data,
        form: indicator.form
    };
}

function createTileObject(
    condition: Condition,
    indicatorCode: IndicatorCode
): Tile {
    return {
        loading: false,
        feedback: null,
        dimmedUntil: 0,
        shouldDimWhenReported:
            condition.indicators.find(
                (feIndicator) => feIndicator?.code === indicatorCode
            )?.tile.report.type === TileType.Dimming
    };
}

export const conditionsNormalize = (data: any) => {
    const byCode = data[0].conditions.reduce((acc: any, curr: any) => {
        const conditionCode = curr.code as ConditionsKey;
        const condition: Condition = conditions[conditionCode];

        if (!condition) {
            throw 'Condition does not exist : ' + conditionCode;
        }

        const tiles: Record<string, Tile> = {};
        const indicatorsByCode = curr.indicators.reduce(
            (
                indicators: Record<IndicatorCode, IndicatorByCode>,
                indicator: IndicatorByCode
            ) => {
                indicators[indicator.code] = createIndicatorObject(indicator);
                tiles[indicator.code] = createTileObject(
                    condition,
                    indicator.code
                );
                return indicators;
            },
            {}
        );
        acc[curr.code] = {
            id: curr.id,
            code: curr.code,
            indicators: {
                allCodes: Object.keys(indicatorsByCode),
                byCode: indicatorsByCode
            },
            tiles
        };
        return acc;
    }, {});

    return {
        allCodes: Object.keys(byCode),
        byCode: byCode
    };
};
