import React from 'react';
import { Icon } from 'antd';
import { Link, NavLink } from 'react-router-dom';
import { keyframes } from 'styled-components';
import { styled } from '../../theme/styled';
import { CustomImage } from '../custom-image/CustomImage';
import { testSelectors } from '../../../src/constants/test-id';
import { appLogos } from '../../constants/app-logos';
import { routePaths } from '../../constants/route-paths';

type Props = { backButtonPath?: string | null; title: string };

export const Header = ({ backButtonPath, title }: Props): JSX.Element => (
    <StyledHeader>
        {backButtonPath && (
            <NavLink to={backButtonPath}>
                <span className="back-indicator">
                    <Icon
                        type="left"
                        data-test-id={testSelectors.nav.backButton}
                    />
                </span>
            </NavLink>
        )}
        <StyledCustomImage src={appLogos.standardLogo.src} alt="" />
        <p data-test-id={testSelectors.page.headerTitle} className="title">
            {title}
        </p>
        <StyledOtherMenuLink to={routePaths.other}>
            <StyledOtherMenuIcon
                type="menu"
                data-test-id={testSelectors.nav.otherMenuButton}
            />
        </StyledOtherMenuLink>
    </StyledHeader>
);

const StyledOtherMenuIcon = styled(Icon)`
    font-size: 20px;
`;

const StyledOtherMenuLink = styled(Link)`
    position: absolute;
    top: 14px;
    right: 16px;
    color: rgba(0, 0, 0, 0.65);

    :active {
        color: rgba(0, 0, 0, 0.65);
    }
`;

const StyledCustomImage: any = styled(CustomImage)`
    width: 30px;
    height: 30px;
`;

const fadeInHeaderTitle = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const StyledHeader: any = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    border-bottom: 1px solid rgba(232, 233, 232, 0.7);
    width: 100%;
    z-index: 9;
    @media (orientation: landscape) and (min-width: 1024px) and (min-height: 640px) {
        position: absolute;
    }

    .header-item {
        position: absolute;
        top: 10px;
        left: 10px;
    }

    .back-indicator {
        position: absolute;
        left: 10px;
        top: 55%;
        transform: translateY(-50%);

        i {
            font-size: 1.6rem;
        }
    }

    .title {
        color: black;
        font-weight: 400;
        line-height: 1;
        font-size: 1.2rem;
        margin: 0;
        animation: ${fadeInHeaderTitle} 0.45s 1 ease-in-out;
        margin-left: 10px;
    }
`;
