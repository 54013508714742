import React from 'react';
import { MarkdownFromDescriptor } from '../../../../../locale/utils/functions/format-markdown';
import { styled } from '../../../../../theme/styled';
import {
    FetchedIndicator,
    isFetchedIndicatorProperties
} from '../../../../../types/indicator/fetched-indicator.type';

type Props = {
    indicator: FetchedIndicator;
    property?: string;
};

export const RadioGroupQuestion = ({
    indicator,
    property
}: Props): JSX.Element => {
    const questionDescriptor =
        property && isFetchedIndicatorProperties(indicator)
            ? indicator.messages[property]?.question
            : indicator.messages.general.question;

    if (questionDescriptor)
        return (
            <StyledRadioGroupQuestion>
                <MarkdownFromDescriptor
                    messageDescriptor={questionDescriptor}
                />
            </StyledRadioGroupQuestion>
        );
    return <></>;
};

export const StyledRadioGroupQuestion = styled.div`
    margin: 25px 0 0 0;

    p {
        margin-bottom: 5px;
        font-size: 12px;
        font-weight: 600;
        line-height: 1.5;
    }
`;
