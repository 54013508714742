import messages from '../../../conditions/shared-indicators/smoke/messages/index';
import { ChartEnum } from '../../../types/indicator/chart.types';
import {
    IndicatorHeatmapRules,
    IndicatorRules
} from '../../../types/indicator/indicator-rules.type';
import { colors } from '../../../theme/colors/common-colors';

export const rules: IndicatorRules & IndicatorHeatmapRules = {
    neutralReportResult: false,
    invertReportResponse: false,
    hideValueWhenReporting: false,
    defaultValue: 0,
    chartTypes: [ChartEnum.Heatmap],
    sumByDay: true,
    heatmapPieces: [
        { value: 0, color: colors.chart.heatmap.purple10 },
        {
            color: colors.chart.heatmap.purple20,
            min: 1,
            max: 5,
            text: messages.heatmapChoices.few
        },
        {
            color: colors.chart.heatmap.purple40,
            min: 6,
            max: 10,
            text: messages.heatmapChoices.few
        },
        {
            color: colors.chart.heatmap.purple50,
            min: 11,
            max: 15,
            text: messages.heatmapChoices.few
        },
        {
            color: colors.chart.heatmap.purple60,
            min: 16,
            max: 20,
            text: messages.heatmapChoices.few
        },
        {
            color: colors.chart.heatmap.purple80,
            min: 21,
            max: 99,
            text: messages.heatmapChoices.more
        }
    ],
    textRanges: [
        { min: 0, max: 0, ...messages.choices.none },
        { min: 1, max: 1, ...messages.choices.one },
        { min: 2, max: 20, ...messages.choices.several },
        { min: 21, max: 99, ...messages.choices.more }
    ]
};

export default rules;
