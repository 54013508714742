import React from 'react';
import { MarkdownFromDescriptor } from '../../../../../locale/utils/functions/format-markdown';
import { styled } from '../../../../../theme/styled';
import {
    FetchedIndicator,
    isFetchedIndicatorProperties
} from '../../../../../types/indicator/fetched-indicator.type';

type Props = {
    indicator: FetchedIndicator;
    property?: string;
};

export const SliderTitle = ({ indicator, property }: Props) => {
    const titleDescriptor =
        property && isFetchedIndicatorProperties(indicator)
            ? indicator.messages[property]?.title
            : indicator.messages.general.title;

    if (titleDescriptor)
        return (
            <StyledSliderTitle>
                <MarkdownFromDescriptor messageDescriptor={titleDescriptor} />
            </StyledSliderTitle>
        );
    return <></>;
};

export const StyledSliderTitle = styled.div`
    margin: 25px 0 0 0;

    p {
        font-size: 12px;
        font-weight: 600;
        line-height: 1.5;
    }
`;
