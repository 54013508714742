import React from 'react';
import { formatMessage } from '../../locale';
import RecommendedReportFrequency from './card-content/report-frequency/RecommendedReportFrequency';
import { ExtraInformation } from './card-content/extra-information/ExtraInformation';
import { IndicatorIconWithBorder } from '../indicator-icon/IndicatorIcon';
import { useFetchedIndicator } from '../../hooks/use-fetched-indicator';
import { useReduxSelector } from '../../hooks/use-redux-selector';
import { getIndicatorConfigurations } from '../../redux/domains/user/user.selectors';
import { RecommendedObservations } from './card-content/observations/RecommendationObservations';
import { Card } from 'antd';
import {
    StyledCardAvatar,
    StyledCardTitle,
    StyledHeader,
    StyledListItem,
    StyledRecommendations
} from './styled';
import { Reminders } from '../../redux/domains/reminders/reminders.types';
import { hideIndicatorNotInUse } from './utils/hide-indicator-not-in-use';
import { hideRecommendation } from './utils/hide-recommendation';

export const RecommendationsCard = (props: {
    code: string;
    reminders: Reminders;
}): JSX.Element => {
    const indicator = useFetchedIndicator(props.code);

    const reminder = indicator.recommendations?.reminder?.enabled || true;
    const limits = indicator.recommendations?.limits?.enabled || false;
    const info = indicator.recommendations?.info?.enabled || false;

    const configuration = useReduxSelector(
        (state) => getIndicatorConfigurations(state)[indicator.code]
    );

    if (
        hideRecommendation({
            reminder: reminder,
            limits: limits,
            info: info
        })
    ) {
        return <></>;
    }

    if (hideIndicatorNotInUse(configuration)) {
        return <></>;
    }

    return (
        <StyledListItem key={props.code}>
            <Card
                title={
                    <StyledHeader>
                        <StyledCardAvatar>
                            <IndicatorIconWithBorder
                                icon={indicator.icon}
                                size={35}
                            />
                        </StyledCardAvatar>
                        <StyledCardTitle>
                            {formatMessage(indicator.messages.general.title)}
                        </StyledCardTitle>
                    </StyledHeader>
                }
            >
                <StyledRecommendations>
                    <RecommendedObservations fetchedIndicator={indicator} />
                    <RecommendedReportFrequency
                        indicator={indicator}
                        reminder={props.reminders[indicator.id]}
                    />
                    <ExtraInformation fetchedIndicator={indicator} />
                </StyledRecommendations>
            </Card>
        </StyledListItem>
    );
};
