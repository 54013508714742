import React from 'react';
import { formatMessage } from '../../../../locale';
import { AboutPage } from './AboutPage';
import { StyledIcon } from '../../../../components/list/StyledIcon';
import { testSelectors } from '../../../../constants/test-id';
import { messagesAbout } from './messages';
import { RouteType } from '../../../../routes/route.type';
import { routePaths } from '../../../../constants/route-paths';

export const aboutRoute: RouteType = {
    component: AboutPage,
    title: formatMessage(messagesAbout.header.title),
    path: routePaths.about,
    icon: <StyledIcon type="info-circle-o" />,
    testId: testSelectors.nav.aboutItem
};
