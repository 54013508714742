import { ProfileType } from './profile.types';
import { MotdMessageType } from '../../redux/domains/motd/motd.types';
import { interactiveYellow } from '../colors/colors';
import { achievements } from '../colors/achievements/theme-achievements';
import { theme } from '../colors/common-colors';
import { ThemeChat } from './types/chat.type';

export enum ThemeEnum {
    DARK = 'DARK',
    LIGHT = 'LIGHT'
}

const ui = {
    iconGray: '#847a7a'
};

const navigation = {
    fontWeight: '400'
};

const chat: ThemeChat = {
    emergency: {
        sticky: {
            buttonColor: '#ff0000',
            textColor: '#f7f7f7',
            background: '#464646'
        }
    },
    message: {
        color: '#F9F9F9',
        subtitlesAlter: '#C9C9C9',
        sender: {
            backgroundColor: '#8B67CE',
            textColor: '#F9F9F9',
            textAlign: 'right',
            justify: 'flex-end'
        },
        receiver: {
            backgroundColor: '#2c8eff',
            textColor: '#F9F9F9',
            textAlign: 'left',
            justify: 'flex-start'
        }
    }
};

const motdColorForTypes: MotdMessageType = {
    warning: '#faad14',
    info: '#1890ff'
};

const motd = {
    motdColorForTypes: motdColorForTypes,
    text: {
        header: '#f7f7f7',
        body: '#464646'
    }
};

const size = {
    humanInterfaceSpace: 52
};

export const profileLight: ProfileType = {
    oldTheme: theme,
    primary: {
        text: '#000'
    },
    secondary: {
        interactive: interactiveYellow
    },
    color: {
        error: 'red',
        icon: {
            highlight: '#54aaff'
        }
    },
    main: '#ddd',
    appBackground: '#f0f2f5',
    appContainerBackground: '#f9f9f9',
    text: '#000',
    backgroundColor: 'white',
    achievements,
    navigation,
    ui,
    chat,
    motd: motd,
    size: size
};

export const profileDark: ProfileType = profileLight;
