import { defineMessages } from 'react-intl';

const general = defineMessages({
    editEmailInformation: {
        id: 'screen.profile.general.edit-email-information',
        defaultMessage: 'To change e-mail contact support@lifepod.se'
    }
});

const button = defineMessages({
    edit: {
        id: 'screen.profile.button.edit',
        defaultMessage: 'Edit'
    },
    cancel: {
        id: 'screen.profile.button.cancel',
        defaultMessage: 'Cancel'
    },
    save: {
        id: 'screen.profile.button.save',
        defaultMessage: 'Save'
    }
});

const label = defineMessages({
    firstName: {
        id: 'screen.profile.label.first-name',
        defaultMessage: 'First name'
    },
    familyName: {
        id: 'screen.profile.label.family-name',
        defaultMessage: 'Family name'
    },
    phoneNumber: {
        id: 'screen.profile.label.phone-number',
        defaultMessage: 'Phone number'
    }
});

const validation = defineMessages({
    firstNameRequired: {
        id: 'screen.profile.validation.first-name.required',
        defaultMessage: 'Cannot be empty'
    },
    familyNameRequired: {
        id: 'screen.profile.validation.family-name.required',
        defaultMessage: 'Cannot be empty'
    },
    phoneNumberRequired: {
        id: 'screen.profile.validation.phone-number.required',
        defaultMessage: 'Cannot be empty'
    },
    phoneNumberFormat: {
        id: 'screen.profile.validation.phone-number.format',
        defaultMessage: 'Invalid phone number'
    }
});

export default {
    general,
    button,
    label,
    validation
};
