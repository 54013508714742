import { defineMessages } from 'react-intl';

export const swollenLegs = defineMessages({
    title: {
        defaultMessage: 'Swollen legs',
        id: 'indicator.ehfscbs.swollen-legs.title'
    },
    description: {
        defaultMessage:
            'If my feet/legs become more swollen than usual, I contact my doctor or nurse',
        id: 'indicator.ehfscbs.swollen-legs.description'
    },
    maxValueDescription: {
        defaultMessage: 'I do not agree at all',
        id: 'indicator.ehfscbs.swollen-legs.max-text'
    },
    minValueDescription: {
        defaultMessage: 'I fully agree',
        id: 'indicator.ehfscbs.swollen-legs.min-text'
    }
});
