import { styled } from '../../../theme/styled';
import { Card } from 'antd';

export const StyledSubPage = styled.div`
    padding: 5px;
`;

export const StyledCard = styled(Card)`
    background-color: white;

    .ant-card-body {
        padding: 25px;
    }

    input {
        border: none;
        border-bottom: 1px solid rgba(128, 128, 128, 0.09);
        border-radius: 0;
        height: 60px;
    }
`;
