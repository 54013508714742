import { defineMessages } from 'react-intl';

const slotaRokaLinjenHref = 'http://www.slutarokalinjen.se/';

export const info = defineMessages({
    main: {
        id: 'indicator.smoke.lp_2_0_copd_rs.info.main',
        defaultMessage:
            'Rökning är en beroendesjukdom framkallad av nikotinet men ' +
            'det handlar också om vanor och inlärt beteende. ' +
            'Att vara röksugen beror till stor del på kroppens starka ' +
            'nikotinberoende. Av alla livsstilsfaktorer är rökstopp ' +
            'det som snabbast ger resultat. Den som lyckas sluta har ' +
            'avsevärt förbättrat förutsättningarna för att leva ett ' +
            'längre och friskare liv. Oavsett vilken ålder man ' +
            'är i så "lönar sig" det alltid med tobaksstopp. ' +
            'Att sluta röka är den enskilt mest betydelsefulla ' +
            'handling som någon kan göra för sin hälsa. \n\n' +
            '' +
            'Vad som händer när man slutat röka; \n\n' +
            '' +
            '20 minuter efter rökstoppet har blodtryck och puls återgått ' +
            'till en normal nivå. \n\n' +
            '' +
            '24 timmar efter rökstoppet har riskerna för en ' +
            'hjärtinfarkt börjat minska. \n\n' +
            '' +
            '6 månader efter rökstoppet är risken för blodpropp ' +
            'mycket mindre. \n\n' +
            '' +
            '1-2 år efter rökstoppet är riskerna för hjärtinfarkt ' +
            'hälften så stora som vid fortsatt rökning. \n\n' +
            '' +
            '5 år efter rökstoppet är risken för en hjärtinfarkt lika ' +
            'liten som för en som aldrig rökt, ' +
            'och riskerna för olika cancersjukdomar har minskat ' +
            'betydligt. \n\n' +
            '' +
            'Du kan få råd och stöd hos ' +
            `[Sluta-röka-linjen](${slotaRokaLinjenHref}). ` +
            'Ring 020-84 00 00 eller gå in på ' +
            `[slutarokalinjen.se](${slotaRokaLinjenHref}) \n\n` +
            '' +
            'Du kan också kontakta en vårdcentral för att få stöd och hjälp. ' +
            'Många sjuksköterskor är särskilt utbildade i ' +
            'tobaksavvänjning och kan hjälpa dig när du behöver extra stöd.'
    }
});
