import messages from './messages/index';
import { ChartEnum } from '../../../types/indicator/chart.types';
import {
    IndicatorChartRules,
    IndicatorRules
} from '../../../types/indicator/indicator-rules.type';
import { colors } from '../../../theme/colors/common-colors';

export const rules: IndicatorRules & IndicatorChartRules = {
    neutralReportResult: false,
    invertReportResponse: false,
    hideValueWhenReporting: false,
    step: 1,
    chartTypes: [ChartEnum.Spline],
    lineColor: colors.chart.graph.obj.vas,
    chartOptions: {
        hidePlotBand: true,
        fixedYAxisMin: 0,
        fixedYAxisMax: 100
    },
    textRanges: [
        {
            min: 0,
            max: 20,
            ...messages.general.veryBad
        },
        {
            min: 21,
            max: 40,
            ...messages.general.bad
        },
        {
            min: 41,
            max: 64,
            ...messages.general.decent
        },
        {
            min: 65,
            max: 80,
            ...messages.general.ok
        },
        {
            min: 81,
            max: 90,
            ...messages.general.good
        },
        {
            min: 91,
            max: 100,
            ...messages.general.veryGood
        }
    ]
};

export default rules;
