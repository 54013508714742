import { actionTypes } from '../domains/authentication/authentication.constants';
import { combineReducers } from 'redux';
import { achievementsReducer } from '../domains/achievements/achievements.reducer';
import { authenticationReducer } from '../domains/authentication/authentication.reducer';
import { chatReducer } from '../domains/chat/chat.reducer';
import { conditionsReducer } from '../domains/conditions/conditions.reducer';
import { groupsReducer } from '../domains/groups/groups.reducer';
import { notificationsReducer } from '../domains/notifications/notifications.reducer';
import { remindersReducer } from '../domains/reminders/reminders.reducer';
import { requestsReducer } from '../domains/requests/requests.reducer';
import { userReducer } from '../domains/user/user.reducer';
import { valuesReducer } from '../domains/values/values.reducer';
import { videoReducer } from '../domains/video/video.reducer';

const appReducer = combineReducers({
    achievements: achievementsReducer,
    authentication: authenticationReducer,
    chat: chatReducer,
    conditions: conditionsReducer,
    groups: groupsReducer,
    notifications: notificationsReducer,
    reminders: remindersReducer,
    requests: requestsReducer,
    user: userReducer,
    values: valuesReducer,
    video: videoReducer
});

export type AppState = ReturnType<typeof appReducer>;

export const rootReducer = (state: AppState | undefined, action: any) => {
    if (action.type === actionTypes.AUTHENTICATION_LOGOUT_FULFILLED) {
        state = undefined;
    }

    return appReducer(state, action);
};
