import achievements_img from '../../../static/images/achievements/index';
import { getCamelCase } from '../../../utils/functions/index';

import { AchievementImageType } from './achievement-model-types';

class AchievementHelper {
    getLastInList(list: Array<any>): any {
        return list[list.length - 1];
    }

    stringsAreValid(string1: string, string2: string): boolean {
        if (string1 != '' && string2 != '') {
            return true;
        }
        return false;
    }

    getImagePath(category = '', type = '', index = 1): AchievementImageType {
        const camelCategory: string = getCamelCase({ string: category });
        const camelType = getCamelCase({ string: type });

        if (this.stringsAreValid(camelCategory, camelType)) {
            const achievementsImg: any = achievements_img;
            const img: any =
                achievementsImg[camelCategory][camelType][`milestone${index}`];
            if (img != null) {
                return img;
            }
        }

        return { src: 'noImgPath' };
    }

    getMajorImagePath(
        category: string,
        type: string,
        index: number
    ): AchievementImageType {
        return this.getImagePath(category, type, index);
    }

    getMinorImagePath(
        category: string,
        type: string,
        index: number
    ): AchievementImageType {
        return this.getImagePath(category, type, index);
    }
}

export default AchievementHelper;
