import { HTTPClient } from '../../../services/http-client/http-client';
import { formatPersonalId, getConfigurations } from './user.utils';
import { UserUpdateType } from './user.types';
import { PatientResponseType } from './user.types.responses';
import { UserSettingsType } from './user.settings.types';

const getBooleanSetting = (
    settings: UserSettingsType,
    category: string,
    property: string
) => {
    let enabled = true;

    if (settings[category]) {
        try {
            if (settings[category]?.[property]?.enabled == null) {
                console.warn('Setting is null:', property);
                enabled = true;
            } else {
                enabled = settings[category]?.[property]?.enabled;
            }
        } catch (e) {
            console.warn('Property missing:', property);
        }
    } else {
        console.error('User setting does not exist:', category);
    }

    return enabled;
};

const getBoolStructure = (
    settings: UserSettingsType,
    category: string,
    property: string
) => {
    return {
        [category]: {
            [property]: {
                enabled: getBooleanSetting(settings, category, property)
            }
        }
    };
};

const putBoolStructure = (
    category: string,
    property: string,
    value: boolean
) => {
    return {
        [category]: {
            [property]: {
                enabled: value
            }
        }
    };
};

export const getUserSettings = (settings: UserSettingsType) => {
    return {
        ...getBoolStructure(settings, 'sms', 'reminders'),
        ...getBoolStructure(settings, 'achievements', 'confetti')
    };
};

const putUserSettings = (settings: UserSettingsType) => {
    //TODO: Generic settings will fail
    return {
        ...putBoolStructure(
            'sms',
            'reminders',
            settings.sms?.reminders.enabled
        ),
        ...putBoolStructure(
            'achievements',
            'confetti',
            settings.achievements?.confetti.enabled
        )
    };
};

const getUserObject = (data: PatientResponseType) => {
    let settings;
    try {
        settings = getUserSettings(data.settings);
    } catch (e) {
        console.warn(`${getUserObject.name}: "data.settings" was not found:`);
        settings = getUserSettings({});
    }
    const user = {
        externalId: data.externalId,
        ...data.meta,
        configurations: getConfigurations(data),
        settings: settings
    };
    user.personal_id = formatPersonalId(user.personal_id);
    return user;
};

export const getUser = (subjectId: string) => {
    return HTTPClient.get(`users/${subjectId}`)
        .then(getUserObject)
        .catch((error) => {
            console.error(`Error in ${getUser.name}\n`, error);
            throw error;
        });
};

export const putUser = async (subjectId: string, data: UserUpdateType) => {
    return HTTPClient.put(`users/${subjectId}`, data)
        .then(getUserObject)
        .catch((error) => {
            console.error(`Error in ${putUser.name}\n`, error);
            throw error;
        });
};

export const putSettings = (subjectId: string, settings: UserSettingsType) => {
    return HTTPClient.put(`users/${subjectId}`, {
        settings: putUserSettings(settings)
    }).catch((error) => {
        console.error(`Error in ${putSettings.name}\n`, error);
        throw error;
    });
};
