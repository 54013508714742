import { defineMessages } from 'react-intl';

export const general = defineMessages({
    title: {
        defaultMessage: 'EQ-5D-3L',
        id: 'indicator.eq5d3l.title'
    },
    titleLong: {
        defaultMessage: 'EQ-5D-3L',
        id: 'indicator.eq5d3l.title-long'
    },
    helpText: {
        defaultMessage:
            'On the following screens, please tap the statement that best describes your health TODAY.',
        id: 'indicator.eq5d3l.help-text'
    },
    copyright: {
        defaultMessage:
            '© EuroQol Research Foundation. EQ-5D™ is a trade mark of the EuroQol Research Foundation',
        id: 'indicator.eq5d3l.copyright'
    },
    value: {
        id: 'indicator.eq5d3l.value',
        defaultMessage: 'Submitted'
    }
});
