import { AchievementHandlerInterface } from './achievement-handler-interface';
import {
    AchievementLocaleType,
    AchievementResponseType
} from './achievement-support/achievement-model-types';
import { achievementDefaults } from './achievement-support/achievement-constants';
import { AchievementModel } from './achievement-model';

export class AchievementHandler implements AchievementHandlerInterface {
    private _achievement: AchievementModel;

    constructor(
        category = achievementDefaults.defaultCategory,
        type = achievementDefaults.defaultCategoryType,
        value = 0,
        targets: Array<Array<number>> = []
    ) {
        this._achievement = new AchievementModel(
            category,
            type,
            value,
            targets
        );
    }

    anyAchieved(): boolean {
        return this._achievement.anyAchieved();
    }

    getCardContentText(): {
        encourage: string;
        percentage: number;
        progress: string;
    } {
        return this._achievement.getCardContentText();
    }

    getCardText(): {
        title: string;
        info: { title: JSX.Element | null; body: JSX.Element | null };
    } {
        return this._achievement.getCardText();
    }

    getCategory(): string {
        return this._achievement.getCategory();
    }

    getCategoryType(): string {
        return this._achievement.getCategoryType();
    }

    getMajorMilestonesData(): Array<{
        index: number;
        stone: number;
        isMinor: boolean;
        isAchieved: boolean;
        img: string;
    }> {
        return this._achievement.getMajorMilestonesData();
    }

    getMinorMilestonesData(): Array<{
        index: number;
        stone: number;
        isMinor: boolean;
        isAchieved: boolean;
        img: string;
    }> {
        return this._achievement.getMinorMilestonesData();
    }

    getModalDetails(): { title: string; body: string; milestoneType: string } {
        return this._achievement.getModalDetails();
    }

    setResponseValues(value?: AchievementResponseType): void {
        if (value != null) {
            this._achievement.setResponseValues(value);
        } else {
            console.warn('Could not setResponseValues');
        }
    }

    getAchievementModalDetails(): {
        title: string;
        body: string;
        avatarUrl: string;
        milestoneType: string;
        handleOnOkAchievement: any;
        showAchievementModal: boolean;
    } {
        return this._achievement.getAchievementModalDetails();
    }

    getCurrentIndex(): number {
        return this._achievement.getCurrentIndex();
    }

    getMajorIndex(): number {
        return this._achievement.getMajorIndex();
    }
    setCurrentIndex(index: number): void {
        this._achievement.setCurrentIndex(index);
    }

    resetAchievement(): void {
        this._achievement.resetAchievement();
    }

    setLocaleObj(achievementLocale: AchievementLocaleType): void {
        this._achievement.setLocaleObj(achievementLocale);
    }

    getModalImg(index: number): string {
        return this._achievement._getModalImg(index).src;
    }

    getValues(): AchievementResponseType {
        return this._achievement.getValues();
    }
}
