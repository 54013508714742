type Property = {
    type: string;
    minimum?: number;
    maximum?: number;
};

type JsonSchema = {
    type: string;
    minimum?: number;
    maximum?: number;
    required?: string[];
    properties?: Record<string, Property>;
};

export type Data = {
    jsonSchema: JsonSchema;
    unitOfMeasure: null | string;
};

export type Limits = {
    report_min?: null | number;
    lower_red?: null | number;
    lower_yellow?: null | number;
    upper_red?: null | number;
    upper_yellow?: null | number;
    report_max?: null | number;
};

export type Configuration = {
    in_use: boolean;
};

export type Properties = Record<string, Limits>;

export type TestIndicatorByCode = {
    id: string;
    code: string;
    category: string;
};

export enum ItemTypes {
    RADIO = 'radio',
    SELECTION = 'selection',
    SLIDER = 'slider',
    STEPPER = 'stepper',
    TEXT = 'text'
}

export type FormItemChoice = { code: string; value: number };

export type FetchedIndicatorPropertiesBaseFormItem = {
    code: string;
    type: ItemTypes;
    validation: { required: boolean };
};

export type FetchedIndicatorPropertiesTextFormItem = {
    type: ItemTypes.TEXT;
} & FetchedIndicatorPropertiesBaseFormItem;

export type FetchedIndicatorPropertiesSliderFormItem = {
    type: ItemTypes.SLIDER | ItemTypes.STEPPER;
    report_max: number;
    report_min: number;
    steps: number;
} & FetchedIndicatorPropertiesBaseFormItem;

export type FetchedIndicatorPropertiesSelectionFormItem = {
    type: ItemTypes.SELECTION;
    choices: FormItemChoice[];
} & FetchedIndicatorPropertiesBaseFormItem;

export type FetchedIndicatorPropertiesRadioFormItem = {
    type: ItemTypes.RADIO;
    options: string[];
} & FetchedIndicatorPropertiesBaseFormItem;

export type FetchedIndicatorPropertiesFormItem =
    | FetchedIndicatorPropertiesTextFormItem
    | FetchedIndicatorPropertiesSliderFormItem
    | FetchedIndicatorPropertiesRadioFormItem
    | FetchedIndicatorPropertiesSelectionFormItem;

export type IndicatorByCode = TestIndicatorByCode & {
    data: Data;
    form?: {
        type: 'combined' | 'questionnaire';
        item?: FetchedIndicatorPropertiesFormItem[];
        title?: string;
        description?: string;
        helptext?: string;
        legaltext?: string;
        copyright?: string;
    };
};

export function isFormSliderItem(
    item: FetchedIndicatorPropertiesFormItem
): item is FetchedIndicatorPropertiesSliderFormItem {
    return item.type === ItemTypes.SLIDER || item.type === ItemTypes.STEPPER;
}

export function isFormRadioGroupItem(
    item: FetchedIndicatorPropertiesFormItem
): item is FetchedIndicatorPropertiesSelectionFormItem {
    return item.type === ItemTypes.SELECTION;
}

export function isFormTextItem(
    item: FetchedIndicatorPropertiesFormItem
): item is FetchedIndicatorPropertiesTextFormItem {
    return item.type === ItemTypes.TEXT;
}
