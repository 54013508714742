import { defineMessages } from 'react-intl';

export const padRS = defineMessages({
    name: {
        defaultMessage: 'Peripheral Artery Disease',
        id: 'conditions.LP_2_0_PAD_RS.name'
    },
    nameLong: {
        defaultMessage: 'Peripheral Artery Disease',
        id: 'conditions.LP_2_0_PAD_RS.name-long'
    }
});
