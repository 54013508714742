import React from 'react';
import { useSelector } from 'react-redux';
import { formatMessage, Strings } from '../../../../locale';
import { useReduxSelector } from '../../../../hooks/use-redux-selector';
import { FetchedIndicator } from '../../../../types/indicator/fetched-indicator.type';
import { styled } from '../../../../theme/styled';
import { Alert, Icon } from 'antd';
import moment from 'moment';
import { timeConversion } from '../../../../utils/time-conversion';
import { getSelectedCondition } from '../../../../redux/domains/conditions/conditions.selectors';
import { getRemindersForSelectedCondition } from '../../../../redux/domains/reminders/reminders.utils';

type Props = { indicator: FetchedIndicator };

export const MissedReportInfo = (props: Props): JSX.Element => {
    const indicator = props.indicator;
    const conditionState = useSelector(getSelectedCondition);
    const reminders = useReduxSelector((state) =>
        getRemindersForSelectedCondition(state)
    );
    const reminder = useReduxSelector(
        (state) => state.reminders.byIndicatorId[indicator.id]
    );
    const missedReport = reminders[indicator.id]?.missedReport || false;
    const isDimmed =
        Date.now() < conditionState.tiles[indicator.code].dimmedUntil;
    const showMissedReportInfo = missedReport && !isDimmed;

    if (!showMissedReportInfo) {
        return <></>;
    }

    const conditionReportConfiguration =
        reminder?.conditionReportConfigurations.find(
            (config) => config.conditionId === conditionState.id
        );

    const formattedMissedReportInterval =
        timeConversion(conditionReportConfiguration?.missedReportInterval) ||
        '';
    const formattedLastReport = reminder?.lastReport
        ? moment(new Date(reminder.lastReport)).format(
              formatMessage(Strings.time.format.reminderReportFormat)
          )
        : '';
    const intervalText = formatMessage(
        Strings.screen.report.header.missedRecommendation,
        {
            value: formattedMissedReportInterval
        }
    );
    const lastReportText = formatMessage(
        Strings.screen.report.header.missedLatestReport,
        {
            value: formattedLastReport
        }
    );
    const missedInformation = `${intervalText} ${
        formattedLastReport ? lastReportText : ''
    }`;

    return (
        <StyledInfoRow
            showIcon={true}
            icon={<Icon type="warning" theme="filled" />}
            message={missedInformation}
            type="warning"
        />
    );
};

export const StyledInfoRow = styled(Alert)`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    && {
        margin-bottom: 10px;
        padding-left: 10px;
    }

    .anticon {
        font-size: xx-large;
        margin: 10px;
        width: 2rem;
    }

    && .ant-alert-icon {
        position: unset;
        top: auto;
        left: auto;
    }

    .ant-alert-message {
        font-size: small;
        padding: 10px;
    }
`;
