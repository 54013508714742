import { defineMessages } from 'react-intl';

const header = defineMessages({
    title: {
        id: 'app.screen.report.header.title',
        defaultMessage: 'Report',
        description: 'Report page title'
    },
    missedRecommendation: {
        id: 'app.screen.report.missed.recommendation',
        defaultMessage:
            'You did not report in time (the recommendation is {value}).'
    },
    missedLatestReport: {
        id: 'app.screen.report.missed.latest-report',
        defaultMessage: 'Your latest report was made on {value}.'
    }
});

export default {
    header
};
