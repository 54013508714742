import { mockedAchievements } from './mocked-achievements';
import { AchievementResponseType } from '../../../models/achievement/achievement-support/achievement-model-types';
import { AchievementHandler } from '../../../models/achievement';
import { defaultResponse } from '../../../models/achievement/achievement-support/achievement-constants';
import { HTTPClient } from '../../../services/http-client/http-client';

function mapResponseToAchievement(
    achievements: Array<AchievementResponseType>
) {
    return achievements.map((achievement) => {
        return new AchievementHandler(
            achievement.category,
            achievement.type,
            achievement.sum.value,
            achievement.sum.targets
        );
    });
}

function validatedData(
    data: AchievementResponseType[] | []
): AchievementResponseType[] {
    if (data.length === 0) {
        return defaultResponse;
    }
    return data;
}

function httpCall(subjectId: string) {
    return HTTPClient.get(`achievements/${subjectId}`).then((data: any) => {
        return mapResponseToAchievement(validatedData(data));
    });
}

function mockedCall() {
    return new Promise((res) => {
        console.warn('Achievement call mocked');
        res(mapResponseToAchievement(validatedData(mockedAchievements)));
    });
}

export function getAchievement(subjectId: string) {
    if (process.env.NODE_ENV === 'test') {
        return mockedCall();
    }

    return httpCall(subjectId);
}
