import { defineMessages } from 'react-intl';

export const legends = defineMessages({
    legendNo: {
        defaultMessage: 'Self-test not ok',
        id: 'indicator.pump-self-test-lvad.legend.no'
    },
    legendYes: {
        defaultMessage: 'Self-test ok',
        id: 'indicator.pump-self-test-lvad.legend.yes'
    }
});
