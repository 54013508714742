import { defineMessages } from 'react-intl';

const text = defineMessages({
    searchPlaceholder: {
        id: 'medic.form.searchPlaceHolder',
        defaultMessage: 'Search for patient'
    },
    requiredFirstName: {
        id: 'medic.form.requiredFirstName',
        defaultMessage: 'Please enter a name'
    },
    requiredLastName: {
        id: 'medic.form.requiredLastName',
        defaultMessage: 'Please enter a name'
    },
    requiredSocialSecurityNumber: {
        id: 'medic.form.requiredSocialSecurityNumber',
        defaultMessage: 'Please enter a valid personal identification number'
    },
    requiredUsername: {
        id: 'medic.form.requiredUsername',
        defaultMessage: 'Please enter an username'
    },
    requiredEmail: {
        id: 'medic.form.requiredEmail',
        defaultMessage: 'Please enter an e-mail'
    },
    requiredPassword: {
        id: 'medic.form.requiredPassword',
        defaultMessage: 'Please enter a password'
    },
    requiredPhone: {
        id: 'medic.form.requiredPhone',
        defaultMessage: 'Please enter a valid phone number'
    },
    requiredGroup: {
        id: 'medic.form.requiredGroup',
        defaultMessage: 'Please select a group'
    },
    validEmail: {
        id: 'medic.form.validEmail',
        defaultMessage: 'Not a valid e-mail'
    },
    validSocialSecurityNumberLength: {
        id: 'medic.form.validSocialSecurityNumberLength',
        defaultMessage:
            'Personal identification number should be exactly 12 digits long'
    },
    validSwedishPIN: {
        id: 'medic.form.validSwedishPIN',
        defaultMessage: 'Not a valid personal identification number'
    },
    usernamePlaceholder: {
        id: 'medic.form.usernamePlaceholder',
        defaultMessage: 'Username'
    },
    passwordPlaceholder: {
        id: 'medic.form.passwordPlaceholder',
        defaultMessage: 'Password'
    },
    requiredNote: {
        id: 'medic.form.requiredNote',
        defaultMessage: 'Note is required'
    },
    requiredReason: {
        id: 'medic.form.requiredReason',
        defaultMessage: 'Please select a reason!'
    },
    required: {
        id: 'medic.form.required',
        defaultMessage: 'This field is required!'
    },
    placeholderReason: {
        id: 'medic.form.placeholderReason',
        defaultMessage: 'Select a reason'
    },
    notePlaceholder: {
        id: 'medic.form.notePlaceholder',
        defaultMessage: 'Write a note here'
    },
    messagePlaceholder: {
        id: 'medic.form.messagePlaceholder',
        defaultMessage: 'Type your message here'
    },
    journal: {
        id: 'medic.form.journal',
        defaultMessage: 'Journal entry'
    },
    formLabelUserName: {
        id: 'medic.form.formLabelUserName',
        defaultMessage: 'Username'
    },
    loginSiths: {
        id: 'medic.form.loginSiths',
        defaultMessage: 'Start logon via SITHS'
    },
    validPhoneLength: {
        id: 'medic.form.validPhoneLength',
        defaultMessage: 'Phone number should be exactly 9 digits'
    },
    pinPlaceholder: {
        id: 'medic.form.pinPlaceholder',
        defaultMessage: 'YYYYMMDDXXXX'
    },
    selectGroupPlaceholder: {
        id: 'medic.form.selectGroupPlaceholder',
        defaultMessage: 'Select group'
    }
});

const login = defineMessages({
    logIn: {
        id: 'auth.log.in',
        defaultMessage: 'Log in'
    },
    logInWith: {
        id: 'auth.log.in-with',
        defaultMessage: 'Log in with {value}'
    },
    logOut: {
        id: 'auth.log.out',
        defaultMessage: 'Log out'
    },
    resolved: {
        id: 'auth.resolved',
        defaultMessage: 'Resolved url'
    },
    resolving: {
        id: 'auth.resolving',
        defaultMessage: 'Resolving launch url...'
    },
    redirect: {
        id: 'auth.redirect',
        defaultMessage: 'Redirecting to LifePod'
    },
    loggingIn: {
        id: 'auth.logging-in',
        defaultMessage: 'Logging into LifePod...'
    },
    samlGeneralError: {
        id: 'auth.saml.error.general',
        defaultMessage: 'Something went wrong. Please contact {email}'
    }
});

export default {
    text,
    login
};
