const auth = {
    emailButton: `auth-email-button`,
    userField: 'user-field-login',
    passwordField: 'user-field-password',
    signInButton: `auth-login-button`,
    signOutButton: 'auth-logout-button'
};

const page = {
    headerTitle: 'header-title',
    reportGrid: 'report-grid'
};

const nav = {
    aboutItem: 'about-item',
    achievementsItem: 'achievements-item',
    backButton: 'back-button',
    chatItem: 'chat-item',
    exportItem: 'export-item',
    motdItem: 'motd-item',
    myDataItem: 'my-data-item',
    notificationsItem: 'notifications-item',
    otherItem: 'other-item',
    otherMenuButton: 'other-menu-button',
    profileItem: 'profile-item',
    recommendationsItem: 'recommendations-item',
    reportItem: 'report-item',
    supportItem: 'support-item',
    thumbsUpItem: 'thumbs-up-item',
    userSettingsItem: 'user-settings-item'
};

const report = {
    tilePrefix: 'report-tile',
    pulseTile: 'report-tile-PULSE',
    weightTile: 'report-tile-BODY-WEIGHT',
    medicationTile: 'report-tile-PRNMED',
    plusButton: 'plus-button',
    minusButton: 'minus-button'
};

const form = {
    submitButton: 'submit-button'
};

export const testSelectors = {
    auth,
    page,
    nav,
    report,
    form
};
