import * as actionTypes from './video.constants';
import { CONVERSATION_FETCH_PENDING } from '../chat/chat.constants';
import {
    VideoSession,
    VideoConnection
} from '@lifepod-legacy/openvidu-frontend/dist/api/video-api';

export type VideoState = {
    activeSessions?: VideoSession[];
    token: string;
};

const initialState: VideoState = {
    token: ''
};

export function videoReducer(state = initialState, action: any) {
    const { type, payload } = action;
    switch (type) {
        case actionTypes.VIDEO_MEETINGS_FETCH_FULFILLED:
            return {
                ...state,
                activeSessions: payload as VideoSession[]
            };
        case actionTypes.VIDEO_MEETING_JOIN_FULFILLED: {
            const { token } = payload as VideoConnection;
            return {
                ...state,
                token
            };
        }
        case actionTypes.VIDEO_MEETING_JOIN_REJECTED: {
            // Removes the current session from active video meetings
            return {
                ...state,
                activeSessions: []
            };
        }
        case actionTypes.VIDEO_MEETING_LEAVE_PENDING:
            // Removes the current session from active video meetings
            return {
                ...state,
                activeSessions: []
            };
        case CONVERSATION_FETCH_PENDING:
            // Reloads active video meetings when loading chat page
            return initialState;
        default:
            return state;
    }
}
