import { defineMessages } from 'react-intl';

const main = defineMessages({
    noData: {
        id: 'medic.chart.patientDetails.noData',
        defaultMessage: 'No data..'
    }
});

export default {
    main
};
