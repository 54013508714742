import { createGlobalStyle } from 'styled-components';
import { StyledThemeProfile } from './styled';

type ThemeProps = {
    theme: StyledThemeProfile;
};

export const GlobalStyle = createGlobalStyle`
    body {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        letter-spacing: 0.05rem;
        word-wrap: break-word;
        overflow: hidden;
    }

    #app {
        height: 100%;
        background: ${(props: ThemeProps) => props.theme.appBackground};

        @media (orientation: landscape) and (min-width: 1024px) and (min-height: 640px) {
            display: flex;
            align-items: center;
        }

        @media (max-width: 1023px) {
            // Fix for firefox on android 
            // (source - "https://dev.to/rachelg/a-javascript-fix-for-the-100vh-problem-on-mobile-screens-9im#:~:text=not%20always...-,100vh.,of%20rems%20of%20your%20design.")
            min-height: 100vh;
            min-height: fill-available;
            min-height: -webkit-fill-available;
            overflow-y: hidden;
        }
    }

    .error {
        color: ${(props: ThemeProps) => props.theme.color.error};
    }

    .ant-layout-content {
        height: 100%;
        overflow: hidden;

        background: ${(props: ThemeProps) =>
            props.theme.appContainerBackground};
    }

    .tab-content {
        &.ant-tabs {
            height: 100%;

            .ant-tabs-content {
                height: calc(100% - 48px);
            }

            .ant-tabs-bar {
                margin: 0 !important;
            }
        }
    }

    .tab-content .ant-tabs-nav {
        width: 100%;
    }

    .tab-content .ant-tabs-nav > div {
        display: flex;

        .ant-tabs-tab {
            flex-grow: 1;
            text-align: center;
        }
    }

    /* Ant design is using margin to center the modal. This is not as flexibel as using display flex. */
    .ant-modal-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow-x: hidden;
    }
`;
