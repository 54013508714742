import { defineMessages } from 'react-intl';

export const general = defineMessages({
    title: {
        defaultMessage: 'Body length',
        id: 'indicator.body-length.title'
    },
    value: {
        id: 'indicator.body-length.value',
        defaultMessage: '{value}'
    }
});
