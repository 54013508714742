import { degree } from '../../../conditions/shared-indicators/swelling/messages/degree';
import { ChartEnum } from '../../../types/indicator/chart.types';
import { IndicatorPropertyRules } from '../../../types/indicator/indicator-rules.type';
import { colors } from '../../../theme/colors/common-colors';
export const toHeatmapValue = (value: SwellingValue) => value.degree;

type SwellingValue = {
    position: number;
    degree: number;
};

export const rules: IndicatorPropertyRules<keyof SwellingValue, SwellingValue> =
    {
        neutralReportResult: true,
        invertReportResponse: false,
        step: 1,
        chartTypes: [ChartEnum.Heatmap],
        toHeatmapValue: toHeatmapValue,
        heatmapPieces: [
            {
                value: 1,
                color: colors.chart.heatmap.purple10,
                text: degree.value
            },
            {
                value: 2,
                color: colors.chart.heatmap.purple30,
                text: degree.value
            },
            {
                value: 3,
                color: colors.chart.heatmap.purple50,
                text: degree.value
            },
            {
                value: 4,
                color: colors.chart.heatmap.purple80,
                text: degree.value
            }
        ]
    };

export default rules;
