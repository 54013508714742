import { styled } from '../../../theme/styled';

export const FlexColumnContainer = `
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const StyledImg = styled.div<{ size: string }>`
    flex-grow: 0;
    height: ${(props) => props.size};
    min-width: ${(props) => props.size};
    width: ${(props) => props.size};

    // IE 11 images fix.
    div {
        div {
            width: ${(props) => props.size};
            height: ${(props) => props.size};
        }
    }
`;

export const StyledRowContainer = styled.li`
    min-height: 68px;
    background-color: white;
    border-bottom: 1px solid rgba(232, 233, 232, 0.7);
    align-items: center;
    padding: 10px;
    position: relative;
`;

export const StyledRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: start;
`;

export const StyledRowComment = styled.div`
    > p {
        &:first-child {
            font-weight: bold;
        }

        margin-bottom: 0;
        font-size: 12px;
    }
`;

export const StyledRowText = styled.div`
    padding: 0;
    line-height: 1.2;
`;

export const StyledRowContent = styled.div`
    margin: 0;
    flex-grow: 1;
    padding-left: 20px;
`;

export const StyledRowTitle = styled<any>(StyledRowText)`
    font-size: 1.2rem;
    color: #2a2a2a;
    font-weight: 400;
    margin-bottom: 3px;
`;

export const StyledDataContainer = styled.div`
    ${FlexColumnContainer}
`;

export const StyledDataItem = styled<any>(StyledRowText)`
    font-size: 0.8rem;
    margin: 0;
    color: #2c8eff;
    text-transform: none;
    display: inline-block;
`;

export const StyledRemoveButtonAndTimestamp = styled.div`
    display: flex;
    flex-grow: 0;
    flex-direction: column;
`;
