export const REPORT_VALUE = 'REPORT_VALUE';
export const REPORT_VALUE_PENDING = 'REPORT_VALUE_PENDING';
export const REPORT_VALUE_FULFILLED = 'REPORT_VALUE_FULFILLED';
export const REPORT_VALUE_REJECTED = 'REPORT_VALUE_REJECTED';
export const RESET_FEEDBACK = 'RESET_FEEDBACK';
export const RESET_TILES = 'RESET_TILES';
export const UPDATE_REPORT_STATUS = 'UPDATE_REPORT_STATUS';
export const FETCH_REPORT_STATUS = 'FETCH_REPORT_STATUS';
export const FETCH_REPORT_STATUS_PENDING = 'FETCH_REPORT_STATUS_PENDING';
export const FETCH_REPORT_STATUS_FULFILLED = 'FETCH_REPORT_STATUS_FULFILLED';
export const FETCH_REPORT_STATUS_REJECTED = 'FETCH_REPORT_STATUS_REJECTED';
