import { styled } from '../../theme/styled';
import { List } from 'antd';

export const StyledMeta = styled(List.Item.Meta)<{
    $isNew: boolean;
}>`
    && {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 0px 10px 10px;

        .ant-list-item-meta-title {
            margin: 0;
            font-size: 13px;
            font-weight: ${(props) => (props.$isNew ? 900 : 'normal')};
        }
        .ant-avatar {
            background-color: transparent;
        }
    }
`;
