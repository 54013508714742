import { defineMessages } from 'react-intl';

export const general = defineMessages({
    title: {
        defaultMessage: 'mMRC',
        id: 'indicator.mmrc.title'
    },
    value: {
        defaultMessage:
            '{value, select, 1 {Breathless from heavy activity, not from fast paced walk or from walk up hill} 2 {Breathless from fast paced walk or from up hill walk} 3 {Breathless while walking on plane ground, at the same pace as someone my age} 4 {I need to stop while walking from breathlessness even though i set my own pace} 5 {Breathlessness from getting dressed or from washing my self}}',
        id: 'indicator.mmrc.value'
    }
});
