import { ChartEnum } from '../../../types/indicator/chart.types';
import { IndicatorPropertyRules } from '../../../types/indicator/indicator-rules.type';
import { colors } from '../../../theme/colors/common-colors';

export const rules: IndicatorPropertyRules<'rest' | 'active'> = {
    neutralReportResult: false,
    invertReportResponse: false,
    hideValueWhenReporting: false,
    step: 1,
    chartTypes: [ChartEnum.Spline],
    lineColor: colors.chart.graph.obj.painCi,
    chartOptions: {
        fixedYAxisMin: 0,
        fixedYAxisMax: 10
    }
};

export default rules;
