import { defineMessages } from 'react-intl';

export const anticoagulant = defineMessages({
    title: {
        defaultMessage: 'Anticoagulant',
        id: 'indicator.medication-ci.anticoagulant.title'
    },
    question: {
        defaultMessage: 'Anticoagulant',
        id: 'indicator.medication-ci.anticoagulant.question'
    },
    value: {
        defaultMessage:
            '{value, select, 0 {No} 1 {Yes} other {I do not have this medication}}',
        id: 'indicator.medication-ci.anticoagulant.value'
    },
    valueNa: {
        defaultMessage: 'I do not have this medication',
        id: 'indicator.medication-ci.anticoagulant.choices.-1'
    },
    valueNo: {
        defaultMessage: 'No',
        id: 'indicator.medication-ci.anticoagulant.choices.0'
    },
    valueYes: {
        defaultMessage: 'Yes',
        id: 'indicator.medication-ci.anticoagulant.choices.1'
    }
});
