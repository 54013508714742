import React from 'react';
import { Card } from 'antd';
import { formatMessage, Strings } from '../../../locale';
import { MarkdownFromDescriptor } from '../../../locale/utils/functions/format-markdown';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ButtonLargePrimary } from '../../../components/ui/buttons/ButtonLarge';
import {
    StyledHeader,
    StyledHeaderContent,
    StyledModal,
    StyledSubtitle,
    StyledTitle
} from '../../../components/ui/modal/StyledModal';
import { useIndicator } from '../../../hooks/use-indicator';
import { styled } from '../../../theme/styled';
import { IndicatorIconWithBorder } from '../../../components/indicator-icon/IndicatorIcon';

type Props = {
    code: string;
    open: boolean;
};

type Params = { code: string };

export const IndicatorInfoModal = (): JSX.Element => {
    const infoMatch = useRouteMatch<Params>('/report/:code/info');
    const match = useRouteMatch<Params>('/report/:code');

    if (!match) {
        return <></>;
    }

    return (
        <ToggleIndicatorInfoModal
            code={match.params.code}
            open={infoMatch != null}
        />
    );
};

const ToggleIndicatorInfoModal = ({ code, open }: Props) => {
    const indicator = useIndicator(code);
    const history = useHistory();

    if (!indicator || !indicator.messages.info) {
        return <></>;
    }

    return (
        <StyledModal
            title={
                <StyledHeader>
                    <IndicatorIconWithBorder icon={indicator.icon} size={45} />
                    <StyledHeaderContent>
                        <StyledTitle>
                            {formatMessage(indicator.messages.general.title)}
                        </StyledTitle>
                        <StyledSubtitle>
                            {formatMessage(Strings.common.text.information)}
                        </StyledSubtitle>
                    </StyledHeaderContent>
                </StyledHeader>
            }
            destroyOnClose={true}
            closable={false}
            visible={open}
            footer={[
                <ButtonLargePrimary
                    style={{ height: '50px' }}
                    key={Strings.ui.buttons.main.ok.id}
                    onClick={() => {
                        history.replace(`/report/${code}`);
                    }}
                >
                    {formatMessage(Strings.ui.buttons.main.ok)}
                </ButtonLargePrimary>
            ]}
        >
            <StyledInfoContent>
                <StyledInfoCard>
                    <MarkdownFromDescriptor
                        messageDescriptor={indicator.messages.info.main}
                    />
                </StyledInfoCard>
            </StyledInfoContent>
        </StyledModal>
    );
};

const StyledInfoContent = styled.div`
    height: 100%;
    padding: 10px;
    overflow: auto;
    background: rgba(230, 236, 240, 0.24);
`;

const StyledInfoCard = styled(Card)`
    && {
        font-size: 13px;
        white-space: unset;
        line-height: 1.9;
    }
`;
