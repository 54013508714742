import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from '../../theme/styled';
import { formatMessage, Strings } from '../../locale';
import { Card } from 'antd';
import { ContentLayout } from '../../components/layout/ContentLayout';
import { Header } from '../../components/layout/Header';
import { theme } from '../../theme/colors/common-colors';
import { AppState } from '../../redux/store/root-reducer';
import { StyledListItem } from '../../components/list/StyledListItem';
import { StyledNavLink } from '../../components/list/StyledNavLink';
import { StyledTitle } from '../../components/list/StyledTitle';
import { StyledArrow } from '../../components/list/StyledArrow';
import { StyledList } from '../../components/list/StyledList';
import { ButtonLarge } from '../../components/ui/buttons/ButtonLarge';
import { testSelectors } from '../../constants/test-id';
import { logout } from '../../redux/domains/authentication/authentication.actions';
import { otherRoutes } from './other-routes';
import { routePaths } from '../../constants/route-paths';

const appConfig = __APP_CONFIG__;

export const OtherPage = (): JSX.Element => {
    const dispatch = useDispatch();
    const handleLogout = () => {
        dispatch(logout());
    };

    const renderListItem = (item: any) => (
        <StyledListItem key={item.path} data-test-id={item.testId}>
            <StyledNavLink to={item.path ? item.path : ''}>
                {item.icon}
                <StyledTitle>{item.title}</StyledTitle>
                <StyledArrow />
            </StyledNavLink>
        </StyledListItem>
    );

    return (
        <>
            <Header title={formatMessage(Strings.navigation.main.other)} />
            <ContentLayout>
                {appConfig.STAGE === 'dev' ? <LoggedInAsCard /> : <></>}

                <StyledList
                    dataSource={otherRoutes}
                    renderItem={renderListItem}
                />

                <StyledMoreLogoutButton
                    icon="lock"
                    htmlType="submit"
                    onClick={handleLogout}
                    size="large"
                    data-test-id={testSelectors.auth.signOutButton}
                >
                    {formatMessage(Strings.common.text.signOut)}
                </StyledMoreLogoutButton>
            </ContentLayout>
        </>
    );
};

function LoggedInAsCard() {
    const user = useSelector((state: AppState) => state.user);
    return (
        <Card
            size="small"
            style={{ margin: '5px' }}
            bodyStyle={{
                padding: 6,
                textAlign: 'center'
            }}
        >
            <StyledLoggedInAs>
                {formatMessage(Strings.common.text.loggedInAs)}
            </StyledLoggedInAs>
            <StyledLoggedInName>{`${user.first_name} ${user.family_name}`}</StyledLoggedInName>
            <StyledLoggedInName>{`${user.email}`}</StyledLoggedInName>
        </Card>
    );
}

const StyledLoggedInAs = styled.div`
    font-weight: 600;
    font-size: 0.7rem;
    margin-bottom: 5px;
`;

const StyledLoggedInName = styled.div`
    color: ${theme.layout.primary};
    font-size: 1.2rem;
`;

const StyledMoreLogoutButton = styled(ButtonLarge)`
    && {
        width: calc(100% - 40px);
        font-size: 18px;
        height: 60px;
        margin: 20px;
    }
`;
