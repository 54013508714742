import React from 'react';
import { styled } from '../../../../../theme/styled';
import { FetchedIndicator } from '../../../../../types/indicator/fetched-indicator.type';
import { MarkdownFromDescriptor } from '../../../../../locale/utils/functions/format-markdown';

type Props = {
    indicator: FetchedIndicator;
};

export const SliderDescription = ({ indicator }: Props) => {
    if (indicator.messages.general.description)
        return (
            <StyledSliderDescription>
                <MarkdownFromDescriptor
                    messageDescriptor={indicator.messages.general.description}
                />
            </StyledSliderDescription>
        );
    return <></>;
};

export const StyledSliderDescription = styled.div`
    margin: 25px 0 0 0;

    p {
        font-size: 12px;
        font-weight: 600;
        line-height: 1.5;
    }
`;
