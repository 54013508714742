import React, { useState } from 'react';
import { range } from 'lodash';
import { Card, Radio } from 'antd';
import { Comment } from '../common/Comment';
import { DateTimePicker } from '../common/DateTimePicker';
import { RadioGroupQuestion } from './components/RadioGroupQuestion';
import { formatMessage } from '../../../../locale';
import { FetchedIndicator } from '../../../../types/indicator/fetched-indicator.type';
import { MissedReportInfo } from '../common/MissedReportInfo';
import { isFetchedIndicatorProperties } from '../../../../types/indicator/fetched-indicator.type';
import { StyledRow } from './components/styled';
import { useCloseModal, useSubmitModal } from '../common/ModalForm';
import { ModalFormButtons } from '../common/ModalFormButtons';
import {
    StyledBody,
    StyledBodyContent
} from '../../../../components/ui/modal/StyledModal';

type Props = {
    indicator: FetchedIndicator;
};

const DEGREE = 'degree';
const POSITION = 'position';

export const SwellingForm = ({ indicator }: Props): JSX.Element => {
    const closeModal = useCloseModal();
    const handleSubmit = useSubmitModal(indicator);
    const [degreeValue, setDegreeValue] = useState<number>(0);
    const [positionValue, setPositionValue] = useState<number>(0);
    const properties = indicator.data.jsonSchema.properties;

    if (
        !properties ||
        !properties[DEGREE] ||
        !properties[POSITION] ||
        !isFetchedIndicatorProperties(indicator)
    ) {
        throw Error(
            'Swelling form must have degree and position as properties in the jsonSchema.'
        );
    }

    const degreeProp = properties[DEGREE];
    const positionProp = properties[POSITION];
    const degreeValueMessageDescriptors = indicator.messages[DEGREE]?.value;
    const positionValueMessageDescriptors = indicator.messages[POSITION]?.value;

    return (
        <StyledBody onSubmit={handleSubmit}>
            <StyledBodyContent>
                <MissedReportInfo indicator={indicator} />
                <Card>
                    <DateTimePicker />
                    <div style={{ margin: '5px 0' }}>
                        <RadioGroupQuestion
                            indicator={indicator}
                            property={DEGREE}
                        />
                        <Radio.Group
                            name={DEGREE}
                            onChange={(event) => {
                                const newDegreeValue = parseInt(
                                    event.target.value
                                );
                                setDegreeValue(newDegreeValue);
                                if (newDegreeValue === 1) {
                                    setPositionValue(0);
                                }
                            }}
                            value={degreeValue}
                        >
                            {range(
                                degreeProp.minimum || 0,
                                (degreeProp.maximum || 0) + 1
                            ).map((value) => (
                                <StyledRow key={value}>
                                    <Radio value={value}>
                                        {degreeValueMessageDescriptors
                                            ? formatMessage(
                                                  degreeValueMessageDescriptors,
                                                  { value }
                                              )
                                            : ''}
                                    </Radio>
                                </StyledRow>
                            ))}
                        </Radio.Group>
                        {degreeValue > 1 && (
                            <>
                                <RadioGroupQuestion
                                    indicator={indicator}
                                    property={POSITION}
                                />
                                <Radio.Group
                                    name={POSITION}
                                    onChange={(event) => {
                                        setPositionValue(
                                            parseInt(event.target.value)
                                        );
                                    }}
                                    value={positionValue}
                                >
                                    {range(
                                        positionProp.minimum || 0,
                                        (positionProp.maximum || 0) + 1
                                    ).map((value) => (
                                        <StyledRow key={value}>
                                            <Radio value={value}>
                                                {positionValueMessageDescriptors
                                                    ? formatMessage(
                                                          positionValueMessageDescriptors,
                                                          { value }
                                                      )
                                                    : ''}
                                            </Radio>
                                        </StyledRow>
                                    ))}
                                </Radio.Group>
                            </>
                        )}
                        {degreeValue < 2 && (
                            <input type="hidden" name={POSITION} value="1" />
                        )}
                    </div>
                    <Comment />
                </Card>
            </StyledBodyContent>
            <ModalFormButtons
                closeModal={closeModal}
                isFormValid={isValid(degreeValue, positionValue)}
            />
        </StyledBody>
    );
};

function isValid(degree: number, position: number) {
    return degree === 1 || (degree > 1 && position > 0);
}
