import { defineMessages } from 'react-intl';

const notification = defineMessages({
    empty: {
        defaultMessage: 'No notifications',
        id: 'notification.empty'
    },
    message: {
        defaultMessage: "You've received a message from {fullName}",
        id: 'notification.message.received'
    },
    reminderUnknown: {
        defaultMessage: 'Missed report',
        id: 'notification.reminder.unknown'
    },
    missedReport: {
        defaultMessage: 'Missed report - {indicatorName}',
        id: 'notification.reminder.missed'
    },
    watchover: {
        defaultMessage: 'Checked by {fullName}',
        id: 'notification.watchover.checked'
    },
    feedback: {
        defaultMessage: 'Well done, keep it up {fullName}',
        id: 'notification.feedback.good-job'
    }
});

export default {
    main: notification
};
