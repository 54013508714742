import { defineMessages } from 'react-intl';

export const demoDiabetes = defineMessages({
    name: {
        defaultMessage: 'Diabetes',
        id: 'conditions.LP_2_0_DIABETES_RS_DEMO_UH.name'
    },
    nameLong: {
        defaultMessage: 'Diabetes',
        id: 'conditions.LP_2_0_DIABETES_RS_DEMO_UH.name-long'
    }
});
