import { defineMessages } from 'react-intl';

export const status = defineMessages({
    value: {
        defaultMessage:
            '{value, select, 1 {No phlegm} 2 {Easy to cough up} 3 {Hard to cough up}}',
        id: 'indicator.phlegm.status.value'
    },
    question: {
        defaultMessage: 'Phlegm status',
        id: 'indicator.phlegm.status.description'
    },
    valueNoPhlegm: {
        defaultMessage: 'No phlegm',
        id: 'indicator.phlegm.status.choices.1'
    },
    valueEasyToCoughUp: {
        defaultMessage: 'Easy to cough up',
        id: 'indicator.phlegm.status.choices.2'
    },
    valueHardToCoughUp: {
        defaultMessage: 'Hard to cough up',
        id: 'indicator.phlegm.status.choices.3'
    }
});
