import { defineMessages } from 'react-intl';

export const general = defineMessages({
    title: {
        defaultMessage: 'EHRA',
        id: 'indicator.ehra.title'
    },
    value: {
        defaultMessage:
            '{value, select, 1 {1 - AF does not cause any symptoms} 2 {2a - Normal daily activity not affected by symptoms related to AF} 3 {2b - Normal daily activity not affected by symptoms related to AF, but patient troubled by symptoms} 4 {3 - Normal daily activity affected by symptoms related to AF} 5 {4 - Normal daily activity discontinued}}',
        id: 'indicator.ehra.value'
    }
});
