import {
    BaseIndicator,
    Indicator,
    Property
} from '../types/indicator/indicator.type';
import { IndicatorPropertyMessages } from '../types/indicator/indicator-messages.type';
import { IndicatorRecommendations } from '../types/indicator/indicator-recommendations.type';
import { IndicatorRules } from '../types/indicator/indicator-rules.type';
import { InfoTileType } from '../types/info-tile/info-tile.type';
import { conditions } from './index';

const indicator = Object.values(conditions)[0].indicators[0];

export type ConditionsKey = keyof typeof conditions;

export const isConditionsKey = (code: string): code is ConditionsKey =>
    code in conditions;

export type ConditionIndicator = (
    | Indicator<IndicatorRules>
    | (BaseIndicator & {
          messages: IndicatorPropertyMessages<Property>;
          recommendations?: IndicatorRecommendations<Property[]>;
          properties?: typeof indicator.properties;
          rules: IndicatorRules;
      })
) & {
    rules: typeof indicator.rules;
};

export type Condition = {
    code: string;
    name: string;
    nameLong: string;
    indicators: ConditionIndicator[];
    infoTiles?: InfoTileType[];
    publicCode: string;
};
