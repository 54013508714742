import React, { useEffect } from 'react';
import axios from 'axios';
import { formatMessage, Strings } from '../../../../locale';
import { Alert } from 'antd';
import { styled } from '../../../../theme/styled';
import { SupportCard } from './SupportCard';
import { StyledSubPage } from '../styled';
import { ContentLayout } from '../../../../components/layout/ContentLayout';
import { Header } from '../../../../components/layout/Header';
import { support } from './message-descriptors';
import { routePaths } from '../../../../constants/route-paths';
import { useCondition } from '../../../../hooks/use-condition';
import { resource } from '../../../../constants/resources';
import { CustomImage } from '../../../../components/custom-image/CustomImage';
import { supportImages } from '../../../../static/images/support/support-images';
import { ButtonLarge } from '../../../../components/ui/buttons/ButtonLarge';

const supportText = {
    title: formatMessage(Strings.screen.support.main.title),
    phoneNumber: Strings.appDetails.companyDetails.phoneNumber,
    supportEmail: Strings.appDetails.companyDetails.supportEmail,
    contactMedic: formatMessage(Strings.screen.support.main.contactMedic),
    contactEmergency: formatMessage(
        Strings.screen.support.main.contactEmergency
    ),
    mailtoSubject: formatMessage(Strings.screen.support.main.emailSubject),
    telephoneButtonText: formatMessage(Strings.screen.support.main.callSupport),
    ahrefTel: `tel:${Strings.appDetails.companyDetails.phoneHref}`,
    emailSupportButton: formatMessage(Strings.screen.support.main.emailSupport)
};

export const SupportPage = () => {
    return (
        <>
            <Header
                backButtonPath={routePaths.other}
                title={formatMessage(support.header.title)}
            />
            <ContentLayout>
                <StyledSubPage>
                    <SupportCard>
                        <Alert
                            banner
                            style={{ margin: '10px 0 10px 0' }}
                            message={supportText.contactMedic}
                            type="warning"
                        />
                        <Alert
                            style={{ margin: '0px 0 10px 0' }}
                            message={supportText.contactEmergency}
                            type="error"
                        />
                    </SupportCard>
                    <SupportCard>
                        <p>
                            {formatMessage(
                                Strings.ui.buttons.main.downloadManual
                            )}
                        </p>
                        <StyledCenterColumn>
                            <ManualButton />
                        </StyledCenterColumn>
                    </SupportCard>
                    <SupportCard>
                        <p>
                            {formatMessage(
                                Strings.screen.support.main.technicalDescription
                            )}
                        </p>
                        <StyledCenterColumn>
                            <StyledButtonLarge
                                icon="mail"
                                href={`mailto:${supportText.supportEmail}?subject=${supportText.mailtoSubject}`}
                            >
                                {supportText.supportEmail}
                            </StyledButtonLarge>
                            <StyledButtonLarge
                                icon="phone"
                                href={supportText.ahrefTel}
                            >
                                {supportText.phoneNumber}
                            </StyledButtonLarge>
                        </StyledCenterColumn>
                    </SupportCard>
                </StyledSubPage>
            </ContentLayout>
        </>
    );
};

const StyledCenterColumn = styled.div`
    && {
        display: flex;
        flex-direction: column;

        align-items: center;
        a:not(:last-child) {
            margin-bottom: 10px;
        }
    }
`;

const ManualButton = () => {
    const url = resource.urlForManual();

    useEffect(
        function () {
            // Preload to make sure Origin header is added with request
            void axios(url);
        },
        [url]
    );

    return (
        <StyledButtonLarge href={url}>
            <StyledSVGIcon src={supportImages.manual} />
            <StyledSVGIcon src={supportImages.triangle} />
            {formatMessage(Strings.ui.buttons.main.downloadTitle)}
        </StyledButtonLarge>
    );
};

const StyledSVGIcon = styled(CustomImage)`
    width: 35px;
    height: 35px;
    margin-right: 5px;

    path {
        fill: rgba(0, 0, 0, 0.65);
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
`;

const StyledButtonLarge = styled(ButtonLarge)`
    width: 80%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    :hover {
        path {
            fill: ${(p) => p.theme.color.icon.highlight};
        }
    }
`;
