import moment from 'moment';
import groupBy from 'lodash/groupBy';
import { Condition } from '../../../conditions/condition.type';
import { CondtionByCode } from '../../../redux/domains/conditions/condtions.types';

export function getFetchHistoryValuesParams(
    condition: Condition,
    conditionState: CondtionByCode,
    offset: number,
    limit: number
) {
    const hiddenIndicators = condition.indicators
        .filter((i) => i.hiddenHistory)
        .map((i) => i.code);
    const { allCodes, byCode = {} } = conditionState.indicators;
    const indicatorCodes = allCodes
        .filter(
            (code: string) =>
                byCode[code].category === 'observation' &&
                !hiddenIndicators.includes(code)
        )
        .map((code: string) => code)
        .join(',');

    return {
        offset,
        limit,
        indicatorCodes
    };
}

export function getDayGroups(values: any) {
    // These values might have to be filtered on type "values" (if only values are to be viewed)
    // in the future since we are getting all kinds of history items back.
    return groupBy(values, (value: any) => {
        return moment(value.date).startOf('day').format();
    });
}
