import { defineMessages } from 'react-intl';

export const cough = defineMessages({
    title: {
        defaultMessage: 'Cough',
        id: 'indicator.cat.cough.title'
    },
    maxValueDescription: {
        defaultMessage: 'I cough all the time',
        id: 'indicator.cat.cough.max-text'
    },
    minValueDescription: {
        defaultMessage: 'I never cough',
        id: 'indicator.cat.cough.min-text'
    }
});
