export const routePaths = {
    about: '/about',
    achievements: '/achievements',
    chat: '/chat',
    export: '/export',
    motd: '/message-of-the-day',
    myData: '/my-data',
    notifications: '/notifications',
    other: '/other',
    profile: '/profile',
    recommendations: '/recommendations',
    report: '/report',
    settings: '/settings',
    signin: '/signin',
    signinEmail: '/signin/email',
    support: '/support',
    thumbsUp: '/thumbs-up',
    video: '/video'
};
