import { defineMessages } from 'react-intl';

export const general = defineMessages({
    title: {
        defaultMessage: 'Body temperature',
        id: 'indicator.body-temp.title'
    },
    value: {
        id: 'indicator.body-temp.value',
        //defaultMessage: '{value} &deg;C'
        defaultMessage: '{value, number, ::.0}'
    },
    unit: {
        id: 'indicator.body-temp.unit',
        defaultMessage: '°C'
    }
});
