import { isEmpty, isMatch, sortBy } from 'lodash';
import { Value, ValuesIndicator } from './values.types';

const NO_SWELLING = {
    degree: 1
};

export function parseValue(value: Value) {
    if (
        value.indicatorCode === 'SWELLING' && // swelling.code -> Circular dependency
        isMatch(value, { value: NO_SWELLING })
    ) {
        return {
            ...value,
            value: NO_SWELLING
        };
    }

    return value;
}

const valuesNormalize = (data: any) => {
    if (isEmpty(data)) return [];

    return data[0].indicators.map(
        ({ values, ...indicator }: ValuesIndicator) => ({
            ...indicator,
            values: sortBy(values, 'date').map(parseValue)
        })
    );
};

export { valuesNormalize };
