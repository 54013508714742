export const achievements = {
    milestone: {
        major: {
            achieved: '#93E76F',
            unAchieved: '#D8D8D8',
            background: {
                achieved: '#93E76F',
                unAchieved: '#D8D8D8',
                next: '#BEE6F9'
            }
        },
        minor: {
            achieved: '#f2ab3a',
            unAchieved: '#D9D9D9',
            background: {
                achieved: '#F5E899',
                unAchieved: '#E1F6FF',
                next: '#BEE6F9'
            }
        },
        shadow: '#ccc'
    }
};
