import React, { Component } from 'react';

import { AchievementMilestone } from './AchievementMilestone';
import { AchievementHandler } from '../../../../../../models/achievement';
import { StyledAchievementCardContent } from './styled-card/StyledAchievementCardContent';
import { BaseAchievedModal } from '../../achieved-modals/BaseAchievedModal';

type Props = { achievement: AchievementHandler; index: number };
type State = { showAchievementModal: boolean; current: number };

export class AchievementCardContent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { showAchievementModal: false, current: 0 };
    }

    handleAchievementTrigger = (
        achievement: AchievementHandler,
        index: number
    ) => {
        achievement.setCurrentIndex(index);
        this.setState({ showAchievementModal: true });
    };

    getMajorStonesComponents(achievement: AchievementHandler) {
        return achievement.getMajorMilestonesData().map((stone) => {
            const handleOk = () => {
                this.handleAchievementTrigger(achievement, stone.index);
                this.setState({
                    showAchievementModal: true,
                    current: stone.index
                });
            };

            return (
                <AchievementMilestone
                    key={stone.stone}
                    isMinor={stone.isMinor}
                    isAchieved={stone.isAchieved}
                    imgSrc={stone.img}
                    onClick={stone.isAchieved ? handleOk : null}
                />
            );
        });
    }

    getMinorStonesComponents(achievement: AchievementHandler) {
        return achievement.getMinorMilestonesData().map((stone) => {
            return (
                <AchievementMilestone
                    key={stone.stone}
                    isMinor={stone.isMinor}
                    isAchieved={stone.isAchieved}
                    imgSrc={stone.img}
                    onClick={null}
                />
            );
        });
    }

    getAchievementModalDetails(achievement: AchievementHandler) {
        const details = achievement.getAchievementModalDetails();
        const handleOk = () => {
            achievement.resetAchievement();
            this.setState({ showAchievementModal: false, current: 0 });
        };

        return (
            <BaseAchievedModal
                title={details.title}
                body={details.body}
                avatarUrl={achievement.getModalImg(this.state.current)}
                milestoneType={'major'}
                handleOnOkAchievement={handleOk}
                showAchievementModal={this.state.showAchievementModal}
            />
        );
    }

    render() {
        const { achievement } = this.props;

        const achievementCardText = achievement.getCardContentText();

        return (
            <StyledAchievementCardContent
                infoModalDetails={this.getAchievementModalDetails(achievement)}
                majorStones={this.getMajorStonesComponents(achievement)}
                content={achievementCardText.encourage}
                minorStones={this.getMinorStonesComponents(achievement)}
                progress={achievementCardText.percentage}
                progressText={achievementCardText.progress}
            />
        );
    }
}
