import { actionTypes } from '../redux/domains/authentication/authentication.constants';
import { extractUserFromIdToken } from '../utils/token';
import { removeTokensFromLocalStorage } from '../utils/local-storage-user';

export function handleError(store: any) {
    removeTokensFromLocalStorage();
    store.dispatch({
        type: actionTypes.AUTHENTICATION_CHECK_REJECTED
    });
}

export function handleSignout(store: any) {
    removeTokensFromLocalStorage();
    store.dispatch({
        type: actionTypes.AUTHENTICATION_LOGOUT_FULFILLED
    });
    localStorage.setItem('logout', Date.now().toString());
}

export function handleSuccess(store: any) {
    store.dispatch({
        type: actionTypes.AUTHENTICATION_CHECK_FULFILLED,
        payload: extractUserFromIdToken()
    });
}
