import { List } from 'antd';
import { styled } from '../../theme/styled';

export const StyledHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const StyledCardTitle = styled.div`
    white-space: break-spaces;
    font-weight: 600;
    margin-left: 20px;
`;

export const StyledCardAvatar = styled.div`
    height: 2.2rem;
    width: 2.2rem;
`;

export const StyledListItem = styled(List.Item)`
    .ant-card {
        width: 100%;
    }

    .ant-list-item-content {
        width: 100%;
    }
`;

export const StyledRecommendations = styled.div`
    margin: auto;
    width: 100%;
    font-size: 13px;
`;
