import { prnmed } from './indicators/prnmed/prnmed';
import { dizziness } from '../shared-indicators/dizziness/dizziness';
import { swelling } from '../shared-indicators/swelling/swelling';
import { bodyWeight } from '../shared-indicators/body-weight/body-weight';
import { tiredness } from '../shared-indicators/tiredness/tiredness';
import { breathlessness } from '../shared-indicators/breathlessness/breathlessness';
import { bloodPressure } from '../shared-indicators/blood-pressure/blood-pressure';
import { bloodPressureAverage } from './indicators/blood-pressure-average/blood-pressure-average';
import { vas } from '../shared-indicators/vas/vas';
import { physact } from '../shared-indicators/physact/physact';
import { pulse } from '../shared-indicators/pulse/pulse';
import { pumpFlow } from './indicators/pump-flow/pump-flow';
import { pumpSpeed } from './indicators/pump-speed/pump-speed';
import { pumpPulseIndex } from './indicators/pump-pulse-index/pump-pulse-index';
import { pumpPower } from './indicators/pump-power/pump-power';
import { pumpSelfTest } from './indicators/pump-self-test/pump-self-test';
import { eq5d3l } from '../shared-indicators/eq5d3l/eq5d3l';
import { formatMessage, Strings } from '../../locale';

const code = 'LP_2_0_LVAD_RS';
const publicCode = 'lvad';

const indicators = [
    prnmed,
    dizziness,
    swelling,
    bodyWeight,
    tiredness,
    breathlessness,
    bloodPressure,
    bloodPressureAverage,
    vas,
    physact,
    pulse,
    pumpFlow,
    pumpSpeed,
    pumpPulseIndex,
    pumpPower,
    pumpSelfTest,
    eq5d3l
];

const condition = {
    [code]: {
        code,
        indicators,
        publicCode,
        name: formatMessage(Strings.conditions.rs.lvad.name),
        nameLong: formatMessage(Strings.conditions.rs.lvad.nameLong)
    }
};

export default condition;
