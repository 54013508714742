import React from 'react';
import moment from 'moment';
import { styled } from '../../../theme/styled';
import { getLanguageCode } from '../../../locale/utils/locale-setup';

type Props = {
    date: string;
};

export const HistoryListDateItem = ({ date }: Props) => {
    return (
        <StyledListItem>
            <StyledDay>
                {moment(date).locale(getLanguageCode()).format('dddd')}
            </StyledDay>
            <StyledDate>
                {moment(date).locale(getLanguageCode()).format('ll')}
            </StyledDate>
        </StyledListItem>
    );
};

const StyledDay = styled.span`
    flex: 1;
`;

const StyledDate = styled.span``;

const StyledListItem = styled.li`
    display: flex;
    background-color: transparent;
    padding: 7.5px 10px;
    color: #575757;
    border-bottom: 1px solid rgba(232, 233, 232, 0.7);
    font-weight: 500;
    font-size: 0.85rem;
`;
