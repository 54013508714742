import { createSelector } from 'reselect';
import keyBy from 'lodash/keyBy';
import { AppState } from '../../store/root-reducer';
import { Indicators } from './condtions.types';

const conditions = (state: AppState) => state.conditions;

const indicators = (state: AppState): Indicators | undefined =>
    getSelectedCondition(state)?.indicators;

export const getSelectedConditionCode = (state: AppState) =>
    state.conditions.selected;

// TODO: Fix type? Could return undefined if selected === ''
export const getSelectedCondition = createSelector(
    [conditions],
    (conditions) => conditions.byCode[conditions.selected]
);

export const getIndicatorsById = createSelector([indicators], (indicators) =>
    keyBy(indicators?.byCode, 'id')
);

export const getIndicatorsByCode = createSelector(
    [indicators],
    (indicators) => indicators?.byCode || {}
);

export const getPatientConditions = createSelector([conditions], (conditions) =>
    Object.values(conditions.byCode)
);
