import React from 'react';
import { formatMessage } from '../../../../../locale';
import {
    FetchedIndicator,
    isFetchedIndicatorProperties
} from '../../../../../types/indicator/fetched-indicator.type';
import { StyledSliderMinMaxDescription } from './StyledSliderMinMaxDescription';

type Props = {
    indicator: FetchedIndicator;
    property?: string;
};

export const SliderMinDescription = ({ indicator, property }: Props) => {
    const minDescriptor =
        property && isFetchedIndicatorProperties(indicator)
            ? indicator.messages[property]?.minValueDescription
            : indicator.messages.general.minValueDescription;
    if (minDescriptor)
        return (
            <StyledSliderMinMaxDescription>
                {formatMessage(minDescriptor)}
            </StyledSliderMinMaxDescription>
        );
    return <></>;
};
