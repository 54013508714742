import { isEmpty } from 'lodash';
import { START_TIMER, STOP_TIMER } from 'redux-timer-middleware';
import { AppState } from '../../../redux/store/root-reducer';
import * as actionTypes from './chat.constants';
import {
    addConversation,
    getConversations,
    getMessages,
    postMessage
} from './chat.api';
import { getParticipants } from './chat.selectors';

export const fetchOrCreateConversation =
    (groupId: string) => (dispatch: any) => {
        dispatch({ type: actionTypes.CONVERSATION_FETCH_OR_CREATE_PENDING });

        return dispatch(fetchConversations())
            .then(({ value }: any) => {
                const { conversationList } = value;
                if (isEmpty(conversationList)) {
                    return dispatch(createConversation(groupId));
                } else {
                    const conversation = conversationList.find(
                        (conversation: any) =>
                            conversation.tags.find(
                                (tag: any) =>
                                    tag.key === 'groupId' &&
                                    tag.value === groupId
                            )
                    );

                    if (conversation) {
                        dispatch(setCurrentConversationId(conversation.id));
                        return dispatch(
                            fetchMessages(groupId, conversation.id)
                        );
                    } else {
                        return dispatch(createConversation(groupId));
                    }
                }
            })
            .then(({ value }: any) => {
                const { conversationId } = value;
                dispatch({
                    type: actionTypes.CONVERSATION_FETCH_OR_CREATE_FULFILLED
                });
                return conversationId;
            });
    };

export const setCurrentConversationId =
    (conversationId: string) => (dispatch: any) =>
        dispatch({
            type: actionTypes.SET_CURRENT_CONVERSATION_ID,
            payload: conversationId
        });

export const fetchConversations = () => (dispatch: any) =>
    dispatch({
        type: actionTypes.CONVERSATION_FETCH,
        payload: getConversations()
    });

export const fetchMessages =
    (groupId: string, conversationId: string) => (dispatch: any) =>
        dispatch({
            type: actionTypes.MESSAGES_FETCH,
            payload: getMessages(groupId, conversationId)
        });

export const createConversation =
    (groupId: string) => (dispatch: any, getState: () => AppState) => {
        const { subjectId } = getState().user;
        const params = {
            topic: 'Conversation',
            tags: {
                groupId,
                subjectId
            },
            participants: getParticipants(getState(), { groupId })
        };
        return dispatch({
            type: actionTypes.CONVERSATION_CREATE,
            payload: addConversation(groupId, params)
        });
    };

export const sendMessage =
    (groupId: string, conversationId: string, body: any) => (dispatch: any) => {
        return dispatch(fetchMessages(groupId, conversationId)).then(() => {
            return dispatch({
                type: actionTypes.MESSAGE_POST,
                payload: postMessage(groupId, conversationId, body)
            });
        });
    };

export const startMessageTimer =
    (conversationId: string, groupId: string) => (dispatch: any) => {
        dispatch({
            type: START_TIMER,
            payload: {
                actionName: actionTypes.MESSAGE_TIMER_TICK,
                timerName: conversationId,
                actionPayload: { conversationId, groupId },
                timerPeriod: -1,
                timerInterval: 10000
            }
        });
    };

export const stopMessageTimer = (conversationId: string) => (dispatch: any) =>
    dispatch({
        type: STOP_TIMER,
        payload: {
            timerName: conversationId
        }
    });
