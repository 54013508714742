import { defineMessages } from 'react-intl';

export const choices = defineMessages({
    none: {
        defaultMessage: 'cigarettes',
        id: 'indicator.smoke.choices.0'
    },
    one: {
        defaultMessage: 'cigarette',
        id: 'indicator.smoke.choices.1'
    },
    several: {
        defaultMessage: 'cigarettes',
        id: 'indicator.smoke.choices.2'
    },
    more: {
        defaultMessage: 'cigarettes or more',
        id: 'indicator.smoke.choices.5'
    }
});
