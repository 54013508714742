import React from 'react';
import { Icon } from 'antd';
import { formatMessage as t, Strings } from '../../locale';
import { Markdown } from '../../locale/utils/functions/format-markdown';
import { FormattedDate } from 'react-intl';
import { v4 } from 'uuid';
import {
    MessageOfTheDayType,
    MotdMessageType
} from '../../redux/domains/motd/motd.types';
import { styled } from '../../theme/styled';
import { getIconType } from './message-of-the-day-utils';

export const buildMessage = (motd: MessageOfTheDayType) => {
    return (
        <MsgWrapper key={v4()}>
            <StyledHeader>
                <StyledHeaderIcon
                    type={getIconType(motd.type)}
                    colortype={motd.type}
                />
                <StyledMessageTitle>{motd.message.title}</StyledMessageTitle>
            </StyledHeader>
            <StyledMsgContent>
                <Markdown source={motd.message.body} />
            </StyledMsgContent>
            <DateWrapper>
                {t(Strings.communication.main.published)}
                <FormattedDate value={motd.startDate} />
            </DateWrapper>
        </MsgWrapper>
    );
};

export const buildMotdMessages = (motds: MessageOfTheDayType[]) => {
    return (
        <StyledMsgsContainer>
            <StyledMsgs>{motds.map((motd) => buildMessage(motd))}</StyledMsgs>
        </StyledMsgsContainer>
    );
};

const MsgWrapper = styled.div`
    &:not(:last-child) {
        border-bottom: 1px solid #ccc;
    }

    margin: 1rem 0;
`;

const StyledHeader = styled.div`
    display: flex;
`;

const StyledHeaderIcon = styled(Icon)<{ colortype: MotdMessageType }>`
    float: left;
    margin: 0 10px 10px 0;
    color: ${(props) => props.theme.motd.motdColorForTypes[props.colortype]};
    font-size: xx-large;
`;

const StyledMessageTitle = styled.div`
    font-weight: bold;
    font-size: x-large;
    margin-bottom: 0.5rem;
`;

export const StyledMsgContent = styled.div``;

const DateWrapper = styled.div`
    color: ${(props) => props.theme.motd.text.body};
    font-style: italic;
    font-size: small;
`;
export const StyledMsgs = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;

    &:last-child {
        margin-bottom: auto;
    }
`;

export const StyledMsgsContainer = styled.div`
    display: block;
`;
