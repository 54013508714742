import { defineMessages } from 'react-intl';

export const general = defineMessages({
    title: {
        defaultMessage: 'Smoking',
        id: 'indicator.smoke.title'
    },
    question: {
        defaultMessage: 'Have you smoked?',
        id: 'indicator.smoke.question'
    },
    description: {
        defaultMessage:
            'How much have you smoked the chosen date?  \n\n*(If you have already reported on that date, then this report will be added to the previous report.)*',
        id: 'indicator.smoke.description'
    },
    value: {
        defaultMessage:
            '{value, plural, one {# cigarette} other {# cigarettes}}',
        id: 'indicator.smoke.value'
    }
});
