import { defineMessages } from 'react-intl';

export const general = defineMessages({
    title: {
        defaultMessage: 'Swelling',
        id: 'indicator.swelling-hs.title'
    },
    description: {
        defaultMessage: 'How is the swelling?',
        id: 'indicator.swelling-hs.description'
    },
    minValueDescription: {
        defaultMessage: 'No edema',
        id: 'indicator.swelling-hs.min-value-description'
    },
    maxValueDescription: {
        defaultMessage: 'Severe edema',
        id: 'indicator.swelling-hs.max-value-description'
    },
    value: {
        defaultMessage: '{value}',
        id: 'indicator.swelling-hs.value'
    }
});
