import { defineMessages } from 'react-intl';

const time = defineMessages({
    sec: {
        id: 'shared.time.sec',
        defaultMessage: '{numeric} sec'
    },
    min: {
        id: 'shared.time.min',
        defaultMessage: '{numeric} min'
    },
    hour: {
        id: 'shared.time.hour',
        defaultMessage: '{timnumerice} hours'
    },
    day: {
        id: 'shared.time.day',
        defaultMessage: '{numeric} days'
    },
    recommendationDynamicSec: {
        id: 'shared.time.recommendation.reminder.dynamic.sec',
        defaultMessage:
            '{numeric, selectordinal, =0 {never} one {every second} two {every other second} few {every #:rd second} other {every #th second}}'
    },
    recommendationDynamicMin: {
        id: 'shared.time.recommendation.reminder.dynamic.min',
        defaultMessage:
            '{numeric, selectordinal, =0 {never} one {every minute} two {every other minute} few {every #:rd minute} other {every #th minute}}'
    },
    recommendationDynamicHour: {
        id: 'shared.time.recommendation.reminder.dynamic.hour',
        defaultMessage:
            '{numeric, selectordinal, =0 {never} one {every hour} two {every other hour} few {every #:rd hour} other {every #th hour}}'
    },
    recommendationDynamicDay: {
        id: 'shared.time.recommendation.reminder.dynamic.day',
        defaultMessage:
            '{numeric, selectordinal, =0 {never} one {daily} =2 {every other day} few {every #:rd day} other {every #th day}}'
    },
    recommendationDynamicMonth: {
        id: 'shared.time.recommendation.reminder.dynamic.month',
        defaultMessage:
            '{numeric, selectordinal, =0 {never} one {monthly} =2 {every other month} few {every #:rd month} other {every #th month}}'
    },
    minutes: {
        id: 'shared.time.minutes',
        defaultMessage: 'minutes'
    }
});

const date = defineMessages({
    today: {
        id: 'date.today',
        defaultMessage: 'Today'
    },
    older: {
        id: 'date.older',
        defaultMessage: 'Older'
    }
});
export const format = defineMessages({
    reminderReportFormat: {
        id: 'recommendation.report.date-format',
        defaultMessage: 'dddd, D MMMM'
    }
});

export default {
    time: time,
    date: date,
    format
};
