import React, { PropsWithChildren } from 'react';

import { StyledRow, StyledRowContent, StyledRowLabel } from './styled';

export const ReccomendationRow = (
    props: PropsWithChildren<{
        label?: string;
        background: string;
    }>
): JSX.Element => {
    return (
        <StyledRow>
            <StyledRowLabel>{props.label}</StyledRowLabel>
            <StyledRowContent style={{ background: props.background }}>
                {props.children}
            </StyledRowContent>
        </StyledRow>
    );
};
