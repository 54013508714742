import { defineMessages } from 'react-intl';

export const general = defineMessages({
    title: {
        defaultMessage: 'Appetite',
        id: 'indicator.appetite.title'
    },
    value: {
        defaultMessage:
            '{value, select, 1 {Better appetite than usual} 2 {Same appetite as usual} 3 {Worse appetite than usual}}',
        id: 'indicator.appetite.value'
    }
});
