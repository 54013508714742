import React from 'react';
import { styled } from '../../../../theme/styled';
import { Card } from 'antd';
import { supportImages } from '../../../../static/images/support/support-images';
import { formatMessage, Strings } from '../../../../locale';
import { CustomImage } from '../../../../components/custom-image/CustomImage';
import { StyledSubPage } from '../styled';
import { MarkdownFromDescriptor } from '../../../../locale/utils/functions/format-markdown';
import { ContentLayout } from '../../../../components/layout/ContentLayout';
import { Header } from '../../../../components/layout/Header';
import { routePaths } from '../../../../constants/route-paths';
import { messagesAbout } from './messages';

export const AboutPage = (): JSX.Element => {
    return (
        <>
            <Header
                backButtonPath={routePaths.other}
                title={formatMessage(messagesAbout.header.title)}
            />
            <ContentLayout>
                <StyledSubPage>
                    <AboutCardWrapper>
                        <h3>
                            <span>{Strings.constant.lifepodDetails.name}</span>
                            <sup>&reg; </sup>
                            {formatMessage(messagesAbout.main.platform)}
                        </h3>
                        <p>
                            {formatMessage(messagesAbout.main.version, {
                                value: process.env.version
                            })}
                        </p>
                        <div style={{ maxWidth: 400 }}>
                            <MarkdownFromDescriptor
                                messageDescriptor={
                                    messagesAbout.main.intendedUse
                                }
                            />
                        </div>

                        <h4>
                            {formatMessage(messagesAbout.main.manufacturer)}
                        </h4>
                        <div style={{ whiteSpace: 'nowrap' }}>
                            {Strings.appDetails.companyDetails.name}
                        </div>
                        <div>
                            {Strings.appDetails.companyDetails.addressRow1}
                        </div>
                        <div>
                            {Strings.appDetails.companyDetails.addressRow2}
                        </div>
                        <div>
                            {Strings.appDetails.companyDetails.phoneNumber}
                        </div>
                        <CustomImageWrapper src={supportImages.manufacturer} />
                        <CustomImageWrapper src={supportImages.ceSquare} />
                        <br />
                        <br />

                        <p>
                            {formatMessage(messagesAbout.main.patent, {
                                patentId:
                                    Strings.appDetails.lifepodDetails.usaPatent
                            })}
                            <br />
                            {formatMessage(messagesAbout.main.patent, {
                                patentId:
                                    Strings.appDetails.lifepodDetails.eurPatent
                            })}
                            <br />
                            {formatMessage(messagesAbout.main.patent, {
                                patentId:
                                    Strings.appDetails.lifepodDetails.canPatent
                            })}
                            <br />
                            {formatMessage(messagesAbout.main.patent, {
                                patentId:
                                    Strings.appDetails.lifepodDetails.ausPatent
                            })}
                            <br />
                            {formatMessage(messagesAbout.main.patent, {
                                patentId:
                                    Strings.appDetails.lifepodDetails.rusPatent
                            })}
                        </p>
                        <br />

                        <p>{formatMessage(messagesAbout.main.openSource)}</p>
                        <p>
                            <span>{Strings.constant.lifepodDetails.name}</span>
                            <sup>&reg; </sup>
                            {formatMessage(messagesAbout.main.trademark, {
                                trademarkId:
                                    Strings.appDetails.lifepodDetails
                                        .trademarkId
                            })}
                            <br />
                            {formatMessage(
                                messagesAbout.main.copyright
                            )} &copy; {Strings.appDetails.companyDetails.name}
                        </p>
                    </AboutCardWrapper>
                </StyledSubPage>
            </ContentLayout>
        </>
    );
};

const CustomImageWrapper = styled(CustomImage)`
    width: 60px;
    height: 60px;
`;

const AboutCardWrapper = styled(Card)`
    h3,
    h4 {
        font-weight: bold;
    }
`;
