import { defineMessages } from 'react-intl';

export const systolic = defineMessages({
    title: {
        defaultMessage: 'Systolic',
        id: 'indicator.blood-pressure.systolic.title'
    },
    limits: {
        id: 'recommendation.limits.blood-pressure.systolic',
        defaultMessage: 'Recommended systolic value:  \n{range}'
    }
});
