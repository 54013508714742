import React, { useState } from 'react';
import moment from 'moment';

import { styled } from '../../../../theme/styled';
import { formatMessage } from '../../../../locale/utils/functions/format-message';
import { datePicker } from './message-descriptors';
import { Input } from 'antd';

type Event = React.ChangeEvent<HTMLInputElement>;

type DatePickerProps = {
    defaultValue: number;
    min?: number;
    max: number;
    onUpdate: (event: Event) => void;
};

const dateBoundary = Date.parse('2000-01-01').valueOf();

export const DatePicker: React.FC<DatePickerProps> = (field) => {
    const [defaultValue, setDefaultValue] = useState(
        moment(field.defaultValue).format('YYYY-MM-DD')
    );
    const [error, setError] = useState<string | null>(null);

    return (
        <div style={{ display: 'flex' }}>
            <Input
                type="date"
                required
                defaultValue={defaultValue}
                min={moment(field.min || dateBoundary).format('YYYY-MM-DD')}
                max={moment(field.max).format('YYYY-MM-DD')}
                onChange={onChange}
                onBlur={onBlur}
            />
            {error && <StyledError>{error}</StyledError>}
        </div>
    );

    function onChange(event: Event) {
        checkValidity(event);
        field.onUpdate(event);
    }

    function onBlur(event: Event) {
        if (event.target.validity.valid) {
            // Fix for iOS. See https://github.com/facebook/react/issues/8938
            setDefaultValue(event.target.value);
        }
        checkValidity(event);
        field.onUpdate(event);
    }

    function checkValidity(event: Event) {
        setError(
            !event.target.validity.valid
                ? formatMessage(datePicker.invalid.date)
                : null
        );
    }
};

const StyledError = styled.div`
    display: flex;
    padding-left: 20px;
    color: ${(props) => props.theme.color.error};
    align-items: center;
`;
