import { Icon } from 'antd';
import { css, keyframes } from 'styled-components';
import { styled } from '../../../../theme/styled';

type Props = { isLoading: boolean; isDimmed: boolean };

const pulse = keyframes`
    50% {
        opacity:0.25;
    }
`;

const rotate = keyframes`
    0% {
        opacity: 0.01;
        transform: rotate(360deg) scale(0.7);
    }

    50% {
        opacity: 1;
        transform: rotate(0deg) scale(1);
    }

    60% {
        transform: rotate(0deg) scale(1.2);
    }

    80% {
        transform: rotate(0deg) scale(1);
    }
`;

export const StyledIndicatorTile = styled.div<Props>`
    && {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #aaaaaa;
        border-radius: 16px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
        position: relative;
        transition: all 0.18s ease-in-out;
        padding: 6.67%;
        align-items: center;
        cursor: pointer;
        background-color: ${(props) =>
            props.isDimmed ? '#8ebff8' : '#2c8eff'};
        overflow: hidden;
        animation: ${(props) =>
            props.isLoading
                ? css`
                      ${pulse} 1s ease-in-out infinite
                  `
                : 'none'};
    }

    .show-feedback {
        i {
            animation: ${rotate} 2s ease;
        }
    }

    .hide-feedback {
        opacity: 0.01;
        transition: all 1s ease;
    }
`;

export const StyledSmileyWrapper = styled.div`
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledSmiley: any = styled(Icon)`
    width: 100%;
    height: 100%;
    font-weight: bold;

    svg {
        width: 80%;
        height: 80%;
        margin: 10%;
    }
`;
