import { actionTypes } from '../../domains/authentication/authentication.constants';
import * as authenticationService from '../../../services/authentication-service';
import * as localStorageUser from '../../../utils/local-storage-user';
import * as tokenUtils from '../../../utils/token';
import {
    fetchNotifications,
    startNotificationTimer,
    stopNotificationTimer
} from '../../domains/notifications/notifications.actions';

const reduxLocalstorageJwtMiddleware =
    () => (store: any) => (next: any) => (action: any) => {
        switch (action.type) {
            case actionTypes.AUTHENTICATION_LOGIN_FULFILLED:
                localStorageUser.addTokensToLocalStorage(action.payload);
                store.dispatch(fetchNotifications(['sent', 'notified']));
                store.dispatch(startNotificationTimer());
                return next(action);
            case actionTypes.AUTHENTICATION_CHECK_REJECTED:
                store.dispatch(stopNotificationTimer());
                return next(action);
            case actionTypes.AUTHENTICATION_LOGOUT:
                authenticationService.handleSignout(store);
                store.dispatch(stopNotificationTimer());
                return next(action);
            case actionTypes.AUTHENTICATION_CHECK: {
                store.dispatch({
                    type: actionTypes.AUTHENTICATION_CHECK_PENDING
                });
                tokenUtils
                    .validateTokens()
                    .then(() => {
                        authenticationService.handleSuccess(store);
                        store.dispatch(startNotificationTimer());
                        store.dispatch(
                            fetchNotifications(['sent', 'notified'])
                        );
                    })
                    .catch(() => {
                        authenticationService.handleError(store);
                    });
                return next(action);
            }

            default:
                return next(action);
        }
    };

export default reduxLocalstorageJwtMiddleware;
