export const AUTHENTICATION_LOGIN = 'AUTHENTICATION_LOGIN';
export const AUTHENTICATION_LOGIN_PENDING = 'AUTHENTICATION_LOGIN_PENDING';
export const AUTHENTICATION_LOGIN_FULFILLED = 'AUTHENTICATION_LOGIN_FULFILLED';
export const AUTHENTICATION_LOGIN_REJECTED = 'AUTHENTICATION_LOGIN_REJECTED';
export const AUTHENTICATION_LOGOUT = 'AUTHENTICATION_LOGOUT';
export const AUTHENTICATION_LOGOUT_PENDING = 'AUTHENTICATION_LOGOUT_PENDING';
export const AUTHENTICATION_LOGOUT_FULFILLED =
    'AUTHENTICATION_LOGOUT_FULFILLED';
export const AUTHENTICATION_LOGOUT_REJECTED = 'AUTHENTICATION_LOGOUT_REJECTED';
export const AUTHENTICATION_CHECK = 'AUTHENTICATION_CHECK';
export const AUTHENTICATION_CHECK_FULFILLED = 'AUTHENTICATION_CHECK_FULFILLED';
export const AUTHENTICATION_CHECK_REJECTED = 'AUTHENTICATION_CHECK_REJECTED';
export const AUTHENTICATION_CHECK_PENDING = 'AUTHENTICATION_CHECK_PENDING';
export const AUTHENTICATION_EID_LOGIN = 'AUTHENTICATION_EID_LOGIN';
export const AUTHENTICATION_EID_LOGIN_PENDING =
    'AUTHENTICATION_EID_LOGIN_PENDING';
export const AUTHENTICATION_EID_LOGIN_FULLFILLED =
    'AUTHENTICATION_EID_LOGIN_FULLFILLED';
export const AUTHENTICATION_EID_LOGIN_REJECTED =
    'AUTHENTICATION_EID_LOGIN_REJECTED';

export const actionTypes = {
    AUTHENTICATION_LOGIN,
    AUTHENTICATION_LOGIN_PENDING,
    AUTHENTICATION_LOGIN_FULFILLED,
    AUTHENTICATION_LOGIN_REJECTED,
    AUTHENTICATION_LOGOUT,
    AUTHENTICATION_LOGOUT_PENDING,
    AUTHENTICATION_LOGOUT_FULFILLED,
    AUTHENTICATION_LOGOUT_REJECTED,
    AUTHENTICATION_CHECK,
    AUTHENTICATION_CHECK_FULFILLED,
    AUTHENTICATION_CHECK_REJECTED,
    AUTHENTICATION_CHECK_PENDING,
    AUTHENTICATION_EID_LOGIN,
    AUTHENTICATION_EID_LOGIN_PENDING,
    AUTHENTICATION_EID_LOGIN_FULLFILLED,
    AUTHENTICATION_EID_LOGIN_REJECTED
};
