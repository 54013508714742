import React from 'react';
import styled from 'styled-components';
import symbols from '../../static/images/symbols';
import { CustomImage } from '../../components/custom-image/CustomImage';
import { Props } from './NotificationsListGroupHeader';

const NotificationDeleteButton: React.FC<Props> = ({ deleteNotifications }) => {
    const onClickHandler = () => {
        deleteNotifications();
    };

    return (
        <StyledNotificationDeleteButton onClick={onClickHandler}>
            <CustomImage src={symbols.deleteOutlined} alt="Delete" />
        </StyledNotificationDeleteButton>
    );
};

export default NotificationDeleteButton;

const StyledNotificationDeleteButton = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    color: #313152;
`;
