import { general } from './general';
import { antiLipoproteins } from './anti-lipoproteins';
import { anticoagulant } from './anticoagulant';
import { recommendation } from './recommendation';

export default {
    general,
    antiLipoproteins,
    anticoagulant,
    recommendation
};
