import { defineMessages } from 'react-intl';

export const intensity = defineMessages({
    title: {
        defaultMessage: 'Intensity',
        id: 'indicator.physact.intensity.title'
    },
    unit: {
        id: 'indicator.physact.intensity.unit',
        defaultMessage: ''
    },
    none: {
        id: 'indicator.physact.intensity.0',
        defaultMessage: 'No effort {range}'
    },
    extremelyEasy: {
        id: 'indicator.physact.intensity.1',
        defaultMessage: 'Extremely easy {range}'
    },
    veryEasy: {
        id: 'indicator.physact.intensity.2',
        defaultMessage: 'Very easy {range}'
    },
    easy: {
        id: 'indicator.physact.intensity.3',
        defaultMessage: 'Easy {range}'
    },
    somewhat: {
        id: 'indicator.physact.intensity.4',
        defaultMessage: 'Somewhat hard {range}'
    },
    hard: {
        id: 'indicator.physact.intensity.5',
        defaultMessage: 'Hard {range}'
    },
    veryHard: {
        id: 'indicator.physact.intensity.6',
        defaultMessage: 'Very hard {range}'
    },
    extremelyHard: {
        id: 'indicator.physact.intensity.7',
        defaultMessage: 'Extremely hard {range}'
    },
    maximum: {
        id: 'indicator.physact.intensity.8',
        defaultMessage: 'Maximum effort! {range}'
    },
    limits: {
        id: 'recommendation.limits.physact.intensity',
        defaultMessage: 'Recommended intensity:  \n{range}'
    }
});
