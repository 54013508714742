import { ChartEnum } from '../../../../types/indicator/chart.types';
import { IndicatorPropertyRules } from '../../../../types/indicator/indicator-rules.type';
import { colors } from '../../../../theme/colors/common-colors';

export const rules: IndicatorPropertyRules<'sum'> = {
    neutralReportResult: true,
    invertReportResponse: false,
    hideValueWhenReporting: false,
    step: 1,
    chartTypes: [ChartEnum.Spline],
    lineColor: colors.chart.graph.obj.ehfscbs,
    chartOptions: {
        property: 'sum',
        fixedYAxisMin: 0,
        fixedYAxisMax: 100,
        hidePlotBand: true
    },
    decimals: {
        sum: 0
    }
};

export default rules;
