import { Indicator } from '../../../types/indicator/indicator.type';
import messages from './messages';
import indicatorRules from './fish-rules';
import icon from './images/fish.svg';
import { TileType, FeedbackType } from '../../../types/indicator/report.type';
import { BooleanForm } from '../../../app/report/forms/radio/BooleanForm';

export const fish: Indicator<typeof indicatorRules> = {
    code: 'FISH',
    icon,
    messages,
    hiddenHistory: false,
    hiddenStatistics: false,
    recommendations: {
        limits: {
            enabled: false
        },
        reminder: {
            enabled: false
        },
        info: {
            enabled: false
        }
    },
    rules: indicatorRules,
    tile: {
        report: {
            form: BooleanForm,
            type: TileType.Dimming,
            feedback: FeedbackType.SmileyInverted
        }
    }
};
