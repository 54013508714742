export const CONVERSATION_FETCH = 'CONVERSATION_FETCH';
export const CONVERSATION_FETCH_PENDING = 'CONVERSATION_FETCH_PENDING';
export const CONVERSATION_FETCH_FULFILLED = 'CONVERSATION_FETCH_FULFILLED';
export const CONVERSATION_FETCH_REJECTED = 'CONVERSATION_FETCH_REJECTED';

export const CONVERSATION_FETCH_OR_CREATE = 'CONVERSATION_FETCH_OR_CREATE';
export const CONVERSATION_FETCH_OR_CREATE_PENDING =
    'CONVERSATION_FETCH_OR_CREATE_PENDING';
export const CONVERSATION_FETCH_OR_CREATE_FULFILLED =
    'CONVERSATION_FETCH_OR_CREATE_FULFILLED';
export const CONVERSATION_FETCH_OR_CREATE_REJECTED =
    'CONVERSATION_FETCH_OR_CREATE_REJECTED';

export const CONVERSATION_CREATE = 'CONVERSATION_CREATE';
export const CONVERSATION_CREATE_PENDING = 'CONVERSATION_CREATE_PENDING';
export const CONVERSATION_CREATE_FULFILLED = 'CONVERSATION_CREATE_FULFILLED';
export const CONVERSATION_CREATE_REJECTED = 'CONVERSATION_CREATE_REJECTED';

export const MESSAGES_FETCH = 'MESSAGES_FETCH';
export const MESSAGES_FETCH_PENDING = 'MESSAGES_FETCH_PENDING';
export const MESSAGES_FETCH_FULFILLED = 'MESSAGES_FETCH_FULFILLED';
export const MESSAGES_FETCH_REJECTED = 'MESSAGES_FETCH_REJECTED';

export const MESSAGE_POST = 'MESSAGE_POST';
export const MESSAGE_POST_PENDING = 'MESSAGE_POST_PENDING';
export const MESSAGE_POST_FULFILLED = 'MESSAGE_POST_FULFILLED';
export const MESSAGE_POST_REJECTED = 'MESSAGE_POST_REJECTED';

export const MESSAGE_TIMER_TICK = 'MESSAGE_TIMER_TICK';

export const SET_CURRENT_CONVERSATION_ID = 'SET_CURRENT_CONVERSATION_ID';
