import { defineMessages } from 'react-intl';

export const general = defineMessages({
    title: {
        defaultMessage: 'Breathlessness',
        id: 'indicator.breathlessness.title'
    },
    value: {
        defaultMessage:
            '{value, select, 1 {Completely unaffected} 2 {Breathless from more than moderate activity} 3 {Breathless from light activity} 4 {Breathless at rest}}',
        id: 'indicator.breathlessness.value'
    }
});
