import messages from './messages/index';
import { ChartEnum } from '../../../types/indicator/chart.types';
import { IndicatorPropertyRules } from '../../../types/indicator/indicator-rules.type';
import { colors } from '../../../theme/colors/common-colors';

type PhysactValue = {
    duration: number;
    intensity: number;
};

export const rules: IndicatorPropertyRules<keyof PhysactValue, PhysactValue> = {
    neutralReportResult: false,
    invertReportResponse: false,
    hideValueWhenReporting: false,
    step: 1,
    chartTypes: [ChartEnum.Heatmap],
    toHeatmapValue: (value) => value.intensity,
    heatmapPieces: [
        {
            value: 6,
            color: colors.chart.heatmap.purple10,
            text: messages.intensity.none,
            textValues: {
                range: ' (6)'
            }
        },
        {
            color: colors.chart.heatmap.purple10,
            min: 7,
            max: 8,
            text: messages.intensity.extremelyEasy,
            textValues: {
                range: ' (7 - 8)'
            }
        },
        {
            color: colors.chart.heatmap.purple20,
            min: 9,
            max: 10,
            text: messages.intensity.veryEasy,
            textValues: {
                range: ' (9 - 10)'
            }
        },
        {
            color: colors.chart.heatmap.purple30,
            min: 11,
            max: 12,
            text: messages.intensity.easy,
            textValues: {
                range: ' (11 - 12)'
            }
        },
        {
            color: colors.chart.heatmap.purple40,
            min: 13,
            max: 14,
            text: messages.intensity.somewhat,
            textValues: {
                range: ' (13 - 14)'
            }
        },
        {
            color: colors.chart.heatmap.purple50,
            min: 15,
            max: 16,
            text: messages.intensity.hard,
            textValues: {
                range: ' (15 - 16)'
            }
        },
        {
            color: colors.chart.heatmap.purple60,
            min: 17,
            max: 18,
            text: messages.intensity.veryHard,
            textValues: {
                range: ' (17 - 18)'
            }
        },
        {
            value: 19,
            color: colors.chart.heatmap.purple70,
            text: messages.intensity.extremelyHard,
            textValues: {
                range: ' (19)'
            }
        },
        {
            value: 20,
            color: colors.chart.heatmap.purple80,
            text: messages.intensity.maximum,
            textValues: {
                range: ' (20)'
            }
        }
    ],
    properties: {
        intensity: {
            textRanges: [
                { min: 6, max: 6, ...messages.intensity.none },
                { min: 7, max: 8, ...messages.intensity.extremelyEasy },
                { min: 9, max: 10, ...messages.intensity.veryEasy },
                { min: 11, max: 12, ...messages.intensity.easy },
                { min: 13, max: 14, ...messages.intensity.somewhat },
                { min: 15, max: 16, ...messages.intensity.hard },
                { min: 17, max: 18, ...messages.intensity.veryHard },
                { min: 19, max: 19, ...messages.intensity.extremelyHard },
                { min: 20, max: 20, ...messages.intensity.maximum }
            ]
        }
    }
};

export default rules;
