import { defineMessages } from 'react-intl';

export const excerciseCheck = defineMessages({
    title: {
        defaultMessage: 'Excercise check',
        id: 'indicator.ehfscbs.excercise-check.title'
    },
    description: {
        defaultMessage: 'I exercise regularly',
        id: 'indicator.ehfscbs.excercise-check.description'
    },
    maxValueDescription: {
        defaultMessage: 'I do not agree at all',
        id: 'indicator.ehfscbs.excercise-check.max-text'
    },
    minValueDescription: {
        defaultMessage: 'I fully agree',
        id: 'indicator.ehfscbs.excercise-check.min-text'
    }
});
