import { defineMessages } from 'react-intl';

export const info = defineMessages({
    main: {
        id: 'indicator.body-weight.lp_2_0_copd_rs.info.main',
        defaultMessage:
            'BMI betyder Body Mass Index och är ett värde som utgår från ' +
            'din längd och vikt. ' +
            'Allt eftersom du ändrar din vikt upp eller ner, ' +
            'ändras också ditt BMI. \n\n' +
            '' +
            '### Så räknar du ut ditt BMI  \n\n' +
            '1. Börja med att väga och mäta dig.  \n' +
            '2. Multiplicera sedan din längd i meter med din längd i meter.  \n' +
            '3. Dividera din vikt med det tal du får ut.  \n\n' +
            '' +
            'Exempel: 70 kg/(1,70 m x 1,70 m) = BMI 24,2.  \n'
    }
});
