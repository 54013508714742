import { defineMessages } from 'react-intl';

export const loading = defineMessages({
    text: {
        id: 'video-meeting.loading.text',
        defaultMessage: 'Loading video meeting'
    }
});

export const open = defineMessages({
    text: {
        id: 'video-meeting.open.text',
        defaultMessage: 'Open video meeting'
    }
});

export const closed = defineMessages({
    text: {
        id: 'video-meeting.closed.text',
        defaultMessage: 'Closed video meeting'
    }
});
