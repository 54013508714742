import React, { useEffect } from 'react';
import { styled } from '../../theme/styled';
import { useSelector, useDispatch } from 'react-redux';
import { useCondition } from '../../hooks/use-condition';
import { getIndicatorsByCode } from '../../redux/domains/conditions/conditions.selectors';
import { Loading } from '../../components/loading/Loading';
import { IndicatorTile } from './tiles/IndicatorTile';
import { ContentSwipeLayout } from '../../components/layout/ContentSwipeLayout';
import { InfoTile } from './tiles/InfoTile';
import { ReportModal } from './modals/ReportModal';
import { IndicatorInfoModal } from './modals/IndicatorInfoModal';
import { MissedReport } from './tiles/components/MissedReport';
import { TileType } from '../../types/indicator/report.type';
import { useReduxSelector } from '../../hooks/use-redux-selector';
import { getIndicatorConfigurations } from '../../redux/domains/user/user.selectors';
import { fetchReportStatus } from '../../redux/domains/report/report.actions';
import { testSelectors } from '../../constants/test-id';
import { createLoadingSelector } from '../../redux/domains/requests/requests.selectors';
import { CONDITIONS_FETCH } from '../../redux/domains/conditions/conditions.constants';

const loadingSelector = createLoadingSelector([CONDITIONS_FETCH]);

type Props = {
    afterCSSTransition: any;
};

export const ReportGrid = ({ afterCSSTransition }: Props): JSX.Element => {
    const condition = useCondition();
    const indicatorsByCode = useSelector(getIndicatorsByCode);
    const indicatorConfigurations = useReduxSelector((state) =>
        getIndicatorConfigurations(state)
    );
    const loading = useReduxSelector(loadingSelector);
    const dispatch = useDispatch();

    useEffect(
        function () {
            dispatch(fetchReportStatus());
        },
        [dispatch, condition]
    );

    if (loading) {
        return (
            <ContentSwipeLayout>
                <Loading />
            </ContentSwipeLayout>
        );
    }

    return (
        <ContentSwipeLayout>
            <StyledGrid data-test-id={testSelectors.page.reportGrid}>
                {condition.infoTiles?.map((infoTile, i) => (
                    <StyledGridItem key={i}>
                        <InfoTile infoTile={infoTile} />
                    </StyledGridItem>
                ))}
                {indicatorConfigurations &&
                    condition.indicators
                        .filter(
                            ({ code, tile }) =>
                                tile.report.type !== TileType.Hidden &&
                                (indicatorsByCode[code] == null ||
                                    indicatorConfigurations[code].in_use)
                        )
                        .map((indicator) => (
                            <StyledGridItem key={indicator.code}>
                                <IndicatorTile
                                    code={indicator.code}
                                    afterCSSTransition={afterCSSTransition}
                                />
                                <MissedReport code={indicator.code} />
                            </StyledGridItem>
                        ))}
                <ReportModal />
                <IndicatorInfoModal />
            </StyledGrid>
        </ContentSwipeLayout>
    );
};

const StyledGrid = styled.div`
    && {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        flex-grow: 1;
        justify-content: center;
        animation: fadeIn 0.45s cubic-bezier(0.38, 0.61, 0.5, 0.96);
    }
`;

const StyledGridItem = styled.div`
    width: 30%;
    margin: 1.5%;
    position: relative;
`;
