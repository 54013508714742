import { Icon } from 'antd';
import { prnmed as prnmedAf } from '../../../conditions/atrial-fibrillation/indicators/prnmed/prnmed';
import { ehfscbs } from '../../../conditions/heart-failure/indicators/ehfscbs/ehfscbs';
import { candy } from '../../../conditions/shared-indicators/candy/candy';
import { fish } from '../../../conditions/shared-indicators/fish/fish';
import { medication } from '../../../conditions/shared-indicators/medication/medication';
import { phlegm } from '../../../conditions/shared-indicators/phlegm/phlegm';
import { physact } from '../../../conditions/shared-indicators/physact/physact';
import { prnmed as prnmedCi } from '../../../conditions/shared-indicators/prnmed-ci/prnmed';
import { prnmed } from '../../../conditions/shared-indicators/prnmed/prnmed';
import { smoke } from '../../../conditions/shared-indicators/smoke/smoke';
import { swelling } from '../../../conditions/shared-indicators/swelling/swelling';
import { vegetables } from '../../../conditions/shared-indicators/vegetables/vegetables';
import isNull from 'lodash/isNull';
import React, { Component } from 'react';
import { roundNumber } from '../../../utils/functions/math';
import { hasOwnProperty } from '../../../utils/has-own-property';
import { FetchedIndicator } from '../../../types/indicator/fetched-indicator.type';
import { IndicatorPropertyRules } from '../../../types/indicator/indicator-rules.type';
import {
    Configuration,
    Limits,
    Properties
} from '../../../types/indicator/state-indicator.type';
import { pumpSelfTest } from '../../../conditions/left-ventricular-assist-device/indicators/pump-self-test/pump-self-test';

type Props = {
    values: any;
    indicator: FetchedIndicator;
    configuration: Configuration & Limits & Properties;
};
type State = {};

const getValueOrUndefined = (value: any) => {
    if (isNull(value)) {
        return undefined; // This is wierd, but do not change to null!
    } else {
        return value;
    }
};

class IndicatorTrend extends Component<Props, State> {
    render() {
        const { values, configuration } = this.props;

        return (
            <>
                {values === undefined ||
                values === null ||
                values.length === 0 ? (
                    <NeutralFace />
                ) : hasOwnProperty(configuration, 'type') ? (
                    this.renderSinglePropertyIndicator()
                ) : (
                    this.renderMultiPropertyIndicator()
                )}
            </>
        );
    }

    renderSinglePropertyIndicator() {
        const { indicator, values, configuration } = this.props;
        const lastValue = values[0].value;

        let { lower_red, lower_yellow, upper_yellow, upper_red } =
            configuration;

        lower_red = getValueOrUndefined(lower_red);
        lower_yellow = getValueOrUndefined(lower_yellow);
        upper_yellow = getValueOrUndefined(upper_yellow);
        upper_red = getValueOrUndefined(upper_red);

        switch (indicator.code) {
            case vegetables.code:
                return lastValue ? <HappyFace /> : <NeutralFace />;
            case fish.code:
                return lastValue ? <HappyFace /> : <NeutralFace />;
            case smoke.code:
                return !lastValue ? <HappyFace /> : <NeutralFace />;
            case candy.code:
                return !lastValue ? <HappyFace /> : <NeutralFace />;
            case pumpSelfTest.code:
                return <Checkmark />;
            default:
                if (lastValue < lower_red! || lastValue > upper_red!) {
                    return <NeutralFace />;
                } else if (
                    lastValue < lower_yellow! ||
                    lastValue > upper_yellow!
                ) {
                    return <NeutralFace />;
                } else {
                    return <HappyFace />;
                }
        }
    }

    renderMultiPropertyIndicator() {
        const { indicator, values, configuration } = this.props;
        const { decimals } = indicator.rules as IndicatorPropertyRules<string>;
        const lastValues = values[0].value;
        let result = <HappyFace />;

        Object.keys(lastValues).forEach((key: string) => {
            let value = lastValues[key];
            const config = configuration[key];

            if (config == null) return null;

            if (decimals && decimals[key]) {
                value = roundNumber(value, decimals[key]);
            }
            let { lower_red, lower_yellow, upper_yellow, upper_red } = config;

            lower_red = getValueOrUndefined(lower_red);
            lower_yellow = getValueOrUndefined(lower_yellow);
            upper_yellow = getValueOrUndefined(upper_yellow);
            upper_red = getValueOrUndefined(upper_red);

            switch (indicator.code) {
                case ehfscbs.code:
                    result = <NeutralFace />;
                    return result;
                case phlegm.code: {
                    const { color, blood } = lastValues;
                    if (blood || color > 1) result = <NeutralFace />;
                    return result;
                }
                case physact.code:
                    if (value < lower_yellow! || value > upper_yellow!) {
                        result = <NeutralFace />;
                    }
                    if (value < lower_red! || value > upper_red!) {
                        result = <NeutralFace />;
                    }
                    return result;
                case swelling.code:
                    if (key === swelling.properties?.position) {
                        return;
                    }
                    if (value < lower_yellow! || value > upper_yellow!) {
                        result = <NeutralFace />;
                    }
                    if (value < lower_red! || value > upper_red!) {
                        result = <NeutralFace />;
                    }
                    return result;
                case medication.code: {
                    const { anticoagulant } = lastValues;
                    const antiLipoproteins = lastValues['anti-lipoproteins'];
                    if (anticoagulant === 0 || antiLipoproteins === 0) {
                        result = <NeutralFace />;
                    }
                    return result;
                }

                case prnmed.code:
                case prnmedCi.code:
                case prnmedAf.code:
                    result = <NeutralFace />;
                    return result;
                default:
                    if (value < lower_yellow! || value > upper_yellow!) {
                        result = <NeutralFace />;
                    }
                    if (value < lower_red! || value > upper_red!) {
                        result = <NeutralFace />;
                    }
                    return result;
            }
        });
        return result;
    }
}

export default IndicatorTrend;

const NeutralFace = () => (
    <Icon type="meh" theme="filled" className="indicator-trend-icon ok" />
);
const HappyFace = () => (
    <Icon type="smile" theme="filled" className="indicator-trend-icon good" />
);
const Checkmark = () => (
    <Icon
        type="check-circle"
        theme="filled"
        className="indicator-trend-icon check-circle"
    />
);
