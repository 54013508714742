import { defineMessages } from 'react-intl';

export const general = defineMessages({
    title: {
        defaultMessage: 'Use of orthosis during the day',
        id: 'indicator.orthosis-day-hs.title'
    },
    description: {
        defaultMessage: 'How many hours did you use orthosis today?',
        id: 'indicator.orthosis-day-hs.description'
    },
    value: {
        defaultMessage: '{value}',
        id: 'indicator.orthosis-day-hs.value'
    },
    unit: {
        id: 'indicator.orthosis-day-hs.unit',
        defaultMessage: '{value, plural, one {hour} other {hours}}'
    }
});
