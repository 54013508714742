import { defineMessages } from 'react-intl';

export const selfCare = defineMessages({
    title: {
        defaultMessage: 'Your self-care TODAY',
        id: 'indicator.eq5d3l.self-care.title'
    },
    choices: {
        defaultMessage:
            '{value, select, 1 {I have no problems with self-care} 2 {I have some problems washing or dressing myself} 3 {I am unable to wash or dress myself}}',
        id: 'indicator.eq5d3l.self-care.choices'
    }
});
