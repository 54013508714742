import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { updateTokens } from '../../utils/token';
import { handleError } from '../authentication-service';
import { formatMessageById as ti } from '../../locale';
import { notification } from 'antd';

const appConfig = __APP_CONFIG__;

interface HTTPClientInterface {
    get<R = any>(url: string, config?: AxiosRequestConfig): Promise<R>;
    post<T = any, R = any>(
        url: string,
        data?: T,
        config?: AxiosRequestConfig
    ): Promise<R>;
    put<T = any, R = any>(
        url: string,
        data?: T,
        config?: AxiosRequestConfig
    ): Promise<R>;
    delete<T = any, R = any>(
        url: string,
        config?: AxiosRequestConfig
    ): Promise<R>;
    setupInterceptors?: (store?: any) => void;
}

export const privateAxios = axios.create({
    baseURL: appConfig.API_ROOT_URL,
    timeout: 30000
});

export const HTTPClient: HTTPClientInterface = {
    get: async (url, config) =>
        await privateAxios.get(url, config).then(({ data }) => data),
    post: async (url, data, config) =>
        await privateAxios.post(url, data, config).then(({ data }) => data),
    put: async (url, data, config) =>
        await privateAxios.put(url, data, config).then(({ data }) => data),
    delete: async (url, config) =>
        await privateAxios.delete(url, config).then(({ data }) => data),
    setupInterceptors: (store: any) => {
        privateAxios.interceptors.request.use(
            (request) => {
                const idToken = window.localStorage.getItem('idToken');
                if (idToken) {
                    request.headers['Authorization'] = idToken;
                    request.headers['Content-Type'] = 'application/json';
                    request.headers['x-api-key'] = appConfig.API_KEY;
                }
                return request;
            },
            (error) => {
                console.error(error);
                return Promise.reject(error);
            }
        );
        privateAxios.interceptors.response.use(
            (response) => response,
            (error: AxiosError) => {
                const errorResponse = error.response;
                if (
                    error.config &&
                    errorResponse &&
                    errorResponse.status === 401
                ) {
                    return updateTokens()
                        .then((idToken) => {
                            error.config.headers['Authorization'] = idToken;
                            return privateAxios.request(error.config);
                        })
                        .catch((error) => {
                            console.error('Could not update tokens');
                            handleError(store);
                            throw error;
                        });
                } else {
                    checkError(error);
                }

                throw error;
            }
        );
    }
};

const errorIds: { [key: string]: string } = {
    general: 'error.general',
    403: 'error.403'
};

const checkError = (error: AxiosError) => {
    const { response } = error;

    if (response && response.status) {
        const errorId = errorIds[response.status] || errorIds.general;
        const message = ti(`${errorId}.message`);
        const description = ti(`${errorId}.description`);

        notification.error({
            message,
            description
        });

        // here we could add logic to send and store custom errors to our backend.
    }
};

export const errorHandler = (error: any, customMessage?: string) => {
    if (customMessage) {
        console.warn('error', customMessage);
    }

    const { response, message } = error;

    if (response && response.data) {
        const { status, message: reason } = response.data;

        throw {
            status,
            message,
            reason
        };
    }

    throw {
        message
    };
};
