import React from 'react';
import { Spin, Icon } from 'antd';
import './loading.css';

const Loading = () => {
    return (
        <div className="loading-screen transparent">
            <div className="help small" />
        </div>
    );
};

const PartialLoading = () => {
    return (
        <div className="partial-loading">
            <div className="help small" />
        </div>
    );
};

type CrossSpinnerProps = {
    iconOnly?: boolean;
    position?: 'left' | 'center' | 'right';
    display?: string;
    text?: string | null;
};

const CrossSpinner = ({
    iconOnly,
    position = 'center',
    display = 'block',
    text
}: CrossSpinnerProps) => {
    const icon = <Icon type="loading" />;
    if (iconOnly) {
        return icon;
    }

    return (
        <div style={{ textAlign: position, display: display }}>
            <Spin indicator={icon} />
            <div style={{ marginTop: 10 }}>{text}</div>
        </div>
    );
};

export { Loading, PartialLoading, CrossSpinner };
