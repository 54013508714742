import { defineMessages } from 'react-intl';

export const duringActivity = defineMessages({
    title: {
        defaultMessage: 'Pain during activity',
        id: 'indicator.pain-during-activity-hs.title'
    },
    description: {
        defaultMessage: 'Do you experience any pain during activity?',
        id: 'indicator.pain-during-activity-hs.description'
    }
});
