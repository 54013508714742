import React, { useState, useRef, useEffect } from 'react';
import NotificationsListItem from './NotificationsListItem';
import { Notification } from '../../redux/domains/notifications/notifications.api';
import { Notifications } from './types/notification.type';

export const InfiniteScroll: React.FC<Notifications> = ({ notifications }) => {
    const [items, setItems] = useState<typeof notifications>([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        fetchData(page);
    }, [page]);

    const fetchData = (newPage: number) => {
        const startIndex = (newPage - 1) * 20;
        const endIndex = startIndex + 20;
        const newItems = notifications.slice(startIndex, endIndex);
        setItems([...items, ...newItems]);
        if (endIndex >= notifications.length) {
            setHasMore(false);
        }
    };

    const onScroll = () => {
        if (isLoading || !hasMore) return;
        const scrollTop = containerRef.current?.scrollTop;
        const scrollHeight = containerRef.current?.scrollHeight;
        const clientHeight = containerRef.current?.clientHeight;

        if (
            scrollTop &&
            clientHeight &&
            scrollHeight &&
            scrollTop + clientHeight >= scrollHeight
        ) {
            setIsLoading(true);
            setPage(page + 1);
        }
    };

    useEffect(() => {
        containerRef.current?.addEventListener('scroll', onScroll);
        return () =>
            containerRef.current?.removeEventListener('scroll', onScroll);
    }, [page, isLoading, items]);

    useEffect(() => {
        setIsLoading(false);
    }, [page]);

    return (
        <div
            ref={containerRef}
            style={{ overflowY: 'auto', height: 'calc(100% - 40px)' }}
        >
            {items.map((item: Notification) => (
                <NotificationsListItem key={item.id} notification={item} />
            ))}
        </div>
    );
};
