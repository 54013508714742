import React from 'react';
import messages from './messages';
import icon from './images/what-if-i-get-worse.svg';
import { InfoTileType } from '../../../types/info-tile/info-tile.type';
import { formatMessage } from '../../../locale';

export const whatIfIGetWorse: InfoTileType = {
    icon,
    title: formatMessage(messages.general.title),
    component: (
        <>
            <p>{formatMessage(messages.general.question)}</p>
            <p>{formatMessage(messages.general.answer)}</p>
            <a
                href={formatMessage(messages.general.treatmentPlan)}
                target="_blank"
                rel="noopener noreferrer"
            >
                {formatMessage(messages.general.link)}
            </a>
        </>
    )
};
