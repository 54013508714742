import * as actionTypes from './groups.constants';

type Group = {
    id: string;
    name: string;
    tags: string[];
    managedBy: string[];
    manages: string[];
};

type MedicGroup = Group & {
    users: MedicUser[];
};

type MedicUser = {
    externalId: string;
    meta: {
        name: string;
        family_name: string;
    };
};

type PatientGroup = Group & {
    manages: [];
    condition: string;
};

export type GroupsState = {
    readonly medicGroups: {
        byId: Record<string, MedicGroup>;
        allIds: string[];
    };
    readonly patientGroups: {
        byId: Record<string, PatientGroup | undefined>;
        allIds: string[];
    };
};

const initialState: GroupsState = {
    medicGroups: {
        byId: {},
        allIds: []
    },
    patientGroups: {
        byId: {},
        allIds: []
    }
};
export function groupsReducer(state = initialState, action: any) {
    const { payload, type } = action;

    switch (type) {
        case actionTypes.GROUPS_FETCH_PENDING:
            return { ...state };
        case actionTypes.GROUPS_FETCH_FULFILLED: {
            return { ...state, ...payload };
        }
        default:
            return state;
    }
}
