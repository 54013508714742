import { RouteType } from '../../../routes/route.type';
import { routeReport } from '../../../app/report/route';
import { routeMyData } from '../../../app/my-data/route';
import { routeChat } from '../../../app/chat/route';
import { routeNotifications } from '../../../app/notifications/route';

export const defaultLinks: RouteType[] = [
    routeReport,
    routeMyData,
    routeChat,
    routeNotifications
];
