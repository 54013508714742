import React from 'react';
import { StyledSmiley } from './StyledIndicatorTile';
import { theme } from '../../../../theme/colors/common-colors';
import { Feedback } from '../../../../redux/domains/conditions/condtions.types';

type Props = {
    feedback: Feedback;
};

export const Smiley = ({ feedback }: Props) => {
    switch (feedback) {
        case 'meh':
            return (
                <StyledSmiley
                    type="meh"
                    style={{ color: '#fae615' }}
                    theme="filled"
                />
            );
        case 'smile':
            return (
                <StyledSmiley
                    type="smile"
                    style={{ color: '#3baa34' }}
                    theme="filled"
                />
            );
        case 'check':
            return (
                <StyledSmiley
                    type="check-circle"
                    style={{ color: theme.interactive.usability_neutral_0 }}
                    theme="filled"
                />
            );
        default:
            return <></>;
    }
};
