import { defineMessages } from 'react-intl';

export const hfRS = defineMessages({
    name: {
        defaultMessage: 'Heart Failure',
        id: 'conditions.LPHF_REG_SKANE.name'
    },
    nameLong: {
        defaultMessage: 'Heart Failure',
        id: 'conditions.LPHF_REG_SKANE.name-long'
    }
});
