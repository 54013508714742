import React, { useEffect, useState } from 'react';
import { formatMessage, Strings } from '../../locale';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Tabs } from 'antd';
import { DetailsPage } from './statistics/pages/DetailsPage';
import { ContentLayout } from '../../components/layout/ContentLayout';
import { ContentSwipeLayout } from '../../components/layout/ContentSwipeLayout';
import { CONDITIONS_FETCH } from '../../redux/domains/conditions/conditions.constants';
import { VALUES_FETCH } from '../../redux/domains/values/values.constants';
import { Header } from '../../components/layout/Header';
import { fetchValues } from '../../redux/domains/values/values.actions';
import { createLoadingSelector } from '../../redux/domains/requests/requests.selectors';
import { TabContent } from '../../components/ui/tab-content/tab-content';
import { StatisticsTab } from './statistics/StatisticsTab';
import { HistoryTab } from './history/HistoryTab';
import { ConditionPicker } from '../../components/layout/ConditionPicker';
import { useReduxSelector } from '../../hooks/use-redux-selector';

const loadingSelector = createLoadingSelector([CONDITIONS_FETCH, VALUES_FETCH]);

const MyDataPaths = {
    root: '/my-data',
    statistics: `/my-data/statistics`,
    statisticsById: `/my-data/statistics/:id`,
    history: `/my-data/history`
};

export const MyDataPage = (): JSX.Element => {
    const routeHistory = useHistory();
    const dispatch = useDispatch();
    const isLoading = useReduxSelector(loadingSelector);
    const [lastVisitedIndicatorId, setLastVisitedIndicatorId] = useState<
        string | null
    >(null);

    useEffect(() => {
        dispatch(
            fetchValues({
                from: moment().subtract(3, 'month').valueOf()
            })
        );
    }, [dispatch]);

    return (
        <Switch>
            <Route
                exact
                path={MyDataPaths.statisticsById}
                render={(props) => {
                    setLastVisitedIndicatorId(props.match.params.id);
                    return (
                        <>
                            <Header
                                title={formatMessage(
                                    Strings.navigation.main.myData
                                )}
                                backButtonPath={`${MyDataPaths.statistics}`}
                            />
                            <ContentLayout>
                                <DetailsPage loading={isLoading} {...props} />
                            </ContentLayout>
                        </>
                    );
                }}
            />
            <Redirect
                exact
                from={MyDataPaths.root}
                to={MyDataPaths.statistics}
            />
            <Route
                exact={false}
                path={MyDataPaths.root}
                render={() => (
                    <>
                        <Header
                            title={formatMessage(
                                Strings.navigation.main.myData
                            )}
                        />
                        <ConditionPicker />
                        <ContentSwipeLayout>
                            <TabContent>
                                <Tabs
                                    className="tab-content"
                                    activeKey={routeHistory.location.pathname}
                                    onChange={(key) => routeHistory.push(key)}
                                    tabBarGutter={0}
                                >
                                    <Tabs.TabPane
                                        key={MyDataPaths.statistics}
                                        tab={formatMessage(
                                            Strings.screen.myData.statistics
                                                .main.title
                                        )}
                                    >
                                        <Route
                                            key={MyDataPaths.statistics}
                                            path={MyDataPaths.statistics}
                                            render={() => (
                                                <StatisticsTab
                                                    lastVisitedIndicatorId={
                                                        lastVisitedIndicatorId
                                                    }
                                                />
                                            )}
                                        />
                                    </Tabs.TabPane>
                                    <Tabs.TabPane
                                        key={MyDataPaths.history}
                                        tab={formatMessage(
                                            Strings.screen.myData.history.main
                                                .title
                                        )}
                                    >
                                        <Route
                                            key={MyDataPaths.history}
                                            path={MyDataPaths.history}
                                            render={() => <HistoryTab />}
                                        />
                                    </Tabs.TabPane>
                                </Tabs>
                            </TabContent>
                        </ContentSwipeLayout>
                    </>
                )}
            />
        </Switch>
    );
};
