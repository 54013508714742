import React from 'react';

import { FetchedIndicator } from '../../../../types/indicator/fetched-indicator.type';
import { useReduxSelector } from '../../../../hooks/use-redux-selector';
import { getIndicatorConfigurations } from '../../../../redux/domains/user/user.selectors';
import { camelCaseToDash } from '../../../../utils/functions/string/camel-case-to-dash';
import { RecommendedObservation } from './RecommendedObservation';
import { getEnabledPropertiesForRecommendations } from '../../../../utils/recommendations/get-enabled-properties-for-recommendations';

export const RecommendedObservations = (props: {
    fetchedIndicator: FetchedIndicator;
}): JSX.Element => {
    const fetchedIndicator = props.fetchedIndicator;
    const recommendations = fetchedIndicator.recommendations;

    const recommendationLimits = getEnabledPropertiesForRecommendations({
        indicator: fetchedIndicator,
        recommendations: recommendations
    });

    const configuration = useReduxSelector(
        (state) => getIndicatorConfigurations(state)[fetchedIndicator.code]
    );

    if (recommendationLimits.enabled) {
        if (recommendationLimits.properties) {
            return (
                <>
                    {recommendationLimits.properties.map((property: any) => (
                        <RecommendedObservation
                            key={property}
                            property={property}
                            indicator={fetchedIndicator}
                            configuration={
                                configuration[camelCaseToDash(property)]
                            }
                        />
                    ))}
                </>
            );
        }

        return (
            <RecommendedObservation
                indicator={fetchedIndicator}
                configuration={configuration}
            />
        );
    }

    return <></>;
};
