import { defineMessages } from 'react-intl';

export const general = defineMessages({
    title: {
        defaultMessage: 'Active Range of Motion',
        id: 'indicator.active-motion-hs.title'
    },
    description: {
        defaultMessage: 'How is your Active Range of Motion (AROM)?',
        id: 'indicator.active-motion-hs.description'
    },
    minValueDescription: {
        defaultMessage: 'No AROM',
        id: 'indicator.active-motion-hs.min-value-description'
    },
    maxValueDescription: {
        defaultMessage: 'Normal AROM',
        id: 'indicator.active-motion-hs.max-value-description'
    },
    value: {
        defaultMessage: '{value}',
        id: 'indicator.active-motion-hs.value'
    }
});
