export const NOTIFICATION_TIMER_TICK = 'NOTIFICATION_TIMER_TICK';
export const NOTIFICATION_TIMER_TICK_END = 'NOTIFICATION_TIMER_TICK_END';

export const NOTIFICATIONS_FETCH = 'NOTIFICATIONS_FETCH';
export const NOTIFICATIONS_FETCH_PENDING = 'NOTIFICATIONS_FETCH_PENDING';
export const NOTIFICATIONS_FETCH_REJECTED = 'NOTIFICATIONS_FETCH_REJECTED';
export const NOTIFICATIONS_FETCH_FULFILLED = 'NOTIFICATIONS_FETCH_FULFILLED';

export const NOTIFICATIONS_UPDATE = 'NOTIFICATIONS_UPDATE';
export const NOTIFICATIONS_UPDATE_PENDING = 'NOTIFICATIONS_UPDATE_PENDING';
export const NOTIFICATIONS_UPDATE_REJECTED = 'NOTIFICATIONS_UPDATE_REJECTED';
export const NOTIFICATIONS_UPDATE_FULFILLED = 'NOTIFICATIONS_UPDATE_FULFILLED';
