import { defineMessages } from 'react-intl';

export const activities = defineMessages({
    title: {
        defaultMessage:
            'Your usual activities TODAY (e.g. work, study, housework, family or leisure activities)',
        id: 'indicator.eq5d3l.activities.title'
    },
    choices: {
        defaultMessage:
            '{value, select, 1 {I have no problems with performing my usual activities} 2 {I have some problems with performing my usual activities} 3 {I am unable to perform my usual activities}}',
        id: 'indicator.eq5d3l.activities.choices'
    }
});
