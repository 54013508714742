import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useReduxSelector } from '../../hooks/use-redux-selector';
import VideoRoom from '@lifepod-legacy/openvidu-frontend';
import {
    fetchVideoMeetings,
    joinVideoMeeting,
    leaveVideoMeeting
} from '../../redux/domains/video/video.actions';
import { routePaths } from '../../constants/route-paths';

export const VideoPage = () => {
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();
    const { activeSessions, token } = useReduxSelector((state) => state.video);
    const user = useReduxSelector((state) => state.user);
    const displayName = `${user.first_name} ${user.family_name}`;

    console.log('VideoPage', id, activeSessions, token);
    useEffect(
        function () {
            if (activeSessions == null) {
                dispatch(fetchVideoMeetings());
            } else {
                const session = activeSessions.find(
                    (session) => session.id === id
                );
                if (session) {
                    dispatch(joinVideoMeeting(session));
                } else {
                    // Navigates back to chat page when the current session has
                    // been removed from active video meetings
                    leaveSession();
                }
            }
        },
        [id, activeSessions, dispatch]
    );

    return (
        <div style={{ height: '100%', width: '100%' }}>
            {token && (
                <VideoRoom
                    userId={user.subjectId}
                    displayName={displayName}
                    token={token}
                    leaveSession={(othersHaveLeft) =>
                        dispatch(leaveVideoMeeting(id, othersHaveLeft))
                    }
                />
            )}
        </div>
    );

    function leaveSession() {
        window.location.href = `#${routePaths.chat}`;
    }
};
