import { defineMessages } from 'react-intl';

export const general = defineMessages({
    title: {
        defaultMessage: 'Vocational activities',
        id: 'indicator.vocational-activity-hs.title'
    },
    description: {
        defaultMessage: 'How is your ability to perform work activities?',
        id: 'indicator.vocational-activity-hs.description'
    },
    minValueDescription: {
        defaultMessage: 'No ability',
        id: 'indicator.vocational-activity-hs.min-value-description'
    },
    maxValueDescription: {
        defaultMessage: 'Normal ability',
        id: 'indicator.vocational-activity-hs.max-value-description'
    },
    value: {
        defaultMessage: '{value}',
        id: 'indicator.vocational-activity-hs.value'
    }
});
