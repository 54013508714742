import { defineMessages } from 'react-intl';

export const general = defineMessages({
    title: {
        defaultMessage: 'Oxygen Saturation',
        id: 'indicator.oxygen-saturation.title'
    },
    value: {
        id: 'indicator.oxygen-saturation.value',
        defaultMessage: '{value}'
    },
    unit: {
        id: 'indicator.oxygen-saturation.unit',
        defaultMessage: '%'
    }
});
