import { ConditionIndicator } from '../../conditions/condition.type';
import { IndicatorPropertyMessages } from './indicator-messages.type';
import { IndicatorPropertyRules } from './indicator-rules.type';
import { IndicatorByCode } from './state-indicator.type';

export type FetchedIndicator = ConditionIndicator & IndicatorByCode;

export type FetchedIndicatorProperties = FetchedIndicator & {
    data: {
        jsonSchema: {
            type: 'object';
            properties: Record<string, unknown>;
        };
    };
    messages: IndicatorPropertyMessages<string>;
    rules: IndicatorPropertyRules<string>;
};

export function isFetchedIndicatorProperties(
    indicator: FetchedIndicator
): indicator is FetchedIndicatorProperties {
    return indicator.data.jsonSchema.type === 'object';
}
