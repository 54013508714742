import React from 'react';
import { StyledList } from '../../../../../components/list/StyledList';
import { SettingsListItem } from './SettingsListItem';
import { UserSettingsType } from '../../../../../redux/domains/user/user.settings.types';
import { formatMessageById as ti } from '../../../../../locale';

type Props = {
    settings?: UserSettingsType;
};

export const SettingsPageList = (props: Props) => {
    const { settings = {} } = props;

    return (
        <>
            {Object.keys(settings).map((category, index) => {
                return renderSettingsCategory(category, settings, index);
            })}
        </>
    );
};

const renderSettingsCategory = (
    category: string,
    settings: UserSettingsType,
    index: number
) => {
    return (
        <StyledList header={ti(`user-settings.${category}.label`)} key={index}>
            {Object.keys(settings[category]).map((property, index) => {
                return (
                    <SettingsListItem
                        key={category + index}
                        category={category}
                        property={property}
                    />
                );
            })}
        </StyledList>
    );
};
