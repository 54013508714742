import messages from './messages';
import { ChartEnum } from '../../../types/indicator/chart.types';
import {
    IndicatorHeatmapRules,
    IndicatorRules
} from '../../../types/indicator/indicator-rules.type';
import { colors } from '../../../theme/colors/common-colors';

type PrnmedValue = {
    dose: number;
};

export const rules: IndicatorRules & IndicatorHeatmapRules<PrnmedValue> = {
    neutralReportResult: true,
    invertReportResponse: false,
    hideValueWhenReporting: false,
    step: 1,
    chartTypes: [ChartEnum.Heatmap],
    toHeatmapValue: (value) => value.dose,
    heatmapPieces: [
        {
            min: 0,
            max: 10,
            color: colors.chart.heatmap.purple80,
            text: messages.general.legendYes
        }
    ]
};

export default rules;
