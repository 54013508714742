import React from 'react';
import { styled } from '../../theme/styled';
import moment from 'moment';
import { Strings } from '../../locale';
import { List } from 'antd';
import { Link } from 'react-router-dom';
import { FormattedTime } from 'react-intl';
import { useIndicatorCode } from '../../hooks/use-indicator-code';
import { StyledMeta } from '../../components/list/StyledMeta';
import { StyledAvatar } from '../../components/list/StyledAvatar';
import { formatMessage } from '../../locale/utils/functions';
import { useIndicator } from '../../hooks/use-indicator';
import { useReduxSelector } from '../../hooks/use-redux-selector';
import { DateTimeProps, NotificationListItem } from './types/notification.type';

const NotificationsListItem: React.FC<NotificationListItem> = ({
    notification
}) => {
    const { notificationType, sentDate, id, senderMeta, meta, tags } =
        notification;
    const { name, family_name } = senderMeta;
    const fullName = `${name} ${family_name}`;
    const code = useIndicatorCode(meta.indicatorId).toLowerCase();
    const indicator = useIndicator(code);
    const isNew = tags.includes('sent');

    const userFirstName = useReduxSelector((state) => state.user.first_name);

    return (
        <>
            {notificationType === 'report_reminder' && indicator && (
                <StyledListItem key={id} $isNew={isNew}>
                    <StyledLink to={`/report/${code}`}>
                        <StyledMeta
                            $isNew={isNew}
                            title={formatMessage(
                                Strings.notification.main.missedReport,
                                {
                                    indicatorName: formatMessage(
                                        indicator.messages.general.title
                                    )
                                }
                            )}
                            avatar={
                                <StyledAvatar icon="warning" $isNew={isNew} />
                            }
                        />
                        <DateTime sentDate={sentDate} isNew={isNew} />
                    </StyledLink>
                </StyledListItem>
            )}
            {notificationType === 'watchover' && (
                <StyledListItem key={id} $isNew={isNew}>
                    <StyledMeta
                        $isNew={isNew}
                        title={formatMessage(
                            Strings.notification.main.watchover,
                            {
                                fullName: fullName
                            }
                        )}
                        avatar={<StyledAvatar icon="eye-o" $isNew={isNew} />}
                    />
                    <DateTime sentDate={sentDate} isNew={isNew} />
                </StyledListItem>
            )}
            {notificationType === 'message' && (
                <StyledListItem key={id} $isNew={isNew}>
                    <StyledLink to={`/chat/${meta.groupId}`}>
                        <StyledMeta
                            $isNew={isNew}
                            title={formatMessage(
                                Strings.notification.main.message,
                                {
                                    fullName: fullName
                                }
                            )}
                            avatar={<StyledAvatar icon="mail" $isNew={isNew} />}
                        />
                        <DateTime sentDate={sentDate} isNew={isNew} />
                    </StyledLink>
                </StyledListItem>
            )}
            {notificationType === 'feedback' && (
                <StyledListItem key={id} $isNew={isNew}>
                    <StyledMeta
                        $isNew={isNew}
                        title={formatMessage(
                            Strings.notification.main.feedback,
                            {
                                fullName: userFirstName
                            }
                        )}
                        avatar={<StyledAvatar icon="like" $isNew={isNew} />}
                    />
                    <DateTime sentDate={sentDate} isNew={isNew} />
                </StyledListItem>
            )}
        </>
    );
};

const DateTime = ({ sentDate, isNew }: DateTimeProps) => {
    return (
        <StyledDateTime>
            <StyledDate $isNew={isNew}>
                {moment(sentDate).format(moment.HTML5_FMT.DATE)}
            </StyledDate>
            <StyledTime $isNew={isNew}>
                <FormattedTime value={sentDate} />
            </StyledTime>
        </StyledDateTime>
    );
};

export default NotificationsListItem;

const StyledLink = styled(Link)`
    display: flex;
    width: 100%;
    align-items: center;
    color: rgba(0, 0, 0, 0.65);

    :hover {
        color: inherit;
    }
`;

const StyledDateTime = styled.div`
    padding-right: 10px;
`;

const StyledDate = styled.div<{
    $isNew: boolean;
}>`
    font-size: 12px;
    font-weight: ${(props) => (props.$isNew ? 900 : 300)};
`;

const StyledTime = styled.div<{
    $isNew: boolean;
}>`
    text-align: right;
    font-size: 10px;
    font-weight: 300;
    font-weight: ${(props) => (props.$isNew ? 900 : 300)};
`;

const StyledListItem = styled(List.Item)<{
    $isNew: boolean;
}>`
    padding: 0;
    background-color: ${(props) => (props.$isNew ? 'white' : '#f8f5f5')};
    border-top: 1px solid #e8e8e8;
`;
