import React, { useState, useMemo, useRef } from 'react';
import { mapKeys } from 'lodash';
import { Card } from 'antd';
import { styled } from '../../../../theme/styled';
import { ModalForm, FormContentProps } from '../common/ModalForm';
import { FormProps } from '../../../../types/indicator/report.type';
import { ButtonLarge } from '../../../../components/ui/buttons/ButtonLarge';
import { QuestionnaireCopyright } from './components/QuestionnaireCopyright';
import { QuestionnaireSlider } from './components/QuestionnaireSlider';
import { isFetchedIndicatorProperties } from '../../../../types/indicator/fetched-indicator.type';
import {
    isFormSliderItem,
    isFormRadioGroupItem,
    isFormTextItem,
    FetchedIndicatorPropertiesFormItem
} from '../../../../types/indicator/state-indicator.type';
import { QuestionnaireRadioGroup } from './components/QuestionnaireRadioGroup';
import { QuestionnaireTitle } from './components/QuestionnaireTitle';
import { QuestionnaireDescription } from './components/QuestionnaireDescription';
import { QuestionnaireHelpText } from './components/QuestionnaireHelpText';
import { QuestionnaireLegalText } from './components/QuestionnaireLegalText';
import { QuestionnairePropertyTitle } from './components/QuestionnairePropertyTitle';
import {
    QuestionnaireFormItem,
    isQuestionnaireFormRadioGroupItem,
    isQuestionnaireFormSliderItem,
    isQuestionnaireFormTextItem
} from './components/questionnaire.types';

type Value = number | string | undefined;
type Values = Record<string, Value>;

export const QuestionnaireForm = ({ indicator }: FormProps): JSX.Element => {
    if (!isFetchedIndicatorProperties(indicator)) {
        throw new Error(
            `Indicator have to be of type FetchedIndicatorProperties`
        );
    }
    if (!indicator.form || !indicator.form.item) {
        throw new Error(`Indicator is missing a form attribute with items`);
    }

    const { title, description, helptext, legaltext, copyright } =
        indicator.form;
    const hasStartPage =
        title || description || helptext || legaltext ? true : false;
    const [values, setValues] = useState<Values>({});
    const items = useMemo(
        () => createItems(indicator?.form?.item, values, hasStartPage),
        [indicator.form.item, values, hasStartPage]
    );
    const numberOfPages: number = Object.keys(items).length + +hasStartPage;
    const [page, setPage] = useState<number>(1);

    const contentRef = useRef<HTMLDivElement>(null);

    return (
        <ModalForm indicator={indicator} contentRef={contentRef}>
            {({ validateField }: FormContentProps) => (
                <>
                    {hasStartPage && page === 1 && (
                        <Card>
                            <QuestionnaireTitle indicator={indicator} />
                            <QuestionnaireDescription indicator={indicator} />
                            <QuestionnaireHelpText indicator={indicator} />
                            <QuestionnaireLegalText indicator={indicator} />
                        </Card>
                    )}
                    {Object.keys(items).map((key: string) => {
                        const item = items[key];
                        const { code, index } = item;

                        return (
                            <Card key={code} hidden={index != page}>
                                {(() => {
                                    if (
                                        isQuestionnaireFormRadioGroupItem(item)
                                    ) {
                                        return (
                                            <QuestionnaireRadioGroup
                                                indicator={indicator}
                                                item={item}
                                                onChange={(event) => {
                                                    setValues({
                                                        ...values,
                                                        [key]: event.target
                                                            .value
                                                    });
                                                    validateField(
                                                        code,
                                                        event.target.value
                                                    );
                                                }}
                                            />
                                        );
                                    }
                                    if (isQuestionnaireFormSliderItem(item)) {
                                        return (
                                            <QuestionnaireSlider
                                                indicator={indicator}
                                                item={item}
                                                onChange={(value) => {
                                                    setValues({
                                                        ...values,
                                                        [key]: value
                                                    });
                                                    validateField(code, value);
                                                }}
                                            />
                                        );
                                    }
                                    if (isQuestionnaireFormTextItem(item)) {
                                        return (
                                            <>
                                                <QuestionnairePropertyTitle
                                                    indicator={indicator}
                                                    property={code}
                                                />
                                                <QuestionnaireDescription
                                                    indicator={indicator}
                                                    property={code}
                                                />
                                                <QuestionnaireHelpText
                                                    indicator={indicator}
                                                    property={code}
                                                />
                                            </>
                                        );
                                    }
                                    throw new Error(
                                        `Questionnaire item type not implemented`
                                    );
                                })()}
                            </Card>
                        );
                    })}
                    <StyledFormNavigation>
                        <StyledPaging>
                            {page}/{numberOfPages}
                        </StyledPaging>
                        <StyledButtonContainer>
                            <ButtonLarge
                                disabled={page === 1}
                                style={{ marginLeft: 8 }}
                                onClick={previousPage}
                                icon="left"
                            />
                            <ButtonLarge
                                type="primary"
                                disabled={
                                    !isPageValid(page) || page === numberOfPages
                                }
                                onClick={nextPage}
                                icon="right"
                                data-test-name={indicator.code + '-right'}
                            />
                        </StyledButtonContainer>
                        {copyright && (
                            <QuestionnaireCopyright indicator={indicator} />
                        )}
                    </StyledFormNavigation>
                </>
            )}
        </ModalForm>
    );

    function scrollToTop() {
        const node = contentRef.current;
        if (node) {
            node.scrollTop = 0;
        }
    }

    function isPageValid(page: number) {
        const key = Object.keys(items).find((key) => items[key].index === page);
        return key ? items[key].isValid : true;
    }

    function nextPage() {
        if (page < numberOfPages) {
            setPage(page + 1);
        }

        scrollToTop();
    }

    function previousPage() {
        if (page > 1) {
            setPage(page - 1);
        }

        scrollToTop();
    }
};

function createItems(
    items: FetchedIndicatorPropertiesFormItem[] | undefined,
    values: Record<string, number | string | undefined>,
    hasStartPage: boolean
) {
    if (!items) {
        throw new Error(`Questionnaire have to have items`);
    }

    return mapKeys(
        items.map((item, index): QuestionnaireFormItem => {
            const value = values[item.code];
            const hasValue = item.code in values;

            if (isFormSliderItem(item)) {
                return {
                    code: item.code,
                    type: item.type,
                    value: typeof value === 'number' ? value : item.report_min,
                    isValid: hasValue || !item?.validation?.required,
                    index: hasStartPage ? index + 2 : index + 1,
                    report_min: item.report_min,
                    report_max: item.report_max,
                    steps: item.steps
                };
            }
            if (isFormRadioGroupItem(item)) {
                return {
                    code: item.code,
                    type: item.type,
                    value: typeof value === 'string' ? value : undefined,
                    isValid: hasValue || !item?.validation?.required,
                    index: hasStartPage ? index + 2 : index + 1,
                    choices: item.choices
                };
            }
            if (isFormTextItem(item)) {
                return {
                    code: item.code,
                    type: item.type,
                    isValid: true,
                    index: hasStartPage ? index + 2 : index + 1
                };
            }
            throw new Error(`Questionnaire item type not implemented`);
        }),
        'code'
    );
}

const StyledFormNavigation = styled.div`
    text-align: center;
`;

const StyledPaging = styled.p`
    margin: 15px 0px 5px;
`;

const StyledButtonContainer = styled.div`
    button {
        margin: 0 10px;
        min-width: 100px;
    }
`;
