import React from 'react';
import Highcharts, { PlotOptions } from 'highcharts';
import { formatMessage } from '../../../../../../locale';
import { roundNumber } from '../../../../../../utils/functions/math';
import moment from 'moment';
import {
    Chart,
    HighchartsChart,
    Legend,
    PlotBand,
    Title,
    Tooltip,
    XAxis,
    YAxis
} from 'react-jsx-highcharts';
import './indicator-chart.css';
import { theme } from '../../../../../../theme/colors/common-colors';
import { useFetchedIndicator } from '../../../../../../hooks/use-fetched-indicator';
import { IndicatorChartRules } from '../../../../../../types/indicator/indicator-rules.type';
import { IndicatorPropertyMessages } from '../../../../../../types/indicator/indicator-messages.type';
import { useReduxSelector } from '../../../../../../hooks/use-redux-selector';
import { getIndicatorConfigurations } from '../../../../../../redux/domains/user/user.selectors';
import { IndicatorCode } from '../../../../../../redux/domains/conditions/condtions.types';
import { PropertyChart } from './PropertyChart';
import { ConnectedMultiValueChart } from './ConnectedMultiValueChart';
import { BasicChart } from './BasicChart';
import { MultiValueChart } from './MultiValueChart';

const plotBandColors = {
    red: '#ffb9b9',
    yellow: '#ffffbb',
    ok_range: '#c3f0c3'
};

export const IndicatorChart = (props: { code: IndicatorCode; values: any }) => {
    const { code } = props;
    const indicator = useFetchedIndicator(code);
    const { rules } = indicator;
    const configuration = useReduxSelector(
        (state) => getIndicatorConfigurations(state)[indicator.code]
    );
    const { lineColor, chartOptions = {} } =
        rules as IndicatorChartRules<string>;
    const plotOptions: PlotOptions = {
        spline: {
            lineWidth: 2,
            states: {
                hover: {
                    lineWidth: 3
                }
            },
            marker: {
                enabled: true
            }
        },
        columnrange: {
            pointWidth: 2
        }
    };
    const { property, fixedYAxisMin, fixedYAxisMax, tickInterval } =
        chartOptions;

    const isSwedishLocale = moment.locale() === 'sv';

    const renderPlotBand = () => {
        const { hidePlotBand = false } = chartOptions;

        if (hidePlotBand) {
            return;
        }

        const from = calculateFrom({ configuration, property });
        const to = calculateTo({ configuration, property });

        return (
            <PlotBand
                from={from ? from : undefined}
                to={to ? to : undefined}
                color={plotBandColors['ok_range']}
            />
        );
    };

    const getSerieName = (property?: string) => {
        const messages = property
            ? (indicator.messages as IndicatorPropertyMessages)[property]
            : indicator.messages.general;

        if (!messages?.title) {
            return formatMessage(indicator.messages.general.title);
        }

        const unit = messages.unit
            ? formatMessage(messages.unit, { value: 0 }).trim()
            : indicator.data.unitOfMeasure || '';

        return formatMessage(messages.title) + (unit && ' (' + unit + ')');
    };

    const { values } = props;
    let showTooltip = false;

    let diagram: JSX.Element = (
        <BasicChart
            values={values}
            lineColors={lineColor}
            getSerieName={getSerieName}
        />
    );

    if (property) {
        diagram = (
            <PropertyChart
                values={values}
                lineColors={lineColor}
                getSerieName={getSerieName}
                rules={rules}
                property={property}
                roundNumber={roundNumber}
            />
        );
    } else if (indicator.data.jsonSchema.required) {
        if (indicator.data.jsonSchema.required[0] === 'systolic') {
            showTooltip = true;

            diagram = (
                <ConnectedMultiValueChart
                    indicator={indicator}
                    required={indicator.data.jsonSchema.required}
                    values={values}
                    lineColors={lineColor}
                    getSerieName={getSerieName}
                />
            );
        } else {
            diagram = (
                <MultiValueChart
                    required={indicator.data.jsonSchema.required}
                    values={values}
                    lineColors={lineColor}
                    getSerieName={getSerieName}
                />
            );
        }
    }

    const from = getYAxisMin({ configuration, property });
    const to = getYAxisMax({ configuration, property });

    return (
        <div className="chart-container">
            <HighchartsChart plotOptions={plotOptions}>
                <Chart
                    style={{
                        fontFamily: '"Lato", sans-serif'
                    }}
                />

                <Title
                    style={{
                        fontSize: '18px',
                        color: theme.base.dark
                    }}
                >
                    {formatMessage(indicator.messages.general.title)}
                </Title>

                {showTooltip ? (
                    <Tooltip
                        shared={true}
                        formatter={function (this: any) {
                            const date = Highcharts.dateFormat(
                                '%e %b %Y %H:%M',
                                this.x
                            );
                            let content = `<b>${date}</b><br>`;
                            this.points.map((point: any) => {
                                if (point.series.linkedParent) {
                                    content += `${point.point.series.name}: <b>${point.point.y}</b><br>`;
                                }
                            });

                            return content;
                        }}
                    />
                ) : (
                    <></>
                )}

                <Legend />

                <XAxis
                    startOnTick={true}
                    endOnTick={true}
                    type="datetime"
                    dateTimeLabelFormats={{
                        millisecond: '%H:%M',
                        second: '%H:%M',
                        day: isSwedishLocale ? '%e %b' : '%e. %b',
                        week: isSwedishLocale ? '%e %b' : '%e. %b',
                        month: isSwedishLocale ? '%b %Y' : "%b '%y"
                    }}
                />

                <YAxis
                    min={fixedYAxisMin || from}
                    max={fixedYAxisMax || to}
                    tickInterval={tickInterval ? tickInterval : undefined}
                >
                    <YAxis.Title />

                    {diagram}

                    {renderPlotBand()}
                </YAxis>
            </HighchartsChart>
        </div>
    );
};

function getPlotbandMin(params: { configuration: any; property: any }) {
    const { configuration, property } = params;

    const { report_min, lower_yellow, lower_red } = property
        ? configuration[property]
        : configuration;

    if (lower_yellow == null && lower_red == null) {
        return null;
    }

    return lower_yellow || lower_red || report_min;
}

function getPlotbandMax(params: { configuration: any; property: any }) {
    const { configuration, property } = params;

    const { report_max, upper_yellow, upper_red } = property
        ? configuration[property]
        : configuration;

    if (upper_yellow == null && upper_red == null) {
        return null;
    }

    return upper_yellow || upper_red || report_max;
}

function getYAxisMin(params: { configuration: any; property: any }) {
    const { configuration, property } = params;

    const { report_min } = property ? configuration[property] : configuration;

    return report_min;
}

function getYAxisMax(params: { configuration: any; property: any }) {
    const { configuration, property } = params;

    const { report_max } = property ? configuration[property] : configuration;

    return report_max;
}

function calculateTo(params: { configuration: any; property: any }) {
    const { configuration, property } = params;

    const plotbandTo = getPlotbandMax({ configuration, property });
    const yAxisTo = getYAxisMax({ configuration, property });

    if (plotbandTo == null && yAxisTo == null) {
        return null;
    }

    return plotbandTo || yAxisTo;
}

function calculateFrom(params: { configuration: any; property: any }) {
    const { configuration, property } = params;

    const plotbandFrom = getPlotbandMin({ configuration, property });
    const yAxisFrom = getYAxisMin({ configuration, property });

    if (plotbandFrom == null && yAxisFrom == null) {
        return null;
    }

    return plotbandFrom || yAxisFrom;
}
