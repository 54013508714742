import { defineMessages } from 'react-intl';

export const vasInfo = defineMessages({
    title: {
        defaultMessage:
            'We would like to know how good or bad your health is TODAY.',
        id: 'indicator.eq5d3l.vas-info.title'
    },
    description: {
        defaultMessage:
            'On the next screen you will see a scale numbered 0 to 100.',
        id: 'indicator.eq5d3l.vas-info.description'
    },
    helpText: {
        defaultMessage:
            '100 means the best health you can imagine. 0 means the worst health you can imagine.',
        id: 'indicator.eq5d3l.vas-info.help-text'
    }
});
