import support from './support';
import auth from './auth';
import report from './report';
import { myData } from './my-data';
import profile from './profile';
import recommendations from './recommendations';

const screen = {
    auth,
    myData,
    profile,
    recommendations,
    report,
    support
};

export { screen };
