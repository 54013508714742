import React from 'react';
import { ReccomendationRow } from '../RecommendationRow';
import { FetchedIndicator } from '../../../../types/indicator/fetched-indicator.type';
import { gradient } from './styled';
import { useIndicator } from '../../../../hooks/use-indicator';

export const ExtraInformation = (props: {
    fetchedIndicator: FetchedIndicator;
}) => {
    const indicator = useIndicator(props.fetchedIndicator.code);
    const enabled = indicator?.recommendations?.info?.enabled;

    if (enabled && indicator.extra?.recommendations) {
        return (
            <ReccomendationRow background={gradient}>
                <indicator.extra.recommendations />
            </ReccomendationRow>
        );
    }

    return <></>;
};
