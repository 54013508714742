import React from 'react';
import { formatMessage } from '../../../../../locale';
import { styled } from '../../../../../theme/styled';
import { FetchedIndicator } from '../../../../../types/indicator/fetched-indicator.type';

type Props = {
    indicator: FetchedIndicator;
};

export const QuestionnaireTitle = ({ indicator }: Props): JSX.Element => {
    if (indicator.messages.general.titleLong)
        return (
            <StyledQuestionnaireTitle>
                {formatMessage(indicator.messages.general.titleLong)}
            </StyledQuestionnaireTitle>
        );
    return <></>;
};

const StyledQuestionnaireTitle = styled.h2`
    margin-bottom: 0;
    font-size: 18px;
`;
