import { defineMessages } from 'react-intl';

export const kfRS = defineMessages({
    name: {
        defaultMessage: 'Kidney Failure',
        id: 'conditions.LP_2_0_KF_RS.name'
    },
    nameLong: {
        defaultMessage: 'Kidney Failure',
        id: 'conditions.LP_2_0_KF_RS.name-long'
    }
});
