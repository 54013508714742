import milestone0 from './milestone-0';
import milestone1 from './milestone-1';
import milestone2 from './milestone-2';
import milestone3 from './milestone-3';

const achievements = {
    milestone0,
    milestone1,
    milestone2,
    milestone3
};

const reportCounter = {
    totalCounter: {
        ...achievements
    }
};

const achievements_img = {
    reportCounter
};

export {
    achievements_img as default,
    achievements_img,
    milestone0,
    milestone1,
    milestone2,
    milestone3
};
