import { defineMessages } from 'react-intl';

export const confidence = defineMessages({
    title: {
        defaultMessage: 'Confidence',
        id: 'indicator.cat.confidence.title'
    },
    maxValueDescription: {
        defaultMessage:
            'I am not at all confident leaving my home because of my lung condition',
        id: 'indicator.cat.confidence.max-text'
    },
    minValueDescription: {
        defaultMessage:
            'I am confident leaving my home despite my lung condition',
        id: 'indicator.cat.confidence.min-text'
    }
});
