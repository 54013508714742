import { defineMessages } from 'react-intl';

export const length = defineMessages({
    title: {
        defaultMessage: 'Length',
        id: 'indicator.bmi.length.title'
    },
    value: {
        defaultMessage: '{value, number, ::.00}',
        id: 'indicator.bmi.length.value'
    },
    unit: {
        defaultMessage: 'm',
        id: 'indicator.bmi.length.unit'
    }
});
