import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { toCsv } from 'react-csv-downloader';
import { saveAs } from 'file-saver';
import { Card, Radio } from 'antd';
import { styled } from '../../../../theme/styled';
import { fetchValues } from '../../../../redux/domains/values/values.actions';
import { VALUES_FETCH } from '../../../../redux/domains/values/values.constants';
import { getValuesToExport } from '../../../../redux/domains/values/values.selectors';
import { getUser } from '../../../../redux/domains/user/user.selectors';
import {
    getConditionsByMedicGroup,
    getVisibleMedicGroups
} from '../../../../redux/domains/groups/groups.selectors';
import { StyledSubPage } from '../styled';
import { createLoadingSelector } from '../../../../redux/domains/requests/requests.selectors';
import { ButtonLarge } from '../../../../components/ui/buttons/ButtonLarge';
import { formatMessage } from '../../../../locale';
import {
    form as formMessages,
    meta as metaMessages
} from '../../../../locale/messages-descriptors/export/index';
import constants from '../../../../locale/messages-descriptors/constant';
import { getMeta, getColumns, getDatas } from './export-indicator';
import { ContentLayout } from '../../../../components/layout/ContentLayout';
import { debounce } from 'lodash';
import { DatePicker } from '../../../../components/ui/input/date-picker/DatePicker';
import { exportData } from './message-descriptors';
import { Header } from '../../../../components/layout/Header';
import { routePaths } from '../../../../constants/route-paths';

const valuesLoadingSelector = createLoadingSelector([VALUES_FETCH]);

const delayTime = 250;

export const ExportPage = () => {
    const dispatch = useDispatch();

    const isLoading = useSelector(valuesLoadingSelector);
    const [disableDateFrom, setDisableDateFrom] = useState(false);
    const [disableDateTo, setDisableDateTo] = useState(false);
    const [toDate, setToDate] = useState(Date.now());
    const oneYearAgo = moment(toDate).subtract(1, 'year').valueOf();
    const [fromDate, setFromDate] = useState(oneYearAgo);

    useEffect(
        function () {
            dispatch(fetchValues({ from: fromDate, to: toDate }));
        },
        [dispatch, fromDate, toDate]
    );

    const conditions = useSelector(getValuesToExport);
    const patient = useSelector(getUser);
    const clinics = useSelector(getVisibleMedicGroups);
    const conditionsByGroup = useSelector(getConditionsByMedicGroup);

    async function downloadExportedValues(conditionCode: string) {
        const isConditionToExport = (condition: { code: string }) =>
            condition.code === conditionCode;

        const condition = conditions.find(isConditionToExport);
        if (!condition) {
            return;
        }
        const clinicsForCondition = clinics.filter((clinic) =>
            conditionsByGroup[clinic.id].some(isConditionToExport)
        );

        const filename =
            [
                constants.lifepodDetails.name,
                patient.personal_id,
                ...formatMessage(metaMessages.dateRange, {
                    from: moment(fromDate).format('YYYY-MM-DD'),
                    to: moment(toDate).format('YYYY-MM-DD')
                }).split(' ')
            ].join('_') + '.txt';

        const conditionMeta = [
            formatMessage(metaMessages.condition),
            condition.nameLong
        ];

        const separator = '\t';
        const nl = '\r\n';

        const csvs = await Promise.all(
            condition.indicators.map(async (indicator) => [
                '',
                getMeta(indicator).join(separator),
                (await toCsv({
                    columns: getColumns(indicator),
                    datas: getDatas(indicator),
                    separator
                })) as string
            ])
        );

        const bomCode = '\ufeff';
        const txt = [
            ...clinicsForCondition.map((clinic) => [
                formatMessage(metaMessages.careUnit),
                clinic.name
            ]),
            conditionMeta
        ]
            .map((meta) => meta.join(separator))
            .concat(...csvs)
            .join(nl);

        const blob = new Blob([`${bomCode}${txt}`], {
            type: 'text/plain;charset=utf-8'
        });
        saveAs(blob, filename);
    }

    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        downloadExportedValues(
            event.currentTarget.querySelector<HTMLInputElement>(
                'input[name=condition]:checked'
            )?.value || ''
        );
    }

    const debouncedSetDateFrom = useMemo(
        () => debounce(setFromDate, delayTime),
        []
    );

    const debouncedSetDateTo = useMemo(
        () => debounce(setToDate, delayTime),
        []
    );

    return (
        <>
            <Header
                backButtonPath={routePaths.other}
                title={formatMessage(exportData.header.title)}
            />
            <ContentLayout>
                <StyledSubPage>
                    <StyledCard>
                        <DatePickWrapper>
                            <p>{formatMessage(exportData.date.beginning)}</p>
                            <DatePicker
                                defaultValue={fromDate}
                                max={Date.now()}
                                onUpdate={(event) => {
                                    setDisableDateFrom(
                                        !event.target.validity.valid
                                    );
                                    if (event.target.validity.valid) {
                                        debouncedSetDateFrom(
                                            event.target.valueAsNumber
                                        );
                                    }
                                }}
                            />
                        </DatePickWrapper>
                        <DatePickWrapper>
                            <p>{formatMessage(exportData.date.end)}</p>
                            <DatePicker
                                defaultValue={Date.now()}
                                max={Date.now()}
                                onUpdate={(event) => {
                                    setDisableDateTo(
                                        !event.target.validity.valid
                                    );
                                    if (event.target.validity.valid) {
                                        debouncedSetDateTo(
                                            event.target.valueAsNumber
                                        );
                                    }
                                }}
                            />
                        </DatePickWrapper>
                        <p>{formatMessage(formMessages.description)}</p>
                        <form onSubmit={handleSubmit}>
                            <Radio.Group
                                name="condition"
                                options={conditions.map((condition) => ({
                                    label: condition.name,
                                    value: condition.code,
                                    disabled: condition.indicators.length == 0
                                }))}
                                defaultValue={
                                    conditions.find(
                                        (condition) =>
                                            condition.indicators.length > 0
                                    )?.code
                                }
                            />
                            <div style={{ textAlign: 'center' }}>
                                <ButtonLarge
                                    htmlType="submit"
                                    icon="download"
                                    loading={isLoading}
                                    disabled={
                                        disableDateFrom ||
                                        disableDateTo ||
                                        conditions.every(
                                            (condition) =>
                                                condition.indicators.length == 0
                                        )
                                    }
                                    style={{
                                        height: '52px',
                                        padding: '0 15px'
                                    }}
                                >
                                    {formatMessage(formMessages.downloadButton)}
                                </ButtonLarge>
                            </div>
                        </form>
                    </StyledCard>
                </StyledSubPage>
            </ContentLayout>
        </>
    );
};

const StyledCard = styled(Card)`
    background-color: white;

    .ant-card-body {
        padding: 25px;
    }

    .ant-radio-group {
        display: block;
        margin-bottom: 1em;
    }

    .ant-radio-wrapper {
        display: block;
    }
`;

const DatePickWrapper = styled.div`
    margin-bottom: 20px;
`;
