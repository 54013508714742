import React from 'react';
import { formatMessage } from '../../../../locale';
import { MessageOfTheDayPage } from './MessageOfTheDayPage';
import { StyledIcon } from '../../../../components/list/StyledIcon';
import { testSelectors } from '../../../../constants/test-id';
import { messageOfTheDay } from './message-descriptors';
import { RouteType } from '../../../../routes/route.type';
import { routePaths } from '../../../../constants/route-paths';

export const messageOfTheDayRoute: RouteType = {
    component: MessageOfTheDayPage,
    title: formatMessage(messageOfTheDay.header.title),
    path: routePaths.motd,
    icon: <StyledIcon type="message" />,
    testId: testSelectors.nav.motdItem
};
