import { defineMessages } from 'react-intl';

export const general = defineMessages({
    title: {
        defaultMessage: 'Sleep',
        id: 'indicator.sleep-hs.title'
    },
    question: {
        defaultMessage:
            'Is your hand injury affecting your sleep during night?',
        id: 'indicator.sleep-hs.question'
    },
    answer: {
        defaultMessage:
            '{value, select, 1 {Not at all} 2 {Some} 3 {Very much}}',
        id: 'indicator.sleep-hs.answer'
    },
    value: {
        defaultMessage:
            '{value, select, 1 {Unaffected} 2 {Affected to some extent} 3 {Very much affected}}',
        id: 'indicator.sleep-hs.value'
    }
});
