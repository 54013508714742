import React from 'react';
import { Strings } from '../../locale';
import { MyDataPage } from './MyDataPage';
import { formatMessage } from '../../locale';
import { Icon } from 'antd';
import { testSelectors } from '../../constants/test-id';
import { routePaths } from '../../constants/route-paths';

export const routeMyData = {
    title: formatMessage(Strings.navigation.main.myData),
    path: routePaths.myData,
    icon: <Icon type="line-chart" />,
    component: MyDataPage,
    testId: testSelectors.nav.myDataItem
};
