import { defineMessages } from 'react-intl';

export const energy = defineMessages({
    title: {
        defaultMessage: 'Energy',
        id: 'indicator.cat.energy.title'
    },
    maxValueDescription: {
        defaultMessage: 'I have no energy at all',
        id: 'indicator.cat.energy.max-text'
    },
    minValueDescription: {
        defaultMessage: 'I have lots of energy',
        id: 'indicator.cat.energy.min-text'
    }
});
