import { defineMessages } from 'react-intl';

export const general = defineMessages({
    title: {
        defaultMessage: 'BMI',
        id: 'indicator.bmi.title'
    },
    value: {
        defaultMessage: '{bmi}',
        id: 'indicator.bmi.value'
    }
});
