import { legend } from './messages/legend';
import { ChartEnum } from '../../../types/indicator/chart.types';
import {
    IndicatorHeatmapRules,
    IndicatorRules
} from '../../../types/indicator/indicator-rules.type';
import { colors } from '../../../theme/colors/common-colors';

type MedicationValue = {
    'anti-lipoproteins': number;
    anticoagulant: number;
};

function toHeatmapValue(value: MedicationValue) {
    const { anticoagulant } = value;
    const antiLipoproteins = value['anti-lipoproteins'];
    return anticoagulant === 0 || antiLipoproteins === 0
        ? 0
        : Math.max(anticoagulant, antiLipoproteins);
}

export const rules: IndicatorRules & IndicatorHeatmapRules<MedicationValue> = {
    neutralReportResult: true,
    invertReportResponse: false,
    hideValueWhenReporting: false,
    chartTypes: [ChartEnum.Heatmap],
    toHeatmapValue,
    heatmapPieces: [
        {
            value: -1,
            color: colors.chart.heatmap.purple10,
            text: legend.na
        },
        {
            value: 1,
            color: colors.chart.heatmap.purple40,
            text: legend.yes
        },
        {
            value: 0,
            color: colors.chart.heatmap.purple80,
            text: legend.no
        }
    ]
};

export default rules;
