import { defineMessages } from 'react-intl';

export const recommendation = defineMessages({
    limits: {
        id: 'recommendation.limits.bmi.bmi',
        defaultMessage: 'Recommended BMI:  \n{range}'
    },
    reminder: {
        id: 'recommendation.reminder.extra.bmi',
        defaultMessage:
            'BMI is automatically calculated when you report your weight'
    }
});
