import images from '../static/images/logos';

type Logo = {
    src: string;
    alt: string;
};

const defaultAlt = 'LifePod Logo';

const authLogo: Logo = {
    src: images.auth_logo,
    alt: defaultAlt
};

const standardLogo: Logo = {
    src: images.logo,
    alt: defaultAlt
};

const standardLogoSvg: Logo = {
    src: images.logo_svg,
    alt: defaultAlt
};

export const appLogos = {
    authLogo,
    standardLogo,
    standardLogoSvg
};
