import { defineMessages } from 'react-intl';

const sms = defineMessages({
    label: {
        id: `user-settings.sms.label`,
        defaultMessage: `SMS`,
        description: ''
    },
    enabled: {
        id: `user-settings.sms.reminders.enabled.label`,
        defaultMessage: `Reminders`,
        description: ''
    }
});

const achievement = defineMessages({
    achievement: {
        id: `user-settings.achievements.label`,
        defaultMessage: `Achievements`,
        description: ''
    },
    enabled: {
        id: `user-settings.achievements.confetti.enabled.label`,
        defaultMessage: `Show confetti rewards`,
        description: ''
    }
});

const userSettings = {
    sms,
    achievement
};

export { userSettings };
