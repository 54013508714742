import React, { useState, useCallback } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useReduxSelector } from '../../../../hooks/use-redux-selector';
import { StyledSubPage, StyledCard } from '../styled';
import { updateUser } from '../../../../redux/domains/user/user.actions';
import { Loading } from '../../../../components/loading/Loading';
import { formatMessage, Strings } from '../../../../locale';
import { validate } from './validation';
import { ContentLayout } from '../../../../components/layout/ContentLayout';
import { Header } from '../../../../components/layout/Header';
import { profile } from './message-descriptors';
import { routePaths } from '../../../../constants/route-paths';
import { isEqual } from 'lodash';
import { UserState } from '../../../../redux/domains/user/user.types';
import {
    StyledP,
    StyledIcon,
    StyledButtonContainer,
    StyledEditButton,
    StyledInfoP,
    StyledCancelButton,
    StyledSaveButton
} from './styled';
import {
    FirstNameInput,
    FamilyNameInput,
    PhoneNumberInput
} from './FormInputs';
import { FormValuesType } from './form.type';

export const ProfilePage = () => {
    const dispatch = useDispatch();
    const user = useReduxSelector((state) => state.user);

    const initialValues: FormValuesType = {
        firstName: user.first_name,
        familyName: user.family_name,
        phoneNumber: user.phone_number
    };

    const [editMode, setEditMode] = useState(false);
    const [currentValues, setCurrentValues] = useState(initialValues);

    const disableSubmit = isEqual(initialValues, currentValues);

    const onSubmit = useCallback(
        async (values: FormValuesType) => {
            await dispatch(
                updateUser(user.subjectId, {
                    name: values.firstName,
                    family_name: values.familyName,
                    phone_number: values.phoneNumber
                })
            );
            setEditMode(false);
        },
        [dispatch, user.subjectId]
    );

    const onReset = useCallback(() => {
        setEditMode(false);
        setCurrentValues(initialValues);
    }, []);

    const formik = useFormik({
        initialValues,
        onSubmit,
        onReset,
        validate
    });

    const isSubmitting = formik.isSubmitting;

    return (
        <>
            <Header
                backButtonPath={routePaths.other}
                title={formatMessage(profile.header.title)}
            />
            <ContentLayout>
                <StyledSubPage>
                    {isSubmitting ? (
                        <Loading />
                    ) : (
                        <StyledCard>
                            {editMode ? (
                                <EditFormComponent
                                    onChange={onChange}
                                    formik={formik}
                                    disableSubmit={disableSubmit}
                                />
                            ) : (
                                <DisplayUserMeta
                                    user={user}
                                    handleOnClick={setEditMode}
                                />
                            )}
                        </StyledCard>
                    )}
                </StyledSubPage>
            </ContentLayout>
        </>
    );

    function onChange(event: React.ChangeEvent<HTMLFormElement>) {
        const eventName = event.target.name;
        const eventValue = event.target.value || '';

        setCurrentValues({
            ...currentValues,
            [eventName]: eventValue
        });
    }
};

const DisplayUserMeta = (props: {
    user: UserState;
    handleOnClick: (val: boolean) => void;
}) => {
    const { user, handleOnClick } = props;

    return (
        <>
            <StyledP>
                <StyledIcon type="user" />
                {user.first_name} {user.family_name}
            </StyledP>
            <StyledP>
                <StyledIcon type="tag" />
                {user.personal_id}
            </StyledP>
            <StyledP>
                <StyledIcon type="mail" />
                {user.email}
            </StyledP>
            <StyledP>
                <StyledIcon type="phone" />
                {user.phone_number}
            </StyledP>
            <StyledButtonContainer>
                <StyledEditButton
                    htmlType="button"
                    icon="edit"
                    onClick={() => {
                        handleOnClick(true);
                    }}
                >
                    {formatMessage(Strings.screen.profile.button.edit)}
                </StyledEditButton>
            </StyledButtonContainer>
        </>
    );
};

const EditFormComponent = (props: {
    onChange: (event: React.ChangeEvent<HTMLFormElement>) => void;
    formik: any;
    disableSubmit: boolean;
}) => {
    const { onChange, formik, disableSubmit } = props;
    const handleSubmit = formik.handleSubmit;
    const handleReset = formik.handleReset;
    const initialValues = formik.initialValues;

    return (
        <form onChange={onChange} onSubmit={handleSubmit}>
            <FirstNameInput formik={formik} />
            <FamilyNameInput formik={formik} />
            <PhoneNumberInput formik={formik} />
            <StyledInfoP>
                {formatMessage(
                    Strings.screen.profile.general.editEmailInformation
                )}
            </StyledInfoP>
            <StyledButtonContainer>
                <StyledCancelButton
                    htmlType="reset"
                    onClick={() => {
                        handleReset(initialValues);
                    }}
                >
                    {formatMessage(Strings.screen.profile.button.cancel)}
                </StyledCancelButton>
                <StyledSaveButton htmlType="submit" disabled={disableSubmit}>
                    {formatMessage(Strings.screen.profile.button.save)}
                </StyledSaveButton>
            </StyledButtonContainer>
        </form>
    );
};
