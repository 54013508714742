import * as actionTypes from './notifications.constants';
import { filter } from 'lodash';
import { getNotifications, putNotifications } from './notifications.api';
import { START_TIMER, STOP_TIMER } from 'redux-timer-middleware';
import { AppState } from '../../../redux/store/root-reducer';

export const ACTION_CONFIRMED = 'confirmed';
export const ACTION_NOTIFIED = 'notified';
export const TAG_CONFIRMED = 'confirmed';
export const TAG_NOTIFIED = 'notified';

export const fetchNotifications =
    (tags: any) => (dispatch: any, getState: () => AppState) => {
        return dispatch({
            type: actionTypes.NOTIFICATIONS_FETCH,
            payload: getNotifications({ tags: tags.join() })
        }).then(() => {
            const { currentConversationId } = getState().chat;

            if (currentConversationId) {
                return dispatch(
                    setMessageNotificationsAsConfirmed(currentConversationId)
                );
            }
        });
    };

export const updateNotifications = (ids: any, action: any) => (dispatch: any) =>
    dispatch({
        type: actionTypes.NOTIFICATIONS_UPDATE,
        payload: putNotifications({ ids, action })
    });

export const startNotificationTimer = () => (dispatch: any) =>
    dispatch({
        type: START_TIMER,
        payload: {
            actionName: 'NOTIFICATION_TIMER_TICK',
            timerName: 'notificationTimer',
            timerInterval: 30000
        }
    });

export const stopNotificationTimer = () => (dispatch: any) => {
    dispatch({
        type: STOP_TIMER,
        payload: {
            timerName: 'notificationTimer'
        }
    });
};

export const setMessageNotificationsAsConfirmed =
    (conversationId: any) => (dispatch: any, getState: () => AppState) => {
        const allNotifications = getState().notifications;
        // get Ids of all notifications that belongs to the current conversation and tags include sent.

        const ids = filter(
            allNotifications,
            (notification) =>
                notification.tags.includes('sent') &&
                notification.meta.conversationId === conversationId
        ).map((notification) => notification.id);

        if (ids.length > 0) {
            return dispatch(updateNotifications(ids, 'confirmed'));
        }
    };
