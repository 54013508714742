import React, { FormEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Icon, Input } from 'antd';
import { formatMessage, Strings } from '../../locale';
import { testSelectors } from '../../constants/test-id';
import { styled } from '../../theme/styled';
import { login } from '../../redux/domains/authentication/authentication.actions';
import { AUTHENTICATION_LOGIN } from '../../redux/domains/authentication/authentication.constants';
import { createLoadingSelector } from '../../redux/domains/requests/requests.selectors';
import { useReduxSelector } from '../../hooks/use-redux-selector';
import {
    ButtonLargePrimary,
    ButtonLarge
} from '../../components/ui/buttons/ButtonLarge';

const loginLoadingSelector = createLoadingSelector([AUTHENTICATION_LOGIN]);
const usernameKey = 'username';
const passwordKey = 'password';

export const LoginEmail = (): JSX.Element => {
    const error = useReduxSelector((state) => state.authentication.loginError);
    const loading = useReduxSelector(loginLoadingSelector);
    const [fieldState, setFieldState] = useState({
        [usernameKey]: {
            value: '',
            isTouched: false,
            isValid: false
        },
        [passwordKey]: {
            isTouched: false,
            isValid: false
        }
    });
    const [showPassword, setShowPassword] = useState(false);
    const togglePassword = () => setShowPassword(!showPassword);
    const handleSubmit = useSubmit();

    const showUsernameError =
        fieldState[usernameKey].isTouched && !fieldState[usernameKey].isValid;
    const showPasswordError =
        fieldState[passwordKey].isTouched && !fieldState[passwordKey].isValid;

    const passwordSuffix = showPassword ? (
        <StyledPasswordSuffix type="eye-invisible" onClick={togglePassword} />
    ) : (
        <StyledPasswordSuffix type="eye" onClick={togglePassword} />
    );

    return (
        <StyledLoginForm>
            <Form onSubmit={handleSubmit}>
                <StyledFormItem
                    validateStatus={showUsernameError ? 'error' : 'success'}
                    help={
                        showUsernameError &&
                        formatMessage(Strings.form.text.validEmail)
                    }
                >
                    <Input
                        name={usernameKey}
                        type="email"
                        required
                        prefix={<Icon type="user" />}
                        placeholder={formatMessage(Strings.labels.label.email)}
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck={false}
                        data-test-id={testSelectors.auth.userField}
                        value={fieldState[usernameKey].value}
                        onChange={onChangeUsername}
                        onBlur={validateField}
                        onInvalid={(event) => event.preventDefault()}
                    />
                </StyledFormItem>
                <StyledFormItem
                    validateStatus={showPasswordError ? 'error' : 'success'}
                    help={
                        showPasswordError &&
                        formatMessage(Strings.validation.validation.password)
                    }
                >
                    <Input
                        name={passwordKey}
                        type={showPassword ? 'text' : 'password'}
                        required
                        prefix={<Icon type="lock" />}
                        placeholder={formatMessage(
                            Strings.placeholder.placeholder.password
                        )}
                        autoCorrect="off"
                        autoCapitalize="off"
                        suffix={passwordSuffix}
                        spellCheck={false}
                        data-test-id={testSelectors.auth.passwordField}
                        onBlur={validateField}
                        onInvalid={(event) => event.preventDefault()}
                    />
                </StyledFormItem>
                {error && (
                    <StyledError>
                        {formatMessage(
                            error.message === 'User not active'
                                ? Strings.error.error.accountDeactivated
                                : Strings.error.error.invalidCredentials
                        )}
                    </StyledError>
                )}
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >
                    <StyledBackButton onClick={() => window.history.back()}>
                        <Icon type="arrow-left" />
                        {formatMessage(Strings.ui.buttons.main.back)}
                    </StyledBackButton>
                    <StyledLoginEmailButton
                        htmlType="submit"
                        loading={loading}
                        data-test-id={testSelectors.auth.signInButton}
                    >
                        {formatMessage(Strings.ui.buttons.main.signInShort)}
                    </StyledLoginEmailButton>
                </div>
            </Form>
        </StyledLoginForm>
    );

    function onChangeUsername(event: React.ChangeEvent<HTMLInputElement>) {
        setFieldState({
            ...fieldState,
            [usernameKey]: {
                ...fieldState[usernameKey],
                value: event.currentTarget.value.trim()
            }
        });
    }

    function validateField(event: React.FocusEvent<HTMLInputElement>) {
        setFieldState({
            ...fieldState,
            [event.currentTarget.name]: {
                value: event.currentTarget.value,
                isTouched: true,
                isValid: event.currentTarget.checkValidity()
            }
        });
    }
};

function useSubmit() {
    const dispatch = useDispatch();

    return function handleSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();

        dispatch(
            login(
                event.currentTarget[usernameKey].value,
                event.currentTarget[passwordKey].value
            )
        );
    };
}

const StyledPasswordSuffix = styled(Icon)`
    font-size: 20px;
    padding: 15px 0;
    color: rgba(0, 0, 0, 0.5);
`;

const StyledLoginForm = styled.div`
    .password-input {
        .ant-input-suffix {
            right: 0;
        }
    }
`;

const StyledFormItem = styled(Form.Item)`
    margin-bottom: 15px;

    input {
        height: 50px;
        border: none;
        border-bottom: 1px solid #23b2f7;
        border-radius: 0;
    }
`;

const StyledError = styled.div`
    color: ${(props) => {
        return props.theme.color.error;
    }};
`;

const StyledBackButton = styled(ButtonLarge)`
    && {
        height: 60px;
        width: 50%;
        margin-right: 10px;
        font-size: 18px;
    }
`;

const StyledLoginEmailButton = styled(ButtonLargePrimary)`
    && {
        height: 60px;
        width: 50%;
        margin-left: 10px;
        font-size: 18px;
    }
`;
