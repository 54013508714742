import React from 'react';
import { AchievementHandler } from '../../../../models/achievement';
import { AchievementTypes } from '../../../../models/achievement/achievement-support/achievement-types';
import { AchievementCard } from './achievement-cards/base/AchievementCard';

type Props = { achievements: AchievementHandler[] };

export const AchievementPageList = (props: Props): JSX.Element => {
    const { achievements = [] } = props;

    return (
        <div style={{ margin: 10 }}>
            {achievements.map((achievement, index: number) => {
                if (
                    achievement.getCategoryType() ===
                    AchievementTypes.totalCounter
                ) {
                    return (
                        <AchievementCard
                            key={index}
                            achievement={achievement}
                            index={index}
                        />
                    );
                }

                return null;
            })}
        </div>
    );
};
