import React, { Fragment, useState } from 'react';
import { mapValues } from 'lodash';
import { Card } from 'antd';
import { useReduxSelector } from '../../../../hooks/use-redux-selector';
import { FormContentProps, ModalForm } from '../common/ModalForm';
import { SliderBox } from './components/SliderBox';
import { Comment } from '../common/Comment';
import { DateTimePicker } from '../common/DateTimePicker';
import { SliderValue } from './components/SliderValue';
import { FormProps } from '../../../../types/indicator/report.type';
import { SliderTitle } from './components/SliderTitle';
import { SliderValueContainer } from './components/SliderValueContainer';
import { SliderMinDescription } from './components/SliderMinDescription';
import { SliderMaxDescription } from './components/SliderMaxDescription';
import { getIndicatorConfigurations } from '../../../../redux/domains/user/user.selectors';

export const MultiSliderForm = ({ indicator }: FormProps) => {
    const { step } = indicator.rules;
    const configuration = useReduxSelector(
        (state) => getIndicatorConfigurations(state)[indicator.code]
    );

    const initialValues: Record<string, number> = mapValues(
        indicator.properties,
        function (property: string) {
            return configuration[property].report_min || 0;
        }
    );
    const [values, setValues] = useState<Record<string, number>>(initialValues);

    return (
        <ModalForm indicator={indicator}>
            {({ validateField }: FormContentProps) => (
                <Card>
                    <DateTimePicker />
                    {Object.keys(indicator.properties || {}).map(
                        (property: string) => {
                            const {
                                report_min,
                                report_max,
                                lower_red,
                                lower_yellow,
                                upper_yellow,
                                upper_red
                            } = configuration[property];

                            return (
                                <Fragment key={property}>
                                    <SliderBox
                                        value={values[property]}
                                        min={report_min || 0}
                                        max={report_max || 100}
                                        step={step}
                                        lower={
                                            lower_yellow ||
                                            lower_red ||
                                            undefined
                                        }
                                        upper={
                                            upper_yellow ||
                                            upper_red ||
                                            undefined
                                        }
                                        useGradient={true}
                                        showButtons={true}
                                        onChange={(value) => {
                                            setValues({
                                                ...values,
                                                [property]: value
                                            });
                                            validateField(property, value);
                                        }}
                                        propertyTestId={property}
                                    >
                                        <SliderTitle
                                            indicator={indicator}
                                            property={property}
                                        />
                                        <SliderValueContainer>
                                            <SliderMinDescription
                                                indicator={indicator}
                                            />
                                            <SliderValue
                                                indicator={indicator}
                                                property={property}
                                                value={values[property]}
                                            />
                                            <SliderMaxDescription
                                                indicator={indicator}
                                            />
                                        </SliderValueContainer>
                                    </SliderBox>
                                    <input
                                        type="hidden"
                                        name={property}
                                        value={values[property]}
                                        required
                                    />
                                </Fragment>
                            );
                        }
                    )}
                    <Comment />
                </Card>
            )}
        </ModalForm>
    );
};
