type GrayFilter = {
    percent: string | number;
};

export const cssGrayscaleFilter = ({ percent }: GrayFilter) => {
    return `
    -webkit-filter: grayscale(${percent}%);
    filter: grayscale(${percent}%);
    `;
};
