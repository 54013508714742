import { defineMessages } from 'react-intl';

export const general = defineMessages({
    title: {
        defaultMessage: 'Pain',
        id: 'indicator.pain-ci.title'
    },
    value: {
        defaultMessage: 'Pain at rest: {rest}\nPain during activity: {active}',
        id: 'indicator.pain-ci.value'
    }
});

export const rest = defineMessages({
    title: {
        defaultMessage: 'Pain at rest',
        id: 'indicator.pain-ci.rest.title'
    }
});

export const active = defineMessages({
    title: {
        defaultMessage: 'Pain during activity',
        id: 'indicator.pain-ci.active.title'
    }
});
