import React, { Component } from 'react';
import { Navbar } from './navbar/Navbar';
import { styled } from '../../theme/styled';

export class Footer extends Component {
    render() {
        return (
            <StyledFooter>
                <Navbar />
            </StyledFooter>
        );
    }
}

const StyledFooter = styled.div`
    && {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: #ffffff;
        box-shadow: 0 0px 2px rgba(71, 71, 71, 0.3);
        border: 0;

        // include iphone X
        height: calc(50px + env(safe-area-inset-bottom)) !important;

        @media (orientation: landscape) and (min-width: 1024px) and (min-height: 640px) {
            width: calc(30% - 2px);
            position: absolute;
            right: 0;
            margin: auto;
            -ms-overflow-style: -ms-autohiding-scrollbar;
            overflow: hidden;
            bottom: 1px;
        }
    }
`;
