import decode from 'jwt-decode';
import { publicAxios } from '../services/http-client/public-http-client';
import {
    addTokenToLocalStorage,
    getTokenFromLocalStorage
} from '../utils/local-storage-user';

export const extractUserFromIdToken = () => {
    const idToken: any = getTokenFromLocalStorage('idToken');
    const refreshToken = getTokenFromLocalStorage('refreshToken');

    const decoded = decode(idToken);
    const {
        username,
        email,
        phone_number,
        personal_id,
        sub: externalId,
        name,
        family_name
    }: any = decoded;

    return {
        username,
        email,
        phone_number,
        personal_id,
        externalId,
        name,
        family_name,
        idToken,
        refreshToken
    };
};

type Token = {
    exp: any;
};

type AuthTokens = {
    idToken: string;
    refreshToken: string;
};

const refreshTokens = async (params: any) => {
    return await publicAxios
        .post('authentication/refresh', params)
        .then(({ data }) => data)
        .catch((error) => {
            console.error('Error in postRefreshToken\n', error);
            throw error;
        });
};

const isTokenValid = (token: any) => {
    if (token != null) {
        const currentTime = Date.now() / 1000;
        return currentTime < decode<Token>(token).exp;
    }

    return false;
};

const addTokens = (tokens: AuthTokens): AuthTokens => {
    const { idToken, refreshToken } = tokens;

    addTokenToLocalStorage('idToken', idToken);
    if (refreshToken) {
        addTokenToLocalStorage('refreshToken', refreshToken);
    }

    return tokens;
};

export const validateTokens = () => {
    return new Promise((resolve, reject) => {
        const idToken = getTokenFromLocalStorage('idToken');

        if (isTokenValid(idToken)) {
            resolve('idToken valid');
        } else {
            const refreshToken = getTokenFromLocalStorage('refreshToken');
            if (isTokenValid(refreshToken)) {
                refreshTokens({ refreshToken })
                    .then(addTokens)
                    .then((tokens) => {
                        resolve('done');
                    })
                    .catch((err: any) => {
                        reject(err);
                    });
            } else {
                reject('Could not validate tokens');
            }
        }
    });
};

export const updateTokens = () => {
    return new Promise((resolve, reject) => {
        const refreshToken = getTokenFromLocalStorage('refreshToken');

        if (isTokenValid(refreshToken)) {
            refreshTokens({ refreshToken })
                .then(addTokens)
                .then((tokens) => {
                    resolve(tokens.idToken);
                })
                .catch((err: any) => {
                    reject(err);
                });
        } else {
            reject('Could not validate refreshToken');
        }
    });
};
