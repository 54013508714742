import React, { Component } from 'react';
import { Card } from 'antd';
import { formatMessage, Strings } from '../../../../../../locale';
import { AchievementHandler } from '../../../../../../models/achievement';
import { AchievementCardContent } from './AchievementCardContent';
import { StyledAchievementCard } from './styled-card/StyledAchievementCard';
import {
    StyledModal,
    StyledBody,
    StyledBodyContent
} from '../../../../../../components/ui/modal/StyledModal';
import { StyledOkButton } from '../../achieved-modals/StyledOkButton';

type Props = { achievement: AchievementHandler; index: number };
type State = { showInfo: boolean };

export class AchievementCard extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            showInfo: false
        };
    }

    render(): JSX.Element {
        const { achievement, index } = this.props;

        const handleInfoModal = () => {
            this.setState({
                showInfo: !this.state.showInfo
            });
        };

        const cardText = achievement.getCardText();

        return (
            <StyledAchievementCard
                headerTitle={cardText.title}
                onClick={handleInfoModal}
            >
                <StyledModal
                    title={cardText.info.title}
                    footer={
                        <StyledOkButton onClick={handleInfoModal}>
                            {formatMessage(Strings.ui.buttons.main.ok)}
                        </StyledOkButton>
                    }
                    visible={this.state.showInfo}
                    closable={false}
                    destroyOnClose={true}
                    forceRender={true}
                    mask={true}
                    centered={true}
                >
                    <StyledBody>
                        <StyledBodyContent>
                            <Card>{cardText.info.body}</Card>
                        </StyledBodyContent>
                    </StyledBody>
                </StyledModal>

                <AchievementCardContent
                    achievement={achievement}
                    index={index}
                />
            </StyledAchievementCard>
        );
    }
}
