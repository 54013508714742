import messages from './messages/index';
import { ChartEnum } from '../../../../types/indicator/chart.types';
import {
    IndicatorRules,
    IndicatorHeatmapRules
} from '../../../../types/indicator/indicator-rules.type';
import { colors } from '../../../../theme/colors/common-colors';

export const rules: IndicatorRules & IndicatorHeatmapRules<boolean, boolean> = {
    neutralReportResult: true,
    invertReportResponse: true,
    hideValueWhenReporting: false,
    chartTypes: [ChartEnum.Heatmap],
    toHeatmapValue: Boolean,
    sumByDay: true,
    addHeatmapValues: (acc, value) => acc && value,
    heatmapPieces: [
        {
            value: true,
            color: colors.chart.heatmap.purple10,
            text: messages.legends.legendYes
        },
        {
            value: false,
            color: colors.chart.heatmap.purple80,
            text: messages.legends.legendNo
        }
    ]
};

export default rules;
