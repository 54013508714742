import { common } from './common';
import { general, rest, active } from './general';

export default {
    general: {
        ...common,
        ...general
    },
    rest,
    active
};
