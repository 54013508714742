import { defineMessages } from 'react-intl';

export const afRS = defineMessages({
    name: {
        defaultMessage: 'Atrial Fibrillation',
        id: 'conditions.LP_2_0_AF_RS.name'
    },
    nameLong: {
        defaultMessage: 'Atrial Fibrillation',
        id: 'conditions.LP_2_0_AF_RS.name-long'
    }
});
