import { defineMessages } from 'react-intl';

const header = defineMessages({
    title: {
        id: 'about.header.title',
        defaultMessage: 'About'
    }
});

const main = defineMessages({
    title: {
        id: 'about.main.title',
        defaultMessage: 'LifePod'
    },
    version: {
        id: 'about.main.version',
        defaultMessage: 'Version {value}'
    },
    about: {
        id: 'about.main.about',
        defaultMessage: 'About'
    },
    platform: {
        id: 'about.main.platform',
        defaultMessage: 'platform'
    },
    patent: {
        id: 'about.main.patent',
        defaultMessage: '{patentId}'
    },
    email: {
        id: 'about.main.email',
        defaultMessage: 'Support e-mail'
    },
    information: {
        id: 'about.main.information',
        defaultMessage: 'Information'
    },
    manuals: {
        id: 'about.main.manuals',
        defaultMessage: 'Manuals'
    },
    manufacturer: {
        id: 'about.main.manufacturer',
        defaultMessage: 'Manufacturer'
    },
    openSource: {
        id: 'about.main.open-source',
        defaultMessage: 'This product utilizes open source'
    },
    telephone: {
        id: 'about.main.telephone',
        defaultMessage: 'Support telephone'
    },
    trademark: {
        id: 'about.main.trademark',
        defaultMessage: 'is a registered trademark: {trademarkId}'
    },
    copyright: {
        id: 'about.main.copyright',
        defaultMessage: 'Copyright'
    },
    publisher: {
        id: 'about.main.publisher',
        defaultMessage: 'Publisher'
    },
    intendedUse: {
        id: 'about.main.intended-use',
        defaultMessage:
            '#### Intended use  \nLifePod^®^ Platform is a software platform to be used in combination with applications, sensors and medical devices to provide information to the healthcare sector to enable prioritization and/or overview of individuals in a patient group for a specific health condition.'
    }
});

export const messagesAbout = {
    main,
    header
};
