import { defineMessages } from 'react-intl';

export const info = defineMessages({
    main: {
        id: 'indicator.mmrc.lp_2_0_copd_rs.info.main',
        defaultMessage:
            'Formuläret mMRC ' +
            '(modified Medical Research Council dyspnea scale) ' +
            'är en femgradig skala som mäter graden av andnöd.'
    }
});
