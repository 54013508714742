import React from 'react';
import { Radio } from 'antd';
import { styled } from '../../../../../theme/styled';
import {
    FetchedIndicator,
    isFetchedIndicatorProperties
} from '../../../../../types/indicator/fetched-indicator.type';
import { QuestionnairePropertyTitle } from './QuestionnairePropertyTitle';
import { formatMessage } from '../../../../../locale/utils/functions';
import { RadioChangeEvent } from 'antd/lib/radio';
import { FormItemChoice } from '../../../../../types/indicator/state-indicator.type';
import { QuestionnaireFormRadioGroupItem } from './questionnaire.types';

type QuestionnaireRadioGroupType = {
    indicator: FetchedIndicator;
    item: QuestionnaireFormRadioGroupItem;
    onChange: (e: RadioChangeEvent) => void;
};

export const QuestionnaireRadioGroup = (
    props: QuestionnaireRadioGroupType
): JSX.Element => {
    const { indicator, item, onChange } = props;

    if (!isFetchedIndicatorProperties(indicator)) {
        throw new Error(
            `Indicator have to be of type FetchedIndicatorProperties`
        );
    }

    const descriptor = indicator.messages[item.code]?.choices;

    if (item.choices && descriptor) {
        return (
            <>
                <QuestionnairePropertyTitle
                    indicator={indicator}
                    property={item.code}
                />
                <Radio.Group name={item.code} onChange={onChange}>
                    {item.choices.map((choice: FormItemChoice) => (
                        <StyledRow key={choice.code}>
                            <Radio
                                value={`{"${[choice.code]}": ${choice.value}}`}
                                data-test-id={`${item.code}-${choice.value}`}
                            >
                                {formatMessage(descriptor, {
                                    value: choice.value
                                })}
                            </Radio>
                        </StyledRow>
                    ))}
                </Radio.Group>
            </>
        );
    }
    return <></>;
};

const StyledRow = styled.div`
    margin: 11px 0;

    label {
        display: inline-flex;
        margin-right: 0;
    }

    .ant-radio {
        height: 16px; // Fixes animation bug when multi line
        width: 16px; // Fixes animation bug when multi line
        vertical-align: top;
        margin-top: 5px;
    }

    .ant-radio + span {
        display: inline-block;
        padding-left: 5px;
        padding-right: 0;
        white-space: normal;
        font-size: 16px;
    }
`;
