import React, {
    FormEvent,
    FormEventHandler,
    PropsWithChildren,
    useRef,
    useState
} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useReduxSelector } from '../../../../hooks/use-redux-selector';
import { reportValue } from '../../../../redux/domains/report/report.actions';
import { FetchedIndicator } from '../../../../types/indicator/fetched-indicator.type';
import { mapValues } from 'lodash';
import moment from 'moment';
import { getIndicatorConfigurations } from '../../../../redux/domains/user/user.selectors';
import { fetchAchievement } from '../../../../redux/domains/achievements/achievements.actions';
import { updateReportStatus } from '../../../../redux/domains/report/report.actions';
import { MissedReportInfo } from './MissedReportInfo';
import { ModalFormButtons } from './ModalFormButtons';
import {
    StyledBody,
    StyledBodyContent
} from '../../../../components/ui/modal/StyledModal';
import { validateReportData } from './validate-report-data';

type Props = PropsWithChildren<{
    indicator: FetchedIndicator;
    contentRef?: React.RefObject<HTMLDivElement>;
}>;

type Fields = Record<string, { isValid: boolean; isTouched: boolean }>;

type Value = null | string | number;

export type FormContentProps = {
    validateField: (name: string, value: Value) => void;
    fields: Fields;
};

export const ModalForm = ({
    indicator,
    children,
    contentRef
}: Props): JSX.Element => {
    const form = useRef<HTMLFormElement>(null);
    const handleSubmit = useSubmitModal(indicator);
    const closeModal = useCloseModal();
    const [fields, setFields] = useState(getFields(indicator));
    const childrenProps: FormContentProps = {
        validateField,
        fields
    };

    return (
        <StyledBody ref={form} onSubmit={handleSubmit} onChange={handleChange}>
            <StyledBodyContent ref={contentRef}>
                <MissedReportInfo indicator={indicator} />
                {typeof children === 'function'
                    ? children(childrenProps)
                    : children}
            </StyledBodyContent>
            <ModalFormButtons
                closeModal={closeModal}
                isFormValid={isValid(fields)}
            />
        </StyledBody>
    );

    function handleChange(event: any) {
        const changeEvent = event as React.ChangeEvent<HTMLInputElement>;
        validateField(changeEvent.target.name, changeEvent.target.value);
    }

    function validateField(name: string, value: Value) {
        if (fields[name] && form && form.current) {
            const element = form.current.elements.namedItem(name);
            const isValid =
                (element &&
                    element instanceof HTMLInputElement &&
                    !(element.required && value == null)) ||
                (isRadioNodeList(element) && value != null) ||
                false;

            setFields({
                ...fields,
                [name]: {
                    isValid,
                    isTouched: true
                }
            });
        }
    }
};

function isRadioNodeList(node: any): node is RadioNodeList {
    return node[0]?.type === 'radio';
}

function isValid(fields: Fields) {
    return (
        Object.keys(fields).every(
            (key) => fields[key].isTouched && fields[key].isValid
        ) || false
    );
}

function getFields(indicator: FetchedIndicator) {
    if (indicator.data.jsonSchema.properties) {
        return mapValues(indicator.data.jsonSchema.properties, () => ({
            isValid: false,
            isTouched: false
        }));
    } else {
        return {
            [indicator.code]: {
                isValid: false,
                isTouched: false
            }
        };
    }
}

export function useCloseModal(): () => void {
    const history = useHistory();

    return function closeModal() {
        history.replace('/report');
    };
}

export function useSubmitModal(
    indicator: FetchedIndicator
): FormEventHandler<HTMLFormElement> {
    const history = useHistory();
    const isSubmitting = () => history.location.pathname === '/report';
    const closeModal = useCloseModal();
    const reportValueSubmit = useReportValueSubmit(indicator);

    return function handleSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();

        if (isSubmitting()) {
            return;
        }
        closeModal();
        void reportValueSubmit(event);
    };
}

function useReportValueSubmit(indicator: FetchedIndicator) {
    const dispatch = useDispatch();
    const subjectId = useReduxSelector((state) => state.user.subjectId);
    const configuration = useReduxSelector(
        (state) => getIndicatorConfigurations(state)[indicator.code]
    );

    return function (event: FormEvent<HTMLFormElement>) {
        const dtObserved = getDateTime(event);
        const reportData = getData(indicator, event);

        if (
            indicator.data.jsonSchema.maximum ||
            indicator.data.jsonSchema.minimum
        ) {
            validateReportData(reportData, indicator.data.jsonSchema);
        }

        dispatch(
            reportValue(subjectId, configuration, {
                indicator,
                dtObserved,
                data: reportData,
                comment: getComment(event)
            })
        );
        dispatch(updateReportStatus(indicator.code, dtObserved));
        dispatch(fetchAchievement());
    };
}

export function getDateTime(event: FormEvent<HTMLFormElement>) {
    const input = event.currentTarget['datetime'];

    if (input) {
        return input.value === input._wrapperState.initialValue
            ? Number(
                  moment(input.value, 'YYYY-MM-DD ').valueOf() +
                      moment().hours() * 1000 * 60 * 60 +
                      moment().minutes() * 1000 * 60 +
                      moment().seconds() * 1000 +
                      moment().milliseconds()
              )
            : Number(
                  moment(input.value, 'YYYY-MM-DD HH:mm').valueOf() +
                      moment().seconds() * 1000 +
                      moment().milliseconds()
              );
    }

    return Date.now();
}

export function getComment(
    event: FormEvent<HTMLFormElement>
): string | undefined {
    const input = event.currentTarget['comment'];
    return (input && input.value.trim()) || undefined;
}

function getData(
    indicator: FetchedIndicator,
    event: FormEvent<HTMLFormElement>
) {
    if (indicator.data.jsonSchema.properties) {
        return mapValues(indicator.data.jsonSchema.properties, (value, key) =>
            parseValue(indicator, event.currentTarget[key], key)
        );
    } else {
        return parseValue(indicator, event.currentTarget[indicator.code]);
    }
}

function parseValue(
    indicator: FetchedIndicator,
    formElement: HTMLFormElement | RadioNodeList,
    key?: string
) {
    const value = formElement.value;

    if (value == null) throw Error('Value cannot be null');

    if (isRadioNodeList(formElement)) {
        if (indicator.form) {
            if (indicator.form.type === 'questionnaire') {
                // For some reason, questionnairs have to send the value as a JSON. Example: {confined_to_bed: 3}
                return JSON.parse(value);
            }
        }
    }

    return getValueByType(
        value,
        key && indicator.data.jsonSchema.properties
            ? indicator.data.jsonSchema.properties[key].type
            : indicator.data.jsonSchema.type
    );
}

function getValueByType(value: any, type: string): number | boolean | string {
    switch (type) {
        case 'integer':
            return parseInt(value);
        case 'number':
            return Number(value);
        case 'boolean':
            return value === 'true';
        default:
            return value;
    }
}
