import { Indicator } from '../../../../types/indicator/indicator.type';
import messages from './messages';
import indicatorRules from './sleep-rules';
import icon from './images/sleep.svg';
import {
    TileType,
    FeedbackType
} from '../../../../types/indicator/report.type';
import { RadioGroupForm } from '../../../../app/report/forms/radio/RadioGroupForm';

export const sleep: Indicator<typeof indicatorRules> = {
    code: 'SLEEP-HS',
    icon,
    messages,
    hiddenHistory: false,
    hiddenStatistics: false,
    recommendations: {
        limits: {
            enabled: false
        },
        reminder: {
            moreOften: false,
            enabled: true
        },
        info: {
            enabled: false
        }
    },
    rules: indicatorRules,
    tile: {
        report: {
            form: RadioGroupForm,
            type: TileType.Dimming,
            feedback: FeedbackType.Checkmark
        }
    }
};
