import React from 'react';
import { formatMessage } from '../../../../locale';
import { ExportPage } from './ExportPage';
import { StyledIcon } from '../../../../components/list/StyledIcon';
import { testSelectors } from '../../../../constants/test-id';
import { exportData } from './message-descriptors';
import { RouteType } from '../../../../routes/route.type';
import { routePaths } from '../../../../constants/route-paths';

export const exportRoute: RouteType = {
    component: ExportPage,
    title: formatMessage(exportData.header.title),
    path: routePaths.export,
    icon: <StyledIcon type="export" />,
    testId: testSelectors.nav.exportItem
};
