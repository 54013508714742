import React from 'react';
import { camelCase, range } from 'lodash';
import { Card, Radio } from 'antd';
import Strings, { formatMessage } from '../../../../locale';
import { ModalForm } from '../common/ModalForm';
import { Comment } from '../common/Comment';
import { DateTimePicker } from '../common/DateTimePicker';
import { FormProps } from '../../../../types/indicator/report.type';
import { StyledRadioGroup } from './components/styled';
import { isFetchedIndicatorProperties } from '../../../../types/indicator/fetched-indicator.type';
import { MarkdownFromDescriptor } from '../../../../locale/utils/functions/format-markdown';
import { styled } from '../../../../theme/styled';
import { theme } from '../../../../theme/colors/common-colors';

export const MultiRadioButtonGroupForm = ({
    indicator
}: FormProps): JSX.Element => {
    if (!isFetchedIndicatorProperties(indicator)) {
        throw new Error(
            `Indicator have to be of type FetchedIndicatorProperties`
        );
    }

    return (
        <ModalForm indicator={indicator}>
            <Card>
                <DateTimePicker />
                <div style={{ margin: '5px 0 20px 0' }}>
                    {indicator.messages.general.question && (
                        <StyledIndicatorQuestion>
                            <MarkdownFromDescriptor
                                messageDescriptor={
                                    indicator.messages.general.question
                                }
                            />
                        </StyledIndicatorQuestion>
                    )}
                    {Object.keys(
                        indicator.data.jsonSchema.properties || {}
                    ).map((property: string) => {
                        const propertyInCamelCase = camelCase(property);
                        const { minimum = 0, maximum = 1 } =
                            indicator.data.jsonSchema.properties[property];
                        const isRequired =
                            indicator.data.jsonSchema.required?.includes(
                                property
                            );
                        const questionDescriptor =
                            indicator.messages[propertyInCamelCase]?.question;
                        const valueDescriptor =
                            indicator.messages[propertyInCamelCase]?.value;

                        if (!valueDescriptor) {
                            throw new Error(
                                `Property is missing value message descriptor`
                            );
                        }

                        return (
                            <div style={{ margin: '5px 0' }} key={property}>
                                {questionDescriptor && (
                                    <StyledPropertyQuestion>
                                        <MarkdownFromDescriptor
                                            messageDescriptor={
                                                questionDescriptor
                                            }
                                            paragraph="span"
                                        />
                                        {isRequired && (
                                            <Required>
                                                {formatMessage(
                                                    Strings.validation
                                                        .validation.general
                                                )}
                                            </Required>
                                        )}
                                    </StyledPropertyQuestion>
                                )}
                                <StyledRadioGroup
                                    name={property}
                                    size="large"
                                    buttonStyle="solid"
                                >
                                    {range(minimum, maximum + 1)
                                        .sort((a, b) => b - a)
                                        .map((value) => (
                                            <Radio.Button
                                                value={value}
                                                key={value}
                                                style={{
                                                    display: 'block'
                                                }}
                                            >
                                                {formatMessage(
                                                    valueDescriptor,
                                                    {
                                                        value
                                                    }
                                                )}
                                            </Radio.Button>
                                        ))}
                                </StyledRadioGroup>
                            </div>
                        );
                    })}
                </div>
                <Comment />
            </Card>
        </ModalForm>
    );
};

const StyledIndicatorQuestion = styled.div`
    margin: 25px 0 0 0;
    text-align: center;
    font-size: 17px;
    font-weight: bold;
`;

const StyledPropertyQuestion = styled.div`
    margin: 20px 0 5px 0;
    text-align: center;

    span {
        margin-bottom: 5px;
        font-size: 14px;
        font-weight: 600;
    }
`;

const Required = styled.span`
    margin-left: 5px;
    color: ${theme.denote.error};
    text-transform: lowercase;
`;
