import { defineMessages } from 'react-intl';

export const diastolic = defineMessages({
    title: {
        defaultMessage: 'Diastolic',
        id: 'indicator.blood-pressure.diastolic.title'
    },
    limits: {
        defaultMessage: 'Recommended diastolic value:  \n{range}',
        id: 'recommendation.limits.blood-pressure.diastolic'
    }
});
