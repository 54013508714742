import { defineMessages } from 'react-intl';

export const atRest = defineMessages({
    title: {
        defaultMessage: 'Pain at rest',
        id: 'indicator.pain-at-rest-hs.title'
    },
    description: {
        defaultMessage: 'Do you experience any pain at rest?',
        id: 'indicator.pain-at-rest-hs.description'
    }
});
