import { defineMessages } from 'react-intl';

export const general = defineMessages({
    title: {
        defaultMessage: 'Pulse',
        id: 'indicator.pulse.title'
    },
    value: {
        defaultMessage: '{value}',
        id: 'indicator.pulse.value'
    },
    unit: {
        id: 'indicator.pulse.unit',
        defaultMessage: 'beats per minute'
    }
});
