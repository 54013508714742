import React from 'react';
import { range } from 'lodash';
import { Card, Radio } from 'antd';
import { formatMessage } from '../../../../locale';
import { ModalForm } from '../common/ModalForm';
import { Comment } from '../common/Comment';
import { DateTimePicker } from '../common/DateTimePicker';
import { FormProps } from '../../../../types/indicator/report.type';
import { RadioGroupQuestion } from './components/RadioGroupQuestion';
import { StyledRow } from './components/styled';

export const RadioGroupForm = ({ indicator }: FormProps): JSX.Element => {
    const { minimum, maximum } = indicator.data.jsonSchema;
    if (minimum === undefined || maximum === undefined) {
        throw Error('Radio group form must have a minimum and a maximum.');
    }
    if (maximum < minimum) {
        throw Error(
            'Radio group form maximum have to be greater or equal to minimum.'
        );
    }

    return (
        <ModalForm indicator={indicator}>
            <Card>
                <DateTimePicker />
                <div style={{ margin: '5px 0 10px 0' }}>
                    <RadioGroupQuestion indicator={indicator} />
                    <Radio.Group name={indicator.code}>
                        {range(minimum, maximum + 1).map((value) => (
                            <StyledRow key={value}>
                                <Radio value={value}>
                                    {formatMessage(
                                        indicator.messages.general.answer ||
                                            indicator.messages.general.value,
                                        { value }
                                    )}
                                </Radio>
                            </StyledRow>
                        ))}
                    </Radio.Group>
                </div>
                <Comment />
            </Card>
        </ModalForm>
    );
};
