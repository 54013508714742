import { defineMessages } from 'react-intl';

export const hsRS = defineMessages({
    name: {
        defaultMessage: 'Hand Surgery',
        id: 'conditions.LP_2_0_HS_RS.name'
    },
    nameLong: {
        defaultMessage: 'Hand Surgery',
        id: 'conditions.LP_2_0_HS_RS.name-long'
    }
});
