import { Indicator } from '../../../types/indicator/indicator.type';
import messages from './messages';
import indicatorRules from './eq5d3l-rules';
import icon from './images/eq5d3l.svg';
import { TileType, FeedbackType } from '../../../types/indicator/report.type';
import { QuestionnaireForm } from '../../../app/report/forms/questionnaire/QuestionnaireForm';

const code = 'EQ5D3L';

export const eq5d3l: Indicator<typeof indicatorRules> = {
    code: code,
    icon,
    messages,
    hiddenHistory: false,
    hiddenStatistics: true,
    rules: indicatorRules,
    tile: {
        report: {
            form: QuestionnaireForm,
            type: TileType.Dimming,
            feedback: FeedbackType.Checkmark
        }
    }
};
