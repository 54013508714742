import { appetite } from '../shared-indicators/appetite/appetite';
import { bloodPressure } from '../shared-indicators/blood-pressure/blood-pressure';
import { bodyLength } from '../shared-indicators/body-length/body-length';
import { bodyWeight } from '../shared-indicators/body-weight/body-weight';
import { breathlessness } from '../shared-indicators/breathlessness/breathlessness';
import { dizziness } from '../shared-indicators/dizziness/dizziness';
import { eq5d3l } from '../shared-indicators/eq5d3l/eq5d3l';
import { swelling } from '../shared-indicators/swelling/swelling';
import { physact } from '../shared-indicators/physact/physact';
import { pulse } from '../shared-indicators/pulse/pulse';
import { smoke } from '../shared-indicators/smoke/smoke';
import { tiredness } from '../shared-indicators/tiredness/tiredness';
import { vas } from '../shared-indicators/vas/vas';
import { waistCircumference } from '../shared-indicators/waist-circumference/waist-circumference';
import { formatMessage, Strings } from '../../locale';

const code = 'LP_2_0_KF_RS';
const publicCode = 'kf';

const indicators = [
    bloodPressure,
    dizziness,
    swelling,
    bodyLength,
    bodyWeight,
    tiredness,
    breathlessness,
    pulse,
    vas,
    physact,
    appetite,
    smoke,
    waistCircumference,
    eq5d3l
];

const condition = {
    [code]: {
        code,
        indicators,
        publicCode,
        name: formatMessage(Strings.conditions.rs.kf.name),
        nameLong: formatMessage(Strings.conditions.rs.kf.nameLong)
    }
};

export default condition;
