import React from 'react';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';

import {
    getAntdLocale,
    getLanguageWithoutRegionCode,
    getMessages,
    setMomentLocale
} from './utils/locale-setup';

export function ensureIntlSupport(): any {
    if (window.Intl) return Promise.resolve();
    return new Promise((resolve) => {
        resolve(require('intl'));
    }).then(() => Promise.all([require('intl/locale-data/jsonp/en.js')]));
}

export const LocaleProvider = ({ children }: { children: any }) => {
    setMomentLocale();

    return (
        <ConfigProvider locale={getAntdLocale()}>
            <IntlProvider
                locale={getLanguageWithoutRegionCode()}
                messages={getMessages()}
            >
                {children}
            </IntlProvider>
        </ConfigProvider>
    );
};
