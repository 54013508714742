import { defineMessages } from 'react-intl';

const slider = defineMessages({
    setValue: {
        id: 'instructions.slider.set-value',
        defaultMessage:
            'Drag the pointer to the correct value. Fine-tune with + and -.'
    }
});

export default {
    slider
};
