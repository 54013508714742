import { defineMessages } from 'react-intl';

export const general = defineMessages({
    title: {
        defaultMessage: 'Blood Pressure',
        id: 'indicator.blood-pressure.title'
    },
    value: {
        defaultMessage: 'Systolic: {systolic}\nDiastolic: {diastolic}',
        id: 'indicator.blood-pressure.value'
    }
});
