import { defineMessages } from 'react-intl';

export const antiLipoproteins = defineMessages({
    title: {
        defaultMessage: 'Medication to prevent high blood lipid',
        id: 'indicator.medication-ci.anti-lipoproteins.title'
    },
    question: {
        defaultMessage: 'Medication to prevent high blood lipid (fats)',
        id: 'indicator.medication-ci.anti-lipoproteins.question'
    },
    value: {
        defaultMessage:
            '{value, select, 0 {No} 1 {Yes} other {I do not have this medication}}',
        id: 'indicator.medication-ci.anti-lipoproteins.value'
    },
    valueNa: {
        defaultMessage: 'I do not have this medication',
        id: 'indicator.medication-ci.anti-lipoproteins.choices.-1'
    },
    valueNo: {
        defaultMessage: 'No',
        id: 'indicator.medication-ci.anti-lipoproteins.choices.0'
    },
    valueYes: {
        defaultMessage: 'Yes',
        id: 'indicator.medication-ci.anti-lipoproteins.choices.1'
    }
});
