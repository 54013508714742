import { defineMessages } from 'react-intl';

export const general = defineMessages({
    title: {
        defaultMessage: 'Pump power',
        id: 'indicator.pump-power-lvad.title'
    },
    value: {
        defaultMessage: '{value, number, ::.0}',
        id: 'indicator.pump-power-lvad.value'
    }
});
