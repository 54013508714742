import { defineMessages } from 'react-intl';

const vardguidenHref =
    'https://www.1177.se/Skane/behandling--hjalpmedel/fler-behandlingar/bukhinnedialys--peritonealdialys/';

export const info = defineMessages({
    main: {
        id: 'indicator.weight-pd.lp_2_0_kf_rs_demo_uh.info.main',
        defaultMessage:
            'Här kan länkar läggas in till mer information och instruktioner om påsdialys:\n\n' +
            `[https://www.1177.se/Skane/behandling--hjalpmedel/fler-behandlingar/bukhinnedialys--peritonealdialys/](${vardguidenHref}).\n\n&nbsp\n\n` +
            'Hjälptexter och instruktioner kan också läggas här för att hjälpa patienten i sin egenvård. '
    }
});
