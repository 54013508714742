import { defineMessages } from 'react-intl';

export const icMAU = defineMessages({
    name: {
        defaultMessage: 'Intermittent Claudication',
        id: 'conditions.LP_2_0_CI_MAU.name'
    },
    nameLong: {
        defaultMessage: 'Intermittent Claudication',
        id: 'conditions.LP_2_0_CI_MAU.name-long'
    }
});
