import React, { FormEvent, FormEventHandler, Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { range } from 'lodash';
import { Card, Radio } from 'antd';
import Strings, { formatMessage } from '../../../../locale';
import { getComment, getDateTime, useCloseModal } from '../common/ModalForm';
import { Comment } from '../common/Comment';
import { DateTimePicker } from '../common/DateTimePicker';
import { FormProps } from '../../../../types/indicator/report.type';
import { StyledRadioGroup } from './components/styled';
import {
    FetchedIndicator,
    isFetchedIndicatorProperties
} from '../../../../types/indicator/fetched-indicator.type';
import { MarkdownFromDescriptor } from '../../../../locale/utils/functions/format-markdown';
import { styled } from '../../../../theme/styled';
import { theme } from '../../../../theme/colors/common-colors';
import {
    StyledBody,
    StyledBodyContent
} from '../../../../components/ui/modal/StyledModal';
import { useReduxSelector } from '../../../../hooks/use-redux-selector';
import { MissedReportInfo } from '../common/MissedReportInfo';
import { ModalFormButtons } from '../common/ModalFormButtons';
import { getIndicatorConfigurations } from '../../../../redux/domains/user/user.selectors';
import {
    reportValue,
    updateReportStatus
} from '../../../../redux/domains/report/report.actions';
import { fetchAchievement } from '../../../../redux/domains/achievements/achievements.actions';

type Values = {
    status?: number;
    color?: number;
    blood?: boolean;
};

export const PhlegmForm = ({ indicator }: FormProps): JSX.Element => {
    if (!isFetchedIndicatorProperties(indicator)) {
        throw new Error(
            `Indicator have to be of type FetchedIndicatorProperties`
        );
    }
    const [values, setValues] = useState<Values>({});
    const closeModal = useCloseModal();
    const handleSubmit = useSubmitModal(indicator, values);
    const noPhlegm = values['status'] == null || values.status === 1;
    const isValid =
        values.status === 1 ||
        (values.status && values.color && values.blood != null) ||
        false;

    return (
        <StyledBody onSubmit={handleSubmit}>
            <StyledBodyContent>
                <MissedReportInfo indicator={indicator} />
                <Card>
                    <DateTimePicker />
                    <div style={{ margin: '5px 0 20px 0' }}>
                        {Object.keys(
                            indicator.data.jsonSchema.properties || {}
                        ).map((property: string) => {
                            const {
                                type,
                                minimum = 0,
                                maximum = 1
                            } = indicator.data.jsonSchema.properties[property];
                            const isRequired =
                                !indicator.data.jsonSchema.required?.includes(
                                    property
                                );
                            const questionDescriptor =
                                indicator.messages[property]?.question;
                            const valueDescriptor =
                                indicator.messages[property]?.value;

                            if (!valueDescriptor) {
                                throw new Error(
                                    `Property is missing value message descriptor`
                                );
                            }

                            if (noPhlegm && property !== 'status') {
                                return <Fragment key={property} />;
                            }

                            return (
                                <div style={{ margin: '5px 0' }} key={property}>
                                    {questionDescriptor && (
                                        <StyledPropertyQuestion>
                                            <MarkdownFromDescriptor
                                                messageDescriptor={
                                                    questionDescriptor
                                                }
                                                paragraph="span"
                                            />
                                            {isRequired && (
                                                <Required>
                                                    {formatMessage(
                                                        Strings.validation
                                                            .validation.general
                                                    )}
                                                </Required>
                                            )}
                                        </StyledPropertyQuestion>
                                    )}
                                    <StyledRadioGroup
                                        name={property}
                                        size="large"
                                        buttonStyle="solid"
                                        onChange={(event) => {
                                            const noPhlegmValue =
                                                property === 'status' &&
                                                event.target.value === 1;
                                            setValues(
                                                noPhlegmValue
                                                    ? { status: 1 }
                                                    : {
                                                          ...values,
                                                          [property]:
                                                              event.target.value
                                                      }
                                            );
                                        }}
                                    >
                                        {type === 'boolean' ? (
                                            <>
                                                <Radio.Button
                                                    value={true}
                                                    style={{
                                                        display: 'block'
                                                    }}
                                                >
                                                    {formatMessage(
                                                        valueDescriptor,
                                                        {
                                                            value: true
                                                        }
                                                    )}
                                                </Radio.Button>
                                                <Radio.Button
                                                    value={false}
                                                    style={{
                                                        display: 'block'
                                                    }}
                                                >
                                                    {formatMessage(
                                                        valueDescriptor,
                                                        {
                                                            value: false
                                                        }
                                                    )}
                                                </Radio.Button>
                                            </>
                                        ) : (
                                            <>
                                                {range(
                                                    minimum,
                                                    maximum + 1
                                                ).map((value) => (
                                                    <Radio.Button
                                                        value={value}
                                                        key={value}
                                                        style={{
                                                            display: 'block'
                                                        }}
                                                    >
                                                        {formatMessage(
                                                            valueDescriptor,
                                                            {
                                                                value
                                                            }
                                                        )}
                                                    </Radio.Button>
                                                ))}
                                            </>
                                        )}
                                    </StyledRadioGroup>
                                </div>
                            );
                        })}
                    </div>
                    <Comment />
                </Card>
            </StyledBodyContent>
            <ModalFormButtons closeModal={closeModal} isFormValid={isValid} />
        </StyledBody>
    );
};

function useSubmitModal(
    indicator: FetchedIndicator,
    values: Values
): FormEventHandler<HTMLFormElement> {
    const history = useHistory();
    const isSubmitting = () => history.location.pathname === '/report';
    const closeModal = useCloseModal();
    const reportValueSubmit = useReportValueSubmit(indicator, values);

    return function handleSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();

        if (isSubmitting()) {
            return;
        }
        closeModal();
        void reportValueSubmit(event);
    };
}

function useReportValueSubmit(indicator: FetchedIndicator, values: Values) {
    const dispatch = useDispatch();
    const subjectId = useReduxSelector((state) => state.user.subjectId);
    const configuration = useReduxSelector(
        (state) => getIndicatorConfigurations(state)[indicator.code]
    );

    return function (event: FormEvent<HTMLFormElement>) {
        const dtObserved = getDateTime(event);
        dispatch(
            reportValue(subjectId, configuration, {
                indicator,
                dtObserved,
                data: values,
                comment: getComment(event)
            })
        );
        dispatch(updateReportStatus(indicator.code, dtObserved));
        dispatch(fetchAchievement());
    };
}

const StyledPropertyQuestion = styled.div`
    margin: 20px 0 5px 0;
    text-align: center;

    span {
        margin-bottom: 5px;
        font-size: 14px;
        font-weight: 600;
    }
`;

const Required = styled.span`
    margin-left: 5px;
    color: ${theme.denote.error};
    text-transform: lowercase;
`;
