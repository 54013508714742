import { styled } from '../../theme/styled';
import { List } from 'antd';
import { getRGBA, theme } from '../../theme/colors/common-colors';

export const StyledList = styled(List)`
    .ant-list-header {
        padding-left: 15px;
        font-weight: bold;
    }

    border-top: 1px solid ${getRGBA(theme.base.alter2, 0.3)};
    border-bottom: 1px solid ${getRGBA(theme.base.alter2, 0.3)};
`;
