import React, { Component } from 'react';
import { styled } from '../../../../../theme/styled';
import { Modal } from 'antd';
import { formatMessage, Strings } from '../../../../../locale';
import { getRGBA, theme } from '../../../../../theme/colors/common-colors';
import { CustomImage } from '../../../../../components/custom-image/CustomImage';
import Confetti from '../../../../../components/confetti/custom-react-dom-confetti/main';
import { ConfettiConfig } from '../../../../../components/confetti/custom-dom-confetti';
import { cssGrayscaleFilter } from '../../../../../theme/utils/css-grayscale-filter';
import { StyledOkButton } from './StyledOkButton';

type Props = {
    title: string;
    body: string;
    avatarUrl: string;
    milestoneType: string;
    handleOnOkAchievement: () => void;
    showAchievementModal: boolean;
};

const config: ConfettiConfig = {
    angle: 90,
    spread: 45,
    startVelocity: 45,
    elementCount: 50,
    dragFriction: 0.1,
    duration: 5000,
    stagger: 0,
    width: '10px',
    height: '10px',
    colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a']
};

const ConfettiWrapper = () => {
    const [showConfetti, setShowConfetti] = React.useState(false);

    React.useEffect(() => {
        setShowConfetti(true);
    }, []);
    return <Confetti config={config} active={showConfetti} />;
};

export class BaseAchievedModal extends Component<Props> {
    render(): JSX.Element {
        const {
            title = '',
            body = '',
            avatarUrl = '',
            milestoneType = 'major',
            handleOnOkAchievement,
            showAchievementModal
        } = this.props;

        const achievedModalConfig = {
            visible: showAchievementModal,
            closable: false,
            onOk: handleOnOkAchievement,
            onCancel: handleOnOkAchievement,
            destroyOnClose: true,
            forceRender: true,
            mask: true,
            centered: true,
            title: title,
            footer: (
                <StyledOkButton onClick={handleOnOkAchievement}>
                    {formatMessage(Strings.ui.buttons.main.ok)}
                </StyledOkButton>
            )
        };

        return (
            <StyledModal {...achievedModalConfig}>
                <StyledModalContent>
                    <StyledModalHeader>
                        <AvatarWrapper milestonetype={milestoneType}>
                            <StyledCustomImage
                                $shouldbegray={
                                    milestoneType === 'minor' ? 'true' : 'false'
                                }
                                src={avatarUrl}
                                alt={avatarUrl}
                            />
                        </AvatarWrapper>
                    </StyledModalHeader>

                    {showAchievementModal && (
                        <StyledConfettiWrapper>
                            <ConfettiWrapper />
                        </StyledConfettiWrapper>
                    )}

                    <StyledModalBody>
                        <StyledModalBodyDescription>
                            <StyledBodyText>{body}</StyledBodyText>
                        </StyledModalBodyDescription>
                    </StyledModalBody>
                </StyledModalContent>
            </StyledModal>
        );
    }
}

const StyledConfettiWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

const StyledBodyText = styled.span`
    white-space: pre-line;
`;

const StyledCustomImage = styled(CustomImage)<{ $shouldbegray: string }>`
    ${(props) =>
        props.$shouldbegray === 'true'
            ? cssGrayscaleFilter({ percent: 80 })
            : ''};
`;

const StyledModal = styled(Modal)`
    overflow: hidden;

    .ant-modal-header {
        background: none;
    }

    .ant-modal-title {
        text-align: center;
    }

    .ant-modal-body {
        padding: 0px;
    }

    .ant-modal-content {
        background-color: ${theme.achievementCard.background}; // FCFAF7
    }
`;

const StyledModalBody = styled.div`
    border-top: 1px solid ${getRGBA(theme.base.alter2, 0.3)};
    margin-top: 1rem;
    padding: 0;
    padding-bottom: 1rem;
    background-color: ${getRGBA(theme.base.alter2, 0.3)};
`;

const StyledModalContent = styled.div`
    padding: 0;
    margin-bottom: 0;
    .ant-modal-body {
        padding: 0;
    }
`;

const StyledModalHeader = styled.div`
    padding: 0;
    padding-top: 1rem;
    margin-bottom: 0;
`;

const StyledModalBodyDescription = styled.div`
    padding-top: 1rem;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 0;
    text-align: center;
`;

const AvatarWrapper = styled.div<{ milestonetype: string }>`
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-size: 150%;
    filter: brightness(110%);

    text-align: center;
    background-color: ${(props) =>
        props.milestonetype === 'minor'
            ? props.theme.achievements.milestone.major.unAchieved
            : props.theme.achievements.milestone.major.achieved};
    border: 3px solid black;
    border-radius: 100%;
    width: 60%;
    height: 60%;
    margin: auto;
    padding: 1rem;
`;
