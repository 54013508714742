import groupBy from 'lodash/groupBy';
import moment from 'moment';
import { HeatmapPiece } from '../../../../../../types/indicator/heatmap-piece.type';
import { Props } from './IndicatorHeatmap';

export function isHeatmapPiecesBoolean(
    heatmapPieces: HeatmapPiece<number | boolean>[]
): heatmapPieces is HeatmapPiece<boolean>[] {
    const piece = heatmapPieces[0];
    return (
        piece != null && 'value' in piece && typeof piece.value === 'boolean'
    );
}

export function getValues<Value, T extends number | boolean>(
    props: Props<Value, T>
) {
    const {
        toHeatmapValue = isHeatmapPiecesBoolean(
            props.indicator.rules.heatmapPieces
        )
            ? Boolean
            : Number,
        sumByDay = false,
        addHeatmapValues = (acc: number | boolean, value: number | boolean) =>
            Number(acc) + Number(value)
    } = props.indicator.rules;
    const { values } = props;

    if (sumByDay) {
        const valuesByDay = groupBy(values, ({ date }) =>
            moment(date).startOf('day').valueOf()
        );
        return Object.values(valuesByDay).map((valuesToSum) => [
            valuesToSum[0].date,
            valuesToSum
                .map(({ value }) => toHeatmapValue(value))
                .reduce(addHeatmapValues)
        ]);
    }

    return values.map((value) => [value.date, toHeatmapValue(value.value)]);
}
