import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { styled } from '../../../../theme/styled';
import { useFetchedIndicator } from '../../../../hooks/use-fetched-indicator';
import { getSelectedCondition } from '../../../../redux/domains/conditions/conditions.selectors';
import { getSelectedConditionReminders } from '../../../../redux/domains/reminders/reminders.selectors';

type Props = { code: string };

export const MissedReport = ({ code }: Props) => {
    const history = useHistory();
    const conditionState = useSelector(getSelectedCondition);
    const indicator = useFetchedIndicator(code);
    const reminders = useSelector(getSelectedConditionReminders) || {};
    const missedReport = reminders[indicator.id]?.missedReport || false;
    const isDimmed =
        Date.now() < conditionState.tiles[indicator.code].dimmedUntil;

    if (missedReport && !isDimmed) {
        return (
            <StyledMissedReport
                onClick={() => {
                    history.replace('/report/' + indicator.code.toLowerCase());
                }}
            />
        );
    }
    return <></>;
};

const StyledMissedReport = styled.div`
    position: absolute;
    height: 10%;
    width: 10%;
    top: -3%;
    right: -3%;
    background: rgb(245, 34, 45);
    border-radius: 100%;
    box-shadow: 0 0 0 2px #f9f9f9;
    cursor: pointer;
`;
