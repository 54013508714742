import React from 'react';
import { formatMessage } from '../../../../../locale';
import { styled } from '../../../../../theme/styled';
import { FetchedIndicator } from '../../../../../types/indicator/fetched-indicator.type';

type Props = {
    indicator: FetchedIndicator;
};

export const QuestionnaireCopyright = ({ indicator }: Props): JSX.Element => {
    if (indicator.messages.general.copyright)
        return (
            <StyledQuestionnaireCopyright>
                {formatMessage(indicator.messages.general.copyright)}
            </StyledQuestionnaireCopyright>
        );
    return <></>;
};

const StyledQuestionnaireCopyright = styled.p`
    font-size: 11px;
    display: flex;
    justify-content: center;
    line-height: 1.2;
    letter-spacing: 0;
    font-style: italic;
    margin-top: 15px;
`;
