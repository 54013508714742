import { IndicatorProperties } from '../../../types/indicator/indicator.type';
import messages from './messages';
import indicatorRules from './phlegm-rules';
import icon from './images/phlegm.svg';
import { TileType, FeedbackType } from '../../../types/indicator/report.type';
import { PhlegmForm } from '../../../app/report/forms/radio/PhlegmForm';

const properties = {
    status: 'status',
    color: '',
    blood: ''
};

export const phlegm: IndicatorProperties<
    keyof typeof properties,
    typeof indicatorRules
> = {
    code: 'PHLEGM',
    properties,
    icon,
    messages,
    hiddenHistory: false,
    hiddenStatistics: false,
    recommendations: {
        limits: {
            enabled: false
        },
        reminder: {
            moreOften: true,
            enabled: true
        },
        info: {
            enabled: false
        }
    },
    rules: indicatorRules,
    tile: {
        report: {
            form: PhlegmForm,
            type: TileType.Dimming,
            feedback: FeedbackType.Checkmark
        }
    }
};
