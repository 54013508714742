import * as actionTypes from './reminders.constants';
import { RemindersState, Reminder } from './reminders.types';

const initialState: RemindersState = {
    byIndicatorId: {}
};

export function remindersReducer(
    state = initialState,
    action: { type: string; payload: Record<string, Reminder> }
): RemindersState {
    switch (action.type) {
        case actionTypes.REMINDERS_FETCH_FULFILLED: {
            return {
                byIndicatorId: action.payload
            };
        }

        default:
            return state;
    }
}
