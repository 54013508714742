import { ChartEnum } from '../../../types/indicator/chart.types';
import {
    IndicatorHeatmapRules,
    IndicatorRules
} from '../../../types/indicator/indicator-rules.type';
import { colors } from '../../../theme/colors/common-colors';

export const rules: IndicatorRules & IndicatorHeatmapRules = {
    neutralReportResult: false,
    invertReportResponse: true,
    hideValueWhenReporting: false,
    step: 1,
    chartTypes: [ChartEnum.Heatmap],
    heatmapPieces: [
        { value: 1, color: colors.chart.heatmap.purple10 },
        { value: 2, color: colors.chart.heatmap.purple40 },
        { value: 3, color: colors.chart.heatmap.purple80 }
    ]
};

export default rules;
