import LocalStorage from './index';

type setting = { [id: string]: any };

type userStorageType = {
    subjectId: string;
    settings: setting;
};

export class LocalStorageKeyValueHandler {
    private _subjectId: string;

    private _localStorage = new LocalStorage();

    private _user: userStorageType;

    constructor(subjectId: string) {
        this._subjectId = subjectId;

        this._user = { subjectId: subjectId, settings: {} };

        this.getUserObj();
    }

    getUserObj(): userStorageType {
        const userObj: userStorageType = JSON.parse(
            this._localStorage.getItem(this._subjectId)
        );
        if (userObj != null) {
            this._user = userObj;
        }
        return this._user;
    }

    removeBoolean(settingKey: string) {
        try {
            delete this._user.settings[settingKey];
            this._localStorage.removeItem(settingKey);
        } catch (err) {
            console.error(err);
        }
    }

    setBoolean(settingKey: any, value: boolean) {
        this._user.settings[settingKey] = value;
        this._localStorage.setItem(this._subjectId, JSON.stringify(this._user));
    }

    getBoolean(
        settingKey: string,
        allowUndefinedReturn = false
    ): boolean | null {
        const userObj: userStorageType = JSON.parse(
            this._localStorage.getItem(this._subjectId)
        );
        let settingValue = undefined;

        try {
            settingValue = userObj.settings[settingKey];
        } catch (err) {
            settingValue = undefined;
        }

        if (settingValue != null) {
            this._user.settings[settingKey] = settingValue;
            return this._user.settings[settingKey];
        } else {
            this._user.settings[settingKey] = false;
        }

        if (allowUndefinedReturn) {
            return null;
        }
        return false;
    }
}
