import { styled } from '../../theme/styled';
import { Avatar } from 'antd';

export const StyledAvatar = styled(Avatar)<{
    $isNew: boolean;
}>`
    .anticon-warning {
        color: ${(props) => (props.$isNew ? '#ed5a06' : 'orange')};
    }

    .anticon-like {
        color: ${(props) => (props.$isNew ? '#016901' : 'green')};
    }
    .anticon-mail,
    .anticon-eye-o {
        color: ${(props) => (props.$isNew ? '#3a73c2' : '#509aff')};
    }
`;
