import { notification } from 'antd';
import Strings, { formatMessage } from '../../../../locale';

export function validateReportData(
    reportData: number,
    jsonSchema: { minimum?: number; maximum?: number }
) {
    const { minimum, maximum } = jsonSchema;

    if (minimum !== undefined && reportData < minimum) {
        notification.error({
            message: formatMessage(Strings.error.error.reportOutsideInterval)
        });
        throw new Error('Reported value is below the accepted minimum.');
    }

    if (maximum !== undefined && reportData > maximum) {
        notification.error({
            message: formatMessage(Strings.error.error.reportOutsideInterval)
        });
        throw new Error('Reported value is above the accepted maximum.');
    }
}
