import React from 'react';
import { CustomSVG } from '../custom-svg/CustomSVG';

function isSVG(src: string) {
    return src.split('.').pop() === 'svg';
}

export const CustomImage = (props: {
    src: string;
    alt?: string;
    style?: React.CSSProperties;
    className?: string; // Comes from StyledComponents
}): JSX.Element => {
    return isSVG(props.src) ? (
        <CustomSVG
            style={props.style}
            src={props.src}
            className={props.className}
        />
    ) : (
        <img
            style={props.style}
            src={props.src}
            alt={props.alt}
            className={props.className}
        />
    );
};
