import { defineMessages } from 'react-intl';

const values = defineMessages({
    noHistory: {
        id: 'common.no-history',
        defaultMessage: 'No history'
    }
});

const text = defineMessages({
    true: {
        id: 'shared.common.true',
        defaultMessage: 'Yes'
    },
    false: {
        id: 'shared.common.false',
        defaultMessage: 'No'
    },
    reason: {
        id: 'shared.common.reason',
        defaultMessage: 'Reason'
    },
    byName: {
        id: 'shared.common.byName',
        defaultMessage: 'by {name}'
    },
    clear: {
        id: 'shared.common.clear',
        defaultMessage: 'Clear'
    },
    infoMissing: {
        id: 'shared.common.infoMissing',
        defaultMessage: 'Information missing'
    },
    send: {
        id: 'shared.common.send',
        defaultMessage: 'Send'
    },
    cancel: {
        id: 'shared.common.cancel',
        defaultMessage: 'Cancel'
    },
    ok: {
        id: 'shared.common.ok',
        defaultMessage: 'OK'
    },
    save: {
        id: 'shared.common.save',
        defaultMessage: 'Save'
    },

    missing: {
        id: 'shared.common.missing',
        defaultMessage: 'Missing'
    },
    profile: {
        id: 'shared.common.profile',
        defaultMessage: 'Profile'
    },
    memberOf: {
        id: 'shared.common.memberOf',
        defaultMessage: 'Member of'
    },
    active: {
        id: 'shared.common.active',
        defaultMessage: 'Active'
    },
    inactive: {
        id: 'shared.common.inactive',
        defaultMessage: 'Inactive'
    },
    unconfirmed: {
        id: 'shared.common.unconfirmed',
        defaultMessage: 'Unconfirmed'
    },
    general: {
        id: 'shared.common.general',
        defaultMessage: 'General'
    },
    journal: {
        id: 'shared.common.journal',
        defaultMessage: 'Journal'
    },
    messages: {
        id: 'shared.common.messages',
        defaultMessage: 'Messages'
    },
    misc: {
        id: 'shared.common.misc',
        defaultMessage: 'Misc'
    },
    history: {
        id: 'shared.common.history',
        defaultMessage: 'History'
    },
    lastReset: {
        id: 'shared.common.lastReset',
        defaultMessage: 'Last Reset'
    },
    lastReviewed: {
        id: 'shared.common.lastReviewed',
        defaultMessage: 'Last Checked'
    },
    register: {
        id: 'shared.common.register',
        defaultMessage: 'Register'
    },
    yes: {
        id: 'shared.common.yes',
        defaultMessage: 'Yes'
    },
    no: {
        id: 'shared.common.no',
        defaultMessage: 'No'
    },
    by: {
        id: 'shared.common.By',
        defaultMessage: 'by'
    },
    overdueBy: {
        id: 'shared.common.overdueBy',
        defaultMessage: 'Overdue by {days}'
    },
    overdue: {
        id: 'shared.common.overdue',
        defaultMessage: 'Overdue'
    },
    note: {
        id: 'shared.common.note',
        defaultMessage: 'Note'
    },
    skip: {
        id: 'shared.common.skip',
        defaultMessage: 'Skip'
    },
    removed: {
        id: 'shared.common.removed',
        defaultMessage: 'Removed'
    },
    loggedInAs: {
        id: 'common.loggedInAs',
        defaultMessage: 'Logged in as'
    },
    download: {
        id: 'common.download',
        defaultMessage: 'Download'
    },
    signOut: {
        id: 'common.logout',
        defaultMessage: 'Sign out'
    },
    showDetails: {
        id: 'common.details.show',
        defaultMessage: 'Show details'
    },
    hideDetails: {
        id: 'common.details.hide',
        defaultMessage: 'Hide details'
    },
    on: {
        id: 'common.on',
        defaultMessage: 'On'
    },
    off: {
        id: 'common.off',
        defaultMessage: 'Off'
    },
    oneMoment: {
        id: 'common.one-moment',
        defaultMessage: 'One moment!'
    },
    new: {
        id: 'common.new',
        defaultMessage: 'New'
    },
    earlier: {
        id: 'common.earlier',
        defaultMessage: 'Earlier'
    },
    information: {
        id: 'common.information',
        defaultMessage: 'Information'
    }
});

export default {
    values,
    text
};
