import { createSelector } from 'reselect';
import { AppState } from '../../store/root-reducer';

const selectNotifications = (state: AppState) => state.notifications;

export const getNewNotificationIds = createSelector(
    selectNotifications,
    (notifications) => {
        const allNotifications = Object.values(notifications);

        return allNotifications
            .filter((notification) => notification.tags.includes('sent'))
            .map(({ id }) => id);
    }
);

export const getNotifications = createSelector(
    selectNotifications,
    (notifications) => {
        const unsorted = Object.values(notifications);
        const sorted = Array.from(unsorted).sort(
            (a, b) => b.sentDate - a.sentDate
        );

        return sorted.filter(
            (notification) => !notification.tags.includes('confirmed')
        );
    }
);
