import { defineMessages } from 'react-intl';

export const general = defineMessages({
    title: {
        defaultMessage: 'Pump flow',
        id: 'indicator.pump-flow-lvad.title'
    },
    value: {
        defaultMessage: '{value, number, ::.0}',
        id: 'indicator.pump-flow-lvad.value'
    },
    unit: {
        id: 'indicator.pump-flow-lvad.unit',
        defaultMessage: 'l/min'
    }
});
