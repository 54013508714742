import React from 'react';
import { formatMessage } from '../../../../locale';
import { SupportPage } from './SupportPage';
import { StyledIcon } from '../../../../components/list/StyledIcon';
import { testSelectors } from '../../../../constants/test-id';
import { support } from './message-descriptors';
import { RouteType } from '../../../../routes/route.type';
import { routePaths } from '../../../../constants/route-paths';

export const supportRoute: RouteType = {
    component: SupportPage,
    title: formatMessage(support.header.title),
    path: routePaths.support,
    icon: <StyledIcon type="question-circle" />,
    testId: testSelectors.nav.supportItem
};
