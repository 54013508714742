import { breathlessness } from './breathlessness';
import { chest } from './chest';
import { confidence } from './confidence';
import { cough } from './cough';
import { energy } from './energy';
import { general } from './general';
import { limitation } from './limitation';
import { phlegm } from './phlegm';
import { recommendation } from './recommendation';
import { sleep } from './sleep';
import { sum } from './sum';

const backendMappedCodes = {
    breathlessness,
    chest,
    confidence,
    cough,
    energy,
    general,
    limitation,
    phlegm,
    recommendation,
    sleep,
    sum
};

export default backendMappedCodes;
