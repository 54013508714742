import { defineMessages } from 'react-intl';

export const fluidLimitation = defineMessages({
    title: {
        defaultMessage: 'Fluid limitation',
        id: 'indicator.ehfscbs.fluid-limitation.title'
    },
    description: {
        defaultMessage:
            'I limit the amount of fluids I drink (not more than 1.5-2 l/day)',
        id: 'indicator.ehfscbs.fluid-limitation.description'
    },
    maxValueDescription: {
        defaultMessage: 'I do not agree at all',
        id: 'indicator.ehfscbs.fluid-limitation.max-text'
    },
    minValueDescription: {
        defaultMessage: 'I fully agree',
        id: 'indicator.ehfscbs.fluid-limitation.min-text'
    }
});
