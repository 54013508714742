import React from 'react';

import { SplineSeries } from 'react-jsx-highcharts';

import { LineColor } from '../../../../../../types/indicator/indicator-rules.type';

const BasicChart = (props: {
    values: [];
    lineColors: LineColor<string>;
    getSerieName: () => string;
}) => {
    const { values, lineColors, getSerieName } = props;

    const data = values.map((value: any) => [value.date, value.value]);

    return (
        <SplineSeries
            data={data}
            name={getSerieName()}
            color={lineColors.default}
            onLegendItemClick={() => false}
        />
    );
};

export { BasicChart };
