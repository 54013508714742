import { styled } from '../../../../../theme/styled';
import { ButtonLarge } from '../../../../../components/ui/buttons/ButtonLarge';

export const StyledOkButton = styled(ButtonLarge)`
    && {
        display: block;
        margin: auto;
        height: auto;
        padding: 8px 25px;
    }
`;
