import { defineMessages } from 'react-intl';

const buttons = defineMessages({
    ok: {
        id: 'app.ui.buttons.ok.label',
        defaultMessage: 'Ok',
        description: 'ok button'
    },
    save: {
        id: 'app.ui.buttons.save',
        defaultMessage: 'Save'
    },
    cancel: {
        id: 'app.ui.buttons.cancel',
        defaultMessage: 'Cancel'
    },
    yes: {
        id: 'app.ui.buttons.yes',
        defaultMessage: 'Yes'
    },
    no: {
        id: 'app.ui.buttons.no',
        defaultMessage: 'No'
    },
    true: {
        id: 'app.ui.buttons.true',
        defaultMessage: 'Yes'
    },
    false: {
        id: 'app.ui.buttons.false',
        defaultMessage: 'No'
    },
    remove: {
        id: 'app.ui.buttons.remove',
        defaultMessage: 'Remove'
    },
    areYouSure: {
        id: 'app.ui.buttons.are-you-sure',
        defaultMessage: 'Are you sure you want to delete the value?'
    },
    submitted: {
        id: 'app.ui.buttons.submitted',
        defaultMessage: 'Submitted'
    },
    forgotPassword: {
        id: 'app.ui.buttons.forgot-password',
        defaultMessage: 'Forgot password?'
    },
    signInShort: {
        id: 'app.ui.buttons.login.short',
        defaultMessage: 'Sign in'
    },
    noHistory: {
        id: 'app.ui.buttons.no-history',
        defaultMessage: 'No history'
    },
    downloadManual: {
        id: 'ui.button.download.manual.title',
        defaultMessage: 'Download manual'
    },
    downloadTitle: {
        id: 'app.patient.screen.manual.button.download.title',
        defaultMessage: 'Manual'
    },
    back: {
        id: 'ui.buttons.back',
        defaultMessage: 'Back'
    }
});

export default {
    main: buttons
};
