import React, { useEffect, useState } from 'react';
import { CONDITIONS_FETCH } from '../../../redux/domains/conditions/conditions.constants';
import { VALUES_FETCH } from '../../../redux/domains/values/values.constants';
import { createLoadingSelector } from '../../../redux/domains/requests/requests.selectors';
import { useCondition } from '../../../hooks/use-condition';
import { useReduxSelector } from '../../../hooks/use-redux-selector';
import { Loading } from '../../../components/loading/Loading';
import { TabContent } from '../../../components/ui/tab-content/tab-content';
import { IndicatorList } from './IndicatorList';

const loadingSelector = createLoadingSelector([CONDITIONS_FETCH, VALUES_FETCH]);

type Props = {
    lastVisitedIndicatorId: null | string;
};

export const StatisticsTab = (props: Props): JSX.Element => {
    const { lastVisitedIndicatorId } = props;
    const [lastVisitedIndicatorElement, setLastVisitedIndicatorElement] =
        useState<HTMLElement | null>(null);
    const condition = useCondition();
    const isLoading = useReduxSelector(
        (state) =>
            loadingSelector(state) ||
            condition.code != state.conditions.selected
    );

    useEffect(() => {
        if (lastVisitedIndicatorId) {
            setLastVisitedIndicatorElement(
                window.document.getElementById(lastVisitedIndicatorId)
            );
        }
    }, [lastVisitedIndicatorId]);

    useEffect(() => {
        if (lastVisitedIndicatorElement) {
            lastVisitedIndicatorElement.scrollIntoView();
        }
    }, [lastVisitedIndicatorElement]);

    return (
        <TabContent>{isLoading ? <Loading /> : <IndicatorList />}</TabContent>
    );
};
