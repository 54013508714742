import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Loading } from '../../../../components/loading/Loading';
import { IndicatorChart } from './charts/graph/IndicatorChart';
import IndicatorHeatmap from './charts/heatmap/IndicatorHeatmap';
import { useIndicatorCode } from '../../../../hooks/use-indicator-code';
import { useIndicator } from '../../../../hooks/use-indicator';
import { Card } from 'antd';
import { getValuesByIndicatorId } from '../../../../redux/domains/values/values.selectors';

type Props = {
    loading: boolean;
    match: { params: { id: string } };
};

export const DetailsPage = ({ loading, match }: Props): JSX.Element => {
    const { id } = match.params;
    const code = useIndicatorCode(id);
    const indicator = useIndicator(code);
    const values = useSelector(getValuesByIndicatorId);
    const indicatorValues = values[id];

    if (loading) {
        return <Loading />;
    } else if (indicator && indicatorValues) {
        return (
            <Card className="statistics-card">
                {'heatmapPieces' in indicator.rules ? (
                    <IndicatorHeatmap
                        values={indicatorValues}
                        indicator={indicator as any}
                    />
                ) : (
                    <IndicatorChart
                        values={indicatorValues}
                        code={indicator.code}
                    />
                )}
                <p />
            </Card>
        );
    } else {
        return <Redirect to="/my-data/statistics" />;
    }
};
