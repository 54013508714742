import { createIntl, createIntlCache } from 'react-intl';

import svSE from 'antd/lib/locale-provider/sv_SE';
import enUS from 'antd/lib/locale-provider/en_US';

import messagesSV from '../messages/sv.json';
import messagesEN from '../messages/en.json';

import { Locale as AntdLocaleProvider } from 'antd/lib/locale-provider';
import moment from 'moment';

const translations: Record<string, Record<string, string>> = {
    sv: messagesSV,
    en: messagesEN
};

interface NavigatorInterface extends Navigator {
    userLanguage?: string;
}

export const getLanguageCode = (): string => {
    const _navigator: NavigatorInterface = navigator;

    return (
        (_navigator.languages && _navigator.languages[0]) ||
        _navigator.language ||
        _navigator.userLanguage ||
        'en'
    );
};

export const getLanguageWithoutRegionCode = (): string =>
    getLanguageCode().toLowerCase().split(/[_-]+/)[0];

export const setMomentLocale = () => {
    const langCode = getLanguageWithoutRegionCode();
    moment.locale(langCode === 'sv' ? langCode : 'en');
};

export const getMessages = (): Record<string, string> => {
    return (
        translations[getLanguageWithoutRegionCode()] ||
        translations[getLanguageCode()] ||
        translations.en
    );
};

export const getAntdLocale = (): AntdLocaleProvider => {
    // needed for translating antd components such as a RangePicker
    const antdProvider: { [key: string]: AntdLocaleProvider } = {
        sv: svSE,
        en: enUS
    };
    return antdProvider[getLanguageWithoutRegionCode()];
};

const cache = createIntlCache();

const getIntl = () => {
    const locale = getLanguageWithoutRegionCode();
    return createIntl(
        {
            locale: locale,
            defaultLocale: 'en',
            messages: getMessages()
        },
        cache
    );
};

const intl = getIntl();

export { intl };
