import { defineMessages } from 'react-intl';

export const statistics = defineMessages({
    false: {
        defaultMessage: 'Self-test not ok',
        id: 'indicator.pump-self-test-lvad.statistics.false'
    },
    true: {
        defaultMessage: 'Self-test ok',
        id: 'indicator.pump-self-test-lvad.statistics.true'
    }
});
