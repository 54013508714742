import React from 'react';
import { styled } from '../../../../../theme/styled';
import { formatNumber } from '../../../../../locale/utils/functions/format-number';
import { formatMessage } from '../../../../../locale';
import {
    FetchedIndicator,
    isFetchedIndicatorProperties
} from '../../../../../types/indicator/fetched-indicator.type';
import { SliderValueText } from './SliderValueText';

type Props = {
    indicator: FetchedIndicator;
    property?: string;
    value: number;
};

export const SliderValue = ({ indicator, property, value }: Props) => {
    const { unitOfMeasure } = indicator.data;
    const { step } = indicator.rules;

    const unitDescriptor =
        property && isFetchedIndicatorProperties(indicator)
            ? indicator.messages[property]?.unit
            : indicator.messages.general.unit;

    return (
        <StyledDiv>
            <StyledValue>
                {formatNumber(value, {
                    minimumFractionDigits: getMinimumFractionDigits(step)
                })}
            </StyledValue>
            <StyledUnit>
                {unitDescriptor
                    ? formatMessage(unitDescriptor, {
                          value
                      })
                    : unitOfMeasure}
            </StyledUnit>
            <SliderValueText
                indicator={indicator}
                property={property}
                value={value}
            />
        </StyledDiv>
    );

    function getMinimumFractionDigits(step = 1) {
        return Math.floor(step) === step
            ? 0
            : step.toString().split('.')[1].length;
    }
};

const StyledDiv = styled.div`
    flex-grow: 1;
    text-align: center;
`;

const StyledValue = styled.p`
    margin: 0;
    padding: 0;
    font-size: 32px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
`;

const StyledUnit = styled.p`
    margin: 0;
    padding: 0;
`;
