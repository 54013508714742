import {
    CONDITIONS_FETCH_FULFILLED,
    CONDITIONS_SELECT
} from './conditions.constants';
import {
    FETCH_REPORT_STATUS_FULFILLED,
    REPORT_VALUE_FULFILLED,
    REPORT_VALUE_PENDING,
    REPORT_VALUE_REJECTED,
    RESET_FEEDBACK,
    RESET_TILES,
    UPDATE_REPORT_STATUS
} from '../report/report.constants';
import {
    selectCondition,
    preserveTilesState,
    setSelectedConditionTile,
    setTiles,
    resetTiles
} from './conditions.utils';
import { ConditionsState } from './condtions.types';

const initialState: ConditionsState = {
    allCodes: [],
    byCode: {},
    selected: ''
};

export function conditionsReducer(
    state: ConditionsState = initialState,
    action: any
): ConditionsState {
    const { type, payload, meta } = action;

    switch (type) {
        case CONDITIONS_FETCH_FULFILLED:
            return selectCondition({
                ...state,
                allCodes: payload.allCodes,
                ...preserveTilesState(state, payload)
            });
        case CONDITIONS_SELECT:
            return selectCondition(state, payload);
        case REPORT_VALUE_PENDING:
            return {
                ...state,
                ...setSelectedConditionTile(state, meta.code, {
                    loading: true,
                    feedback: null
                })
            };
        case REPORT_VALUE_FULFILLED:
            return {
                ...state,
                ...setSelectedConditionTile(state, meta.code, {
                    loading: false,
                    feedback: meta.feedback
                })
            };
        case REPORT_VALUE_REJECTED:
            return {
                ...state,
                ...setSelectedConditionTile(state, meta.code, {
                    loading: false
                })
            };
        case RESET_FEEDBACK:
            return {
                ...state,
                ...setSelectedConditionTile(state, meta.code, {
                    feedback: null
                })
            };
        case RESET_TILES: {
            return resetTiles(state);
        }
        case UPDATE_REPORT_STATUS: {
            const code: string = payload.code;
            const dimmedUntil = Object.values(state.byCode)
                .map((condition) => condition.tiles[code]?.dimmedUntil || 0)
                .reduce(
                    (dimmedUntil: number, currentDimmedUntil) =>
                        Math.max(dimmedUntil, currentDimmedUntil),
                    payload.dimmedUntil || 0
                );
            return {
                ...state,
                ...setTiles(state, {
                    [code]: { dimmedUntil }
                })
            };
        }
        case FETCH_REPORT_STATUS_FULFILLED:
            return {
                ...state,
                ...setTiles(state, payload)
            };
        default:
            return state;
    }
}
