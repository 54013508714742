import { defineMessages } from 'react-intl';

export const general = defineMessages({
    title: {
        defaultMessage: 'Candy',
        id: 'indicator.candy.title'
    },
    value: {
        defaultMessage: '{value, select, true {Yes} false {No}}',
        id: 'indicator.candy.value'
    },
    legendNo: {
        defaultMessage: 'Have not eaten candy',
        id: 'indicator.candy.legend.no'
    },
    legendYes: {
        defaultMessage: 'Have eaten candy',
        id: 'indicator.candy.legend.yes'
    },
    question: {
        defaultMessage: 'Have you eaten candy?',
        id: 'indicator.candy.question'
    }
});
