import { Indicator } from '../../../../types/indicator/indicator.type';
import { common } from '../../../shared-indicators/pain/messages/common';
import { atRest } from './messages/at-rest';
import indicatorRules from './pain-rules';
import icon from './images/pain-at-rest.svg';
import {
    TileType,
    FeedbackType
} from '../../../../types/indicator/report.type';
import { SliderForm } from '../../../../app/report/forms/slider/SliderForm';

export const painAtRest: Indicator = {
    code: 'PAIN-AT-REST-HS',
    icon,
    messages: {
        general: { ...common, ...atRest }
    },
    hiddenHistory: false,
    hiddenStatistics: false,
    recommendations: {
        limits: {
            enabled: false
        },
        reminder: {
            moreOften: false,
            enabled: true
        },
        info: {
            enabled: false
        }
    },
    rules: indicatorRules,
    tile: {
        report: {
            form: SliderForm,
            type: TileType.Dimming,
            feedback: FeedbackType.Checkmark
        }
    }
};
