import { styled } from '../../../../theme/styled';
import React from 'react';
import { Card } from 'antd';

const SupportCard = ({ children }: any) => (
    <StyledSupportCard>{children}</StyledSupportCard>
);

const StyledSupportCard = styled(Card)`
    && {
        text-align: center;
        margin-bottom: 0.3rem;
    }
`;

export { SupportCard };
