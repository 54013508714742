import { defineMessages } from 'react-intl';

export const general = defineMessages({
    title: {
        defaultMessage: 'Painkiller',
        id: 'indicator.medication-hs.title'
    },
    description: {
        defaultMessage: 'How many doses have you taken today?',
        id: 'indicator.medication-hs.description'
    },
    value: {
        defaultMessage: '{value}',
        id: 'indicator.medication-hs.value'
    }
});
