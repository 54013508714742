export const USER_FETCH = 'USER_FETCH';
export const USER_FETCH_PENDING = 'USER_FETCH_PENDING';
export const USER_FETCH_FULFILLED = 'USER_FETCH_FULFILLED';
export const USER_FETCH_REJECTED = 'USER_FETCH_REJECTED';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_UPDATE_PENDING = 'USER_UPDATE_PENDING';
export const USER_UPDATE_FULFILLED = 'USER_UPDATE_FULFILLED';
export const USER_UPDATE_REJECTED = 'USER_UPDATE_REJECTED';
export const USER_SETTINGS_UPDATE = 'USER_SETTINGS_UPDATE';
export const USER_SETTINGS_UPDATE_PENDING = 'USER_SETTINGS_UPDATE_PENDING';
export const USER_SETTINGS_UPDATE_FULFILLED = 'USER_SETTINGS_UPDATE_FULFILLED';
export const USER_SETTINGS_UPDATE_REJECTED = 'USER_SETTINGS_UPDATE_REJECTED';
