import { defineMessages } from 'react-intl';

const header = defineMessages({
    title: {
        id: 'achievements.header.title',
        defaultMessage: 'Achievements'
    }
});

export const achievements = {
    header
};
