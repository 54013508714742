import { MessageDescriptor } from 'react-intl';

import { intl, getMessages } from '../locale-setup';
import { PrimitiveType } from 'intl-messageformat';

export const formatMessage = (
    messageDescriptor: MessageDescriptor,
    values?: Record<string, PrimitiveType> | undefined
): string => {
    if (!messageDescriptor.defaultMessage) {
        console.warn(`Empty string:${messageDescriptor.id}`);
        return '';
    }

    return intl.formatMessage(messageDescriptor, values);
};

export const formatMessageById = (
    id: string,
    values?: Record<string, PrimitiveType>
): string => {
    if (hasMessageId(id)) {
        return intl.formatMessage({ id }, values);
    }

    return '';
};

export const hasMessageId = (id: string): boolean => {
    return id in getMessages();
};
