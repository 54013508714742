import { createSelector } from 'reselect';
import { isString, mapValues } from 'lodash';
import { GroupsState } from './groups.reducer';
import { getPatientConditions } from '../conditions/conditions.selectors';

type State = {
    groups: GroupsState;
};

const medicGroupsById = (state: State) => state.groups.medicGroups.byId;
const patientGroupsById = (state: State) => state.groups.patientGroups.byId;

const medicGroup = (state: State, props: { groupId: string }) =>
    state.groups.medicGroups.byId[props.groupId];

export const getMedicsInGroup = createSelector([medicGroup], (medicGroup) => {
    if (!medicGroup) return [];

    return medicGroup.users.map((user) => {
        return {
            name: user.meta.name,
            family_name: user.meta.family_name,
            id: user.externalId
        };
    });
});

export const getVisibleMedicGroups = createSelector(
    [medicGroupsById],
    (medicGroupsById) =>
        Object.values(medicGroupsById).filter(
            ({ tags }) => tags.includes('medic') && !tags.includes('medicadmin')
        )
);

export const getConditionsByMedicGroup = createSelector(
    [medicGroupsById, patientGroupsById, getPatientConditions],
    (medicGroupsById, patientGroupsById, conditions) =>
        mapValues(medicGroupsById, (medicGroup) => {
            const conditionIds = medicGroup.manages
                .map(
                    (patientGroupId) =>
                        patientGroupsById[patientGroupId]?.condition
                )
                .filter(isString);

            return conditions.filter((condition) =>
                conditionIds.includes(condition.id)
            );
        })
);
