import { defineMessages } from 'react-intl';

export const general = defineMessages({
    title: {
        defaultMessage: 'Fish',
        id: 'indicator.fish.title'
    },
    value: {
        defaultMessage: '{value, select, true {Yes} false {No}}',
        id: 'indicator.fish.value'
    },
    legendNo: {
        defaultMessage: 'Have not eaten fish',
        id: 'indicator.fish.legend.no'
    },
    legendYes: {
        defaultMessage: 'Have eaten fish',
        id: 'indicator.fish.legend.yes'
    },
    question: {
        defaultMessage: 'Have you eaten fish?',
        id: 'indicator.fish.question'
    }
});
