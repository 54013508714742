import { defineMessages } from 'react-intl';

export const position = defineMessages({
    question: {
        defaultMessage: 'Where?',
        id: 'indicator.swelling.position.question'
    },
    value: {
        defaultMessage:
            'The swelling feels {value, select, 1 {most in the legs} 2 {general}}',
        id: 'indicator.swelling.position.value'
    }
});
