import { bloodSugar } from '../shared-indicators/blood-sugar/blood-sugar';
import { weightPd } from '../shared-indicators/weight-pd/weight-pd';
import { formatMessage, Strings } from '../../locale';

const code = 'LP_2_0_FAKE_CONDITION_RS';
const publicCode = 'fc';

const indicators = [bloodSugar, weightPd];

const condition = {
    [code]: {
        code,
        indicators,
        publicCode,
        name: formatMessage(Strings.conditions.rs.fc.name),
        nameLong: formatMessage(Strings.conditions.rs.fc.nameLong)
    }
};

export default condition;
