import { LocalStorageKeyValueHandler } from '../../services/local-storage/local-storage-key-value-handler';

export class UserSettingHandler {
    private _subjectId: string;
    private _name: string;
    private _settingKey: string;
    private _status: boolean;
    private _hidden: boolean;

    private _localStorageKeyValue: LocalStorageKeyValueHandler;

    constructor(
        subjectId: string,
        name: string,
        settingKey: string,
        defaultOn: boolean,
        hidden: boolean
    ) {
        this._subjectId = subjectId;
        this._name = name;
        this._settingKey = settingKey;
        this._status = defaultOn;
        this._hidden = hidden;
        this._localStorageKeyValue = new LocalStorageKeyValueHandler(subjectId);

        this.updateThisStatus();
    }

    private getStorageValue(): boolean {
        const setting = this._localStorageKeyValue.getBoolean(
            this._settingKey,
            true
        );

        if (setting != null) {
            return setting;
        }

        return this._status;
    }

    private updateThisStatus() {
        this._status = this.getStorageValue();
    }

    private setStorageValue(value: boolean) {
        this._localStorageKeyValue.setBoolean(this._settingKey, value);
    }

    private removeStorageValue() {
        this._localStorageKeyValue.removeBoolean(this._settingKey);
    }

    removeValueFromStorage() {
        this.removeStorageValue();
    }

    getName(): string {
        return this._name;
    }

    isEnabled(): boolean {
        this.getStorageValue();
        return this._status;
    }

    setStatus(status: boolean) {
        this._status = status;
        this.setStorageValue(this._status);
    }

    flipStatus() {
        this._status = !this._status;
        this.setStorageValue(this._status);
    }

    isHidden() {
        return this._hidden;
    }
}
