import { activities } from './activities';
import { anxiety } from './anxiety';
import { general } from './general';
import { mobility } from './mobility';
import { pain } from './pain';
import { selfCare } from './selfCare';
import { sum } from './sum';
import { vas } from './vas';
import { vasInfo } from './vasInfo';

const backendMappedCodes = {
    activities,
    anxiety,
    general,
    mobility,
    pain,
    selfcare: selfCare,
    sum,
    vas,
    vas_info: vasInfo
};

export default backendMappedCodes;
