import React, { useEffect } from 'react';
import { Icon, Modal } from 'antd';
import { formatMessage as t, Strings } from '../../locale';
import { MarkdownFromDescriptor } from '../../locale/utils/functions/format-markdown';
import { OnClick } from '../../types/on-click.type';
import { messageOfTheDay } from '../../app/other/pages/message-of-the-day/message-descriptors';
import { usePoll } from '../../hooks/use-poll';
import {
    getMessageOfTheDay,
    markAsRead
} from '../../redux/domains/motd/motd.api';
import { MessageOfTheDayType } from '../../redux/domains/motd/motd.types';
import { styled } from '../../theme/styled';
import { ButtonLargePrimary } from '../ui/buttons/ButtonLarge';
import { hasMotds, noMotds } from './message-of-the-day-utils';
import { buildMotdMessages } from './motdShared';

const buildTitle = () => {
    return (
        <IconWrapper>
            <StyledIcon type="mail" />
            {t(messageOfTheDay.header.title)}
        </IconWrapper>
    );
};

const buildFooter = (handleOk: OnClick) => {
    return (
        <StyledFooterContainer>
            <StyledOKMessage>
                <MarkdownFromDescriptor
                    messageDescriptor={
                        Strings.communication.main.messageOfTheDayOKInfoMD
                    }
                />
            </StyledOKMessage>
            <ButtonLargePrimary onClick={handleOk}>
                {t(Strings.common.text.ok)}
            </ButtonLargePrimary>
        </StyledFooterContainer>
    );
};

export const MessageOfTheDayModal = () => {
    const [visible, setVisible] = React.useState(true);
    const [motds, setMotds] = React.useState<MessageOfTheDayType[]>([]);

    usePoll(() => {
        getMessageOfTheDay({ status: 'unread' }).then((maybeMotds) => {
            setMotds(maybeMotds);
        });
    }, 60000);

    useEffect(() => {
        if (hasMotds(motds)) {
            setVisible(true);
        }
    }, [motds]);

    if (noMotds(motds)) {
        return null;
    }

    const handleOk = async () => {
        try {
            await markAsRead(motds);
            setMotds([]);
            setVisible(false);
        } catch (error) {
            console.warn(`Could not markAsRead: \n${error}`);
        }
    };

    return (
        <StyledModal
            title={buildTitle()}
            visible={visible}
            closable={false}
            footer={false}
        >
            {buildMotdMessages(motds)}
            {buildFooter(handleOk)}
        </StyledModal>
    );
};

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const StyledIcon = styled(Icon)`
    margin-right: 10px;
`;

const StyledOKMessage = styled.div`
    width: 100%;
    padding-bottom: 0.5rem;
`;

const StyledFooterContainer = styled.div`
    display: block;
    text-align: center;
    padding: 25px 0;
    border-top: 1px solid #ccc;
    margin-top: auto;
`;

const StyledModal = styled(Modal)`
    && {
        position: fixed;
        top: 0;
        max-width: 100%;
        height: 100%;
        width: 100%;
        margin-top: 0;
        margin-bottom: 0;
        padding-bottom: 0;
        -webkit-user-select: none;

        @media (orientation: landscape) and (min-width: 1024px) and (min-height: 640px) {
            height: 70%;
            top: 15%;
        }
    }

    .ant-modal-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        border-radius: 0;
        border-top: 0;
        -webkit-overflow-scrolling: touch;
    }

    .ant-modal-header {
    }

    .ant-modal-body {
        padding: 30px 30px 0 30px;
        background-color: ${(props) => props.theme.backgroundColor};
        display: flex;
        flex-direction: column;
        overflow: auto;
        height: 100%;

        // Force show a scrollbar:
        &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 7px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: rgba(0, 0, 0, 0.5);
            -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
        }
    }
`;
