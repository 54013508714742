import { AnyAction, combineReducers } from 'redux';

export type RequestsState = {
    readonly loading: {
        [actionType: string]: boolean;
    };
    readonly error: {
        [actionType: string]: boolean;
    };
};

const initialState: RequestsState = {
    loading: {},
    error: {}
};

const loading = (state = initialState.loading, action: AnyAction) => {
    const { type } = action;
    const matches = /(.*)_(PENDING|FULFILLED|REJECTED)/.exec(type);

    if (!matches) return state;

    const [, requestName, requestState] = matches;

    return { ...state, [requestName]: requestState === 'PENDING' };
};

const error = (state = initialState.error, action: AnyAction) => {
    const { type, payload = {} } = action;
    const matches = /(.*)_(PENDING|REJECTED)/.exec(type);

    if (!matches) return state;

    const error = payload.data ? payload.data : payload || '';
    const [, requestName, requestState] = matches;

    return {
        ...state,
        [requestName]: requestState === 'REJECTED' ? error : ''
    };
};

const requestsReducer = combineReducers({
    loading,
    error
});

export { requestsReducer };
