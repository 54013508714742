import { defineMessages } from 'react-intl';

export const general = defineMessages({
    title: {
        defaultMessage: 'Body Weight',
        id: 'indicator.body-weight.title'
    },
    value: {
        id: 'indicator.body-weight.value',
        defaultMessage: '{value, number, ::.0}'
    }
});
