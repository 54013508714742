import { defineMessages } from 'react-intl';

export const weightCheck = defineMessages({
    title: {
        defaultMessage: 'Weight check',
        id: 'indicator.ehfscbs.weight-check.title'
    },
    description: {
        defaultMessage: 'I weigh myself every day',
        id: 'indicator.ehfscbs.weight-check.description'
    },
    maxValueDescription: {
        defaultMessage: 'I do not agree at all',
        id: 'indicator.ehfscbs.weight-check.max-text'
    },
    minValueDescription: {
        defaultMessage: 'I fully agree',
        id: 'indicator.ehfscbs.weight-check.min-text'
    }
});
