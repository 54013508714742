import { breathlessness } from './breathlessness';
import { excerciseCheck } from './excerciseCheck';
import { fatigueCheck } from './fatigueCheck';
import { fluidLimitation } from './fluidLimitation';
import { general } from './general';
import { lowSaltDiet } from './lowSaltDiet';
import { medicationCheck } from './medicationCheck';
import { swollenLegs } from './swollenLegs';
import { weightCheck } from './weightCheck';
import { weightGain } from './weightGain';

const backendMappedCodes = {
    breathlessness,
    excercisecheck: excerciseCheck,
    fatiguecheck: fatigueCheck,
    fluidlimitation: fluidLimitation,
    general,
    lowsaltdiet: lowSaltDiet,
    medicationcheck: medicationCheck,
    swollenlegs: swollenLegs,
    weightcheck: weightCheck,
    weightgain: weightGain
};

export default backendMappedCodes;
