import { camelCase, mapKeys, mapValues } from 'lodash';
import { formatMessage } from '../../locale';
import {
    IndicatorValue,
    KeyValues,
    Value
} from '../../types/indicator/indicator-value.type';
import { FetchedIndicator } from '../../types/indicator/fetched-indicator.type';
import { IndicatorPropertyMessages } from '../../types/indicator/indicator-messages.type';

export function formatValue(
    indicator: FetchedIndicator,
    value: IndicatorValue
) {
    return typeof value === 'object'
        ? formatMessage(
              indicator.messages.general.value,
              formatProperties(indicator, value)
          ).split('\n')
        : [formatMeasurement(indicator, value).toString()];
}

function formatProperties(indicator: FetchedIndicator, values: KeyValues) {
    const properties = indicator.properties || {};

    return {
        ...properties,
        ...mapValues(
            mapKeys(values, (_, key) => camelCase(key)),
            (value, key) => formatMeasurement(indicator, value, key)
        )
    };
}

function formatMeasurement(
    indicator: FetchedIndicator,
    value: Value,
    key = 'general'
) {
    const messages = (indicator.messages as IndicatorPropertyMessages)[key];
    const valueMessage = messages?.value;
    const unitMessage = messages?.unit;

    const data = { value };

    const unit = unitMessage
        ? formatMessage(unitMessage, data).trim()
        : indicator.data.unitOfMeasure;

    const formattedValue = valueMessage
        ? formatMessage(valueMessage, data)
        : value;

    return unit ? `${formattedValue} ${unit}` : formattedValue;
}
