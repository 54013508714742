import React from 'react';
import { formatMessage } from '../../../../../locale';
import { styled } from '../../../../../theme/styled';
import {
    FetchedIndicator,
    isFetchedIndicatorProperties
} from '../../../../../types/indicator/fetched-indicator.type';

type Props = {
    indicator: FetchedIndicator;
    property?: string;
};

export const QuestionnaireHelpText = ({
    indicator,
    property
}: Props): JSX.Element => {
    if (!isFetchedIndicatorProperties(indicator)) {
        throw new Error(
            `Indicator have to be of type FetchedIndicatorProperties`
        );
    }

    const descriptor = property
        ? indicator.messages[property]?.helpText
        : indicator.messages.general.helpText;

    if (descriptor)
        return (
            <StyledQuestionnaireHelpText>
                {formatMessage(descriptor)}
            </StyledQuestionnaireHelpText>
        );
    return <></>;
};

const StyledQuestionnaireHelpText = styled.p`
    margin-bottom: 20px;
    white-space: pre-line;

    :last-of-type {
        margin-bottom: 0;
    }
`;
