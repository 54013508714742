import { defineMessages } from 'react-intl';

const main = defineMessages({
    title: {
        id: 'app.screen.history.tab.title',
        defaultMessage: 'History'
    }
});

export default {
    main
};
