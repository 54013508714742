import { defineMessages } from 'react-intl';

const header = defineMessages({
    title: {
        id: 'userSettings.header.title',
        defaultMessage: 'Settings'
    }
});

export const userSettings = {
    header
};
