import React from 'react';
import {
    ButtonLargePrimary,
    ButtonLarge
} from '../../../../components/ui/buttons/ButtonLarge';
import { StyledButtonContainer } from './StyledForm';
import { formatMessage, Strings } from '../../../../locale';
import { testSelectors } from '../../../../constants/test-id';

type Props = {
    closeModal: () => void;
    isFormValid: boolean;
};

export const ModalFormButtons = (props: Props) => (
    <StyledButtonContainer>
        <ButtonLarge style={{ height: '50px' }} onClick={props.closeModal}>
            {formatMessage(Strings.ui.buttons.main.cancel)}
        </ButtonLarge>
        <ButtonLargePrimary
            style={{ height: '50px' }}
            data-test-id={testSelectors.form.submitButton}
            htmlType="submit"
            disabled={!props.isFormValid}
        >
            {formatMessage(Strings.ui.buttons.main.save)}
        </ButtonLargePrimary>
    </StyledButtonContainer>
);
