import { defineMessages } from 'react-intl';

export const sleep = defineMessages({
    title: {
        defaultMessage: 'Sleep',
        id: 'indicator.cat.sleep.title'
    },
    choicesTitle: {
        defaultMessage: 'Sleep',
        id: 'indicator.cat.sleep.choices.title'
    },
    maxValueDescription: {
        defaultMessage: "I don't sleep soundly because of my lung condition",
        id: 'indicator.cat.sleep.max-text'
    },
    minValueDescription: {
        defaultMessage: 'I sleep soundly',
        id: 'indicator.cat.sleep.min-text'
    }
});
