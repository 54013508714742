import { Fragment } from 'react';
import { Indicator } from '../../../types/indicator/indicator.type';
import messages from './messages';
import indicatorRules from './body-length-rules';
import icon from './images/body-length.svg';
import { TileType, FeedbackType } from '../../../types/indicator/report.type';

export const bodyLength: Indicator<typeof indicatorRules> = {
    code: 'BODY-LENGTH',
    icon,
    messages,
    hiddenHistory: true,
    hiddenStatistics: true,
    recommendations: {
        limits: {
            enabled: false
        },
        reminder: {
            moreOften: false,
            enabled: false
        },
        info: {
            enabled: false
        }
    },
    rules: indicatorRules,
    tile: {
        report: {
            form: Fragment,
            type: TileType.Hidden,
            feedback: FeedbackType.None
        }
    }
};
