import { defineMessages } from 'react-intl';

export const general = defineMessages({
    title: {
        defaultMessage: 'Extra medication',
        id: 'indicator.prnmed.title'
    },
    value: {
        defaultMessage: '{dose} {medication}',
        id: 'indicator.prnmed.values'
    },
    legendYes: {
        defaultMessage: 'One or more doses',
        id: 'indicator.prnmed.legend.yes'
    }
});
