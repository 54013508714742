import React from 'react';
import { styled } from '../../../theme/styled';

import moment from 'moment';
import { MessageType } from '../../../redux/domains/chat/chat.types';
import { getThemeProfile } from '../../../theme/theme-profile.provider';
import { useStyledThemeProfile } from '../../../theme/provider/provider.hook';
import { FormattedContent } from './FormattedContent';

const MessageListItem = ({
    message,
    isOutgoing
}: {
    message: MessageType;
    isOutgoing: boolean;
}) => {
    const themeProfile = getThemeProfile(useStyledThemeProfile().theme);

    const {
        senderMeta: { name, family_name },
        content,
        dtCreated
    } = message;

    const backgroundColor = isOutgoing
        ? themeProfile.chat.message.sender.backgroundColor
        : themeProfile.chat.message.receiver.backgroundColor;
    const textAlign = isOutgoing
        ? themeProfile.chat.message.sender.textAlign
        : themeProfile.chat.message.receiver.textAlign;
    const justify = isOutgoing
        ? themeProfile.chat.message.sender.justify
        : themeProfile.chat.message.receiver.justify;

    return (
        <MessageItemWrapper $justify={justify}>
            <Meta $textAlign={textAlign}>
                {`${name} ${family_name} - ${moment(dtCreated).format(
                    'HH:mm'
                )}`}
            </Meta>
            <MessageBubbleWrapper $justify={justify}>
                <FormattedContent
                    content={content}
                    backgroundColor={backgroundColor}
                />
            </MessageBubbleWrapper>
        </MessageItemWrapper>
    );
};

export default MessageListItem;

const MessageItemWrapper = styled.div<{ $justify: string }>`
    max-width: 75%;
    display: flex;
    justify-content: ${(props) => props.$justify};
    flex-direction: column;
`;

const MessageBubbleWrapper = styled.div<{ $justify: string }>`
    display: flex;
    justify-content: ${(props) => props.$justify};
`;

const Meta = styled.div<{ $textAlign: string }>`
    font-size: 10px;
    font-weight: bold;
    margin-bottom: 5px;
    color: ${(props) => props.theme.chat.message.subtitlesAlter};
    text-align: ${(props) => props.$textAlign};
`;
