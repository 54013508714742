import { AppState } from '../../../redux/store/root-reducer';
import { getAchievement } from './achievements.api';
import { ACHIEVEMENTS_FETCH } from './achievements.constants';

export const fetchAchievement =
    () => (dispatch: any, getState: () => AppState) => {
        const { subjectId } = getState().user;

        return dispatch({
            type: ACHIEVEMENTS_FETCH,
            payload: getAchievement(subjectId)
        });
    };
