import { defineMessages } from 'react-intl';

export const weightGain = defineMessages({
    title: {
        defaultMessage: 'Weight gain',
        id: 'indicator.ehfscbs.weight-gain.title'
    },
    description: {
        defaultMessage:
            'If I gain 2 kg in 1 week, I contact my doctor or nurse',
        id: 'indicator.ehfscbs.weight-gain.description'
    },
    maxValueDescription: {
        defaultMessage: 'I do not agree at all',
        id: 'indicator.ehfscbs.weight-gain.max-text'
    },
    minValueDescription: {
        defaultMessage: 'I fully agree',
        id: 'indicator.ehfscbs.weight-gain.min-text'
    }
});
