import { defineMessages } from 'react-intl';

const main = defineMessages({
    patientCommentTitle: {
        id: 'communication.patient-comment.title',
        defaultMessage: 'Patient comments'
    },
    published: {
        id: 'communication.published',
        defaultMessage: 'Published: '
    },
    noMessagesOfTheDay: {
        id: 'communication.message-of-the-day.empty',
        defaultMessage: 'there are no messages'
    },
    messageOfTheDayOKInfoMD: {
        id: 'communication.message-of-the-day.info.title',
        defaultMessage:
            'By pressing OK you have confirmed that you have read this information. You can find all previous messages in the menu at the top right.'
    },
    unsupportedBrowserWarningBar: {
        id: 'communication.unsupported-browser-warning-bar',
        defaultMessage: 'For a better experience - change web browser'
    },
    unsupportedBrowserModalTitle: {
        id: 'communication.unsupported-browser-modal.title',
        defaultMessage: 'Your browser is not compatible with LifePod!'
    },
    unsupportedBrowserModalContent: {
        id: 'communication.unsupported-browser-modal.content',
        defaultMessage:
            'You are using Internet Explorer. This is not supported and LifePod might not work as expected. \n\n We reccomend that you use one of the following browsers instead: '
    }
});

export default {
    main,
    rootId: 'communication.'
};
