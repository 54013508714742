import React from 'react';
import { formatMessage } from '../../../../../locale';
import { styled } from '../../../../../theme/styled';
import {
    FetchedIndicator,
    isFetchedIndicatorProperties
} from '../../../../../types/indicator/fetched-indicator.type';

type Props = {
    indicator: FetchedIndicator;
    property: string;
};

export const QuestionnairePropertyTitle = ({
    indicator,
    property
}: Props): JSX.Element => {
    if (!isFetchedIndicatorProperties(indicator)) {
        throw new Error(`Indicator must be of type FetchedIndicatorProperties`);
    }
    const descriptor = indicator.messages[property]?.title;

    if (descriptor)
        return (
            <QuestionnaireStyledPropertyTitle>
                {formatMessage(descriptor)}
            </QuestionnaireStyledPropertyTitle>
        );
    return <></>;
};

export const QuestionnaireStyledPropertyTitle = styled.h2`
    margin-bottom: 0;
    font-size: 18px;
`;
