import * as actionTypes from './groups.constants';
import { getGroups } from './groups.api';

export const fetchGroups = () => (dispatch: any) =>
    dispatch({
        type: actionTypes.GROUPS_FETCH,
        payload: getGroups({
            expand: 'managedBy'
        })
    });
