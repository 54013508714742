import React, { useState } from 'react';
import moment from 'moment';
import { Input } from 'antd';
const dateTimeFormat = 'YYYY-MM-DDTHH:mm';

export const MobileDateTimePicker = () => {
    const max = moment().format(dateTimeFormat);
    const min = moment()
        .subtract(5, 'days')
        .startOf('day')
        .format(dateTimeFormat);

    const preventedKeys = ['Delete', 'Backspace'];
    const [defaultValue, setDefaultValue] = useState(
        moment().format(dateTimeFormat)
    );

    const preventKeys = (event: React.KeyboardEvent) => {
        if (preventedKeys.includes(event.key)) {
            event.preventDefault();
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.validity.valid) {
            const date = event.target.value;

            event.target.value = moment(date).isBefore(min)
                ? min
                : moment(date).isAfter(max)
                ? max
                : date;
        }
    };

    return (
        <Input
            name="datetime"
            type="datetime-local"
            max={max}
            min={min}
            onChange={handleChange}
            onBlur={(event) => {
                if (event.target.validity.valid) {
                    // Fix for iOS. See https://github.com/facebook/react/issues/8938
                    setDefaultValue(event.target.value);
                }
            }}
            onKeyDown={preventKeys}
            defaultValue={defaultValue}
        />
    );
};
