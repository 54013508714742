import { IndicatorProperties } from '../../../types/indicator/indicator.type';
import messages from './messages';
import indicatorRules from './prnmed-rules';
import icon from './images/prnmed.svg';
import { TileType, FeedbackType } from '../../../types/indicator/report.type';
import { PrnmedForm } from '../../../app/report/forms/combined/PrnmedForm';

const properties = {
    medication: 'medication',
    dose: 'dose'
};

export const prnmed: IndicatorProperties<
    keyof typeof properties,
    typeof indicatorRules
> = {
    code: 'PRNMED',
    properties,
    icon,
    messages,
    hiddenHistory: false,
    hiddenStatistics: false,
    rules: indicatorRules,
    tile: {
        report: {
            form: PrnmedForm,
            type: TileType.NoDimming,
            feedback: FeedbackType.Checkmark
        }
    }
};
