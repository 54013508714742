import React, { useState } from 'react';
import { Card } from 'antd';
import { InfoModal } from '../modals/InfoTileModal';
import { IndicatorIcon } from '../../../components/indicator-icon/IndicatorIcon';
import { StyledTile } from './components/StyledTile';
import { InfoTileType } from '../../../types/info-tile/info-tile.type';

type Props = {
    infoTile: InfoTileType;
};

export const InfoTile = (props: Props): JSX.Element => {
    const [isOpen, setOpen] = useState(false);

    return (
        <>
            <StyledTile onClick={() => setOpen(!isOpen)}>
                <IndicatorIcon icon={props.infoTile.icon} color="white" />
            </StyledTile>
            <InfoModal
                infoTile={props.infoTile}
                visible={isOpen}
                onOK={() => setOpen(!isOpen)}
            >
                <Card>{props.infoTile.component}</Card>
            </InfoModal>
        </>
    );
};
