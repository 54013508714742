import { defineMessages } from 'react-intl';

export const general = defineMessages({
    title: {
        defaultMessage: 'Quality of Life',
        id: 'indicator.vas.title'
    },
    value: {
        defaultMessage: '{value}',
        id: 'indicator.vas.value'
    },
    veryBad: {
        id: 'indicator.vas.choices.1',
        defaultMessage: 'Very bad'
    },
    bad: {
        id: 'indicator.vas.choices.2',
        defaultMessage: 'Bad'
    },
    decent: {
        id: 'indicator.vas.choices.3',
        defaultMessage: 'Decent'
    },
    ok: {
        id: 'indicator.vas.choices.4',
        defaultMessage: 'OK'
    },
    good: {
        id: 'indicator.vas.choices.5',
        defaultMessage: 'Good'
    },
    veryGood: {
        id: 'indicator.vas.choices.6',
        defaultMessage: 'Very good'
    }
});
