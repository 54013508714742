export default ({ string = '', delimiter = '-' }) => {
    return string
        .split(delimiter)
        .map((str, idx) => {
            if (idx >= 1) {
                if (str == '') {
                    return;
                }
                return str[0].toUpperCase() + str.slice(1);
            }
            return str;
        })
        .join('');
};
