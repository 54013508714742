import { defineMessages } from 'react-intl';

export const duration = defineMessages({
    title: {
        defaultMessage: 'Duration',
        id: 'indicator.physact.duration.title'
    },
    unit: {
        id: 'indicator.physact.duration.unit',
        defaultMessage: 'min'
    },
    legendNo: {
        defaultMessage: 'Activity less than 30 minutes',
        id: 'indicator.physact.legend.no'
    }
});
