import React, { PropsWithChildren } from 'react';
import { Icon } from 'antd';
import { formatMessage, Strings } from '../../locale';
import { styled } from '../../theme/styled';
import { useHistory } from '../../hooks/use-route';
import { ButtonLarge } from '../../components/ui/buttons/ButtonLarge';
import { EidAuthMethods } from '../../redux/domains/authentication/authentication.enums';
import { eidLogin } from '../../redux/domains/authentication/authentication.api';
import { routePaths } from '../../constants/route-paths';
import { useReduxSelector } from '../../hooks/use-redux-selector';
import { createErrorSelector } from '../../redux/domains/requests/requests.selectors';
import { createLoadingSelector } from '../../redux/domains/requests/requests.selectors';
import {
    AUTHENTICATION_EID_LOGIN,
    AUTHENTICATION_LOGIN
} from '../../redux/domains/authentication/authentication.constants';
import { Loading } from '../../components/loading/Loading';
import { testSelectors } from '../../constants/test-id';
import { useDispatch } from 'react-redux';
import { checkEid } from '../../redux/domains/authentication/authentication.actions';

const { AUTH_METHODS } = __APP_CONFIG__;
const errorSelector = createErrorSelector([
    AUTHENTICATION_LOGIN,
    AUTHENTICATION_EID_LOGIN
]);
const loadingSelector = createLoadingSelector([AUTHENTICATION_LOGIN]);

export const LoginMethods = (): JSX.Element => {
    const error = useReduxSelector(errorSelector);
    const isLoading = useReduxSelector(loadingSelector);
    const routeHistory = useHistory();
    const dispatch: any = useDispatch();

    const authMethods = {
        email: (
            <LoginMethodButton
                key={EidAuthMethods.Email}
                onClick={() => routeHistory.push(routePaths.signinEmail)}
                dataTestId={testSelectors.auth.emailButton}
            >
                {formatMessage(Strings.labels.label.email)}
            </LoginMethodButton>
        ),
        bankid: (
            <LoginMethodButton
                key={EidAuthMethods.BankID}
                onClick={() =>
                    void dispatch(checkEid(EidAuthMethods.BankID, true)).catch(
                        () => console.error('Error in Eid BankID')
                    )
                }
            >
                {formatMessage(Strings.screen.auth.main.bankid)}
            </LoginMethodButton>
        ),
        freja: (
            <LoginMethodButton
                key={EidAuthMethods.Freja}
                onClick={() =>
                    void dispatch(checkEid(EidAuthMethods.Freja, true)).catch(
                        () => console.error('Error in Eid Freja')
                    )
                }
            >
                {formatMessage(Strings.screen.auth.main.freja)}
            </LoginMethodButton>
        )
    };

    if (isLoading) {
        return <Loading />;
    }

    return (
        <div style={{ margin: 20, marginTop: 50 }}>
            {AUTH_METHODS.map(
                (authMethod) =>
                    authMethods[authMethod as keyof typeof authMethods]
            )}
            {error && (
                <StyledError>
                    {formatMessage(Strings.screen.auth.main.error)}
                </StyledError>
            )}
        </div>
    );
};

type LoginMethodButtonProps = {
    onClick: () => void;
    dataTestId?: string;
};

const LoginMethodButton = (
    props: PropsWithChildren<LoginMethodButtonProps>
) => {
    return (
        <StyledLoginMethodButton
            block
            onClick={props.onClick}
            data-test-id={props.dataTestId}
        >
            {props.children}
            <Icon type="caret-right" />
        </StyledLoginMethodButton>
    );
};

const StyledLoginMethodButton = styled(ButtonLarge)`
    && {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 50px;
        margin-bottom: 10px;
        font-size: 16px;

        :last-child {
            margin-bottom: 0;
        }
    }
`;

const StyledError = styled.div`
    margin: 0 20px;
    color: red;
`;
