import { combineReducers } from 'redux';
import { keyBy } from 'lodash';
import {
    HISTORY_VALUES_FETCH_FULFILLED,
    VALUES_FETCH_FULFILLED,
    VALUE_DELETE_FULFILLED
} from './values.constants';
import { REPORT_VALUE_FULFILLED } from '../report/report.constants';
import { ValuesState } from './values.types';
import { INDICATOR_REPORT_FULFILLED } from '../observations/observations.constants';
import { deleteHistoryValue, setHistoryByConditionCode } from './values.utils';

const initialState: ValuesState = {
    indicators: {},
    history: {}
};

function indicators(state = initialState.indicators, action: any) {
    const { payload, type } = action;

    switch (type) {
        case VALUES_FETCH_FULFILLED:
            return keyBy(payload, 'indicatorId');
        case VALUE_DELETE_FULFILLED: {
            const { valueId, indicatorId } = action.meta;
            const { values, ...indicator } = state[indicatorId];

            return {
                ...state,
                [indicatorId]: {
                    ...indicator,
                    values: values.filter((item) => item.id !== valueId)
                }
            };
        }
        default:
            return state;
    }
}

function history(state = initialState.history, action: any) {
    const { payload, meta, type } = action;

    switch (type) {
        case HISTORY_VALUES_FETCH_FULFILLED:
            return {
                ...state,
                ...setHistoryByConditionCode(
                    state,
                    meta.conditionCode,
                    payload || [],
                    meta.limit
                )
            };
        case VALUE_DELETE_FULFILLED:
            return {
                ...state,
                ...deleteHistoryValue(state, meta.valueId)
            };
        case REPORT_VALUE_FULFILLED:
            //TODO: This is working, but the values will be fetched again. The value should instead be put first for all conditions with the indicator (new forms)
            return {};
        case INDICATOR_REPORT_FULFILLED:
            //TODO: This is working, but the values will be fetched again. The value should instead be put first for all conditions with the indicator (old forms)
            return {};
        default:
            return state;
    }
}

export const valuesReducer = combineReducers({
    indicators,
    history
});
