import { createSelector } from 'reselect';
import { sortBy } from 'lodash';
import { getMedicsInGroup } from '../groups/groups.selectors';
import { getUser } from '../authentication/authentication.selectors';

const getConversation = (state: any, props: any) => {
    const { groupId } = props.match.params;
    return state.chat.byGroupId[groupId];
};

export const getConversationId = createSelector(
    [getConversation],
    (conversation = {}) => {
        const { id = null } = conversation;
        return id;
    }
);

export const getMessages = createSelector(
    [getConversation],
    (conversation = {}) => {
        const { messages = [] } = conversation;
        return sortBy(messages, 'dtCreated');
    }
);

export const getParticipants = createSelector(
    [getMedicsInGroup, getUser],
    (medics, user) => {
        return [
            ...medics,
            {
                name: user.first_name,
                family_name: user.family_name,
                id: user.subjectId
            }
        ];
    }
);
