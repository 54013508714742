import React, { useState } from 'react';
import { Card } from 'antd';
import { useReduxSelector } from '../../../../hooks/use-redux-selector';
import { FormContentProps, ModalForm } from '../common/ModalForm';
import { SliderBox } from './components/SliderBox';
import { Comment } from '../common/Comment';
import { DateTimePicker } from '../common/DateTimePicker';
import { SliderValue } from './components/SliderValue';
import { FormProps } from '../../../../types/indicator/report.type';
import { SliderDescription } from './components/SliderDescription';
import { SliderValueContainer } from './components/SliderValueContainer';
import { SliderMinDescription } from './components/SliderMinDescription';
import { SliderMaxDescription } from './components/SliderMaxDescription';
import { getIndicatorConfigurations } from '../../../../redux/domains/user/user.selectors';

export const SliderForm = ({ indicator }: FormProps) => {
    const { step } = indicator.rules;
    const configuration = useReduxSelector(
        (state) => getIndicatorConfigurations(state)[indicator.code]
    );
    const {
        report_min,
        report_max,
        lower_red,
        lower_yellow,
        upper_yellow,
        upper_red
    } = configuration;
    const [value, setValue] = useState<number>(report_min || 0);

    return (
        <ModalForm indicator={indicator}>
            {({ validateField }: FormContentProps) => (
                <Card>
                    <DateTimePicker />
                    <SliderBox
                        value={value}
                        min={report_min || 0}
                        max={report_max || 100}
                        step={step}
                        lower={lower_yellow || lower_red || undefined}
                        upper={upper_yellow || upper_red || undefined}
                        useGradient={true}
                        showButtons={true}
                        onChange={(value) => {
                            setValue(value);
                            validateField(indicator.code, value);
                        }}
                        propertyTestId={indicator.code}
                    >
                        <SliderDescription indicator={indicator} />
                        <SliderValueContainer>
                            <SliderMinDescription indicator={indicator} />
                            <SliderValue indicator={indicator} value={value} />
                            <SliderMaxDescription indicator={indicator} />
                        </SliderValueContainer>
                    </SliderBox>
                    <input
                        type="hidden"
                        name={indicator.code}
                        value={value}
                        required
                    />
                    <Comment />
                </Card>
            )}
        </ModalForm>
    );
};
