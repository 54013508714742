import utils from './utils';
import Strings from './messages-descriptors';

const { formatMessage, formatMessageById, hasMessageId, Message } = utils;

export {
    Strings as default,
    Strings,
    formatMessage,
    formatMessageById,
    hasMessageId,
    Message,
    utils
};
