import React from 'react';
import Strings from '../../../../../../locale';
import colors from '../../../../../../theme/colors/common-colors';
import { HeatmapPiece } from '../../../../../../types/indicator/heatmap-piece.type';
import { Icon, Tooltip } from 'antd';
import { formatLegendItemText } from './formatLegendItemText';
import {
    IndicatorHeatmapRules,
    IndicatorRules
} from '../../../../../../types/indicator/indicator-rules.type';
import { IndicatorCommonMessages } from '../../../../../../types/indicator/indicator-messages.type';

const legendItemNoReport: HeatmapPiece<null> = {
    value: null,
    text: Strings.chart.heatmap.noReport,
    color: colors.chart.heatmap.gray.light
};

export function RenderLegendItems<Value, T extends number | boolean>(props: {
    rules: IndicatorRules & IndicatorHeatmapRules<Value, T>;
    messages: IndicatorCommonMessages;
}): JSX.Element {
    const { heatmapPieces = [] } = props.rules;

    if (!heatmapPieces.length) return <></>;

    const tooltipContent = [legendItemNoReport, ...heatmapPieces].map(
        (item, i: number) => (
            <div key={i}>
                <svg width="14" height="14">
                    <rect width="14" height="14" style={{ fill: item.color }} />
                </svg>
                <span style={{ fontSize: 12, marginLeft: 5 }}>
                    {formatLegendItemText(item, props.messages)}
                </span>
            </div>
        )
    );

    return (
        <Tooltip
            trigger="click"
            title={tooltipContent}
            placement="left"
            overlayClassName="heatmap-help-tooltip"
        >
            <Icon type="question-circle" style={{ fontSize: 20 }} />
        </Tooltip>
    );
}
