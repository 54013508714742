import { Strings } from '../../locale';
import { formatMessage } from '../../locale';
import { OtherPage } from './OtherPage';
import { testSelectors } from '../../constants/test-id';
import { routePaths } from '../../constants/route-paths';

export const routeOther = {
    title: formatMessage(Strings.navigation.main.other),
    path: routePaths.other,
    component: OtherPage,
    testId: testSelectors.nav.otherItem
};
