import React, { PureComponent } from 'react';
import { styled } from '../../../theme/styled';
import { formatMessage as t, Strings } from '../../../locale';
import { Divider, Form, Input } from 'antd';

import { ButtonLargePrimary } from '../../../components/ui/buttons/ButtonLarge';

type Props = {
    form: any;
    wrappedComponentRef: any;
    onSubmit: any;
    loading: any;
};

class SendMessageForm extends PureComponent<Props> {
    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;

        return (
            <>
                <Divider style={{ marginTop: 3, marginBottom: 3 }} />
                <StyledForm onSubmit={this.props.onSubmit}>
                    {getFieldDecorator('lp-message-content')(
                        <Input
                            autoComplete="off"
                            style={{
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0
                            }}
                        />
                    )}
                    <StyledSubmitButton
                        loading={this.props.loading}
                        htmlType="submit"
                        disabled={!getFieldValue('lp-message-content')}
                    >
                        {t(Strings.chat.main.send)}
                    </StyledSubmitButton>
                </StyledForm>
            </>
        );
    }
}

const StyledForm = styled(Form)`
    && {
        display: flex;
        padding: 4px 10px;
    }
`;
const StyledSubmitButton = styled(ButtonLargePrimary)`
    && {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;

        height: unset;
        width: '60px';
    }
`;

export default Form.create<Props>()(SendMessageForm);
