import { IndicatorProperties } from '../../../types/indicator/indicator.type';
import messages from './messages';
import indicatorRules from './swelling-rules';
import icon from './images/swelling.svg';
import { TileType, FeedbackType } from '../../../types/indicator/report.type';
import { SwellingForm } from '../../../app/report/forms/radio/SwellingForm';

const properties = {
    position: '',
    degree: 'degree'
};

export const swelling: IndicatorProperties<
    keyof typeof properties,
    typeof indicatorRules
> = {
    code: 'SWELLING',
    properties,
    icon,
    messages,
    hiddenHistory: false,
    hiddenStatistics: false,
    recommendations: {
        limits: {
            enabled: false
        },
        reminder: {
            moreOften: true,
            enabled: true
        },
        info: {
            enabled: false
        }
    },
    rules: indicatorRules,
    tile: {
        report: {
            form: SwellingForm,
            type: TileType.Dimming,
            feedback: FeedbackType.Checkmark
        }
    }
};
