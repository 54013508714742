import React, { useState, useEffect, useRef } from 'react';
import { Card } from 'antd';
import { styled } from '../../../../theme/styled';
import { ContentLayout } from '../../../../components/layout/ContentLayout';
import { CrossSpinner } from '../../../../components/loading/Loading';
import { getMessageOfTheDay } from '../../../../redux/domains/motd/motd.api';
import { MessageOfTheDayType } from '../../../../redux/domains/motd/motd.types';
import { formatMessage as t, Strings, formatMessage } from '../../../../locale';
import { hasMotds } from '../../../../components/message-of-the-day/message-of-the-day-utils';
import { buildMotdMessages } from '../../../../components/message-of-the-day/motdShared';
import { Header } from '../../../../components/layout/Header';
import { messageOfTheDay } from './message-descriptors';
import { routePaths } from '../../../../constants/route-paths';

const buildLoadingIndicator = () => {
    return (
        <ContentLayout>
            <CrossSpinner />
        </ContentLayout>
    );
};

const buildEmptyMessage = () => {
    return (
        <StyledEmptyMessage>
            {t(Strings.communication.main.noMessagesOfTheDay)}
        </StyledEmptyMessage>
    );
};

export const buildTimeline = (motds: MessageOfTheDayType[]) => {
    return hasMotds(motds) ? buildMotdMessages(motds) : buildEmptyMessage();
};

export const MessageOfTheDayPage = () => {
    const mounted = useRef(true);
    const [loading, setLoading] = useState(true);
    const [motds, setMOTDs] = useState<MessageOfTheDayType[]>([]);

    useEffect(() => {
        if (mounted.current) {
            getMessageOfTheDay({ status: 'read' }).then((motds) => {
                setMOTDs(motds);
                setLoading(false);
            });
        }

        return () => {
            mounted.current = false;
        };
    }, [loading, motds]);

    return (
        <>
            <Header
                backButtonPath={routePaths.other}
                title={formatMessage(messageOfTheDay.header.title)}
            />
            <ContentLayout>
                <Card>
                    {loading ? buildLoadingIndicator() : buildTimeline(motds)}
                </Card>
            </ContentLayout>
        </>
    );
};

const StyledEmptyMessage = styled.div`
    text-align: center;
`;
