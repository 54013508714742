import { formatMessage } from '../../../../../../locale';
import { HeatmapPiece } from '../../../../../../types/indicator/heatmap-piece.type';
import { IndicatorCommonMessages } from '../../../../../../types/indicator/indicator-messages.type';

export function formatLegendItemText<T extends number | boolean | null>(
    item: HeatmapPiece<T>,
    messages: IndicatorCommonMessages
) {
    if ('min' in item) {
        const { text, min, max, textValues = { min, max } } = item;
        return formatMessage(text, textValues);
    }

    const {
        value,
        text = messages.general.value,
        textValues = { value }
    } = item;
    return formatMessage(text, textValues);
}
