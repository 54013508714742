import React, { useState } from 'react';
import { styled } from '../../theme/styled';
import { formatMessage as t, Strings } from '../../locale';
import { useDispatch, useSelector } from 'react-redux';
import { InfiniteScroll } from './InfiniteScroll';
import { NotificationsHeader } from './NotificationsListGroupHeader';
import {
    ACTION_CONFIRMED,
    ACTION_NOTIFIED,
    updateNotifications
} from '../../redux/domains/notifications/notifications.actions';
import {
    getNewNotificationIds,
    getNotifications
} from '../../redux/domains/notifications/notifications.selectors';

const newNotifications = (newNotificationIds: string[]) => {
    return newNotificationIds.length > 0;
};

export const NotificationsList = () => {
    const dispatch = useDispatch();
    const allNotifications = useSelector(getNotifications);

    const [notifications, setNotifications] = useState(() => allNotifications);

    const fetchedNotifications = useSelector(getNewNotificationIds);

    const deleteNotifications = () => {
        const ids = allNotifications.map(({ id }) => id);
        setNotifications([]);
        dispatch(updateNotifications(ids, ACTION_CONFIRMED));
    };

    if (newNotifications(fetchedNotifications)) {
        dispatch(updateNotifications(fetchedNotifications, ACTION_NOTIFIED));
    }

    return notifications.length > 0 ? (
        <StyledContentLayout>
            <NotificationsHeader deleteNotifications={deleteNotifications} />
            <InfiniteScroll notifications={notifications} />
        </StyledContentLayout>
    ) : (
        <StyledNoNotifications>
            {t(Strings.notification.main.empty)}
        </StyledNoNotifications>
    );
};

const StyledNoNotifications = styled.p`
    margin-top: 50px;
    text-align: center;
`;

const StyledContentLayout = styled.div`
    margin: 50px 0px;
    overflow-x: hidden;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    /* https://webkit.org/blog/7929/designing-websites-for-iphone-x/ */
    height: calc(100% - 100px - env(safe-area-inset-bottom));
`;
