import React from 'react';
import * as echarts from 'echarts';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import { formatMessage } from '../../../../../../locale';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/visualMap';
import 'echarts/lib/component/calendar';
import 'echarts/lib/chart/heatmap';
import './indicator-heatmap.css';
import { BaseIndicator } from '../../../../../../types/indicator/indicator.type';
import { IndicatorHeatmapRules } from '../../../../../../types/indicator/indicator-rules.type';
import { RenderLegendItems } from './RenderLegendItems';
import { getOptions } from './getOptions';

type HeatmapIndicator<Value, T extends number | boolean> = BaseIndicator & {
    rules: IndicatorHeatmapRules<Value, T>;
};

export type Props<Value, T extends number | boolean> = {
    indicator: HeatmapIndicator<Value, T>;
    values: {
        date: number;
        value: Value;
    }[];
};

function IndicatorHeatmap<Value, T extends number | boolean>(
    props: Props<Value, T>
): JSX.Element {
    const { rules, messages } = props.indicator;
    const title = formatMessage(messages.general.title);

    return (
        <div className="heatmap-container">
            <div className="heatmap-title">
                <h2 style={{ textAlign: 'center' }}>{title}</h2>

                <div className="help-btn">
                    <RenderLegendItems rules={rules} messages={messages} />
                </div>
            </div>

            <ReactEchartsCore
                echarts={echarts}
                option={getOptions(props)}
                opts={{ renderer: 'svg' }}
                style={{ width: '100%', height: '100%' }}
                lazyUpdate={true}
                notMerge={true}
            />
        </div>
    );
}

export default IndicatorHeatmap;
