import { HTTPClient } from '../../../services/http-client/http-client';

export const getReminders = (params = {}) =>
    HTTPClient.get('reminders/reportreminders', { params }).then((data) => {
        const reminders: any = Object.values(data)[0] || [];
        return reminders.reduce((acc: any, curr: any) => {
            const { indicatorId } = curr;
            acc[indicatorId] = curr;
            return acc;
        }, {});
    });
