import {
    AUTHENTICATION_LOGIN,
    AUTHENTICATION_LOGIN_FULFILLED,
    AUTHENTICATION_LOGOUT,
    AUTHENTICATION_CHECK,
    AUTHENTICATION_EID_LOGIN
} from './authentication.constants';
import { postLogin, eidGetSession, eidLogin } from './authentication.api';
import { EidAuthMethods } from './authentication.enums';
import { AuthTokens } from './authentication.types';

export const login = (username: string, password: string) =>
    authenticateWithTokens(
        postLogin({ username: username.toLowerCase(), password })
    );

export const loginFullfilled = (
    idToken: string,
    refreshToken: string,
    uuid: string
) => ({
    type: AUTHENTICATION_LOGIN_FULFILLED,
    payload: {
        idToken,
        refreshToken,
        uuid
    }
});

export const logout = () => ({
    type: AUTHENTICATION_LOGOUT
});

export const checkAuthentication = () => ({
    type: AUTHENTICATION_CHECK
});

export const authenticateWithSessionId = (
    sessionId: string,
    authMethod: EidAuthMethods
) => authenticateWithTokens(eidGetSession(sessionId, authMethod));

const authenticateWithTokens = (tokens: AuthTokens | Promise<AuthTokens>) => ({
    type: AUTHENTICATION_LOGIN,
    payload: Promise.resolve(tokens)
});

export const checkEid = (authMethod: EidAuthMethods, qr?: true) => ({
    type: AUTHENTICATION_EID_LOGIN,
    payload: eidLogin(authMethod, qr)
});
