import {
    AchievementLocaleType,
    AchievementResponseType
} from './achievement-model-types';
import { AchievementTypes } from './achievement-types';

export const achievementLocale: AchievementLocaleType = {
    details: {
        title: { id: 'no string', defaultMessage: 'no string' },
        body: { id: 'no string', defaultMessage: 'no string' }
    },
    milestone: {
        title: { id: 'no string', defaultMessage: 'no string' }
        // body: { id: 'no string', defaultMessage: 'no string' }
    },
    info: {
        title: { id: 'no string', defaultMessage: 'no string' },
        body: { id: 'no string', defaultMessage: 'no string' }
    },
    encourage: {
        first: { id: 'no string', defaultMessage: 'no string' },
        milestoneCompleted: { id: 'no string', defaultMessage: 'no string' },
        minorMilestoneCompleted: {
            id: 'no string',
            defaultMessage: 'no string'
        },
        achievementCompleted: { id: 'no string', defaultMessage: 'no string' },
        untilCompleted: { id: 'no string', defaultMessage: 'no string' }
    }
};

export const defaultResponse: [AchievementResponseType] = [
    {
        category: AchievementTypes.reportCounter,
        type: AchievementTypes.totalCounter,
        sum: {
            value: 241,
            targets: [
                [60, 70, 80, 100],
                [110, 120, 130, 150],
                [160, 170, 180, 200],
                [210, 220, 230, 240]
            ]
        }
    }
];

export const achievementDefaults = {
    milestoneMinor: 'minor',
    milestoneMajor: 'major',
    initInt: 0,
    defaultMilestoneType: 'major',

    defaultCategory: AchievementTypes.reportCounter,
    defaultCategoryType: AchievementTypes.totalCounter
};
