import React, { Component } from 'react';
import { styled } from '../../../../../../theme/styled';
import { CustomImage } from '../../../../../../components/custom-image/CustomImage';
import { cssGrayscaleFilter } from '../../../../../../theme/utils/css-grayscale-filter';
import { cssShadow } from '../../../../../../theme/utils/css-shadow';

type Props = {
    isAchieved: boolean;
    isMinor: boolean;
    imgSrc: any;
    onClick: any;
};

type State = {
    screenWidth: number;
    screenHeight: number;
};

export class AchievementMilestone extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            screenWidth: 0,
            screenHeight: 0
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({
            screenWidth: window.innerWidth,
            screenHeight: window.innerHeight
        });
    }

    render() {
        const {
            isAchieved = true,
            isMinor = false,
            imgSrc,
            onClick
        } = this.props;

        return (
            <StyledMilestone
                onClick={isAchieved ? onClick : null}
                height={
                    isMinor
                        ? `${this.state.screenWidth * 0.008}rem`
                        : `${this.state.screenWidth * 0.013}rem`
                }
                width={
                    isMinor
                        ? `${this.state.screenWidth * 0.008}rem`
                        : `${this.state.screenWidth * 0.013}rem`
                }
                minor_id={'minor-0'}
                is_minor={isMinor}
                is_achieved={isAchieved}
            >
                <StyledSVGStyle isAchieved={isAchieved}>
                    <StyledCustomImage src={imgSrc} />
                </StyledSVGStyle>
            </StyledMilestone>
        );
    }
}

interface StyledSVGStyleInterface {
    isAchieved: boolean;
}

const StyledCustomImage = styled(CustomImage)`
    height: 100%;
    width: 100%;
`;

const StyledSVGStyle = styled.div<StyledSVGStyleInterface>`
    height: 100%;
    width: 100%;

    ${(props) => (props.isAchieved ? '' : cssGrayscaleFilter({ percent: 80 }))};
`;

const StyledAvatarBorder_ = styled.div.attrs((props) => {
    return {
        shadow: cssShadow({
            horizontalOffset: 0,
            verticalOffset: 3,
            blur: 6,
            spread: 0,
            color: props.theme.achievements.milestone.shadow
        })
    };
})`
    ${(props) => () => props.shadow} border-style: solid;
    border-width: 0.1rem;
    border-color: black;
    border-radius: 100%;
`;

interface StyledMilestoneInterface {
    height: any;
    width: any;

    minor_id: any;
    is_minor: any;
    is_achieved: any;
}

const getBackgroundColor = (color: any, isAchieved: boolean) => {
    return isAchieved ? color.background.achieved : color.background.unAchieved;
};

const StyledMilestone = styled(StyledAvatarBorder_)<StyledMilestoneInterface>`
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    height: ${(props) => props.height};
    width: ${(props) => props.width};
    max-height: 5.3rem;
    max-width: 5.3rem;

    background-color: ${(props) => {
        return getBackgroundColor(
            props.is_minor
                ? props.theme.achievements.milestone.minor
                : props.theme.achievements.milestone.major,
            props.is_achieved
        );
    }};

    border-color: ${(props) =>
        props.is_minor
            ? props.is_achieved
                ? props.theme.achievements.milestone.minor.achieved
                : props.theme.achievements.milestone.minor.unAchieved
            : 'none'};

    // This targets id within achievements svg
    #${(props) => props.minor_id} {
        fill: ${(props) =>
            props.is_achieved
                ? props.theme.achievements.milestone.minor.achieved
                : props.theme.achievements.milestone.minor.unAchieved};
    }
`;
