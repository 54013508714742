const appConfig = __APP_CONFIG__;

export const resource = {
    root: appConfig.ASSETS_ROOT_URL,
    version: appConfig.ASSETS_VERSION,
    fileName: 'Manual-LifePod-Patient',
    urlForManual: (): string =>
        `${resource.root}${resource.version}/general/sv/patient/manual/${resource.fileName}.pdf`,
    urlForManualWithLocale: (locale = 'sv'): string =>
        `${resource.root}${resource.version}/general/${locale}/patient/manual/${resource.fileName}.pdf`
};
