import { Dispatch } from 'redux';
import moment from 'moment';
import { AppState } from '../../../redux/store/root-reducer';
import { FeedbackType } from '../../../types/indicator/report.type';
import { ReportValue, Feedback } from '../conditions/condtions.types';
import { postReport } from './report.api';
import {
    REPORT_VALUE,
    RESET_FEEDBACK,
    UPDATE_REPORT_STATUS,
    FETCH_REPORT_STATUS,
    RESET_TILES
} from './report.constants';
import {
    Configuration,
    Limits,
    Properties
} from '../../../types/indicator/state-indicator.type';
import { fetchLatestValuesForEachIndicator } from '../values/values.actions';
import { fetchReminders } from '../reminders/reminders.actions';
import { ValuesIndicator } from '../values/values.types';
import { isFetchedIndicatorProperties } from '../../../types/indicator/fetched-indicator.type';

export const reportValue =
    (
        subjectId: string,
        configuration: Configuration & Limits & Properties,
        value: ReportValue
    ) =>
    async (dispatch: Dispatch<any>) => {
        return await dispatch({
            type: REPORT_VALUE,
            payload: postReport({
                subjectId,
                observations: [
                    {
                        indicatorCode: value.indicator.code,
                        dtObserved: value.dtObserved,
                        data: value.data,
                        comment: value.comment
                    }
                ]
            }),
            meta: {
                code: value.indicator.code,
                feedback: getFeedback(value, configuration)
            }
        });
    };

export const resetFeedback = (code: string) => ({
    type: RESET_FEEDBACK,
    meta: { code }
});

export const resetTiles = () => ({
    type: RESET_TILES
});

export const fetchReportStatus =
    () => (dispatch: any, getState: () => AppState) => {
        const reportStatus = dispatch(fetchLatestValuesForEachIndicator()).then(
            ({ value: latestValues }: { value: ValuesIndicator[] }) =>
                dispatch(fetchReminders()).then(() => {
                    const reminders = getState().reminders.byIndicatorId;

                    const conditions = getState().conditions;
                    const condition = conditions.byCode[conditions.selected];
                    return latestValues.reduce(
                        (
                            acc: Record<string, { dimmedUntil: number }>,
                            { indicatorId, indicatorCode, values }
                        ) => {
                            const reminder = reminders[indicatorId];
                            let dimmedUntil: number = moment()
                                .endOf('day')
                                .valueOf();

                            if (!condition.tiles[indicatorCode]) {
                                return acc;
                            }

                            if (
                                condition.tiles[indicatorCode]
                                    .shouldDimWhenReported
                            ) {
                                if (reminder) {
                                    const {
                                        conditionReportConfigurations = []
                                    } = reminder;
                                    const conditionReportConfiguration =
                                        conditionReportConfigurations.find(
                                            (config) =>
                                                config.conditionId ===
                                                condition.id
                                        );
                                    const { date } = values[0];

                                    if (
                                        conditionReportConfiguration?.active &&
                                        conditionReportConfiguration.missedReportInterval
                                    ) {
                                        dimmedUntil = moment(date)
                                            .add(
                                                conditionReportConfiguration.missedReportInterval
                                            )
                                            .startOf('day')
                                            .valueOf();
                                    } else if (
                                        !moment(date).isSame(dimmedUntil, 'day')
                                    ) {
                                        dimmedUntil = 0; // The reminder is inactive and the indicator should be dimmed, BUT it's not reported today
                                    }
                                }
                            } else {
                                dimmedUntil = 0; // Example: PRNMED
                            }
                            acc[indicatorCode] = { dimmedUntil: dimmedUntil };
                            return acc;
                        },
                        {}
                    );
                })
        );

        return dispatch({
            type: FETCH_REPORT_STATUS,
            payload: reportStatus
        });
    };

export const updateReportStatus =
    (code: string, dtObserved: number) =>
    (dispatch: any, getState: () => AppState) => {
        const conditions = getState().conditions;
        const condition = conditions.byCode[conditions.selected];
        const indicatorId = condition.indicators.byCode[code].id;
        const reminder = getState().reminders?.byIndicatorId[indicatorId];
        const conditionReportConfiguration =
            reminder?.conditionReportConfigurations.find(
                (config) => config.conditionId === condition.id
            );

        let dimmedUntil: number | null = moment().endOf('day').valueOf();

        if (condition.tiles[code].shouldDimWhenReported) {
            if (
                reminder &&
                conditionReportConfiguration?.missedReportInterval
            ) {
                const { missedReportInterval, active } =
                    conditionReportConfiguration;

                if (active && missedReportInterval) {
                    dimmedUntil = moment(dtObserved)
                        .add(missedReportInterval)
                        .startOf('day')
                        .valueOf();
                } else if (!moment(dtObserved).isSame(dimmedUntil, 'day')) {
                    dimmedUntil = null; // The reminder is inactive and the indicator should be dimmed, BUT it's not reported today
                }
            }
        } else {
            dimmedUntil = null; // Example: PRNMED
        }

        return dispatch({
            type: UPDATE_REPORT_STATUS,
            payload: { indicatorId, code, dimmedUntil }
        });
    };

function getFeedback(
    value: ReportValue,
    configuration: Configuration & Limits & Properties
): Feedback {
    const { indicator } = value;
    const { feedback = FeedbackType.Smiley } = indicator.tile.report || {}; // Report should be required in the future

    if (feedback === FeedbackType.Checkmark) {
        return 'check';
    }

    const type =
        isFetchedIndicatorProperties(indicator) && indicator?.form
            ? indicator.form.type
            : indicator.data.jsonSchema.type;

    switch (type) {
        case 'boolean': {
            return (feedback === FeedbackType.SmileyInverted) === value.data
                ? 'smile'
                : 'meh';
        }
        case 'integer':
        case 'number': {
            return isValueInRange(value.data, configuration) ? 'smile' : 'meh';
        }
        case 'object': {
            return Object.keys(value.data).every((key: string) => {
                const multiValue: any = value.data;
                return isValueInRange(multiValue[key], configuration[key]);
            })
                ? 'smile'
                : 'meh';
        }
        case 'questionnaire':
            return 'check';
        default:
            return 'check';
    }
}

function isValueInRange(value: any, configuration: any) {
    const {
        lower_red,
        lower_yellow,
        upper_yellow,
        upper_red,
        report_min,
        report_max
    } = configuration;

    return (
        value >= (lower_yellow || lower_red || report_min) &&
        value <= (upper_yellow || upper_red || report_max)
    );
}
