import { defineMessages } from 'react-intl';

export const afterActivity = defineMessages({
    title: {
        defaultMessage: 'Pain after activity',
        id: 'indicator.pain-after-activity-hs.title'
    },
    description: {
        defaultMessage: 'Do you experience any pain after activity?',
        id: 'indicator.pain-after-activity-hs.description'
    }
});
