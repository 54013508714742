import { ConditionCode } from '../conditions/condtions.types';
import { ValuesHistory } from './values.types';

export function setHistoryByConditionCode(
    state: ValuesHistory,
    conditionCode: ConditionCode,
    values: any[],
    limit: number
) {
    return {
        [conditionCode]: {
            values: [...(state[conditionCode]?.values || []), ...values],
            hasMoreValues: values.length === limit
        }
    };
}

export function deleteHistoryValue(state: ValuesHistory, valueId: string) {
    return {
        ...Object.keys(state).reduce((acc: any, curr: any) => {
            acc[curr] = {
                ...state[curr],
                values: state[curr].values.filter(
                    (value) => value.id !== valueId
                )
            };
            return acc;
        }, {})
    };
}
