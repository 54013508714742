import React from 'react';
import { formatMessage } from '../../../../../locale';
import { styled } from '../../../../../theme/styled';
import { FetchedIndicator } from '../../../../../types/indicator/fetched-indicator.type';

type Props = {
    indicator: FetchedIndicator;
};

export const QuestionnaireLegalText = ({ indicator }: Props): JSX.Element => {
    if (indicator.messages.general.legalText)
        return (
            <StyledQuestionnaireLegalText>
                {formatMessage(indicator.messages.general.legalText)}
            </StyledQuestionnaireLegalText>
        );
    return <></>;
};

const StyledQuestionnaireLegalText = styled.p`
    margin-bottom: 0px;
    white-space: pre-line;
    line-height: 1.2;
    letter-spacing: 0px;
    font-style: italic;
    font-size: 11px;
`;
