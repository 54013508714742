import React, { ReactNode, CSSProperties } from 'react';
import { Form, Slider as AntSlider } from 'antd';
import { SliderProps, SliderValue } from 'antd/lib/slider';
import Strings, { formatMessage } from '../../../../../locale';
import { styled } from '../../../../../theme/styled';
import { ButtonLargeRound } from '../../../../../components/ui/buttons/ButtonLarge';
import { theme } from '../../../../../theme/colors/common-colors';
import './slider.css';
import { testSelectors } from '../../../../../constants/test-id';

type Props = {
    propertyTestId: string;
    value: number;
    min: number;
    max: number;
    step?: number;
    useGradient: boolean;
    showButtons: boolean;
    lower?: number;
    upper?: number;
    onChange: (value: number) => void;
    style?: CSSProperties;
    children: ReactNode;
};

type AntSliderProps = SliderProps & {
    trackStyle: { background: string };
    railStyle: {
        background: string;
        height: number;
    };
};

type AntSliderOnChange = (value: SliderValue) => void;

export function SliderBox(props: Props) {
    const {
        value = 0,
        min,
        max,
        step = 1,
        useGradient,
        showButtons,
        onChange,
        style,
        children
    } = props;

    const gradient = useGradient
        ? generateGradient(props)
        : theme.interactive.usability_positive_2;

    const sliderProps: AntSliderProps = {
        value,
        min,
        max,
        step,
        tipFormatter: null,
        trackStyle: { background: 'transparent' },
        railStyle: {
            background: gradient,
            height: 6
        },
        onChange: onChange as AntSliderOnChange
    };

    // Handle floating point precision errors when step is 0.1
    const roundToStep = (newValue: number) =>
        step < 1
            ? Math.round(newValue / step) / Math.round(1 / step)
            : newValue;

    const decreaseValue = () => {
        if (value > min) {
            const decreasedValue = roundToStep(value - step);
            onChange(Math.max(decreasedValue, min));
        }
    };

    const increaseValue = () => {
        if (value < max) {
            const increasedValue = roundToStep(Math.max(value, min) + step);
            onChange(Math.min(increasedValue, max));
        }
    };

    return (
        <div className="slider-box" style={style}>
            {children}
            <SliderContainer>
                {showButtons && (
                    <ButtonLargeRound
                        data-test-id={testSelectors.report.minusButton}
                        data-test-name={props.propertyTestId + '-minus'}
                        icon="minus"
                        onClick={decreaseValue}
                        // disabled=true will result in this bug: LC-591
                        className={value <= min ? 'ant-btn-disabled' : ''}
                    />
                )}
                <Form.Item
                    style={{
                        width: showButtons ? '60%' : '100%',
                        margin: 0
                    }}
                >
                    <AntSlider className="indicator-slider" {...sliderProps} />
                </Form.Item>
                {showButtons && (
                    <ButtonLargeRound
                        data-test-id={testSelectors.report.plusButton}
                        data-test-name={props.propertyTestId + '-plus'}
                        icon="plus"
                        onClick={increaseValue}
                        // disabled=true will result in this bug: LC-591
                        className={value >= max ? 'ant-btn-disabled' : ''}
                    />
                )}
            </SliderContainer>
            <StyledSliderInstruction>
                {formatMessage(Strings.instructions.slider.setValue)}
            </StyledSliderInstruction>
        </div>
    );
}

function generateGradient({ lower, upper, min = 0, max = 1 }: Props) {
    const green = theme.denote.good;
    const orange = theme.denote.bad;

    if (!lower && !upper) {
        return green;
    }

    // calculate ratio for each limit
    const lowerRatio = lower
        ? parseFloat(((lower - min) / (max - min)).toFixed(3))
        : 0;
    const upperRatio = upper
        ? parseFloat(((upper - min) / (max - min)).toFixed(3))
        : 1;

    // convert to %
    const lowerLimit = lowerRatio * 100;
    const upperLimit = upperRatio * 100;

    if (!upper) {
        return `linear-gradient(to right, ${orange} ${
            lowerLimit - 5
        }%, ${green} ${lowerLimit + 5}%, ${green})`;
    } else if (!lower) {
        return `linear-gradient(to right, ${green} ${
            upperLimit - 5
        }%, ${orange} ${upperLimit + 5}%, ${orange})`;
    } else {
        return `linear-gradient(to right, ${orange} ${
            lowerLimit - 5
        }%, ${green} ${lowerLimit + 5}%, ${green}, ${green} ${
            upperLimit - 5
        }%, ${orange} ${upperLimit + 5}%)`;
    }
}

export const StyledSliderInstruction = styled.p`
    font-size: 13px;
    display: flex;
    justify-content: center;
    line-height: 1.2;
    letter-spacing: 0;
    font-style: italic;
`;

export const SliderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
`;
