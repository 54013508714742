import React from 'react';
import { styled } from '../../../theme/styled';
import { Menu } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';

import { ClickParam } from 'antd/lib/menu';
import { getNewNotificationIds } from '../../../redux/domains/notifications/notifications.selectors';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../redux/domains/notifications/notifications.actions';
import { updateNotifications } from '../../../redux/domains/notifications/notifications.actions';
import { routeNotifications } from '../../../app/notifications/route';
import { defaultLinks } from './navbar-routes';

const newNotifications = (newNotificationIds: string[]) => {
    return newNotificationIds.length > 0;
};

export const Navbar = () => {
    const location = useLocation();

    const matchedLinks = defaultLinks.filter(({ path }) =>
        location.pathname.startsWith(path)
    );

    const dispatch = useDispatch();
    const fetchedNotifications = useSelector(getNewNotificationIds);

    const markNotificationsAsNotified = () => {
        if (newNotifications(fetchedNotifications)) {
            dispatch(
                updateNotifications(
                    fetchedNotifications,
                    actions.ACTION_NOTIFIED
                )
            );
        }
    };

    return (
        <StyledMenu
            mode="horizontal"
            selectedKeys={matchedLinks.map(({ path }) => path)}
            onClick={(item: ClickParam) => {
                if (item.key === routeNotifications.path) {
                    markNotificationsAsNotified();
                }
            }}
        >
            {defaultLinks.map((item) => {
                return (
                    <StyledMenuItem key={item.path}>
                        <StyledNavLink
                            data-test-id={`${item.testId}`}
                            to={item.path}
                        >
                            {item.icon}
                            <StyledText>{item.title}</StyledText>
                        </StyledNavLink>
                    </StyledMenuItem>
                );
            })}
        </StyledMenu>
    );
};

const StyledMenuItem = styled(Menu.Item)`
    &&& {
        height: 100%;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-size: 15px;
        border-bottom: 0;
        padding: 0;

        i {
            margin: 5px 0;
        }

        :hover {
            border-bottom: 0;
        }
    }
`;

const StyledNavLink = styled(NavLink)`
    &&& {
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color: transparent;
        position: relative;
        text-align: center;
    }
`;

const StyledText = styled.p`
    margin: 0;
    text-align: center;
    padding: 0;
    font-weight: 400;

    // Iphone 5
    @media (max-width: 320px) {
        font-size: 12px;
        margin-top: 2px;
    }

    // Small desktop
    @media (orientation: landscape) and (min-width: 1024px) and (max-width: 1200px) and (min-height: 640px) {
        font-size: 12px;
        margin-top: 2px;
    }
`;

const StyledMenu = styled(Menu)`
    &&& {
        display: flex;
        justify-content: space-between;
        background-color: #fff;
        width: 100%;
        height: 100%;
        border: 0;
        box-shadow: none;

        .ant-menu-submenu {
            display: none;
        }

        @media (hover: none), (hover: on-demand) {
            // Fixes LC-1217 and LC-3173
            .ant-menu-item-active:not(.ant-menu-item-selected) a:hover,
            .ant-menu-item:not(.ant-menu-item-selected) a {
                color: rgba(0, 0, 0, 0.65);
            }
        }
    }
`;
