import { defineMessages } from 'react-intl';

export const weight = defineMessages({
    title: {
        defaultMessage: 'Weight',
        id: 'indicator.bmi.weight.title'
    },
    value: {
        defaultMessage: '{value, number, ::.0}',
        id: 'indicator.bmi.weight.value'
    },
    unit: {
        defaultMessage: 'kg',
        id: 'indicator.bmi.weight.unit'
    }
});
