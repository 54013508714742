import React from 'react';
import { CustomImage } from '../custom-image/CustomImage';
import { styled } from '../../theme/styled';

type Props = {
    icon: string;
    color?: string;
    size?: number;
};

export const IndicatorIcon = (props: Props): JSX.Element => {
    return (
        <StyledIndicatorImg
            $color={props.color}
            $size={props.size}
            src={props.icon}
        />
    );
};

export const IndicatorIconWithBorder = (props: Props): JSX.Element => {
    return (
        <StyledIndicatorIconWithBorder
            $color={props.color}
            $size={props.size}
            $padding="3px"
            src={props.icon}
        />
    );
};

const StyledIndicatorImg = styled(CustomImage)<{
    $color?: string;
    $size?: number;
    $padding?: string;
}>`
    width: ${(props) => (props.$size ? `${props.$size}px` : '100%')};
    min-width: ${(props) => (props.$size ? `${props.$size}px` : 'auto')};
    height: ${(props) =>
        props.$size
            ? `${props.$size}px`
            : 'auto'}; // height:auto; does not work in IE.
    padding: ${(props) => (props.$padding ? props.$padding : '0')};

    // target the fill inside svg
    // Sketch creates an extra g path, so in order to change the color, we need to target it like this.
    && g {
        fill: ${(props) => (props.$color ? props.$color : 'black')};
        && g {
            fill: ${(props) => (props.$color ? props.$color : 'black')};
        }
    }

    // fix for path from sketch
    && polygon {
        fill: ${(props) => (props.$color ? props.$color : 'black')};
    }

    // fix for compressed-path from sketch
    && path {
        fill: ${(props) => (props.$color ? props.$color : 'black')};
    }
`;

const StyledIndicatorIconWithBorder = styled(StyledIndicatorImg)<{
    src: string;
    $color?: string;
}>`
    border-radius: 10%;
    border: 0.15rem solid;
    border-color: ${(props) => (props.$color ? props.$color : 'black')};
`;
