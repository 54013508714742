import React from 'react';
import { FormikErrors } from 'formik';
import { formatMessage, Strings } from '../../../../locale';
import { StyledLabel, StyledInput, StyledError } from './styled';
import { FormValuesType } from './form.type';

type InputPropsType = {
    formik: {
        handleChange: any;
        values: FormValuesType;
        errors: FormikErrors<FormValuesType>;
    };
};

export const FirstNameInput = (props: InputPropsType) => {
    const {
        formik: { handleChange, values, errors }
    } = props;

    return (
        <>
            <StyledLabel htmlFor="firstName">
                {formatMessage(Strings.screen.profile.label.firstName)}
            </StyledLabel>
            <StyledInput
                id="firstName"
                name="firstName"
                type="text"
                autoCorrect="off"
                autoCapitalize="on"
                onChange={handleChange}
                value={values.firstName}
            />
            {errors.firstName && <StyledError>{errors.firstName}</StyledError>}
        </>
    );
};

export const FamilyNameInput = (props: InputPropsType) => {
    const {
        formik: { handleChange, values, errors }
    } = props;

    return (
        <>
            <StyledLabel htmlFor="familyName">
                {formatMessage(Strings.screen.profile.label.familyName)}
            </StyledLabel>
            <StyledInput
                id="familyName"
                name="familyName"
                type="text"
                autoCorrect="off"
                autoCapitalize="on"
                onChange={handleChange}
                value={values.familyName}
            />
            {errors.familyName && (
                <StyledError>{errors.familyName}</StyledError>
            )}
        </>
    );
};

export const PhoneNumberInput = (props: InputPropsType) => {
    const {
        formik: { handleChange, values, errors }
    } = props;

    return (
        <>
            <StyledLabel htmlFor="phoneNumber">
                {formatMessage(Strings.screen.profile.label.phoneNumber)}
            </StyledLabel>
            <StyledInput
                id="phoneNumber"
                name="phoneNumber"
                type="text"
                autoCorrect="off"
                onChange={handleChange}
                value={values.phoneNumber}
            />
            {errors.phoneNumber && (
                <StyledError>{errors.phoneNumber}</StyledError>
            )}
        </>
    );
};
