import React from 'react';
import { styled } from '../../theme/styled';
import { Icon, Modal, Button } from 'antd';
import { formatMessage as t, Strings } from '../../locale';

export const getCurrentBrowser = () => {
    const userAgent = window.navigator.userAgent;
    let browser = '';

    switch (true) {
        case userAgent.indexOf('MSIE ') != -1:
            browser = 'ie';
            break;
        case userAgent.indexOf('Trident/') != -1:
            browser = 'ie';
            break;
        case userAgent.indexOf('Chrome') != -1:
            browser = 'chrome';
            break;
        case userAgent.indexOf('Firefox') != -1:
            browser = 'firefox';
            break;

        default:
            break;
    }

    return browser;
};

export const BrowserWarningBar = () => {
    return (
        <StyledBrowserWarningBar>
            {t(Strings.communication.main.unsupportedBrowserWarningBar)}
        </StyledBrowserWarningBar>
    );
};

const buildTitle = () => {
    return (
        <StyledModalHeader className="notification-bar">
            <Icon type="warning" />
            {t(Strings.communication.main.unsupportedBrowserModalTitle)}
        </StyledModalHeader>
    );
};

const buildContent = () => {
    return (
        <StyledModalContent>
            <p>
                {t(Strings.communication.main.unsupportedBrowserModalContent)}
            </p>
            <ul>
                <li>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.google.com/chrome/"
                    >
                        Chrome
                    </a>
                </li>
                <li>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.mozilla.org/firefox/new/"
                    >
                        Firefox
                    </a>
                </li>
            </ul>
        </StyledModalContent>
    );
};

export const BrowserWarningModal = () => {
    const [showModal, setModal] = React.useState(true);

    const handleOk = () => {
        setModal(false);
    };

    return (
        <Modal
            title={buildTitle()}
            visible={showModal === true}
            closable={false}
            footer={<Button onClick={handleOk}>Ok</Button>}
        >
            {buildContent()}
        </Modal>
    );
};

const StyledModalHeader = styled.div`
    i {
        color: red;
        margin-right: 10px;
    }
`;

const StyledModalContent = styled.div`
    white-space: pre-line; //Fix for linebreak in translation
`;

const StyledBrowserWarningBar = styled.div.attrs({
    className: 'notification-bar'
})`
    position: relative;
    display: block;
    background-color: red;
    color: white;
    margin-top: 50px;
    height: 50px;
    width: 100%;
    text-align: center;
    line-height: 3;
`;
