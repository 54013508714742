import auth_logo from './auth_logo.png';
import logo from './logo.png';
import logo_svg from './logo.svg';

const logos = {
    auth_logo,
    logo,
    logo_svg
};

export { logos as default, auth_logo, logo, logo_svg };
