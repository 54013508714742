import { defineMessages } from 'react-intl';

const header = defineMessages({
    title: {
        id: 'support.header.title',
        defaultMessage: 'Support'
    }
});

export const support = {
    header
};
