import React, { PropsWithChildren, MouseEventHandler } from 'react';
import { IndicatorIconWithBorder } from '../../../components/indicator-icon/IndicatorIcon';
import { formatMessage, Strings } from '../../../locale';
import { ButtonLargePrimary } from '../../../components/ui/buttons/ButtonLarge';
import { StyledModal, StyledModalHeader } from './components/StyledInfoModal';
import { InfoTileType } from '../../../types/info-tile/info-tile.type';

type Props = {
    infoTile: InfoTileType;
    visible: boolean;
    onOK: MouseEventHandler<HTMLElement>;
};

export const InfoModal = ({
    infoTile,
    visible,
    onOK,
    children
}: PropsWithChildren<Props>): JSX.Element => {
    return (
        <StyledModal
            title={
                <StyledModalHeader>
                    <IndicatorIconWithBorder icon={infoTile.icon} size={45} />

                    <div style={{ marginLeft: '16px' }}>
                        <p>{infoTile.title}</p>
                    </div>
                </StyledModalHeader>
            }
            destroyOnClose={true}
            wrapClassName="vertical-center-modal"
            closable={false}
            visible={visible}
            bodyStyle={{ position: 'relative' }}
            footer={[
                <ButtonLargePrimary
                    style={{ height: '50px', width: '46%' }}
                    key={Strings.ui.buttons.main.ok.id}
                    onClick={onOK}
                >
                    {formatMessage(Strings.ui.buttons.main.ok)}
                </ButtonLargePrimary>
            ]}
        >
            {children}
        </StyledModal>
    );
};
