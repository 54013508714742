import { defineMessages } from 'react-intl';

const main = defineMessages({
    viewedBy: {
        id: 'shared.chat.viewedBy',
        defaultMessage: 'Viewed by {name} {formattedDate}'
    },
    empty: {
        id: 'shared.chat.empty',
        defaultMessage: 'Nothing here...'
    },
    beginConversation: {
        id: 'shared.chat.conversation.empty',
        defaultMessage: 'Write a message to start a conversation.'
    },
    newConversation: {
        id: 'shared.chat.newConversation',
        defaultMessage: 'Write a message to start a conversation.'
    },
    send: {
        id: 'shared.chat.conversation.send',
        defaultMessage: 'Send'
    },
    groupDevider: {
        id: 'shared.chat.group-list.divider',
        defaultMessage: 'Or'
    },
    chooseClinic: {
        id: 'shared.chat.group-list.header',
        defaultMessage: 'Choose clinic'
    }
});

const notForEmergency = defineMessages({
    stickyBody: {
        id: 'shared.chat.not-for-emergency.sticky-body',
        defaultMessage:
            'The health provider will respond to these messages when they have time.' +
            '  \n\n' +
            '**In case of emergency:**' +
            '  \n' +
            'Call 112 or 1177' +
            '  \n\n' +
            '**1177 The care guide:**' +
            '  \n' +
            'You renew prescriptions and handle many other care matters at [e-tjanster.1177.se](https://e-tjanster.1177.se/)'
    },
    showInfo: {
        id: 'shared.chat.not-for-emergency.show-info',
        defaultMessage: 'Show Info'
    },
    closeInfo: {
        id: 'shared.chat.not-for-emergency.close-info',
        defaultMessage: 'Hide info'
    }
});

export default {
    main,
    notForEmergency
};
