import { Dispatch } from 'react';
import {
    ConditionCode,
    ConditionsState
} from '../../../../redux/domains/conditions/condtions.types';
import { selectCondition } from '../../../../redux/domains/conditions/conditions.actions';
import { fetchReportStatus } from '../../../../redux/domains/report/report.actions';

function conditionHasIndicator(
    conditionsState: ConditionsState,
    conditionCode: ConditionCode,
    indicatorCode: string
) {
    return conditionsState.byCode[conditionCode].indicators.allCodes.includes(
        indicatorCode.toUpperCase()
    );
}

function getConditionCodeByIndicatorCode(
    conditionsState: ConditionsState,
    indicatorCode: string
) {
    if (
        conditionHasIndicator(
            conditionsState,
            conditionsState.selected,
            indicatorCode
        )
    ) {
        return conditionsState.byCode[conditionsState.selected].code;
    }

    return conditionsState.allCodes.find((conditionCode) =>
        conditionHasIndicator(conditionsState, conditionCode, indicatorCode)
    );
}

export function selectConditionWithIndicator(
    dispatch: Dispatch<any>,
    conditionsState: ConditionsState,
    indicatorCode: string
) {
    const conditionCode = getConditionCodeByIndicatorCode(
        conditionsState,
        indicatorCode
    );

    if (conditionCode && conditionsState.selected !== conditionCode) {
        dispatch(selectCondition(conditionCode));
        dispatch(fetchReportStatus());
    }
}
