import { IndicatorProperties } from '../../../types/indicator/indicator.type';
import messages from './messages';
import indicatorRules from './pain-rules';
import icon from './images/pain.svg';
import { TileType, FeedbackType } from '../../../types/indicator/report.type';
import { MultiSliderForm } from '../../../app/report/forms/slider/MultiSliderForm';

const properties = {
    rest: 'rest',
    active: 'active'
};

export const pain: IndicatorProperties<keyof typeof properties> = {
    code: 'PAIN-CI',
    properties,
    icon,
    messages,
    hiddenHistory: false,
    hiddenStatistics: false,
    recommendations: {
        limits: {
            enabled: false
        },
        reminder: {
            moreOften: true,
            enabled: true
        },
        info: {
            enabled: false
        }
    },
    rules: indicatorRules,
    tile: {
        report: {
            form: MultiSliderForm,
            type: TileType.Dimming,
            feedback: FeedbackType.Smiley
        }
    }
};
