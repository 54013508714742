import { general } from '../../../conditions/shared-indicators/fish/messages/general';
import { ChartEnum } from '../../../types/indicator/chart.types';
import {
    IndicatorHeatmapRules,
    IndicatorRules
} from '../../../types/indicator/indicator-rules.type';
import { colors } from '../../../theme/colors/common-colors';

export const rules: IndicatorRules & IndicatorHeatmapRules<boolean, boolean> = {
    neutralReportResult: false,
    invertReportResponse: true,
    hideValueWhenReporting: false,
    chartTypes: [ChartEnum.Heatmap],
    heatmapPieces: [
        {
            value: true,
            color: colors.chart.heatmap.purple10,
            text: general.legendYes
        },
        {
            value: false,
            color: colors.chart.heatmap.purple80,
            text: general.legendNo
        }
    ]
};

export default rules;
