import * as actionTypes from './notifications.constants';
import { NormalizedObjects, Notification } from './notifications.api';

const initialState: NormalizedObjects<Notification> = {};

type Action = {
    type: string;
    payload: NormalizedObjects<Notification>;
};

export function notificationsReducer(state = initialState, action: Action) {
    switch (action.type) {
        case actionTypes.NOTIFICATIONS_FETCH_PENDING:
            return state;
        case actionTypes.NOTIFICATIONS_FETCH_FULFILLED:
            return {
                ...state,
                ...action.payload
            };
        case actionTypes.NOTIFICATIONS_FETCH_REJECTED:
            return {};
        case actionTypes.NOTIFICATIONS_UPDATE_PENDING:
            return state;
        case actionTypes.NOTIFICATIONS_UPDATE_FULFILLED:
            return {
                ...state,
                ...action.payload
            };
        case actionTypes.NOTIFICATIONS_UPDATE_REJECTED:
            return state;
        default:
            return state;
    }
}
