import { defineMessages } from 'react-intl';

const invalid = defineMessages({
    date: {
        defaultMessage: 'Invalid date',
        id: 'input.date-picker.invalid.date'
    }
});

export const datePicker = {
    invalid
};
