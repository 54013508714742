const notificationsNormalize = (notifications: any): any => {
    if (notifications === null || notifications.length === 0) {
        return {};
    }

    const normalizedNotifications = Object.assign.apply(
        {},
        notifications.map((notification: any) => {
            return {
                [notification.id]: notification
            };
        })
    );
    return normalizedNotifications;
};

export { notificationsNormalize };
