import { defineMessages } from 'react-intl';

export const lowSaltDiet = defineMessages({
    title: {
        defaultMessage: 'Low salt diet',
        id: 'indicator.ehfscbs.low-salt-diet.title'
    },
    description: {
        defaultMessage: 'I eat a low salt diet',
        id: 'indicator.ehfscbs.low-salt-diet.description'
    },
    maxValueDescription: {
        defaultMessage: 'I do not agree at all',
        id: 'indicator.ehfscbs.low-salt-diet.max-text'
    },
    minValueDescription: {
        defaultMessage: 'I fully agree',
        id: 'indicator.ehfscbs.low-salt-diet.min-text'
    }
});
