import { defineMessages } from 'react-intl';

const reminder = defineMessages({
    reportFrequency: {
        id: 'recommendation.reminder.report-frequency',
        defaultMessage: 'Report {formattedTime}'
    },
    reportFrequencyOrMoreOften: {
        id: 'recommendation.reminder.report-frequency-or-more-often',
        defaultMessage:
            'Report {formattedTime} or more often if your condition changes'
    },
    deactivated: {
        id: 'recommendation.reminder.deactivated',
        defaultMessage: 'Report this parameter when your condition changes'
    }
});

const extra = defineMessages({
    smoke: {
        id: 'recommendation.reminder.extra.smoke',
        defaultMessage:
            'Of all lifestyle factors, quit smoking is the quickest one to improve the conditions for a longer and healthier life.'
    },
    didYouKnow: {
        id: 'recommendation.reminder.extra.did-you-know',
        defaultMessage: 'Did you know?'
    }
});

export default {
    reminder,
    extra
};
