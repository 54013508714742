import { defineMessages } from 'react-intl';

export const pain = defineMessages({
    title: {
        defaultMessage: 'Your pain/discomfort TODAY',
        id: 'indicator.eq5d3l.pain.title'
    },
    choices: {
        defaultMessage:
            '{value, select, 1 {I have no pain or discomfort} 2 {I have moderate pain or discomfort} 3 {I have extreme pain or discomfort}}',
        id: 'indicator.eq5d3l.pain.choices'
    }
});
