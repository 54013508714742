import { useCallback, useContext } from 'react';
import { ThemeEnum } from '../profile/profiles';
import { ThemeProfileContext } from './provider.context';

export function useStyledThemeProfile() {
    const context = useContext(ThemeProfileContext);

    if (!context) throw new Error('must be used within a Provider');

    const { theme, setThemeProfile } = context;

    const toggle = useCallback(() => {
        if (theme === ThemeEnum.DARK) {
            setThemeProfile(ThemeEnum.LIGHT);
        } else {
            setThemeProfile(ThemeEnum.DARK);
        }
    }, [theme, setThemeProfile]);

    return {
        theme,
        toggle
    };
}
