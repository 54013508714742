import { defineMessages } from 'react-intl';

export const fcRS = defineMessages({
    name: {
        defaultMessage: 'Fake condition',
        id: 'conditions.LP_2_0_FAKE_CONDITION_RS.name'
    },
    nameLong: {
        defaultMessage: 'Fake condition',
        id: 'conditions.LP_2_0_FAKE_CONDITION_RS.name-long'
    }
});
