import { defineMessages } from 'react-intl';

export const vas = defineMessages({
    title: {
        defaultMessage: 'Your health TODAY',
        id: 'indicator.eq5d3l.vas.title'
    },
    helpText: {
        defaultMessage:
            'Please tap on the scale to indicate how your health is TODAY.',
        id: 'indicator.eq5d3l.vas.help-text'
    }
});
