import { ChartEnum } from '../../../types/indicator/chart.types';
import { IndicatorRules } from '../../../types/indicator/indicator-rules.type';

export const rules: IndicatorRules = {
    neutralReportResult: true,
    invertReportResponse: false,
    hideValueWhenReporting: false,
    step: 1,
    chartTypes: [ChartEnum.Spline]
};

export default rules;
