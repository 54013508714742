function rgb2hex(red, green, blue) {
    const rgb = blue | (green << 8) | (red << 16);
    return '#' + (0x1000000 + rgb).toString(16).slice(1);
}

const hex2rgb = (hex) => {
    const long = hex.match(/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i);
    if (long) {
        return long.slice(1, 4).map(function (x) {
            return parseInt(x, 16);
        });
    }

    const short = hex.match(/^#([0-9a-f])([0-9a-f])([0-9a-f])$/i);
    if (short) {
        return short.slice(1, 4).map(function (x) {
            return 0x11 * parseInt(x, 16);
        });
    }
    return null;
};

export const getRGBA = (hex, alpha) => {
    if (alpha != null) {
        return `rgba(${hex2rgb(hex)}, ${alpha})`;
    }
    return `rgba(${hex2rgb(hex)}, 1)`;
};
