import { actionTypes } from './authentication.constants';
import { AuthenticationState } from './authentication.types';

const initialState: AuthenticationState = {
    isAuthenticated: false,
    loginError: null,
    idToken: '',
    refreshToken: ''
};

export function authenticationReducer(
    state = initialState,
    action: any
): AuthenticationState {
    switch (action.type) {
        case actionTypes.AUTHENTICATION_LOGIN_FULFILLED:
            return {
                ...state,
                idToken: action.payload.idToken,
                refreshToken: action.payload.refreshToken,
                isAuthenticated: true,
                loginError: null
            };
        case actionTypes.AUTHENTICATION_LOGIN_REJECTED:
            return {
                ...state,
                isAuthenticated: false,
                loginError: action.payload.data
            };
        case actionTypes.AUTHENTICATION_CHECK_FULFILLED: {
            const { idToken, refreshToken } = action.payload;
            return {
                ...state,
                isAuthenticated: true,
                idToken,
                refreshToken
            };
        }
        case actionTypes.AUTHENTICATION_CHECK_REJECTED:
            return { ...state, isAuthenticated: false };
        case actionTypes.AUTHENTICATION_LOGOUT_FULFILLED:
            return {
                ...state,
                idToken: '',
                refreshToken: '',
                isAuthenticated: false
            };
        default:
            return state;
    }
}
