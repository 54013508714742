import { Modal } from 'antd';
import { styled } from '../../../theme/styled';

export const StyledModal = styled(Modal)`
    && {
        max-width: none;
        top: 0;
        margin: 0;
        padding: 0;
    }
    -webkit-user-select: none;

    @media (orientation: landscape) and (min-width: 1024px) and (min-height: 640px) {
        && {
            height: 70vh;
            max-width: 520px; // Use same width as ant design
        }
    }

    .ant-modal-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        background: white;
        border-radius: 0;
        border-top: 0;

        .ant-modal-header {
            border-radius: 0;
        }

        .ant-modal-body {
            height: 100%;
            position: relative;
            padding: 0;
            background: #fff;
            border-radius: 0;
        }
    }

    width: 100%;
    height: 100%;
    z-index: 2000;
    flex-grow: 1;

    .ant-modal-body {
        height: auto;
        flex: 1;
        overflow: auto;
    }
`;

export const StyledHeader = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledHeaderContent = styled.div`
    flex-grow: 1;
    margin: 0 16px;
`;

export const StyledTitle = styled.p`
    font-size: 1.1rem;
    font-weight: 400;
    color: #2a2a2a;
    margin: 0;
    padding: 0;
`;

export const StyledSubtitle = styled.p`
    font-size: 0.9rem;
    font-weight: 500;
    color: grey;
    margin: 0;
    padding: 0;
`;

export const StyledBody = styled.form`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const StyledBodyContent = styled.div`
    flex-grow: 1;
    padding: 10px;
    overflow: auto;
    background: rgba(230, 236, 240, 0.24);
`;
