import React, { Component, createRef, Fragment } from 'react';
import { styled } from '../../../theme/styled';
import moment from 'moment';

import { isEmpty } from 'lodash';
import { Divider } from 'antd';
import { FormattedDate } from 'react-intl';
import { formatMessage as t, Strings } from '../../../locale';

import MessageListItem from './ConversationMessageListItem';
import { theme } from '../../../theme/colors/common-colors';

type Props = {
    messages: any;
    userId: any;
};

export default class MessageList extends Component<Props> {
    private el = createRef<HTMLDivElement>();

    constructor(props: Props) {
        super(props);
    }

    componentDidMount() {
        this.scrollToBottom();
    }

    componentDidUpdate(prevProps: any) {
        if (prevProps.messages.length !== this.props.messages.length) {
            this.scrollToBottom();
        }
    }

    render() {
        const { messages } = this.props;

        return (
            <MessageListContainer>
                {isEmpty(messages) ? (
                    <EmptyMessage />
                ) : (
                    messages.map(this.renderMessage)
                )}
                <div ref={this.el} />
            </MessageListContainer>
        );
    }

    renderMessage = (message: any, idx: any, messages: any) => {
        const { userId } = this.props;
        const { id, sender: senderId } = message;
        const isOutgoing = senderId === userId;
        const renderDivider = this.shouldRenderDivider(message, idx, messages);

        return (
            <Fragment key={id}>
                {renderDivider && (
                    <Divider
                        style={{
                            fontWeight: 400,
                            fontSize: 12,
                            color: theme.layout.subtitles
                        }}
                    >
                        <FormattedDate
                            value={message.dtCreated}
                            year="numeric"
                            month="long"
                            day="numeric"
                        />
                    </Divider>
                )}
                <MessageRow $isOutgoing={isOutgoing}>
                    <MessageListItem
                        message={message}
                        isOutgoing={isOutgoing}
                    />
                </MessageRow>
            </Fragment>
        );
    };

    scrollToBottom = () => {
        const node = this.el.current;
        if (node) {
            node.scrollIntoView({ behavior: 'auto' });
        }
    };

    shouldRenderDivider = (message: any, idx: any, messages: any) => {
        if (idx === 0) return true;

        const { dtCreated } = message;
        const { dtCreated: previousDtCreated } = messages[idx - 1];

        return !moment(dtCreated).isSame(previousDtCreated, 'day');
    };
}

const MessageListContainer = styled.div`
    height: calc(100% - 82px);
    padding: 15px;
    overflow: auto;
`;

const MessageRow = styled.div<{ $isOutgoing: boolean }>`
    margin-bottom: 20px;
    display: flex;
    flex-direction: ${(props) => (props.$isOutgoing ? 'row-reverse' : 'row')};
`;

const EmptyMessage = () => (
    <div
        style={{
            textAlign: 'center',
            color: theme.layout.subtitles_alter,
            marginTop: 50
        }}
    >
        {t(Strings.chat.main.beginConversation)}
    </div>
);
