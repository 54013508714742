import { privateAxios } from '../../../services/http-client/http-client';
import {
    getActiveSessions,
    createConnection,
    deleteSession,
    VideoSession
} from '@lifepod-legacy/openvidu-frontend/dist/api/video-api';

export const getActiveVideoSessions = (subjectId: string) =>
    getActiveSessions(privateAxios, subjectId).then(({ data }) => data);

export const createVideoConnection = (videoSession: VideoSession) =>
    createConnection(privateAxios, videoSession).then(({ data }) => data);

export const deleteVideoSession = (videoSession: VideoSession) =>
    deleteSession(privateAxios, videoSession).then(({ data }) => data);
