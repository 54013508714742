import ceSquare from './images/ce_square.svg';
import manual from './images/manual_icon.svg';
import manufacturer from './images/manufacturer_icon.svg';
import triangle from './images/ISO_7000-0434B.svg';

export const supportImages = {
    ceSquare,
    manual,
    manufacturer,
    triangle
};
