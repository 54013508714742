import { Radio } from 'antd';
import { styled } from '../../../../../theme/styled';

export const StyledRow = styled.div`
    margin: 11px 0;

    label {
        display: inline-flex;
        margin-right: 0;
    }

    .ant-radio {
        height: 16px; // Fixes animation bug when multi line
        width: 16px; // Fixes animation bug when multi line
        vertical-align: top;
    }

    .ant-radio + span {
        display: inline-block;
        padding-left: 5px;
        padding-right: 0;
        white-space: normal;
        font-size: 13px;
    }
`;

export const StyledRadioGroup = styled(Radio.Group)`
    width: 100%;
    text-align: center;

    .ant-radio-group {
        display: flex;
        flex-direction: column;
    }

    .ant-radio-button-wrapper {
        border: 1px solid #d9d9d9;
        overflow: hidden;
    }

    && {
        .ant-radio-button-wrapper {
            box-shadow: none;
            border-radius: 0;
        }
    }

    .ant-radio-button-wrapper:first-child {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
    }

    .ant-radio-button-wrapper:last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }
`;
