import * as notificationActionTypes from '../../domains/notifications/notifications.constants';
import * as chatActionTypes from '../../domains/chat/chat.constants';

import { fetchNotifications } from '../../domains/notifications/notifications.actions';
import { fetchMessages } from '../../domains/chat/chat.actions';

const reduxTimerMiddleware =
    () => (store: any) => (next: any) => (action: any) => {
        switch (action.type) {
            case notificationActionTypes.NOTIFICATION_TIMER_TICK:
                store.dispatch(fetchNotifications(['sent', 'notified']));
                return next(action);
            case chatActionTypes.MESSAGE_TIMER_TICK: {
                const { conversationId, groupId } = action.payload;
                store.dispatch(fetchMessages(groupId, conversationId));
                return next(action);
            }
            default:
                return next(action);
        }
    };

export default reduxTimerMiddleware;
