import React from 'react';
import { isMobile } from 'react-device-detect';
import { DesktopDateTimePicker } from './DesktopDateTimePicker';
import { MobileDateTimePicker } from './MobileDateTimePicker';

export const DateTimePicker = () => {
    return (
        <>{isMobile ? <MobileDateTimePicker /> : <DesktopDateTimePicker />}</>
    );
};
