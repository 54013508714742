import React, { useState } from 'react';
import { DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import { styled } from '../../../../theme/styled';

export const DesktopDateTimePicker = () => {
    const [value, setValue] = useState(moment());
    const [disabledHours, setDisabledHours] = useState<number[]>(
        range(moment().hour() + 1, 24)
    );
    const [disabledMinutes, setDisabledMinutes] = useState<number[]>(
        range(moment().minutes() + 1, 60)
    );
    const disabledDate = (current: null | Moment) =>
        current
            ? current < moment().subtract(5, 'days').startOf('day') ||
              current > moment()
            : true;

    const disabledDateTime = () => ({
        disabledHours: () => disabledHours,
        disabledMinutes: () => disabledMinutes
    });

    return (
        <StyledDatePickerWrapper>
            <DatePicker
                format="YYYY-MM-DD HH:mm"
                showTime={{ format: 'HH:mm' }}
                disabledDate={disabledDate}
                disabledTime={disabledDateTime}
                allowClear={false}
                value={value}
                onChange={(value) => setTimePickerValues(value)}
                name="datetime"
            />
        </StyledDatePickerWrapper>
    );

    function setTimePickerValues(value: Moment | null = null) {
        setValue(value ? value : moment());

        const hoursRange =
            value?.date() === moment().date()
                ? range(moment().hours() + 1, 24)
                : range(24, 24);

        const minutesRange =
            value?.date() === moment().date() &&
            value.hours() === moment().hours()
                ? range(moment().minutes() + 1, 60)
                : range(60, 60);

        setDisabledHours(hoursRange);
        setDisabledMinutes(minutesRange);
    }
};

export function range(start: number, end: number) {
    if (end < start) {
        throw new Error('End value is lower than start value');
    }

    if (start < 0 || end < 0) {
        throw new Error('Start or end values cannot be negative');
    }

    return Array.from({ length: end - start }, (_, i) => i + start);
}

const StyledDatePickerWrapper = styled.div`
    text-align: center;
`;
