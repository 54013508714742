import React from 'react';
import { useSelector } from 'react-redux';
import { styled } from '../../../theme/styled';
import { Card, Divider } from 'antd';
import { Redirect } from 'react-router-dom';
import { formatMessage, Strings } from '../../../locale';
import { Header } from '../../../components/layout/Header';
import { ContentLayout } from '../../../components/layout/ContentLayout';
import {
    getVisibleMedicGroups,
    getConditionsByMedicGroup
} from '../../../redux/domains/groups/groups.selectors';
import {
    getCondition,
    isExistingCondition
} from '../../../redux/domains/conditions/conditions.utils';
import { routePaths } from '../../../constants/route-paths';
import { ButtonLarge } from '../../../components/ui/buttons/ButtonLarge';

export const GroupListPage = () => {
    const visibleMedicGroups = useSelector(getVisibleMedicGroups);
    const conditionsByGroup = useSelector(getConditionsByMedicGroup);

    if (visibleMedicGroups.length === 1) {
        return (
            <Redirect
                exact
                from={routePaths.chat}
                to={`${routePaths.chat}/${visibleMedicGroups[0].id}`}
            />
        );
    }

    const buttons = visibleMedicGroups.map(({ id, name }, index) => (
        <React.Fragment key={id}>
            {index > 0 && (
                <StyledDivider>
                    {formatMessage(Strings.chat.main.groupDevider)}
                </StyledDivider>
            )}
            <ButtonLarge
                block
                href={`#${routePaths.chat}/${id}`}
                style={{ lineHeight: '1.5', height: 'auto', padding: '10px' }}
            >
                <StyledMedicGroupName>{name}</StyledMedicGroupName>
                {conditionsByGroup[id]
                    .filter(isExistingCondition)
                    .map(({ code }) => (
                        <StyledConditionName key={code}>
                            {getCondition(code).name}
                        </StyledConditionName>
                    ))}
            </ButtonLarge>
        </React.Fragment>
    ));

    return (
        <>
            <Header
                title={formatMessage(Strings.navigation.main.messagesTab)}
            />
            <ContentLayout>
                <StyledCard>
                    <StyledH2>
                        {formatMessage(Strings.chat.main.chooseClinic)}
                    </StyledH2>
                    {buttons}
                </StyledCard>
            </ContentLayout>
        </>
    );
};

const StyledH2 = styled.h2`
    margin-bottom: 10px;
    font-size: 18px;
`;

const StyledDivider = styled(Divider)`
    &&& {
        font-size: 14px;
    }
`;

const StyledCard = styled(Card)`
    height: 100%;
    text-align: center;

    .ant-card-body {
        heigth: 100%;
    }
`;

const StyledMedicGroupName = styled.span`
    && {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 15px;
        margin-bottom: 5px;
    }
`;

const StyledConditionName = styled.span`
    && {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
    }
`;
