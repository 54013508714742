import React from 'react';
import { SplineSeries } from 'react-jsx-highcharts';
import { LineColor } from '../../../../../../types/indicator/indicator-rules.type';

export const MultiValueChart = (props: {
    required: string[];
    values: { value: any; date: number }[];
    lineColors: LineColor<string>;
    getSerieName: (property: string) => string;
}) => {
    const { required, values, lineColors, getSerieName } = props;

    return (
        <>
            {required.map((property: string) => {
                const data = values.map((value: any) => [
                    value.date,
                    value.value[property]
                ]);

                return (
                    <SplineSeries
                        data={data}
                        name={getSerieName(property)}
                        key={property}
                        color={lineColors[property]}
                    />
                );
            })}
        </>
    );
};
