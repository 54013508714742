import { ChartEnum } from '../../../../types/indicator/chart.types';
import {
    IndicatorChartRules,
    IndicatorRules
} from '../../../../types/indicator/indicator-rules.type';
import { colors } from '../../../../theme/colors/common-colors';

export const rules: IndicatorRules & IndicatorChartRules = {
    neutralReportResult: false,
    invertReportResponse: false,
    hideValueWhenReporting: false,
    step: 1,
    chartTypes: [ChartEnum.Spline],
    lineColor: colors.chart.graph.obj.defaultObj,
    chartOptions: {
        hidePlotBand: false
    }
};

export default rules;
