import React, { Component, Fragment } from 'react';
import { styled } from '../../theme/styled';
import { Layout } from 'antd';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Footer } from './Footer';
import { Loading } from '../loading/Loading';
import { MessageOfTheDayModal } from '../message-of-the-day/MessageOfTheDayModal';
import {
    BrowserWarningModal,
    BrowserWarningBar,
    getCurrentBrowser
} from '../browser-warning/BrowserWarning';
import { VideoPage } from '../../app/video/VideoPage';
import { mainRoutes } from './main-routes';
import { createLoadingSelector } from '../../redux/domains/requests/requests.selectors';
import { USER_FETCH } from '../../redux/domains/user/user.constants';
import { GROUPS_FETCH } from '../../redux/domains/groups/groups.constants';
import { bindActionCreators } from 'redux';
import { checkAuthentication } from '../../redux/domains/authentication/authentication.actions';
import { fetchUserThunk } from '../../redux/domains/user/user.thunks';
import { fetchGroups } from '../../redux/domains/groups/groups.actions';
import { fetchConditions } from '../../redux/domains/conditions/conditions.actions';
import { connect } from 'react-redux';
import { routePaths } from '../../constants/route-paths';
import { AppState } from '../../redux/store/root-reducer';

const { Content } = Layout;

type Props = {
    actions: {
        checkAuthentication: () => void;
        fetchUserThunk: any;
        fetchGroups: any;
        fetchConditions: any;
    };
    loading: boolean;
};

type State = { initApp: boolean };

class MainLayout extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            initApp: false
        };
    }

    addStorageEventListener = () => {
        window.addEventListener('storage', this.syncLogout);
    };

    syncLogout = (event: any) => {
        if (event.key === 'logout') {
            this.props.actions.checkAuthentication();
        }
    };

    async componentDidMount() {
        this.addStorageEventListener();
        await this.initApp();
    }

    componentWillUnmount() {
        window.removeEventListener('storage', this.syncLogout);
    }

    initApp = async () => {
        const { fetchUserThunk, fetchGroups, fetchConditions } =
            this.props.actions;

        this.setState({ initApp: true });

        Promise.all([fetchUserThunk(), fetchGroups(), fetchConditions()])
            .then(() => {
                this.setState({ initApp: false });
            })
            .catch((error) => {
                console.log('Error initializing app', error);
            });
    };

    render() {
        return (
            <Switch>
                <Route path={`${routePaths.video}/:id`}>
                    {__APP_CONFIG__.FEATURE_VIDEO_MEETING && <VideoPage />}
                </Route>
                <Route path="/">
                    <StyledMainLayout>
                        <StyledContainer>
                            <MessageOfTheDayModal />
                            <Content>
                                {getCurrentBrowser() === 'ie' && (
                                    <>
                                        <BrowserWarningModal />
                                        <BrowserWarningBar />
                                    </>
                                )}
                                {this.props.loading || this.state.initApp ? (
                                    <Loading />
                                ) : (
                                    <Switch>
                                        {mainRoutes.map((route) => (
                                            <Route
                                                path={route.path}
                                                component={route.component}
                                                key={route.path}
                                            />
                                        ))}
                                        <Redirect
                                            from="/"
                                            to={routePaths.report}
                                        />
                                    </Switch>
                                )}
                            </Content>
                        </StyledContainer>
                        <Footer />
                    </StyledMainLayout>
                </Route>
                );
            </Switch>
        );
    }
}

const loadingSelector = createLoadingSelector([USER_FETCH, GROUPS_FETCH]);

const mapStateToProps = (state: AppState) => {
    return {
        loading: loadingSelector(state)
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        actions: bindActionCreators(
            {
                checkAuthentication,
                fetchUserThunk,
                fetchGroups,
                fetchConditions
            },
            dispatch
        )
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);

const StyledContainer = styled.div`
    position: relative;
    height: 100%;
    && {
        @media (orientation: landscape) and (min-width: 1024px) and (min-height: 640px) {
            width: 30%;
            margin: 0 auto;
            border: 1px solid #ccc;
        }
    }
`;

const StyledMainLayout = styled(Layout)`
    && {
        max-width: 100%;
        height: 100%;
        background: ${(props) => props.theme.appBackground};

        @media (orientation: landscape) and (min-width: 1024px) and (min-height: 640px) {
            -ms-overflow-style: -ms-autohiding-scrollbar;
            height: 80%;
            position: relative;
            width: 100%;
        }
    }
`;
