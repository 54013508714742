import { Indicator } from '../../../../types/indicator/indicator.type';
import messages from './messages';
import indicatorRules from './pump-flow-rules';
import icon from './images/pump-flow.svg';
import {
    TileType,
    FeedbackType
} from '../../../../types/indicator/report.type';
import { SliderForm } from '../../../../app/report/forms/slider/SliderForm';

export const pumpFlow: Indicator = {
    code: 'PUMP-FLOW-LVAD',
    icon,
    messages,
    hiddenHistory: false,
    hiddenStatistics: false,
    recommendations: {
        limits: {
            enabled: false
        },
        reminder: {
            moreOften: false,
            enabled: true
        },
        info: {
            enabled: false
        }
    },
    rules: indicatorRules,
    tile: {
        report: {
            form: SliderForm,
            type: TileType.Dimming,
            feedback: FeedbackType.Checkmark
        }
    }
};
