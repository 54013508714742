import {
    MessageOfTheDayType,
    MotdMessageType
} from '../../redux/domains/motd/motd.types';

export const noMotds = (motds: MessageOfTheDayType[]) => {
    return motds.length === 0;
};

export const hasMotds = (motds: MessageOfTheDayType[]) => {
    return motds.length > 0;
};

export const getIconType = (type: MotdMessageType) => {
    if (type === 'info') {
        return 'info-circle';
    }
    if (type === 'warning') {
        return 'warning';
    }
    return '';
};
