import React from 'react';
import { IndicatorIconWithBorder } from '../../../components/indicator-icon/IndicatorIcon';
import { styled } from '../../../theme/styled';
import IndicatorTrend from './IndicatorTrend';
import { formatMessage } from '../../../locale';
import { Icon, List } from 'antd';
import { NavLink } from 'react-router-dom';
import { useFetchedIndicator } from '../../../hooks/use-fetched-indicator';
import { formatValue } from '../format-value';
import { FetchedIndicator } from '../../../types/indicator/fetched-indicator.type';
import {
    Configuration,
    Limits,
    Properties
} from '../../../types/indicator/state-indicator.type';
import { useReduxSelector } from '../../../hooks/use-redux-selector';
import { getIndicatorConfigurations } from '../../../redux/domains/user/user.selectors';
import { getValuesByIndicatorId } from '../../../redux/domains/values/values.selectors';
import { Value } from '../../../redux/domains/values/values.types';

type Props = {
    code: string;
};

function IndicatorListItem(props: Props): JSX.Element {
    const { code } = props;
    const indicator = useFetchedIndicator(code);
    const configuration = useReduxSelector(
        (state) => getIndicatorConfigurations(state)[indicator.code]
    );
    const values = useReduxSelector(
        (state) => getValuesByIndicatorId(state)[indicator.id]
    );
    const indicatorValues = values ? [...values].reverse() : [];

    if (!configuration.in_use) {
        return <></>;
    }

    return indicatorValues.length === 0 ? (
        <StyledListItem id={indicator.id} style={{ opacity: 0.5 }}>
            <div>
                <IndicatorItem
                    indicator={indicator}
                    values={indicatorValues}
                    configuration={configuration}
                />
            </div>
        </StyledListItem>
    ) : (
        <StyledListItem id={indicator.id} data-test-id={indicator.code}>
            <NavLink to={`/my-data/statistics/${indicator.id}`}>
                <IndicatorItem
                    indicator={indicator}
                    values={indicatorValues}
                    configuration={configuration}
                />
            </NavLink>
        </StyledListItem>
    );
}

const IndicatorItem = ({
    indicator,
    values,
    configuration
}: {
    indicator: FetchedIndicator;
    values: Value[];
    configuration: Configuration & Limits & Properties;
}) => {
    return (
        <>
            <IndicatorIconWithBorder icon={indicator.icon} size={50} />
            <IndicatorText indicator={indicator} values={values} />
            <IndicatorTrend
                indicator={indicator}
                values={values}
                configuration={configuration}
            />
            <Icon className="arrow" type="right" />
        </>
    );
};

function IndicatorText({
    indicator,
    values
}: {
    indicator: FetchedIndicator;
    values: Value[];
}) {
    if (values && values.length === 0) {
        return (
            <StyledTextContainer>
                {formatMessage(indicator.messages.general.title)}
            </StyledTextContainer>
        );
    }

    const latestValue = values[0].value;
    const formattedValue: string[] = formatValue(indicator, latestValue);

    return (
        <StyledTextContainer>
            {formatMessage(indicator.messages.general.title)}
            {formattedValue.map((line, index) => (
                <StyledLatestValue key={index}>{line}</StyledLatestValue>
            ))}
        </StyledTextContainer>
    );
}

export default IndicatorListItem;

const StyledTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    color: #2a2a2a;
    font-size: 1.2rem;
    margin: 0 5px 0 20px;
`;

const StyledLatestValue = styled.div`
    white-space: pre-wrap;
    color: #2c8eff;
    font-weight: 400;
    font-size: 0.8rem;
`;

const StyledListItem = styled(List.Item)`
    background: #fff;

    & > * {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        padding: 0 10px;

        .indicator-trend-icon {
            font-size: 40px;

            &.good {
                color: #3baa34;
            }

            &.bad {
                color: #e61b21;
            }

            &.ok {
                color: #fae615;
            }
        }

        .arrow {
            color: #666;
            margin-left: 20px;
        }
    }

    & > div {
        .indicator-trend-icon {
            visibility: hidden;
        }

        .arrow {
            visibility: hidden;
        }
    }
`;
