import { defineMessages } from 'react-intl';

export const general = defineMessages({
    title: {
        defaultMessage: 'Bag weight',
        id: 'indicator.weight-pd.title'
    },
    value: {
        defaultMessage: '{value}',
        id: 'indicator.weight-pd.value'
    },
    unit: {
        id: 'indicator.weight-pd.unit',
        defaultMessage: 'g'
    }
});
