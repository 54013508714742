type Props = {
    horizontalOffset: number;
    verticalOffset: number;
    blur: number;
    spread: number;
    color: string;
};

export const cssShadow = ({
    horizontalOffset,
    verticalOffset,
    blur,
    spread,
    color
}: Props) => {
    const pixelToRemRatio = 0.0625;

    const horizontalRem = horizontalOffset * pixelToRemRatio;
    const verticalRem = verticalOffset * pixelToRemRatio;
    const blurRem = blur * pixelToRemRatio;
    const spreadRem = spread * pixelToRemRatio;

    return `
    -moz-box-shadow: ${horizontalRem}rem ${verticalRem}rem  ${blurRem}rem ${spreadRem}rem ${color};
    -webkit-box-shadow: ${horizontalRem}rem ${verticalRem}rem ${blurRem}rem ${spreadRem}rem ${color};
    box-shadow: ${horizontalRem}rem ${verticalRem}rem ${blurRem}rem ${spreadRem}rem ${color};
`;
};
