import React from 'react';
import Strings from '../../../../../locale/messages-descriptors';
import { formatMessage } from '../../../../../locale/utils/functions/format-message';

export const ExtraInfoSmoke = () => {
    return (
        <div style={{ textAlign: 'left' }}>
            <h4 style={{ textAlign: 'center' }}>
                {formatMessage(Strings.recommendation.extra.didYouKnow)}
            </h4>
            {formatMessage(Strings.recommendation.extra.smoke)}
        </div>
    );
};
