import { Fragment } from 'react';
import { IndicatorProperties } from '../../../types/indicator/indicator.type';
import messages from './messages';
import indicatorRules from './bmi-rules';
import icon from './images/bmi.svg';
import { TileType, FeedbackType } from '../../../types/indicator/report.type';

const code = 'BMI';

const properties = {
    bmi: 'bmi'
};

export const bmi: IndicatorProperties<keyof typeof properties> = {
    code,
    properties,
    icon,
    messages,
    hiddenHistory: true,
    hiddenStatistics: false,
    recommendations: {
        limits: {
            enabled: true
        },
        info: {
            enabled: false
        }
    },
    rules: indicatorRules,
    tile: {
        report: {
            form: Fragment,
            type: TileType.Hidden,
            feedback: FeedbackType.None
        }
    }
};
