export class LocalStorage {
    private storageRef = window.localStorage;

    storageAvailable() {
        try {
            const x = '__storage_test__';
            this.storageRef.setItem(x, x);
            this.storageRef.removeItem(x);
            return true;
        } catch (e) {
            return (
                e instanceof DOMException &&
                // everything except Firefox
                (e.code === 22 ||
                    // Firefox
                    e.code === 1014 ||
                    // test name field too, because code might not be present
                    // everything except Firefox
                    e.name === 'QuotaExceededError' ||
                    // Firefox
                    e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
                // acknowledge QuotaExceededError only if there's something already stored
                this.storageRef &&
                this.storageRef.length !== 0
            );
        }
    }

    clear() {
        if (this.storageAvailable()) {
            return this.storageRef.clear();
        } else {
            console.error('Local Storage : getItem');
            return 'false';
        }
    }

    getItem(key: string): any {
        if (this.storageAvailable()) {
            return this.storageRef.getItem(key);
        } else {
            console.error('Local Storage : getItem');
            return 'false';
        }
    }

    setItem(key: string, value: string) {
        if (this.storageAvailable()) {
            this.storageRef.setItem(key, value);
        } else {
            console.error('Local Storage : setItem');
        }
    }

    removeItem(key: string) {
        if (this.storageAvailable()) {
            this.storageRef.removeItem(key);
        } else {
            console.error('Local Storage : removeItem');
        }
    }
}
