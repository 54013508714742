import { defineMessages } from 'react-intl';

export const general = defineMessages({
    title: {
        defaultMessage: 'Dizziness',
        id: 'indicator.dizziness.title'
    },
    value: {
        defaultMessage:
            '{value, select, 1 {No dizziness} 2 {Light dizziness} 3 {Moderate dizziness} 4 {Severe dizziness}}',
        id: 'indicator.dizziness.value'
    }
});
