export const VIDEO_MEETINGS_FETCH = 'VIDEO_MEETINGS_FETCH';
export const VIDEO_MEETINGS_FETCH_PENDING = 'VIDEO_MEETINGS_FETCH_PENDING';
export const VIDEO_MEETINGS_FETCH_FULFILLED = 'VIDEO_MEETINGS_FETCH_FULFILLED';
export const VIDEO_MEETINGS_FETCH_REJECTED = 'VIDEO_MEETINGS_FETCH_REJECTED';

export const VIDEO_MEETING_JOIN = 'VIDEO_MEETING_JOIN';
export const VIDEO_MEETING_JOIN_PENDING = 'VIDEO_MEETING_JOIN_PENDING';
export const VIDEO_MEETING_JOIN_FULFILLED = 'VIDEO_MEETING_JOIN_FULFILLED';
export const VIDEO_MEETING_JOIN_REJECTED = 'VIDEO_MEETING_JOIN_REJECTED';

export const VIDEO_MEETING_LEAVE = 'VIDEO_MEETING_LEAVE';
export const VIDEO_MEETING_LEAVE_PENDING = 'VIDEO_MEETING_LEAVE_PENDING';
export const VIDEO_MEETING_LEAVE_FULFILLED = 'VIDEO_MEETING_LEAVE_FULFILLED';
export const VIDEO_MEETING_LEAVE_REJECTED = 'VIDEO_MEETING_LEAVE_REJECTED';
