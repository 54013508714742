import { defineMessages } from 'react-intl';

export const degree = defineMessages({
    question: {
        defaultMessage: 'Degree',
        id: 'indicator.swelling.degree.question'
    },
    value: {
        defaultMessage:
            '{value, select, 1 {No} 2 {Light} 3 {Moderate} 4 {Severe}} swelling',
        id: 'indicator.swelling.degree.value'
    }
});
