import base from '../../base';

const gray = {
    primary: base.gray.primary,
    primaryDark: base.gray.primaryDark,
    dark: base.gray.secondaryDark,
    light: base.gray.primaryExtraBright
};

const purple = {
    purple10: '#E3D9F2',
    purple20: '#C7B4E4',
    purple30: '#AB8ED7',
    purple40: '#8F68CA',
    purple50: '#7342BD',
    purple60: '#5C3597',
    purple70: '#512E84',
    purple80: '#3A215E'
};

export default {
    gray,
    ...purple
};
