import { createSelector } from 'reselect';
import { AppState } from '../../store/root-reducer';
import { getSelectedConditionCode } from '../conditions/conditions.selectors';

export const getUser = (state: AppState) => state.user;

// TODO: Fix type? Could return undefined if selected === ''
export const getIndicatorConfigurations = createSelector(
    [getUser, getSelectedConditionCode],
    (user, selected) => user.configurations[selected]
);
