import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { formatMessage, Strings } from '../../locale';
import { ConditionPicker } from '../../components/layout/ConditionPicker';
import { Header } from '../../components/layout/Header';
import { AchievementHandler } from '../../models/achievement';
import { fetchAchievement } from '../../redux/domains/achievements';
import { fetchConditions } from '../../redux/domains/conditions/conditions.actions';
import { resetTiles } from '../../redux/domains/report/report.actions';
import { UserState } from '../../redux/domains/user/user.types';
import { BaseAchievedModal } from '../other/pages/achievements/achieved-modals/BaseAchievedModal';
import { ReportGrid } from './ReportGrid';
import { AppState } from '../../redux/store/root-reducer';

type Props = {
    user: UserState;
    actions: any;
    achievements: AchievementHandler[];
};

type State = {
    showAchievementModal: boolean;
};

class ReportPage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showAchievementModal: false
        };
    }

    componentDidMount() {
        this.props.actions.fetchConditions();
    }

    componentWillUnmount() {
        this.props.actions.resetTiles();
    }

    handleAchievementTrigger = (achievement: any, index: number) => {
        achievement.setCurrentIndex(index);
        this.setState({ showAchievementModal: true });
    };

    afterCSSTransition = () => {
        if (this.props.user.settings.achievements?.confetti.enabled) {
            if (this.props.achievements[0]?.anyAchieved()) {
                return this.handleAchievementTrigger(
                    this.props.achievements[0],
                    this.props.achievements[0].getMajorIndex()
                );
            }
        }
    };

    getAchievementModalDetails(achievement: AchievementHandler) {
        const details = achievement.getAchievementModalDetails();
        const handleOk = () => {
            achievement.resetAchievement();
            this.setState({ showAchievementModal: false });
        };

        return (
            <BaseAchievedModal
                title={details.title}
                body={details.body}
                avatarUrl={details.avatarUrl}
                milestoneType={details.milestoneType}
                handleOnOkAchievement={handleOk}
                showAchievementModal={this.state.showAchievementModal}
            />
        );
    }

    render() {
        return (
            <>
                {this.props.achievements[0] &&
                    this.getAchievementModalDetails(this.props.achievements[0])}
                <Header
                    title={formatMessage(Strings.screen.report.header.title)}
                />
                <ConditionPicker />
                <ReportGrid afterCSSTransition={this.afterCSSTransition} />
            </>
        );
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        user: state.user,
        achievements: state.achievements
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        actions: bindActionCreators(
            {
                fetchConditions,
                fetchAchievement,
                resetTiles
            },
            dispatch
        )
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportPage);
