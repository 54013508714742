import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Icon } from 'antd';
import { formatMessage, Strings } from '../../../locale';
import {
    StyledHeader,
    StyledHeaderContent,
    StyledModal,
    StyledSubtitle,
    StyledTitle
} from '../../../components/ui/modal/StyledModal';
import { useFetchedIndicator } from '../../../hooks/use-fetched-indicator';
import { useIndicator } from '../../../hooks/use-indicator';
import { useReduxSelector } from '../../../hooks/use-redux-selector';
import { IndicatorIconWithBorder } from '../../../components/indicator-icon/IndicatorIcon';
import { selectConditionWithIndicator } from './components/report-utils';

type Props = {
    code: string;
    open: boolean;
};

type Params = { code?: string };

export const ReportModal = () => {
    const dispatch = useDispatch();
    const { params = {} } = useRouteMatch<Params>('/report/:code?') || {};
    // code is needed in state for animation when closing modal
    const [code, setCode] = useState(params.code || '');
    const conditionsState = useReduxSelector((state) => state.conditions);
    const indicator = useIndicator(code);

    useEffect(() => {
        if (params.code) {
            setCode(params.code);

            if (!indicator) {
                selectConditionWithIndicator(
                    dispatch,
                    conditionsState,
                    params.code
                );
            }
        }
    }, [params, conditionsState, indicator, dispatch]);

    if (!indicator) {
        return <></>;
    }

    return <ToggleReportModal code={code} open={code === params.code} />;
};

const ToggleReportModal = ({ code, open }: Props) => {
    const indicator = useFetchedIndicator(code);
    const history = useHistory();
    const Form = indicator?.tile.report?.form;

    if (!indicator || !Form) {
        return <></>;
    }

    return (
        <StyledModal
            title={
                <StyledHeader>
                    <IndicatorIconWithBorder icon={indicator.icon} size={45} />
                    <StyledHeaderContent>
                        <StyledTitle>
                            {formatMessage(indicator.messages.general.title)}
                        </StyledTitle>
                        <StyledSubtitle>
                            {formatMessage(Strings.values.main.report, {
                                isMulti:
                                    indicator.properties ||
                                    indicator?.form?.item?.length
                                        ? 1
                                        : 0
                            })}
                        </StyledSubtitle>
                    </StyledHeaderContent>
                    {indicator.messages?.info && (
                        <Icon
                            onClick={() => {
                                history.replace(`/report/${code}/info`);
                            }}
                            type="info-circle"
                            style={{ fontSize: 30 }}
                        />
                    )}
                </StyledHeader>
            }
            destroyOnClose={true}
            closable={false}
            visible={open}
            footer={null}
        >
            <Form indicator={indicator} />
        </StyledModal>
    );
};
