import moment from 'moment';
import { camelCase, fromPairs } from 'lodash';
import Strings, { formatMessage, hasMessageId } from '../../../../locale';
import {
    meta,
    columns
} from '../../../../locale/messages-descriptors/export/index';
import { Data } from '../../../../types/indicator/state-indicator.type';
import { Value } from '../../../../redux/domains/values/values.types';
import {
    IndicatorCommonMessages,
    IndicatorPropertyMessages
} from '../../../../types/indicator/indicator-messages.type';
import { KeyValues } from '../../../../types/indicator/indicator-value.type';
import { formatNumber } from '../../../../locale/utils/functions/format-number';
import { capitalizeString } from './capitalize-string';

type ExportIndicator = {
    code: string;
    data: Data;
    messages: IndicatorCommonMessages;
    values: Value[];
};

type ExportIndicatorProperties = ExportIndicator & {
    data: {
        jsonSchema: {
            type: 'object';
            properties: {};
        };
    };
    form?: {
        type: 'combined' | 'questionnaire';
    };
    messages: IndicatorPropertyMessages;
    values: Value<KeyValues>[];
};

function isExportIndicatorProperties(
    indicator: ExportIndicator
): indicator is ExportIndicatorProperties {
    return indicator.data.jsonSchema.type === 'object';
}

export function getMeta(indicator: ExportIndicator) {
    return [
        formatMessage(meta.indicator),
        formatMessage(indicator.messages.general.title)
    ];
}

export function getColumns(indicator: ExportIndicator) {
    const columns = [
        getColumn('date'),
        getColumn('time'),
        ...getValueColumns(indicator)
    ];

    const isQuestionnaire =
        isExportIndicatorProperties(indicator) &&
        indicator.form?.type === 'questionnaire';

    if (!isQuestionnaire) {
        columns.push(getColumn('comment'));
    }

    return columns;
}

export function getDatas(indicator: ExportIndicator) {
    return getFormattedValues(indicator)
        .map(({ date, comment = '', ...value }) => ({
            date: moment(date).format('YYYY-MM-DD'),
            time: moment(date).format('HH:mm:ss'),
            ...value,
            comment
        }))
        .reverse();
}

const getColumn = (id: keyof typeof columns) => ({
    id,
    displayName: formatMessage(columns[id])
});

function getValueColumns(indicator: ExportIndicator) {
    if (isExportIndicatorProperties(indicator)) {
        return Object.keys(indicator.data.jsonSchema.properties)
            .concat(indicator.form?.type === 'questionnaire' ? 'sum' : [])
            .map((prop) => ({
                id: `value.${prop}`,
                displayName: formatValueColumnTitle(indicator, prop) || prop
            }));
    }

    return [
        {
            id: 'value',
            displayName: formatValueColumnTitle(indicator)
        }
    ];
}

function getFormattedValues(indicator: ExportIndicator) {
    if (isExportIndicatorProperties(indicator)) {
        const values: Value<KeyValues>[] = indicator.values;

        return values.map(({ date, value, comment }) => ({
            date,
            ...fromPairs(
                Object.keys(value).map((prop) => [
                    `value.${prop}`,
                    formatValueData(indicator, value[prop], prop)
                ])
            ),
            comment
        }));
    }

    return indicator.values.map(({ date, value, comment }) => ({
        date,
        value: formatValueData(indicator, value),
        comment
    }));
}

function formatValueColumnTitle(indicator: ExportIndicator, prop?: string) {
    const messages =
        prop && isExportIndicatorProperties(indicator)
            ? indicator.messages[camelCase(prop)] || {}
            : indicator.messages.general;

    const title = messages.title ||
        messages.question || {
            id: `indicator.${indicator.code.toLowerCase()}.${prop}.title`,
            defaultMessage: prop
        };
    const defaultUnit =
        indicator.data.jsonSchema.type === 'object'
            ? ''
            : indicator.data.unitOfMeasure;

    const unit = messages.unit
        ? formatMessage(messages.unit, { value: 0 }).trim()
        : defaultUnit;

    return `${formatMessage(title)}${unit ? ` (${unit})` : ''}`;
}

function formatValueData(
    indicator: ExportIndicator,
    value: any,
    prop?: string
) {
    const messages =
        prop && isExportIndicatorProperties(indicator)
            ? indicator.messages[camelCase(prop)]
            : indicator.messages.general;

    const valueMessage = messages?.value;

    if (valueMessage) {
        return capitalizeString(formatMessage(valueMessage, { value }));
    }

    if (typeof value === 'object') {
        value = Object.values(value);
    }

    if (prop && (typeof value === 'boolean' || Math.round(value) == value)) {
        const id = `indicator.${indicator.code.toLowerCase()}.${prop}.choices.${value}`;
        if (hasMessageId(id)) {
            return formatMessage({ id });
        }
    }

    if (typeof value === 'boolean') {
        return formatMessage(Strings.common.text[value ? 'true' : 'false']);
    }

    if (typeof value === 'number') {
        return `${formatNumber(value)}`;
    }

    return value + '';
}
