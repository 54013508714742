import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createLoadingSelector } from '../../../../redux/domains/requests/requests.selectors';
import { fetchAchievement } from '../../../../redux/domains/achievements';
import { ACHIEVEMENTS_FETCH } from '../../../../redux/domains/achievements/achievements.constants';
import { ContentLayout } from '../../../../components/layout/ContentLayout';
import { AchievementPageList } from './AchievementPageList';
import { useReduxSelector } from '../../../../hooks/use-redux-selector';
import { Loading } from '../../../../components/loading/Loading';
import { Header } from '../../../../components/layout/Header';
import { formatMessage } from '../../../../locale';
import { achievements as texts } from './message-descriptors';
import { routePaths } from '../../../../constants/route-paths';

export const AchievementsPage = () => {
    const dispatch = useDispatch();
    const achievements = useReduxSelector((state) => state.achievements);
    const loadingSelector = createLoadingSelector([ACHIEVEMENTS_FETCH]);
    const isLoading = useSelector(loadingSelector);

    useEffect(() => {
        dispatch(fetchAchievement());
    }, [dispatch]);

    if (isLoading) return <Loading />;

    return (
        <>
            <Header
                backButtonPath={routePaths.other}
                title={formatMessage(texts.header.title)}
            />
            <ContentLayout>
                <AchievementPageList achievements={achievements} />
            </ContentLayout>
        </>
    );
};
