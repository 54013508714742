import React from 'react';
import { formatMessageById as ti } from '../../../../../locale';
import { StyledLabel } from '../../../../../components/list/StyledLabel';
import { StyledListItem } from '../../../../../components/list/StyledListItem';
import { StyledSwitch } from '../../../../../components/list/StyledSwitch';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserSettings } from '../../../../../redux/domains/user/user.actions';
import { UserSettingsType } from '../../../../../redux/domains/user/user.settings.types';
import { UserState } from '../../../../../redux/domains/user/user.types';

type Props = {
    category: string;
    property: string;
};

type State = { user: UserState };

const changeEnabledSetting = (
    settings: UserSettingsType,
    domain: string,
    property: string,
    enabled: boolean
) => {
    return {
        ...settings,
        [domain]: {
            [property]: {
                enabled: enabled
            }
        }
    };
};

export const SettingsListItem = (props: Props) => {
    const dispatch = useDispatch();
    const user = useSelector((state: State) => state.user);

    let settings = user.settings;
    const { category, property } = props;

    const changeSwitchSetting = (
        category: string,
        property: string,
        enabled: boolean
    ) => {
        settings = changeEnabledSetting(settings, category, property, enabled);
        dispatch(updateUserSettings(user.subjectId, settings));
    };

    const label = ti(`user-settings.${category}.${property}.enabled.label`);

    return (
        <StyledListItem>
            <StyledLabel>{label}</StyledLabel>
            <StyledSwitch
                defaultChecked={settings[category][property].enabled}
                onChange={() => {
                    changeSwitchSetting(
                        category,
                        property,
                        !settings[category][property].enabled
                    );
                }}
            />
        </StyledListItem>
    );
};
