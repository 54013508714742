import { defineMessages } from 'react-intl';

export const bmi = defineMessages({
    title: {
        defaultMessage: 'BMI',
        id: 'indicator.bmi.bmi.title'
    },
    value: {
        defaultMessage: '{value, number, ::.0}',
        id: 'indicator.bmi.bmi.value'
    },
    unit: {
        defaultMessage: '',
        id: 'indicator.bmi.bmi.unit'
    }
});
