import { Button } from 'antd';
import { formatMessage, Strings } from '../../../locale';
import React from 'react';
import { useReduxSelector } from '../../../hooks/use-redux-selector';
import { styled } from '../../../theme/styled';

type FormattedContentProps = { content: string; backgroundColor: string };

function VideoMeetingButton(props: { href: string }) {
    const sessionId = props.href.split('/')[1];

    const active = useReduxSelector(
        (state) => state.chat.videoMeetings[sessionId]?.active || false
    );
    const { activeSessions } = useReduxSelector((state) => state.video);

    if (active == null && activeSessions == null) {
        return (
            <Button loading>{formatMessage(Strings.video.loading.text)}</Button>
        );
    }

    if (active) {
        return (
            <Button {...props}>{formatMessage(Strings.video.open.text)}</Button>
        );
    }

    return <Button disabled>{formatMessage(Strings.video.closed.text)}</Button>;
}

function formatLinks(content: string) {
    const urlRegex =
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    const contentArray: string[] = content.split(urlRegex);

    return contentArray.map((item, index) => {
        if (urlRegex.test(item)) {
            return (
                <StyledMessageLink
                    key={index}
                    href={!item.startsWith('http') ? '//' + item : item}
                >
                    {item}
                </StyledMessageLink>
            );
        }
        return item;
    });
}

const FormattedContent = ({
    content,
    backgroundColor
}: FormattedContentProps): JSX.Element => {
    if (content.startsWith('#video/')) {
        return <VideoMeetingButton href={content} />;
    }

    return (
        <MessageBubble $backgroundColor={backgroundColor}>
            {formatLinks(content)}
        </MessageBubble>
    );
};

const MessageBubble = styled.div<{
    $backgroundColor: string;
}>`
    display: inline-block;
    border-radius: 15px;
    padding: 5px 15px;
    background-color: ${(props) => props.$backgroundColor};
    color: ${(props) => props.theme.chat.message.color};
    overflow: hidden;
    font-size: 14px;
`;

const StyledMessageLink = styled.a`
    color: white;
    text-decoration: underline;
`;

export { FormattedContent };
