import { defineMessages } from 'react-intl';

export const medication = defineMessages({
    question: {
        defaultMessage: 'Type of medicine',
        id: 'indicator.prnmed.medication.description'
    },
    value: {
        defaultMessage:
            '{value, select, ' +
            'analgesic {painkiller} ' +
            'antibiotic {antibiotics} ' +
            'betablocker {betablocker} ' +
            'bronchodilator {bronchodilator} ' +
            'cortisone {cortisone} ' +
            'diuretic {diuretic} ' +
            'nitroglycerine {nitroglycerine} ' +
            'other {{value}}' +
            '}',
        id: 'indicator.prnmed.medication.value'
    }
});
