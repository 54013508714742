import { styled } from '../../../../theme/styled';
import { Modal } from 'antd';

export const StyledModal = styled(Modal)`
    && {
        top: 0;
        padding: 0;
        margin: 0;
        overflow: hidden;
        z-index: 2000;
        max-width: 100%;
        height: 100%;
        width: 100%;
        flex: 1;
        -webkit-user-select: none;

        @media (orientation: landscape) and (min-width: 1024px) and (min-height: 640px) {
            && {
                height: 70vh;
                max-width: 520px; // Use same width as ant design
            }
        }
    }

    .ant-modal-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        border-radius: 0;
        border-top: 0;
    }

    .ant-modal-body {
        background-color: rgba(230, 236, 240, 0.24);
        -webkit-overflow-scrolling: touch;

        padding: 10px;
        flex-grow: 1;

        overflow: auto;
        height: 75%;
    }

    .ant-modal-footer {
        padding-bottom: calc(10px + env(safe-area-inset-bottom)) !important;
    }

    .ant-form {
        flex-grow: 1;
    }

    .ant-form-item-control {
        text-align: center;
    }

    .ant-form-explain {
        margin: 5px 0px 10px 0;
    }

    .radio-group-form {
        .ant-form-item {
            margin-bottom: 0;
        }

        .ant-radio-wrapper {
            display: block;
            margin: 10px 0;

            span {
                display: inline-block;
                widows: 100%;
                white-space: normal;
                font-size: 13px;
            }

            .ant-radio {
                margin-top: 5px;
                vertical-align: top;
            }
        }

        .ant-radio-group {
            width: 100%;
        }
    }

    .questionnaire-form {
        p {
            margin-bottom: 20px;
            white-space: pre-line;
        }

        p:last-of-type {
            margin: 0;
        }

        .ant-card {
            &.active {
            }

            &.hidden {
                display: none;
            }

            h2 {
                margin-bottom: 0;
                font-size: 18px;
            }

            .ant-form-item {
                margin-bottom: 0;
            }

            .ant-form-item-control {
                text-align: left;

                .ant-calendar-picker {
                    width: 100% !important;
                }
            }

            .ant-radio-wrapper {
                display: block;
                margin: 10px 0;

                span {
                    display: inline-block;
                    widows: 100%;
                    white-space: normal;
                }

                .ant-radio {
                    margin-top: 5px;
                    vertical-align: top;
                }
            }

            .ant-radio-group {
                width: 100%;
            }

            .slider-box {
                margin: 0;
                margin-top: 20px;

                .text-wrapper {
                    min-height: 0;
                }

                .ant-slider-with-marks {
                    margin-bottom: 0px;
                }

                .ant-slider-mark {
                    position: static;
                    top: unset;
                    left: unset;
                    margin-top: 36px;
                    display: flex;
                    justify-content: space-between;

                    .ant-slider-mark-text {
                        position: static;
                        color: inherit;
                        font-size: 12px;
                    }
                }
            }
        }
    }

    .button-container {
        text-align: center;

        div {
            margin: 15px 0 5px 0;
        }

        button {
            margin: 0 10px;
            min-width: 100px;
        }
    }

    .vertical-center-modal {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .ant-modal-footer {
        padding-bottom: calc(10px + env(safe-area-inset-bottom)) !important;
    }
`;

export const StyledModalHeader = styled.div`
    display: flex;
    align-items: center;

    img {
        position: absolute;
        left: -10px;
        width: 45px;
    }

    p {
        font-size: 1.1rem;
        font-weight: 400;
        color: #2a2a2a;
        padding: 0;
        margin: 0;

        span {
            font-size: 1.1rem;
            font-weight: 400;
            color: #2a2a2a;
            padding: 0;
            margin: 0;
        }
    }

    span {
        padding: 0;
        margin: 0;
        font-size: 0.9rem;
        font-weight: 500;
        color: gray;
        text-transform: none;
    }

    //IE 11 fix.
    div {
        height: 100%;
        div {
            height: 100%;
        }
    }
`;
