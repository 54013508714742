import { defineMessages } from 'react-intl';

export const mobility = defineMessages({
    title: {
        defaultMessage: 'Your mobility TODAY',
        id: 'indicator.eq5d3l.mobility.title'
    },
    choices: {
        defaultMessage:
            '{value, select, 1 {I have no problems in walking about} 2 {I have some problems in walking about} 3 {I am confined to bed}}',
        id: 'indicator.eq5d3l.mobility.choices'
    }
});
