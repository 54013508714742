// Layout colors
// lowContrastNeutral - Formfields, sidebars etc "containers    "
// brightHighContrastBackground "blacks and whites"
// alter! and 2 - crosses lowContrast -saves colros. Subtitles, bodytexts
//
//
//
// cognitive overhead "noise"
//
// interactive hoover state, touch state etc
const base = {
    primary: '#8B67CE',
    extraBright: '#FFFFFF',
    bright: '#F9F9F9',
    dark: '#323232', // #2f374a // #323232
    extraDark: '#1A1A1A', // #2f374a // #323232
    brightBackground: '#E9E9E9', // #e1e1e1bf // #ccc
    darkBackground: '#232323',
    alter1: '#5D6568', // colors.ui.title.primary
    alter2: '#C9C9C9' // colors.ui.alert.primarySoft // #c1c1c5
};

const denote = {
    error: '#E8462C',
    success: '#76DCA0',
    brightDisabled: '#8F8F8F', // #999999 // #d3d3d3
    darkDisabled: '#5D6568', // #545454
    yellowBright: '#fcf8e3',
    good: '#82fb6e',
    bad: '#fbd859',
    neutral: '#82fb6e'
};

const interactive = {
    usability_negative_2: '#033C7F',
    usability_negative_1: '#0A5DBD',
    usability_neutral_0: '#2c8eff',
    usability_positive_1: '#4F94E4',
    usability_positive_2: '#71A9E9' // #91d5ff // #91d5ff
};

const interactiveYellow = {
    usability_negative_2: '#ffe65a',
    usability_negative_1: '#ffec81',
    usability_neutral_0: '#fff2a8',
    usability_positive_1: '#fff8cf',
    usability_positive_2: '#fffef6'
};

const achievementCard = {
    background: '#fcfaf7'
};
const brightProfile = {
    layout: {
        background: base.brightBackground,
        primary: base.primary,
        brightLowContrastNeutral: base.alter2,
        brightHighContrastBackground: base.darkBackground,
        subtitles: base.alter1,
        subtitles_alter: base.alter2
    },
    denote: {
        error: denote.error,
        success: denote.success,
        disabled: denote.brightDisabled, // #999999 // #d3d3d3
        yellowBright: denote.yellowBright,
        good: denote.good,
        bad: denote.bad,
        neutral: denote.neutral
    },
    interactive,
    interactiveYellow,
    achievementCard,
    bright: base.bright,
    dark: base.dark,
    base
};

export default brightProfile;
