import { ThunkAction } from 'redux-thunk';
import { AppState } from '../../store/root-reducer';
import { FetchUserAction } from './user.types';
import { fetchUser } from './user.actions';

export const fetchUserThunk =
    (): ThunkAction<void, AppState, null, FetchUserAction> =>
    (dispatch, getState) => {
        const { subjectId } = getState().user;
        return dispatch(fetchUser(subjectId));
    };
