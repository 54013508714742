import { defineMessages } from 'react-intl';

export const general = defineMessages({
    title: {
        defaultMessage: 'Pump speed',
        id: 'indicator.pump-speed-lvad.title'
    },
    value: {
        defaultMessage: '{value, number}',
        id: 'indicator.pump-speed-lvad.value'
    },
    unit: {
        id: 'indicator.pump-speed-lvad.unit',
        defaultMessage: 'rpm'
    }
});
