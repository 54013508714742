import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List } from 'antd';
import { styled } from '../../../../theme/styled';
import { createLoadingSelector } from '../../../../redux/domains/requests/requests.selectors';
import { fetchConditions } from '../../../../redux/domains/conditions/conditions.actions';
import { fetchReminders } from '../../../../redux/domains/reminders/reminders.actions';
import { CONDITIONS_FETCH } from '../../../../redux/domains/conditions/conditions.constants';
import { REMINDERS_FETCH } from '../../../../redux/domains/reminders/reminders.constants';
import { RecommendationsCard } from '../../../../components/recommendations/Card';
import { useCondition } from '../../../../hooks/use-condition';
import { useReduxSelector } from '../../../../hooks/use-redux-selector';
import { ContentSwipeLayout } from '../../../../components/layout/ContentSwipeLayout';
import { ConditionPicker } from '../../../../components/layout/ConditionPicker';
import { Loading } from '../../../../components/loading/Loading';
import { Header } from '../../../../components/layout/Header';
import { formatMessage } from '../../../../locale';
import { recommendations } from './message-descriptors';
import { routePaths } from '../../../../constants/route-paths';

export const RecommendationPage = (): JSX.Element => {
    const dispatch = useDispatch();
    const condition = useCondition();
    const reminders = useReduxSelector(
        (state) => state.reminders.byIndicatorId
    );
    const loadingSelector = createLoadingSelector([
        CONDITIONS_FETCH,
        REMINDERS_FETCH
    ]);
    const isLoading = useSelector(loadingSelector);

    useEffect(() => {
        dispatch(fetchConditions());
        dispatch(fetchReminders());
    }, [dispatch]);

    if (isLoading) return <Loading />;

    return (
        <>
            <Header
                backButtonPath={routePaths.other}
                title={formatMessage(recommendations.header.title)}
            />
            <ConditionPicker />
            <ContentSwipeLayout>
                <RecommendationsList>
                    {condition.indicators.map((indicator) => {
                        return (
                            <RecommendationsCard
                                key={indicator.code}
                                code={indicator.code}
                                reminders={reminders}
                            />
                        );
                    })}
                </RecommendationsList>
            </ContentSwipeLayout>
        </>
    );
};

const RecommendationsList = styled(List)`
    && .ant-list-item {
        border: none;
        padding: 8px 4px 4px;
    }
`;
