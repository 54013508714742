import { defineMessages } from 'react-intl';

const general = defineMessages({
    title: {
        id: 'indicator.what-if-i-get-worse.title',
        defaultMessage: 'What if I get worse?'
    },
    question: {
        id: 'indicator.what-if-i-get-worse.question',
        defaultMessage:
            'If you get worse and the treatment plan is not enough you should contact your health care provider.'
    },
    answer: {
        id: 'indicator.what-if-i-get-worse.answer',
        defaultMessage:
            'You should have an individual treatment plan. If you feel unsecure or if you have questions regarding the plan please contact your health care provider.'
    },
    link: {
        id: 'indicator.what-if-i-get-worse.link',
        defaultMessage: "Link to SKL's general treatment plan"
    },
    treatmentPlan: {
        id: 'indicator.what-if-i-get-worse.treatment-plan',
        defaultMessage:
            'https://webbutik.skl.se/bilder/artiklar/pdf/5444.pdf?issuusl=ignore'
    }
});

export default {
    general
};
