import React from 'react';
import { formatMessage } from '../../../../locale';
import { ReccomendationRow } from '../RecommendationRow';
import { FetchedIndicator } from '../../../../types/indicator/fetched-indicator.type';
import { Limits } from '../../../../types/indicator/state-indicator.type';
import { IndicatorPropertyMessages } from '../../../../types/indicator/indicator-messages.type';
import { Property } from '../../../../types/indicator/indicator.type';
import { recommendation } from '../../../../conditions/left-ventricular-assist-device/indicators/blood-pressure-average/messages/recommendation';
import { MarkdownFromDescriptor } from '../../../../locale/utils/functions/format-markdown';
import { gradient } from './styled';

const getLowerLimit = (configuration: Limits) => {
    return (
        configuration.lower_yellow ||
        configuration.lower_red ||
        configuration.report_min ||
        0
    );
};

const getUpperLimit = (configuration: Limits) => {
    return (
        configuration.upper_yellow ||
        configuration.upper_red ||
        configuration.report_max ||
        0
    );
};

export const RecommendedObservation = (props: {
    property?: Property;
    indicator: FetchedIndicator;
    configuration: Limits;
}): JSX.Element => {
    const property = props.property;
    const indicator = props.indicator;
    const configuration = props.configuration;

    const min = getLowerLimit(configuration);
    const max = getUpperLimit(configuration);
    const range = [min, max];

    const messages = property
        ? (indicator.messages as IndicatorPropertyMessages<Property>)[property]
        : indicator.messages.general;
    const valueMessage = messages?.value;
    const unitMessage = messages?.unit;

    const endash = '–';
    const nbsp = String.fromCharCode(0xa0);

    const formattedRange = (
        valueMessage
            ? range.map((value) => formatMessage(valueMessage, { value }))
            : range
    ).join(endash);

    const unit = unitMessage
        ? formatMessage(unitMessage, { value: max }).trim()
        : indicator.data.unitOfMeasure;

    const limitsMessage =
        (messages && 'limits' in messages && messages.limits) ||
        indicator.messages.recommendation?.limits ||
        recommendation.limits;

    return (
        <ReccomendationRow background={gradient}>
            <MarkdownFromDescriptor
                messageDescriptor={limitsMessage}
                values={{
                    recommendedMin: min,
                    recommendedMax: max,
                    range: unit
                        ? `${formattedRange}${nbsp}${unit}`
                        : formattedRange
                }}
                paragraph="span"
            />
        </ReccomendationRow>
    );
};
