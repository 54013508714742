import React from 'react';

import { SplineSeries } from 'react-jsx-highcharts';

import {
    IndicatorPropertyRules,
    LineColor
} from '../../../../../../types/indicator/indicator-rules.type';

const PropertyChart = (props: {
    values: [];
    lineColors: LineColor<string>;
    getSerieName: () => string;
    rules: any;
    property: string;
    roundNumber: (n1: number, n2: number) => void;
}) => {
    const { values, lineColors, getSerieName, rules, property, roundNumber } =
        props;
    const { decimals } = rules as IndicatorPropertyRules<string>;

    const data = values.map(({ date, value }: any) => {
        if (decimals && decimals[property] != null) {
            value = roundNumber(value[property], decimals[property]);
        } else {
            value = value[property];
        }

        return [date, value];
    });

    return (
        <SplineSeries
            data={data}
            name={getSerieName()}
            color={lineColors.default}
            onLegendItemClick={() => false}
        />
    );
};

export { PropertyChart };
