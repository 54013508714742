import { defineMessages } from 'react-intl';

export const general = defineMessages({
    title: {
        defaultMessage: 'Grip strength',
        id: 'indicator.grip-hs.title'
    },
    description: {
        defaultMessage: 'How is your grip strength?',
        id: 'indicator.grip-hs.description'
    },
    minValueDescription: {
        defaultMessage: 'No strength',
        id: 'indicator.grip-hs.min-value-description'
    },
    maxValueDescription: {
        defaultMessage: 'Normal strength',
        id: 'indicator.grip-hs.max-value-description'
    },
    value: {
        defaultMessage: '{value}',
        id: 'indicator.grip-hs.value'
    }
});
