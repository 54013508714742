import { defineMessages } from 'react-intl';

export const general = defineMessages({
    title: {
        defaultMessage: 'CAT',
        id: 'indicator.cat.title'
    },
    titleLong: {
        defaultMessage: 'COPD Assessment Test',
        id: 'indicator.cat.title-long'
    },
    description: {
        defaultMessage:
            'This questionnaire will help you and your healthcare professional measure the impact COPD (Chronic Obstructive Pulmonary Disease) is having on your wellbeing and daily life.\n\nYour answers and test score, can be used by you and your healthcare professional to help improve the management of your COPD and get the greatest benefit from treatment.',
        id: 'indicator.cat.description'
    },
    helpText: {
        defaultMessage:
            'Please indicate your answer on each of the following questions by moving the slider to select the number that best describes your current situation',
        id: 'indicator.cat.help-text'
    },
    legalText: {
        defaultMessage:
            'The COPD Assessment Test was developed by a multi-disciplinary group of international experts in COPD supported by GSK. GSK activities with respect to the COPD Assessment Test are overseen by a governance board that includes independent external experts, one of whom chairs the board.',
        id: 'indicator.cat.legal-text'
    },
    value: {
        defaultMessage: 'Sum {sum}',
        id: 'indicator.cat.value'
    },
    copyright: {
        defaultMessage:
            'COPD Assessment Test and the CAT logo is a trademark of the GlaxoSmithKline group of companies. © 2009 GlaxoSmithKline. All rights reserved.',
        id: 'indicator.cat.copyright'
    }
});
