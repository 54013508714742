import { defineMessages } from 'react-intl';

const livsmedelsverketHref =
    'https://www.livsmedelsverket.se/matvanor-halsa--miljo/kostrad-och-matvanor/naringsrekommendationer';

const kunskapsguidenHref =
    'http://www.kunskapsguiden.se/aldre/Teman/Kostochnutrition/Sidor/default.aspx';

export const info = defineMessages({
    main: {
        id: 'indicator.appetite.lp_2_0_copd_rs.info.main',
        defaultMessage:
            'Maten vi äter innehåller många olika näringsämnen som alla ' +
            'påverkar kroppens funktioner och därmed också hälsan och ' +
            'välbefinnandet. ' +
            'Därför är det viktigt att vara noga med maten och att äta ' +
            'näringsriktigt. Det är bra att äta flera små måltider ' +
            'spridda över dagen. ' +
            'Man kan bli både trött och andfådd om man äter mycket på en gång. ' +
            'Frukost, lunch, middag samt 3-4 mellanmål behövs för att ' +
            'tillgodose energibehovet. ' +
            'För att bibehålla den värdefulla energibalansen är det bra ' +
            'att äta ett litet mål mat innan läggdags. ' +
            'Om man är utan mat för många timmar på dygnet så kan det ' +
            'medverka till viktförlust och att kroppens muskelmassa bryts ned. ' +
            'Nattfastan ska helst inte överstiga 11 timmar. ' +
            'Tänk också på att stimulera aptiten genom att vara ute i friska ' +
            'luften och röra på dig. \n\n' +
            '' +
            'För mer information se:  \n' +
            `[Livsmedelsverket](${livsmedelsverketHref})  \n` +
            `[Kunskapsguiden](${kunskapsguidenHref})`
    }
});
