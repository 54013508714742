import NotificationDeleteButton from './NotificationDeleteButton';
import { styled } from '../../theme/styled';
import React from 'react';

export interface Props {
    deleteNotifications: () => void;
}

export const NotificationsHeader = ({ deleteNotifications }: Props) => {
    return (
        <StyledGroupHeader>
            <NotificationDeleteButton
                deleteNotifications={deleteNotifications}
            />
        </StyledGroupHeader>
    );
};

const StyledGroupHeader = styled.div`
    padding: 5px 10px;
    color: #76728e;
    display: flex;
    justify-content: flex-end;
    align-items: right;
    height: 40px;
`;
