import { useSelector } from 'react-redux';
import { getIndicatorsById } from '../redux/domains/conditions/conditions.selectors';

export const useIndicatorCode = (id: string) => {
    const indicatorsById = useSelector(getIndicatorsById);
    const indicator = indicatorsById[id];
    if (!indicator) {
        return 'unknown';
    }
    return indicator.code;
};
