import { defineMessages } from 'react-intl';

const main = defineMessages({
    title: {
        id: 'App.AboutPage.Support.Title',
        defaultMessage: 'Support'
    },
    contactMedic: {
        id: 'App.AboutPage.Support.ContactMedic',
        defaultMessage:
            'For medical questions, please contact your healthcare provider or call 1177'
    },
    technicalDescription: {
        id: 'App.AboutPage.Support.support.technical.description',
        defaultMessage: 'For technical support about LifePod'
    },
    contactEmergency: {
        id: 'App.AboutPage.Support.ContactEmergency',
        defaultMessage: 'In case of emergency, Call 112'
    },
    emailSubject: {
        id: 'App.AboutPage.Support.EmailSubject',
        defaultMessage: 'LifePod Patient Support'
    },
    callSupport: {
        id: 'App.AboutPage.Support.CallSupport',
        defaultMessage: 'Call Support'
    },
    emailSupport: {
        id: 'App.AboutPage.Support.EmailSupport',
        defaultMessage: 'E-mail Support'
    }
});

export default {
    main
};
