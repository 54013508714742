import { defineMessages } from 'react-intl';

export const general = defineMessages({
    title: {
        defaultMessage: 'Blood sugar',
        id: 'indicator.blood-sugar.title'
    },
    value: {
        defaultMessage: '{value}',
        id: 'indicator.blood-sugar.value'
    },
    unit: {
        id: 'indicator.blood-sugar.unit',
        defaultMessage: 'mmol/l'
    }
});
