import React from 'react';
import { List } from 'antd';
import { styled } from '../../../theme/styled';
import IndicatorListItem from './IndicatorListItem';
import { Indicator } from '../../../types/indicator/indicator.type';
import { useCondition } from '../../../hooks/use-condition';

export const IndicatorList = (): JSX.Element => {
    const condition = useCondition();
    const indicators = condition.indicators.filter(
        (indicator) => !indicator.hiddenStatistics
    );

    return (
        <StyledIndicatorList
            dataSource={indicators}
            renderItem={(indicator: Indicator) => {
                return <IndicatorListItem code={indicator.code} />;
            }}
        />
    );
};

const StyledIndicatorList: any = styled(List)`
    margin-bottom: 2px;
    border-bottom: 1px solid #e8e8e8;
`;
