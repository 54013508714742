export const getLastInList = (
    list: Array<any>,
    defaultValue: number | null = 0
): any => {
    if (list != null) {
        return list[list.length - 1];
    }
    return defaultValue;
};

const percentageOf = (val1: number, val2: number): number => {
    return val1 / val2;
};

const ifNotFirstMilestone = (
    calculation: calculationType,
    minorStones: Array<number>,
    majorIndex: number,
    mileStoneList: Array<Array<number>>
) => {
    let maxMinorMilestoneValue = getLastInList(minorStones);

    if (majorIndex > 0) {
        const x = getLastInList(mileStoneList[majorIndex - 1]);
        calculation.current = calculation.current - x;
        maxMinorMilestoneValue = maxMinorMilestoneValue - x;
    }
    return maxMinorMilestoneValue;
};

const ifAchievementValueAboveMilestone = (calculation: calculationType) => {
    if (calculation.percent > 1) {
        calculation.percent = 1;
        calculation.current = calculation.max;
    }
};

const ifAchievementValueEqualMilestone = (calculation: calculationType) => {
    if (calculation.percent === 1) {
        calculation.percent = 1;
        calculation.current = calculation.max;
    }
};

type calculationType = {
    percent: number;
    current: number;
    max: number;
    milestoneCompleted: boolean;
    achievementCompleted: boolean;
    achievementFinished: boolean;
};

export function getAchievementCalculation(
    achievementValue: number,
    majorIndex: number,
    mileStoneList: Array<Array<number>>,
    minorStones: Array<number>
) {
    const calculation: calculationType = {
        percent: 0,
        current: achievementValue,
        max: 0,
        milestoneCompleted: false,
        achievementCompleted: false,
        achievementFinished: false
    };

    const lastOfAchievements = getLastInList(getLastInList(mileStoneList));

    calculation.max = ifNotFirstMilestone(
        calculation,
        minorStones,
        majorIndex,
        mileStoneList
    );
    calculation.percent = percentageOf(calculation.current, calculation.max);
    ifAchievementValueEqualMilestone(calculation);
    ifAchievementValueAboveMilestone(calculation);
    calculation.percent = calculation.percent * 100;

    if (calculation.current === calculation.max) {
        calculation.milestoneCompleted = true;
        calculation.percent = 100;
    }

    if (calculation.current > calculation.max) {
        calculation.milestoneCompleted = true;

        calculation.percent = 100;
    }

    if (achievementValue === lastOfAchievements) {
        calculation.achievementCompleted = true;

        calculation.percent = 100;
    }

    if (achievementValue > lastOfAchievements) {
        calculation.achievementCompleted = true;
        calculation.milestoneCompleted = false;
        calculation.achievementFinished = true;

        calculation.percent = 100;
    }
    return calculation;
}
