import { Indicator } from '../../../types/indicator/indicator.type';
import messages from './messages';
import indicatorRules from './dizziness-rules';
import icon from './images/dizziness.svg';
import { TileType, FeedbackType } from '../../../types/indicator/report.type';
import { RadioGroupForm } from '../../../app/report/forms/radio/RadioGroupForm';

export const dizziness: Indicator<typeof indicatorRules> = {
    code: 'DIZZINESS',
    icon,
    messages,
    hiddenHistory: false,
    hiddenStatistics: false,
    recommendations: {
        limits: {
            enabled: false
        },
        reminder: {
            moreOften: true,
            enabled: true
        },
        info: {
            enabled: false
        }
    },
    rules: indicatorRules,
    tile: {
        report: {
            form: RadioGroupForm,
            type: TileType.Dimming,
            feedback: FeedbackType.Checkmark
        }
    }
};
