import { styled } from '../../../../theme/styled';

export const StyledTile = styled.div<{ bgColor?: string }>`
    && {
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 16px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
        transition: all 0.18s ease-in-out;
        padding: 6.67%;
        align-items: center;
        background-color: ${(props) =>
            props.bgColor ? props.bgColor : '#2c8eff'};
        overflow: hidden;

        &.was-reported {
            background-color: #fff;
            transition: all 0.5s ease;
        }

        &.loading {
            animation: pulse-background 1s ease-in-out infinite;
        }

        &.has-reported {
            background-color: #8ebff8;
        }

        &:hover,
        &:active {
            cursor: pointer;
            background-color: darken(#2c8eff, 5%);
        }

        .indicator-bar {
            width: 100%;
            height: 20px;
            border-radius: 4px;
            border-left: 1px solid rgba(0, 0, 0, 0.16);
            border-bottom: 1px solid rgba(0, 0, 0, 0.16);
            border-right: 1px solid rgba(0, 0, 0, 0.16);
            padding-left: 7px;
            padding-right: 7px;
            position: absolute;
            bottom: -21px;
            background: rgba(210, 210, 210, 0.04);
            left: 0;
        }
    }
`;

export const StyledLabel = styled.div`
    color: white;
`;
