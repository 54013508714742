import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { resetFeedback } from '../../../../redux/domains/report/report.actions';
import { StyledSmileyWrapper } from './StyledIndicatorTile';
import { Smiley } from './Smiley';
import { FetchedIndicator } from '../../../../types/indicator/fetched-indicator.type';
import { getSelectedCondition } from '../../../../redux/domains/conditions/conditions.selectors';

type Props = {
    indicator: FetchedIndicator;
    afterCSSTransition: any;
};

export const Feedback = ({ indicator, afterCSSTransition }: Props) => {
    const { code } = indicator;
    const conditionState = useSelector(getSelectedCondition);
    const dispatch = useDispatch();

    const { feedback } = conditionState.tiles[code];

    return (
        <CSSTransition
            in={!!feedback}
            timeout={{
                enter: 2000,
                exit: 1000
            }}
            classNames={{
                enterActive: 'show-feedback',
                exitActive: 'hide-feedback'
            }}
            unmountOnExit
            onEntered={() => {
                dispatch(resetFeedback(code));
            }}
            onExited={afterCSSTransition}
        >
            <StyledSmileyWrapper>
                <Smiley feedback={feedback} />
            </StyledSmileyWrapper>
        </CSSTransition>
    );
};
