import React from 'react';
import { formatMessage } from '../../../../locale';
import { SettingsPage } from './SettingsPage';
import { StyledIcon } from '../../../../components/list/StyledIcon';
import { testSelectors } from '../../../../constants/test-id';
import { userSettings } from './message-descriptors';
import { RouteType } from '../../../../routes/route.type';
import { routePaths } from '../../../../constants/route-paths';

export const settingsRoute: RouteType = {
    component: SettingsPage,
    title: formatMessage(userSettings.header.title),
    path: routePaths.settings,
    icon: <StyledIcon type="setting" />,
    testId: testSelectors.nav.userSettingsItem
};
