import React, { Dispatch, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IndicatorIconWithBorder } from '../../../components/indicator-icon/IndicatorIcon';
import moment from 'moment';
import { formatMessage, Strings } from '../../../locale';
import { Modal } from 'antd';
import { ButtonLargeRound } from '../../../components/ui/buttons/ButtonLarge';
import { useFetchedIndicator } from '../../../hooks/use-fetched-indicator';
import { IndicatorValue } from '../../../types/indicator/indicator-value.type';
import { formatValue } from '../format-value';
import { removeValue } from '../../../redux/domains/values/values.actions';
import {
    StyledDataContainer,
    StyledDataItem,
    StyledImg,
    StyledRemoveButtonAndTimestamp,
    StyledRow,
    StyledRowComment,
    StyledRowContainer,
    StyledRowContent,
    StyledRowText,
    StyledRowTitle
} from './styled-history-list-item';

type Event = {
    id: string;
    date: number;
    indicatorCode: string;
    indicatorId: string;
    value: IndicatorValue;
    comment?: string;
};

type Props = {
    event: Event;
};

export const HistoryListItem = (props: Props) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { event } = props;
    const { id, date, indicatorCode, indicatorId, value, comment } = event;
    const indicator = useFetchedIndicator(indicatorCode);

    return (
        <StyledRowContainer>
            <StyledRow>
                <StyledImg size="50px">
                    <IndicatorIconWithBorder icon={indicator.icon} size={50} />
                </StyledImg>
                <StyledRowContent>
                    <StyledRowTitle>
                        {formatMessage(indicator.messages.general.title)}
                    </StyledRowTitle>
                    <StyledRowText>
                        <StyledDataContainer>
                            {formatValue(indicator, value).map(
                                (line, index) => (
                                    <StyledDataItem key={index}>
                                        {line}
                                    </StyledDataItem>
                                )
                            )}
                        </StyledDataContainer>
                    </StyledRowText>
                    {comment && (
                        <StyledRowComment>
                            <p>
                                {formatMessage(
                                    Strings.placeholder.placeholder.comment
                                )}
                                :
                            </p>
                            <p>{comment}</p>
                        </StyledRowComment>
                    )}
                </StyledRowContent>
                <StyledRemoveButtonAndTimestamp>
                    <small>{convertTimestampToFormattedString(date)}</small>
                    {showDeleteButton(date) && (
                        <ButtonLargeRound
                            icon="delete"
                            onClick={() => {
                                confirmRemoveValue(
                                    dispatch,
                                    setLoading,
                                    id,
                                    indicatorId
                                );
                            }}
                            loading={loading}
                        />
                    )}
                </StyledRemoveButtonAndTimestamp>
            </StyledRow>
        </StyledRowContainer>
    );
};

function confirmRemoveValue(
    dispatch: Dispatch<any>,
    setLoading: Dispatch<React.SetStateAction<boolean>>,
    valueId: string,
    indicatorId: string
) {
    return Modal.confirm({
        title: formatMessage(Strings.ui.buttons.main.remove),
        content: formatMessage(Strings.ui.buttons.main.areYouSure),
        okText: formatMessage(Strings.ui.buttons.main.yes),
        cancelText: formatMessage(Strings.ui.buttons.main.no),

        onOk: () => {
            setLoading(true);
            dispatch(removeValue({}, valueId, indicatorId));
        }
    });
}

function showDeleteButton(date: number) {
    return moment(Date.now()).add(-5, 'days').startOf('day').isBefore(date);
}

export function convertTimestampToFormattedString2(timestamp: Date): string {
    const timestampHours = String(timestamp.getHours()).padStart(2, '0');
    const timestampMinutes = String(timestamp.getMinutes()).padStart(2, '0');
    return `${timestampHours}:${timestampMinutes}`;
}

export function convertTimestampToFormattedString(timestamp: number): string {
    return moment(timestamp).format('HH:mm');
}
