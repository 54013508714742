import { physact as sharedPhysact } from '../../../shared-indicators/physact/physact';
import { info } from './messages/info';
import { recommendation } from './messages/recommendation';

export const physact: typeof sharedPhysact = {
    ...sharedPhysact,
    recommendations: {
        limits: {
            enabled: true,
            properties: ['duration']
        },
        reminder: {
            enabled: false
        },
        info: {
            enabled: false
        }
    },
    messages: { ...sharedPhysact.messages, info, recommendation }
};
