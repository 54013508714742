import { defineMessages } from 'react-intl';

export const general = defineMessages({
    title: {
        defaultMessage: 'Swelling',
        id: 'indicator.swelling.title'
    },
    value: {
        defaultMessage: '{degree}\n{position}',
        id: 'indicator.swelling.values'
    }
});
