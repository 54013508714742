import React from 'react';
import { Strings } from '../../locale';
import NotificationsPage from './NotificationsPage';
import { formatMessage } from '../../locale';
import Icon from 'antd/lib/icon';
import { useSelector } from 'react-redux';
import { getNewNotificationIds } from '../../redux/domains/notifications/notifications.selectors';
import { Badge } from 'antd';
import { testSelectors } from '../../constants/test-id';
import { routePaths } from '../../constants/route-paths';

const IconWithBadge = () => {
    const fetchedNotifications = useSelector(getNewNotificationIds);

    return (
        <Badge offset={[-14, 14]} count={fetchedNotifications.length}>
            <Icon type="bell" />
        </Badge>
    );
};

export const routeNotifications = {
    title: formatMessage(Strings.navigation.main.notifications),
    path: routePaths.notifications,
    icon: <IconWithBadge />,
    component: NotificationsPage,
    testId: testSelectors.nav.notificationsItem
};
