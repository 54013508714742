import { defineMessages } from 'react-intl';

export const general = defineMessages({
    title: {
        defaultMessage: 'Physical Activity',
        id: 'indicator.physact.title'
    },
    value: {
        defaultMessage: '{duration}\n{intensity} intensity',
        id: 'indicator.physact.value'
    }
});
