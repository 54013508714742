import { defineMessages } from 'react-intl';

export const copdRS = defineMessages({
    name: {
        defaultMessage: 'COPD',
        id: 'conditions.LP_2_0_COPD_RS.name'
    },
    nameLong: {
        defaultMessage: 'COPD (Chronic Obstructive Pulmonary Disease)',
        id: 'conditions.LP_2_0_COPD_RS.name-long'
    }
});
