import React from 'react';
import {
    formatMessage as t,
    formatMessageById as ti,
    Strings
} from '../../locale';
import { NotificationsList } from './NotificationsList';
import { Header } from '../../components/layout/Header';
import { createLoadingSelector } from '../../redux/domains/requests/requests.selectors';
import { NOTIFICATIONS_FETCH } from '../../redux/domains/notifications/notifications.constants';
import { useReduxSelector } from '../../hooks/use-redux-selector';
import { Loading } from '../../components/loading/Loading';

const loadingSelector = createLoadingSelector([NOTIFICATIONS_FETCH]);

const NotificationsPage: React.FC = () => {
    const isLoading = useReduxSelector(loadingSelector);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <>
            <Header title={ti(Strings.navigation.main.notifications.id)} />
            <NotificationsList />
        </>
    );
};
export default NotificationsPage;
