import * as localeSetup from './locale-setup';
import * as functions from './functions';
import * as components from './components';

const localeUtils = {
    ...localeSetup,
    ...functions,
    ...components
};

export default localeUtils;
