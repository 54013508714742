import { bloodPressure } from '../../shared-indicators/blood-pressure/blood-pressure';
import { pulse } from '../../shared-indicators/pulse/pulse';
import { bodyWeight } from '../../shared-indicators/body-weight/body-weight';
import { vas } from '../../shared-indicators/vas/vas';
import { physact } from '../../shared-indicators/physact/physact';
import { smoke } from '../../shared-indicators/smoke/smoke';
import { fish } from '../../shared-indicators/fish/fish';
import { vegetables } from '../../shared-indicators/vegetables/vegetables';
import { candy } from '../../shared-indicators/candy/candy';

const code = 'CAPIO_HYPERTONI';
const publicCode = 'capio';

const indicators = [
    bloodPressure,
    pulse,
    bodyWeight,
    vas,
    physact,
    smoke,
    fish,
    vegetables,
    candy
];

const condition = {
    [code]: { code, indicators, publicCode, name: code, nameLong: code }
};

export default condition;
