import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import { HISTORY_VALUES_FETCH } from '../../../redux/domains/values/values.constants';
import { TabContent } from '../../../components/ui/tab-content/tab-content';
import { Loading } from '../../../components/loading/Loading';
import { createLoadingSelector } from '../../../redux/domains/requests/requests.selectors';
import { HistoryList } from './HistoryList';
import { useReduxSelector } from '../../../hooks/use-redux-selector';
import { fetchHistoryValues } from '../../../redux/domains/values/values.actions';
import { useCondition } from '../../../hooks/use-condition';
import { getFetchHistoryValuesParams } from './utils';

const loadingSelector = createLoadingSelector([HISTORY_VALUES_FETCH]);

export const HistoryTab = () => {
    const dispatch = useDispatch();
    const isLoading = useReduxSelector(loadingSelector);
    const condition = useCondition();
    const conditionState = useReduxSelector(
        (state) => state.conditions.byCode[condition.code]
    );
    const history = useReduxSelector((state) => state.values.history);
    const { values = [], hasMoreValues = true } = history[condition.code] || {};

    useEffect(fetchMoreValues, [condition]);

    return (
        <TabContent>
            {!history[condition.code] ? (
                <Loading />
            ) : (
                <InfiniteScroll
                    pageStart={0}
                    initialLoad={false}
                    loadMore={fetchMoreValues}
                    hasMore={!isLoading && hasMoreValues}
                    useWindow={false}
                >
                    <HistoryList />
                </InfiniteScroll>
            )}
        </TabContent>
    );

    function fetchMoreValues() {
        const params = getFetchHistoryValuesParams(
            condition,
            conditionState,
            values.length,
            20
        );
        dispatch(fetchHistoryValues(params));
    }
};
