import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IndicatorIcon } from '../../../components/indicator-icon/IndicatorIcon';
import { StyledIndicatorTile } from './components/StyledIndicatorTile';
import { Feedback } from './components/Feedback';
import { useFetchedIndicator } from '../../../hooks/use-fetched-indicator';
import { getSelectedCondition } from '../../../redux/domains/conditions/conditions.selectors';
import { testSelectors } from '../../../constants/test-id';

type Props = {
    code: string;
    afterCSSTransition: any;
};

export const IndicatorTile = ({ code, afterCSSTransition }: Props) => {
    const indicator = useFetchedIndicator(code);
    const history = useHistory();
    const conditionState = useSelector(getSelectedCondition);
    const testId = `${testSelectors.report.tilePrefix}-${code}`;

    return (
        <StyledIndicatorTile
            data-test-id={testId}
            isLoading={conditionState.tiles[code].loading}
            isDimmed={Date.now() < conditionState.tiles[code].dimmedUntil}
            onClick={() => {
                history.replace('/report/' + code.toLowerCase());
            }}
        >
            <IndicatorIcon icon={indicator.icon} color="white" />
            <Feedback
                indicator={indicator}
                afterCSSTransition={afterCSSTransition}
            />
        </StyledIndicatorTile>
    );
};
