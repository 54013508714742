import React, { useState } from 'react';
import { Input } from 'antd';
import { formatMessageById, Strings } from '../../../../locale';
import { styled } from '../../../../theme/styled';
import { MarkdownFromDescriptor } from '../../../../locale/utils/functions/format-markdown';

export const Comment = () => {
    const [value, setValue] = useState<string>('');

    return (
        <>
            <Input
                name="comment"
                autoComplete="off"
                placeholder={formatMessageById(
                    Strings.placeholder.placeholder.comment.id
                )}
                value={value}
                onChange={(value) => {
                    setValue(value.currentTarget.value);
                }}
            />
            <StyledCommentInfo>
                <MarkdownFromDescriptor
                    messageDescriptor={
                        Strings.placeholder.placeholder.commentHelpText
                    }
                />
            </StyledCommentInfo>
        </>
    );
};

const StyledCommentInfo = styled.span`
    display: block;
    color: #afafaf;
    font-size: 11px;
    font-style: italic;
    line-height: 1.2;
    letter-spacing: 0;
    margin-top: 7px;
`;
