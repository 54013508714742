import React from 'react';
import { Strings } from '../../locale';
import ReportPage from './ReportPage';
import { formatMessage } from '../../locale';
import { Icon } from 'antd';
import { testSelectors } from '../../constants/test-id';
import { routePaths } from '../../constants/route-paths';

export const routeReport = {
    title: formatMessage(Strings.navigation.main.report),
    path: routePaths.report,
    icon: <Icon type="plus-square-o" />,
    component: ReportPage,
    testId: testSelectors.nav.reportItem
};
