import { defineMessages } from 'react-intl';

export const dose = defineMessages({
    question: {
        defaultMessage: 'Number of doses exceeding prescription',
        id: 'indicator.prnmed.dose.description'
    },
    value: {
        defaultMessage: '{value, plural, one {# dose} other {# doses}}',
        id: 'indicator.prnmed.dose.value'
    }
});
