import { prnmed } from '../shared-indicators/prnmed-ci/prnmed';
import { medication } from '../shared-indicators/medication/medication';
import { pain } from '../shared-indicators/pain/pain';
import { bloodPressure } from '../shared-indicators/blood-pressure/blood-pressure';
import { smoke } from '../shared-indicators/smoke/smoke';
import { physact } from '../shared-indicators/physact/physact';
import { vas } from '../shared-indicators/vas/vas';
import { formatMessage, Strings } from '../../locale';

const code = 'LP_2_0_CI_MAU';
const publicCode = 'ci';

const indicators = [
    prnmed,
    medication,
    pain,
    bloodPressure,
    smoke,
    physact,
    vas
];

const condition = {
    [code]: {
        code,
        indicators,
        publicCode,
        name: formatMessage(Strings.conditions.mau.ic.name),
        nameLong: formatMessage(Strings.conditions.mau.ic.nameLong)
    }
};

export default condition;
