import { hot } from 'react-hot-loader/root';
import React, { Component } from 'react';
import { GlobalStyle } from './theme/GlobalStyle';

import { formatMessage, Strings } from './locale';
import { connect, Provider } from 'react-redux';

import { Route, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { setHighchartsLang } from './locale/utils/charts/charts';

import MainLayout from './components/layout/MainLayout';
import { AuthenticationPage } from './app/authentication/LoginPage';
import { Loading } from './components/loading/Loading';

import { checkAuthentication } from './redux/domains/authentication/authentication.actions';
import { AUTHENTICATION_CHECK } from './redux/domains/authentication/authentication.constants';
import { createLoadingSelector } from './redux/domains/requests/requests.selectors';

import { ThemeProfileProvider } from './theme/theme-profile.provider';
import { AppState } from './redux/store/root-reducer';

type Props = {
    store: any;
    actions: {
        checkAuthentication: any;
    };
    checkAuthenticationLoading: boolean;
    isAuthenticated: boolean;
};

class App extends Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    componentDidMount() {
        setHighchartsLang(formatMessage(Strings.other.main.noData));
        this.props.actions.checkAuthentication();
    }

    render() {
        if (this.props.checkAuthenticationLoading) {
            return <Loading />;
        }

        return (
            <ThemeProfileProvider>
                <GlobalStyle />
                <Provider store={this.props.store}>
                    <Route
                        path="/"
                        render={() => {
                            return this.props.isAuthenticated ? (
                                <MainLayout />
                            ) : (
                                <AuthenticationPage />
                            );
                        }}
                    />
                </Provider>
            </ThemeProfileProvider>
        );
    }
}

const checkAuthenticationLoadingSelector = createLoadingSelector([
    AUTHENTICATION_CHECK
]);

const mapStateToProps = (state: AppState) => {
    return {
        isAuthenticated: state.authentication.isAuthenticated,
        checkAuthenticationLoading: checkAuthenticationLoadingSelector(state)
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        actions: bindActionCreators(
            {
                checkAuthentication
            },
            dispatch
        )
    };
};

const AppConnectedWithRouter = hot(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(App) as any)
);

export { AppConnectedWithRouter };
