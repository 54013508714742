import { defineMessages } from 'react-intl';

export const general = defineMessages({
    title: {
        defaultMessage: 'Vegetables',
        id: 'indicator.vegetables.title'
    },
    value: {
        defaultMessage: '{value}',
        id: 'indicator.vegetables.value'
    },
    legendNo: {
        defaultMessage: 'Have not eaten vegetables',
        id: 'indicator.vegetables.legend.no'
    },
    legendYes: {
        defaultMessage: 'Have eaten vegetables',
        id: 'indicator.vegetables.legend.yes'
    }
});
