import moment from 'moment';
import { theme } from '../../../../../../theme/colors/common-colors';
import { getValues } from './getValues';
import { Props } from './IndicatorHeatmap';

export function getOptions<Value, T extends number | boolean>(
    props: Props<Value, T>
) {
    const startDate = moment().subtract(3, 'months').format('YYYY-MM-DD');
    const endDate = moment().format('YYYY-MM-DD');

    return {
        visualMap: {
            orient: 'horizontal',
            hoverLink: false,
            pieces: props.indicator.rules.heatmapPieces,
            show: false,
            dimension: 1,
            itemGap: 50,
            left: 0,
            top: 50
        },
        series: [
            {
                type: 'heatmap',
                coordinateSystem: 'calendar',
                data: getValues(props),
                name: props.indicator.code
            }
        ],
        calendar: [
            {
                range: [startDate, endDate],
                dayLabel: {
                    firstDay: 1,
                    nameMap: moment
                        .localeData()
                        .weekdaysMin()
                        .map((day) => day.charAt(0))
                },
                monthLabel: {
                    nameMap: moment.localeData().monthsShort()
                },
                yearLabel: {
                    show: false,
                    margin: 0
                },
                top: 50,
                right: 0,
                left: '8%',
                itemStyle: {
                    borderColor: theme.base.bright,
                    color: theme.layout.background
                },
                splitLine: {
                    lineStyle: {
                        width: 0.5,
                        color: theme.base.dark
                    }
                },
                silent: true
            }
        ]
    };
}
