import { ConditionIndicator } from '../conditions/condition.type';
import { useCondition } from './use-condition';

export const useIndicator = (code: string): ConditionIndicator | undefined => {
    const condition = useCondition();
    const { indicators } = condition;
    const indicator =
        indicators &&
        indicators.find((ind) => ind.code.toLowerCase() === code.toLowerCase());
    return indicator;
};
