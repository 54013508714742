import React from 'react';

import { ColumnRangeSeries } from 'react-jsx-highcharts';
import { SplineSeries } from 'react-jsx-highcharts';

import Highcharts from 'highcharts';
import addHighchartsMore from 'highcharts/highcharts-more';

import { RangeSeriesData } from '../../../../../../types/graph/range-series-data.type';
import { FetchedIndicator } from '../../../../../../types/indicator/fetched-indicator.type';
import { LineColor } from '../../../../../../types/indicator/indicator-rules.type';

addHighchartsMore(Highcharts);

export const ConnectedMultiValueChart = (props: {
    indicator: FetchedIndicator;
    required: string[];
    values: { value: any; date: number }[];
    lineColors: LineColor<string>;
    getSerieName: (property: string) => string;
}) => {
    const { indicator, required, values, lineColors, getSerieName } = props;
    const connectedLines: RangeSeriesData[] = [];

    return (
        <ColumnRangeSeries
            name={getSerieName(indicator.code)}
            data={connectedLines}
            id={indicator.code}
            onLegendItemClick={() => false}
        >
            {required.map((property: string) => {
                values.forEach((value) =>
                    connectedLines.push(getVerticalLineColor(value))
                );

                const data = values.map((value: any) => [
                    value.date,
                    value.value[property]
                ]);

                return (
                    <SplineSeries
                        data={data}
                        name={getSerieName(property)}
                        key={property}
                        onLegendItemClick={() => false}
                        linkedTo={indicator.code}
                        color={lineColors[property]}
                    />
                );
            })}
        </ColumnRangeSeries>
    );
};

function getVerticalLineColor(params: {
    value: any;
    date: number;
}): RangeSeriesData {
    const { value, date } = params;

    const values = Object.values(value) as unknown as number[];

    const low = Math.min(...values);
    const high = Math.max(...values);

    const connectedValuesLine: RangeSeriesData = {
        x: date,
        low: low,
        high: high
    };
    return connectedValuesLine;
}
