import { combineReducers } from 'redux';
import { every } from 'lodash';
import * as actionTypes from './chat.constants';
import { VIDEO_MEETINGS_FETCH_FULFILLED } from '../video/video.constants';
import { VideoSession } from '@lifepod-legacy/openvidu-frontend/dist/api/video-api';

export type ChatState = {
    readonly byGroupId: ByGroupId;
    readonly currentConversationId: string;
    videoMeetings: VideoMeetings;
};

type ByGroupId = {
    [groupId: string]: any;
};

// State for all video meeting messages.
// active == null means it is unknown if the meeting is still active or not
// (because we have not fetched active video meetings recently)
type VideoMeetings = Record<
    VideoSessionId,
    {
        active?: boolean;
    }
>;

type VideoSessionId = string;

type Message = {
    content: string;
    dtCreated: number;
};

const initialState: ChatState = {
    byGroupId: {},
    currentConversationId: '',
    videoMeetings: {}
};

const addConversationEntry = (state: ByGroupId, action: any) => {
    const { data, groupId } = action.payload;
    return {
        ...state,
        [groupId]: data
    };
};

const addMessageToConversation = (state: ByGroupId, action: any) => {
    const { groupId, message } = action.payload;

    const oldMessages = state[groupId].messages || [];

    return {
        ...state,
        [groupId]: {
            ...state[groupId],
            messages: [...oldMessages, message]
        }
    };
};

function byGroupId(state = initialState.byGroupId, action: any) {
    const { payload, type } = action;

    switch (type) {
        case actionTypes.CONVERSATION_FETCH_FULFILLED: {
            const { conversations } = payload;
            return {
                ...state,
                ...conversations
            };
        }
        case actionTypes.MESSAGES_FETCH_FULFILLED: {
            const { data, groupId = '' } = payload;

            return {
                ...state,
                [groupId]: {
                    ...state[groupId],
                    messages: data
                }
            };
        }
        case actionTypes.MESSAGE_POST_FULFILLED:
            return addMessageToConversation(state, action);
        case actionTypes.CONVERSATION_CREATE_FULFILLED: {
            return addConversationEntry(state, action);
        }
        default:
            return state;
    }
}

const currentConversationId = (
    state = initialState.currentConversationId,
    action: any
) => {
    const { payload, type } = action;
    switch (type) {
        case actionTypes.SET_CURRENT_CONVERSATION_ID:
            return payload;
        default:
            return state;
    }
};

const activeFalse = { active: false };
const activeTrue = { active: true };

const videoMeetings = (state = initialState.videoMeetings, action: any) => {
    if (__APP_CONFIG__.FEATURE_VIDEO_MEETING) {
        switch (action.type) {
            case actionTypes.MESSAGES_FETCH_FULFILLED: {
                const messages: Message[] = action.payload.data;
                const videoMessages = messages.filter((message) =>
                    message.content.startsWith('#video/')
                );
                const videoMeetings: VideoMeetings = {};

                const value = every(state, 'active') ? activeFalse : {};

                for (const message of videoMessages) {
                    const sessionId = message.content.split('/')[1];
                    videoMeetings[sessionId] = value;
                }

                return Object.assign(videoMeetings, state);
            }
            case VIDEO_MEETINGS_FETCH_FULFILLED: {
                const activeSessions: VideoSession[] = action.payload;
                const videoMeetings: VideoMeetings = {};

                for (const sessionId in state) {
                    videoMeetings[sessionId] = activeFalse;
                }

                for (const session of activeSessions) {
                    videoMeetings[session.id] = activeTrue;
                }

                return videoMeetings;
            }
        }
    }
    return state;
};

export const chatReducer = combineReducers({
    byGroupId,
    currentConversationId,
    videoMeetings
});
