import React from 'react';
import { styled } from '../../../theme/styled';
import { ButtonProps } from 'antd/lib/button/button';
import { ButtonLarge } from '../../../components/ui/buttons/ButtonLarge';

const ConversationInfoButton = (props: ButtonProps) => (
    <StyledInfoButton {...props} />
);

const StyledInfoButton = styled(ButtonLarge)`
    width: 48%;
    background-color: ${(props) =>
        props.theme.chat.emergency.sticky.textColor} !important;
    display: block !important;
    margin: auto !important;
    margin-bottom: 2rem !important;
    padding: 1rem !important;
    padding-left: 2rem !important;
    padding-right: 2rem !important;
    height: auto !important;
    width: auto !important;
    font-size: 18px !important;
    border-radius: 6px !important;
`;

export { ConversationInfoButton };
