import moment from 'moment';

//Should this really be a util function?
// Helper function to determine if a reported value is inside the missedReportInterval
export default (targetDate: any, interval: number) => {
    if (!targetDate || !interval) return false;

    const today = moment().format(moment.HTML5_FMT.DATE);
    targetDate = moment(targetDate).format(moment.HTML5_FMT.DATE);

    const daysSinceReport = moment(today).diff(targetDate, 'days');
    const missedIntervalAsDays = moment.duration(interval).asDays();

    return daysSinceReport < missedIntervalAsDays;
};
