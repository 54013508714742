import React, { useState } from 'react';
import { Card, Radio } from 'antd';
import { MessageDescriptor } from 'react-intl';
import Strings, { formatMessage } from '../../../../locale';
import { useReduxSelector } from '../../../../hooks/use-redux-selector';
import { FormProps } from '../../../../types/indicator/report.type';
import { useCloseModal, useSubmitModal } from '../common/ModalForm';
import { Comment } from '../common/Comment';
import { DateTimePicker } from '../common/DateTimePicker';
import { styled } from '../../../../theme/styled';
import { theme } from '../../../../theme/colors/common-colors';
import { getIndicatorConfigurations } from '../../../../redux/domains/user/user.selectors';
import { isFetchedIndicatorProperties } from '../../../../types/indicator/fetched-indicator.type';
import { SliderBox } from '../slider/components/SliderBox';
import { SliderValue } from '../slider/components/SliderValue';
import { StyledRadioGroup } from '../radio/components/styled';
import {
    FetchedIndicatorPropertiesFormItem,
    FetchedIndicatorPropertiesRadioFormItem,
    ItemTypes
} from '../../../../types/indicator/state-indicator.type';
import messages from '../../../../conditions/shared-indicators/prnmed/messages';
import { RadioChangeEvent } from 'antd/lib/radio';
import {
    StyledBody,
    StyledBodyContent
} from '../../../../components/ui/modal/StyledModal';
import { MissedReportInfo } from '../common/MissedReportInfo';
import { ModalFormButtons } from '../common/ModalFormButtons';

const MEDICATION = 'medication';
const DOSE = 'dose';

const validateMessage = (message?: MessageDescriptor) => {
    if (!message) {
        throw new Error('message cannot be null');
    }
    return message;
};

export const PrnmedForm = ({ indicator }: FormProps): JSX.Element => {
    if (!isFetchedIndicatorProperties(indicator)) {
        throw new Error(
            `Indicator have to be of type FetchedIndicatorProperties`
        );
    }

    const item = indicator.form?.item?.find((item) => item.code === MEDICATION);

    if (!item) {
        throw new Error('itemtype cannot be null');
    }
    if (!isFetchedIndicatorPropertiesRadioFormItem(item)) {
        throw new Error('medication itemtype is not a radiotype');
    }

    const configuration = useReduxSelector(
        (state) => getIndicatorConfigurations(state)[indicator.code]
    );
    const { report_min, report_max } = configuration[DOSE];
    const { step } = indicator.rules;
    const isRequired = indicator.data.jsonSchema.required?.includes(MEDICATION);
    const closeModal = useCloseModal();
    const handleSubmit = useSubmitModal(indicator);
    const [isDoseTouched, setIsDoseTouched] = useState<boolean>(false);
    const [dose, setDose] = useState<number>(
        configuration[DOSE].report_min || 0
    );
    const [medication, setMedication] = useState<string | null>(
        item.options.length === 1 ? item.options[0] : null
    );

    return (
        <StyledBody onSubmit={handleSubmit}>
            <StyledBodyContent>
                <MissedReportInfo indicator={indicator} />
                <Card>
                    <div style={{ margin: '5px 0 0 0' }} />
                    <DateTimePicker />
                    <div style={{ margin: '5px 0 8px 0' }}>
                        <StyledPropertyQuestion>
                            {formatMessage(
                                validateMessage(
                                    indicator.messages[MEDICATION]?.question
                                )
                            )}
                            {isRequired && (
                                <Required>
                                    {formatMessage(
                                        Strings.validation.validation.general
                                    )}
                                </Required>
                            )}
                        </StyledPropertyQuestion>
                        <StyledRadioGroup
                            name={MEDICATION}
                            size="large"
                            buttonStyle="solid"
                            value={medication}
                            onChange={(event: RadioChangeEvent) => {
                                setMedication(event.target.value);
                            }}
                        >
                            {item.options.map((value) => (
                                <Radio.Button
                                    key={value}
                                    value={value}
                                    style={{
                                        display: 'block',
                                        textTransform: 'capitalize'
                                    }}
                                >
                                    {formatMessage(messages.medication.value, {
                                        value
                                    })}
                                </Radio.Button>
                            ))}
                        </StyledRadioGroup>
                        <>
                            <SliderBox
                                value={dose}
                                min={report_min || 0}
                                max={report_max || 0}
                                step={step}
                                showButtons={true}
                                onChange={(value) => {
                                    setDose(value);
                                    setIsDoseTouched(true);
                                }}
                                useGradient={false}
                                propertyTestId={DOSE}
                            >
                                <StyledPropertyQuestion>
                                    {formatMessage(
                                        validateMessage(
                                            indicator.messages[DOSE]?.question
                                        )
                                    )}
                                </StyledPropertyQuestion>
                                <div style={{ display: 'flex' }}>
                                    <SliderValue
                                        indicator={indicator}
                                        property={DOSE}
                                        value={dose}
                                    />
                                </div>
                            </SliderBox>
                            <input
                                type="hidden"
                                name={DOSE}
                                value={dose}
                                required
                            />
                        </>
                    </div>
                    <Comment />
                </Card>
            </StyledBodyContent>
            <ModalFormButtons
                closeModal={closeModal}
                isFormValid={isValid(medication != null, isDoseTouched)}
            />
        </StyledBody>
    );
};

function isValid(isMedicationTouched: boolean, isDoseTouched: boolean) {
    return isMedicationTouched && isDoseTouched;
}

function isFetchedIndicatorPropertiesRadioFormItem(
    item: FetchedIndicatorPropertiesFormItem
): item is FetchedIndicatorPropertiesRadioFormItem {
    return item.type === ItemTypes.RADIO;
}

const StyledPropertyQuestion = styled.div`
    margin: 26px 0 6px 0;
    text-align: center;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
`;

const Required = styled.span`
    margin-left: 5px;
    color: ${theme.denote.error};
    text-transform: lowercase;
`;
