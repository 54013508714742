import { defineMessages } from 'react-intl';

export const breathlessness = defineMessages({
    title: {
        defaultMessage: 'Breathlessness',
        id: 'indicator.ehfscbs.breathlessness.title'
    },
    description: {
        defaultMessage:
            'If my shortness of breath increases, I contact my doctor or nurse',
        id: 'indicator.ehfscbs.breathlessness.description'
    },
    maxValueDescription: {
        defaultMessage: 'I do not agree at all',
        id: 'indicator.ehfscbs.breathlessness.max-text'
    },
    minValueDescription: {
        defaultMessage: 'I fully agree',
        id: 'indicator.ehfscbs.breathlessness.min-text'
    }
});
