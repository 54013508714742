import React from 'react';
import { FetchedIndicator } from '../../../../../types/indicator/fetched-indicator.type';
import { SliderBox } from '../../slider/components/SliderBox';
import { QuestionnairePropertyTitle } from './QuestionnairePropertyTitle';
import { SliderValueContainer } from '../../slider/components/SliderValueContainer';
import { SliderMinDescription } from '../../slider/components/SliderMinDescription';
import { SliderValue } from '../../slider/components/SliderValue';
import { SliderMaxDescription } from '../../slider/components/SliderMaxDescription';
import { QuestionnaireHelpText } from './QuestionnaireHelpText';
import { QuestionnaireFormSliderItem } from './questionnaire.types';
import { QuestionnaireDescription } from './QuestionnaireDescription';

type QuestionnaireSliderType = {
    indicator: FetchedIndicator;
    item: QuestionnaireFormSliderItem;
    onChange: (value: number) => void;
};

export const QuestionnaireSlider = (
    props: QuestionnaireSliderType
): JSX.Element => {
    const { indicator, item, onChange } = props;
    const { code, value, report_min, report_max } = item;

    return (
        <SliderBox
            value={value}
            min={report_min}
            max={report_max}
            useGradient={false}
            showButtons={true}
            onChange={onChange}
            style={{ marginBottom: 0 }}
            propertyTestId={code}
        >
            <QuestionnairePropertyTitle indicator={indicator} property={code} />
            <QuestionnaireDescription indicator={indicator} property={code} />
            <QuestionnaireHelpText indicator={indicator} property={code} />
            <SliderValueContainer>
                <SliderMinDescription indicator={indicator} property={code} />
                <SliderValue indicator={indicator} value={value} />
                <SliderMaxDescription indicator={indicator} property={code} />
            </SliderValueContainer>
            <input type="hidden" name={code} value={value} required />
        </SliderBox>
    );
};
