import { styled } from '../../../theme/styled';
import { getRGBA, theme } from '../../../theme/colors/common-colors';

export const StyledRow = styled.div`
    margin: auto;
    margin-bottom: 20px;
`;

export const StyledRowLabel = styled.div`
    text-align: center;
    margin: 0 auto 8px;
    box-shadow: 0px 0px 0px ${getRGBA(theme.base.alter2, 0.3)};
`;

export const StyledRowContent = styled.div`
    max-width: 500px;
    background-color: ${getRGBA(theme.base.alter2, 0.02)};
    text-align: center;
    border: 1px solid ${getRGBA(theme.base.alter2, 0.3)};
    border-radius: 3px;
    box-shadow: inset 0px 0px 30px 5px ${getRGBA(theme.base.alter2, 0.02)};
    padding: 16px;
    margin: auto;

    .ant-divider-horizontal {
        &.ant-divider-with-text {
            &:before,
            &:after {
                border-top: 1px solid ${theme.base.alter2};
            }
        }
    }
`;
