import { defineMessages } from 'react-intl';

export const general = defineMessages({
    title: {
        defaultMessage: 'Tiredness',
        id: 'indicator.tiredness.title'
    },
    value: {
        defaultMessage:
            '{value, select, 1 {Completely unaffected} 2 {Tired from more than moderate activity} 3 {Tired from light activity} 4 {Tired at rest}}',
        id: 'indicator.tiredness.value'
    }
});
