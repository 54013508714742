import React from 'react';
import { Icon } from 'antd';
import { styled } from '../../theme/styled';

const StyledIcon = styled(Icon)`
    color: lightgray;
    font-size: 16px;
`;

export const StyledArrow = () => <StyledIcon type="right" />;
