import { defineMessages } from 'react-intl';

const auth = defineMessages({
    bankid: {
        id: 'screen.auth.bankid',
        defaultMessage: 'BankID'
    },
    freja: {
        id: 'screen.auth.freja',
        defaultMessage: 'Freja eID'
    },
    error: {
        id: 'screen.auth.error',
        defaultMessage:
            'Log in failed. Please try again. Contact support if the error persists.'
    }
});

export default {
    main: auth
};
