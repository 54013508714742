import Highcharts from 'highcharts';

import moment from 'moment';

export const setHighchartsLang = (
    noDataText: null | string = null,
    resetZoomText: null | string = null
) => {
    const _noData = noDataText ? noDataText : 'noData';
    const _resetZoom = resetZoomText ? resetZoomText : 'resetZoom';

    Highcharts.setOptions({
        lang: {
            shortMonths: moment.localeData().monthsShort(),
            noData: _noData,
            resetZoom: _resetZoom,
            decimalPoint: moment.locale() === 'sv' ? ',' : '.'
        }
    });
};
