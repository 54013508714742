import { styled } from '../../../../../../../theme/styled';
import React from 'react';
import { Card, Icon } from 'antd';

const CardHeader = ({ title, onClick }: any) => {
    return (
        <StyledCardHeader>
            {title}
            <Icon
                onClick={() => onClick()}
                style={{ fontSize: 30 }}
                type="info-circle"
            />
        </StyledCardHeader>
    );
};

export const StyledAchievementCard = ({
    headerTitle,
    onClick,
    children
}: any) => {
    return (
        <Card title={<CardHeader title={headerTitle} onClick={onClick} />}>
            {children}
        </Card>
    );
};

const StyledCardHeader = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
`;
