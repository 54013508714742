import { createSelector } from 'reselect';
import { mapValues } from 'lodash';
import { ValuesState } from './values.types';
import { getPatientConditions } from '../conditions/conditions.selectors';
import {
    getCondition,
    isExistingCondition
} from '../conditions/conditions.utils';

type State = {
    values: ValuesState;
};

export const getValuesByIndicatorId = createSelector(
    [(state: State) => state.values.indicators],
    (indicators) => mapValues(indicators, 'values')
);

export const getValuesToExport = createSelector(
    [getPatientConditions, getValuesByIndicatorId],
    (conditions, values) =>
        conditions
            .filter(isExistingCondition)
            .map(function ({ code, indicators }) {
                const condition = getCondition(code);
                return {
                    ...condition,
                    indicators: condition.indicators
                        .map(function ({ code, messages }) {
                            const indicator = indicators.byCode[code];
                            return {
                                ...indicator,
                                messages,
                                values: values[indicator.id]
                            };
                        })
                        .filter(({ values }) => values && values.length)
                };
            })
);
