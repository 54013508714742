import { defineMessages } from 'react-intl';

export const anxiety = defineMessages({
    title: {
        defaultMessage: 'Your anxiety/depression TODAY',
        id: 'indicator.eq5d3l.anxiety.title'
    },
    choices: {
        defaultMessage:
            '{value, select, 1 {I am not anxious or depressed} 2 {I am moderately anxious or depressed} 3 {I am extremely anxious or depressed}}',
        id: 'indicator.eq5d3l.anxiety.choices'
    }
});
