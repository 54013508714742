import {
    ACHIEVEMENTS_FETCH_PENDING,
    ACHIEVEMENTS_FETCH_FULFILLED
} from './achievements.constants';

export type AchievementsState = [];

const initialState: AchievementsState = [];

export function achievementsReducer(state = initialState, action: any) {
    switch (action.type) {
        case ACHIEVEMENTS_FETCH_PENDING:
            return state;
        case ACHIEVEMENTS_FETCH_FULFILLED:
            return action.payload;
        default:
            return state;
    }
}
