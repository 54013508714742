import React, { PropsWithChildren } from 'react';
import { render } from 'react-dom';

import { HashRouter as Router } from 'react-router-dom';

import { configureStore } from './redux/store/configure-store';
import { HTTPClient } from './services/http-client/http-client';
import { AppConnectedWithRouter } from './App';
import { ensureIntlSupport, LocaleProvider } from './locale/LocaleProvider';
import { HighchartsProvider } from 'react-jsx-highcharts';
import Highcharts from 'highcharts';

const store = configureStore();
// @ts-ignore
HTTPClient.setupInterceptors(store);

const AppProvider = ({ children }: PropsWithChildren<unknown>) => {
    return (
        <HighchartsProvider Highcharts={Highcharts}>
            <LocaleProvider>{children}</LocaleProvider>
        </HighchartsProvider>
    );
};

const renderRoot = (Component: React.FC<{ store: unknown }>) => {
    render(
        <AppProvider>
            <Router>
                <Component store={store} />
            </Router>
        </AppProvider>,
        document.getElementById('app')
    );
};

// @ts-ignore
ensureIntlSupport().then(renderRoot(AppConnectedWithRouter));
