import flow from 'lodash/fp/flow';
import some from 'lodash/fp/some';
import map from 'lodash/fp/map';
import get from 'lodash/get';
import compact from 'lodash/fp/compact';
import head from 'lodash/fp/head';

function isPending(action: any, state: any) {
    return get(state, `requests.loading.${action}`);
}

export const createLoadingSelector = (actions: any[]) => (state: any) => {
    return flow(some((action: any) => isPending(action, state)))(actions);
};

export const createErrorSelector = (actions: any[]) => (state: any) => {
    return flow(
        map((action) => get(state, `requests.error.${action}`)),
        compact,
        head || ''
    )(actions);
};
