import { defineMessages } from 'react-intl';

const header = defineMessages({
    title: {
        id: 'message-of-the-day.header.title',
        defaultMessage: 'Important information'
    }
});

export const messageOfTheDay = {
    header
};
