import { defineMessages } from 'react-intl';

export const general = defineMessages({
    title: {
        defaultMessage: 'Blood Pressure LVAD',
        id: 'indicator.blood-pressure-lvad.title'
    },
    value: {
        defaultMessage: '{value}',
        id: 'indicator.blood-pressure-lvad.value'
    }
});
