import { defineMessages } from 'react-intl';

export const info = defineMessages({
    main: {
        id: 'indicator.physact.lp_2_0_copd_rs.info.main',
        defaultMessage:
            'Att motionera kan kännas främmande för den person som har drabbats ' +
            'av sjukdomen KOL. ' +
            'Rädslan för andfåddhet är en faktor som ibland hindrar från att ' +
            'utföra aktiviteter. ' +
            'Att bli andfådd vid träning är inte farligt. ' +
            'Har du fått sjukdomen KOL är det viktigt med regelbunden motion, ' +
            'vilket har en gynnsam effekt på bland annat arbetsförmåga, ' +
            'högt blodtryck och höga blodfetter. ' +
            'Konditionsträning ger förbättrad funktion och livskvalitet.  \n\n' +
            '' +
            'KOL kan ge begränsningar i vardagen – och hur problematiken ser ' +
            'ut är individuell. ' +
            'Försök att träna varje dag på måttlig nivå under minst 30 minuter, ' +
            '5-7 gånger i veckan. ' +
            'Det går bra att dela upp dessa 30 minuter i kortare pass. ' +
            'Välj någon aktivitet du tycker om, till exempel promenader, ' +
            'cykling eller simning.  \n\n' +
            '' +
            '### Några råd vid fysisk aktivitet  \n' +
            '' +
            'Förebygg eventuella besvär med bronkvidgande medicin, ' +
            'om du tycker att det hjälper dig vid träningen. ' +
            'Om du har lätt för att bli andfådd, förbered dig gärna med att ' +
            'använda sluten läppandning redan innan träningen startar. ' +
            'Börja träningen långsamt och stegra därefter i mycket långsam takt. ' +
            'Träna inte så att du blir helt slut – ta pauser. ' +
            'Använd gärna gånghjälpmedel till exempel rollator, ' +
            'så att du kan förflytta dig längre sträckor. ' +
            'Prata inte samtidigt som du tränar. ' +
            'Man orkar mindre om man pratar och tränar samtidigt.  \n\n' +
            '' +
            'Känns det svårt att komma igång, ' +
            'ta kontakt med din sjukgymnast/fysioterapeut som kan hjälpa dig! ' +
            'Planera dina aktiviteter så att du kan prioritera det du tycker är ' +
            'viktigast.'
    }
});
