import { defineMessages } from 'react-intl';

export const chest = defineMessages({
    title: {
        defaultMessage: 'Chest',
        id: 'indicator.cat.chest.title'
    },
    maxValueDescription: {
        defaultMessage: 'My chest feels very tight',
        id: 'indicator.cat.chest.max-text'
    },
    minValueDescription: {
        defaultMessage: 'My chest does not feel tight at all',
        id: 'indicator.cat.chest.min-text'
    }
});
