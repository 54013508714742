import { defineMessages } from 'react-intl';

export const common = defineMessages({
    minValueDescription: {
        defaultMessage: 'None',
        id: 'indicator.pain.min-value-description'
    },
    maxValueDescription: {
        defaultMessage: 'Worst imaginable',
        id: 'indicator.pain.max-value-description'
    },
    value: {
        defaultMessage: '{value}',
        id: 'indicator.pain.value'
    }
});
