import { RouteType } from '../../routes/route.type';
import { routeReport } from '../../app/report/route';
import { routeMyData } from '../../app/my-data/route';
import { routeChat } from '../../app/chat/route';
import { routeNotifications } from '../../app/notifications/route';
import { otherRoutes } from '../../app/other/other-routes';
import { routeOther } from '../../app/other/route';

export const mainRoutes: RouteType[] = [
    routeReport,
    routeMyData,
    routeChat,
    routeNotifications,
    routeOther,
    ...otherRoutes
];
