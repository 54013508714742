import { IndicatorProperties } from '../../../types/indicator/indicator.type';
import messages from './messages';
import indicatorRules from './physact-rules';
import icon from './images/physact.svg';
import { TileType, FeedbackType } from '../../../types/indicator/report.type';
import { MultiSliderForm } from '../../../app/report/forms/slider/MultiSliderForm';

const code = 'PHYSACT';

const properties = {
    duration: 'duration',
    intensity: 'intensity'
};

export const physact: IndicatorProperties<
    keyof typeof properties,
    typeof indicatorRules
> = {
    code,
    properties,
    icon,
    messages,
    hiddenHistory: false,
    hiddenStatistics: false,
    recommendations: {
        limits: {
            enabled: true
        },
        info: {
            enabled: false
        }
    },
    rules: indicatorRules,
    tile: {
        report: {
            form: MultiSliderForm,
            type: TileType.Dimming,
            feedback: FeedbackType.Smiley
        }
    }
};
