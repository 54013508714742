import * as actionTypes from './reminders.constants';
import { getReminders } from './reminders.api';

export const ACTION_CONFIRMED = 'confirmed';
export const ACTION_NOTIFIED = 'notified';
export const TAG_CONFIRMED = 'confirmed';
export const TAG_NOTIFIED = 'notified';

export const fetchReminders = () => (dispatch: any) =>
    dispatch({
        type: actionTypes.REMINDERS_FETCH,
        payload: getReminders()
    });
