import React from 'react';
import { useDispatch } from 'react-redux';
import { Icon } from 'antd';
import { ConditionsKey } from '../../conditions/condition.type';
import { conditions } from '../../conditions/index';
import { styled } from '../../theme/styled';
import { useReduxSelector } from '../../hooks/use-redux-selector';
import {
    nextCondition,
    previousCondition
} from '../../redux/domains/conditions/conditions.actions';
import { AppState } from '../../redux/store/root-reducer';
import { Carousel } from 'react-responsive-carousel';

export const ConditionPicker = () => {
    const dispatch = useDispatch();
    const conditionsState = useReduxSelector(
        (state: AppState) => state.conditions
    );
    const hasMultipleConditions = conditionsState.allCodes.length > 1;

    return (
        <StyledCarousel
            selectedItem={conditionsState.allCodes.indexOf(
                conditionsState.selected
            )}
            showThumbs={false}
            showArrows={false}
            showStatus={false}
            showIndicators={hasMultipleConditions}
            infiniteLoop={true}
            stopOnHover={false}
            swipeable={false}
            renderArrowPrev={() =>
                hasMultipleConditions && (
                    <StyledButton
                        onClick={() => {
                            dispatch(previousCondition());
                        }}
                    >
                        <Icon type="left" />
                    </StyledButton>
                )
            }
            renderArrowNext={() =>
                hasMultipleConditions && (
                    <StyledButton
                        onClick={() => {
                            dispatch(nextCondition());
                        }}
                    >
                        <Icon type="right" />
                    </StyledButton>
                )
            }
        >
            {conditionsState.allCodes.map((key) => (
                <StyledConditionName key={key}>
                    {conditions[key as ConditionsKey].name}
                </StyledConditionName>
            ))}
        </StyledCarousel>
    );
};

const StyledCarousel = styled(Carousel)`
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid rgba(232, 233, 232, 0.7);
    overflow: hidden;
    outline: 0;

    .carousel {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 100%;

        .slider-wrapper {
            width: 100%;
            margin: auto;
            overflow: hidden;

            .slider {
                position: relative;
                display: flex;
                height: 100%;
                width: 100%;
                margin: 0;
                padding: 0;
                list-style: none;

                .slide {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    min-width: 100%;
                    width: 100%;
                }
            }
        }

        .control-dots {
            position: absolute;
            width: auto;
            bottom: -2px;
            padding: 0;
            margin-bottom: 0;
            list-style: none;

            .dot {
                display: inline-block;
                width: 8px;
                height: 8px;
                margin: 0 2px;
                border-radius: 50%;
                background: #2c8eff;
                opacity: 0.3;
            }

            .dot.selected {
                opacity: 1;
            }
        }

        .carousel-status {
            display: none;
        }
    }
`;

const StyledButton = styled.button`
    border: none;
    outline: none;
    background: inherit;
    cursor: pointer;
    font-size: 2rem;
`;

const StyledConditionName = styled.p`
    margin: 0;
    padding: 0 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
