import { HTTPClient } from '../../../services/http-client/http-client';
import { normalizeConversations } from './chat.normalize';

export const getConversations = () =>
    HTTPClient.get(`chat/conversations`).then(normalizeConversations);

export const getMessages = (groupId: any, conversationId: any) =>
    HTTPClient.get(`chat/conversations/${conversationId}/messages`).then(
        (data) => {
            return {
                data,
                groupId,
                conversationId
            };
        }
    );

export const addConversation = (groupId: any, params: any) => {
    console.log('addConversation', groupId, params);
    return HTTPClient.post('chat/conversations', params).then((data) => {
        const { id: conversationId } = data;
        return { data, conversationId, groupId };
    });
};

export const postMessage = (groupId: any, conversationId: any, body: any) =>
    HTTPClient.post(`chat/conversations/${conversationId}/messages`, body).then(
        (message) => ({ groupId, conversationId, message })
    );
