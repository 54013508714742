import { useEffect, useRef } from 'react';

export const usePoll = (callback: Function, msDelay: number | null) => {
    const savedCallback = useRef<Function>(callback);

    useEffect(() => {
        savedCallback.current = callback;
    });

    useEffect(() => {
        const tick = () => savedCallback.current();

        if (msDelay !== null) {
            const id = setInterval(tick, msDelay);
            return () => clearInterval(id);
        }

        return;
    }, [msDelay]);

    useEffect(() => {
        if (msDelay !== null) {
            savedCallback.current();
        }
    }, []);
};
