import React from 'react';
import { styled } from '../../theme/styled';

type Props = { children: React.ReactElement | React.ReactElement[] };

export const ContentLayout = (props: Props) => {
    return <StyledContentLayout>{props.children}</StyledContentLayout>;
};

const StyledContentLayout = styled.div`
    margin: 50px 0px;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    /* https://webkit.org/blog/7929/designing-websites-for-iphone-x/ */
    height: calc(100% - 100px - env(safe-area-inset-bottom));

    // Give space for notification-bar:
    .notification-bar ~ & {
        margin-top: 0;
        height: calc(100% - 150px);
    }
`;
