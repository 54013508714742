import { IndicatorProperties } from '../../../types/indicator/indicator.type';
import messages from './messages';
import indicatorRules from './medication-rules';
import icon from './images/medication.svg';
import { TileType, FeedbackType } from '../../../types/indicator/report.type';
import { MultiRadioButtonGroupForm } from '../../../app/report/forms/radio/MultiRadioButtonGroupForm';

const properties = {
    antiLipoproteins: '',
    anticoagulant: ''
};

export const medication: IndicatorProperties<
    'antiLipoproteins' | 'anticoagulant',
    typeof indicatorRules
> = {
    code: 'MEDICATION-CI',
    properties,
    icon,
    messages,
    hiddenHistory: false,
    hiddenStatistics: false,
    recommendations: {
        limits: {
            enabled: true,
            properties: ['antiLipoproteins']
        },
        reminder: {
            moreOften: false,
            enabled: true
        },
        info: {
            enabled: false
        }
    },
    rules: indicatorRules,
    tile: {
        report: {
            form: MultiRadioButtonGroupForm,
            type: TileType.Dimming,
            feedback: FeedbackType.Checkmark
        }
    }
};
