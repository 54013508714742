import { styled } from '../../../../../../../theme/styled';
import React from 'react';
import { Progress } from 'antd';

export const StyledAchievementCardContent = ({
    infoModalDetails,
    majorStones,
    content,
    minorStones,
    children,
    progress,
    progressText
}: any) => {
    return (
        <StyledCardContent>
            <ModalWrapper>{infoModalDetails}</ModalWrapper>
            <StyledMajorLayout>{majorStones}</StyledMajorLayout>
            <StyledTextContent>{content}</StyledTextContent>
            <StyledMinorLayout>{minorStones}</StyledMinorLayout>
            <StyledProgress>
                <Progress percent={progress} showInfo={false} />
            </StyledProgress>
            <StyledProgressText>{progressText}</StyledProgressText>
            {children}
        </StyledCardContent>
    );
};

const StyledMileStonesLayout = styled.div`
    display: flex;
    flex-direction: row;
`;

const StyledCardContent = styled.div`
    margin: auto;
`;

const ModalWrapper = styled.div`
    margin: auto;
    width: 10rem;
`;

const StyledMajorLayout = styled(StyledMileStonesLayout)`
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
`;

const StyledTextContent = styled.div`
    width: 90%;
    margin: auto;
    margin-top: 3rem;
    margin-bottom: 3rem;
    text-align: center;
`;

const StyledMinorLayout = styled(StyledMileStonesLayout)``;

const StyledProgress = styled.div`
    width: 90%;
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: center;
`;

const StyledProgressText = styled.div`
    width: 90%;
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: center;
`;
