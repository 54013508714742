import { defineMessages } from 'react-intl';

export const heatmap = defineMessages({
    none: {
        defaultMessage: 'No phlegm',
        id: 'indicator.phlegm.heatmap.legend.1'
    },
    colorless: {
        defaultMessage: 'Colorless phlegm',
        id: 'indicator.phlegm.heatmap.legend.2'
    },
    yellow: {
        defaultMessage: 'Yellow phlegm',
        id: 'indicator.phlegm.heatmap.legend.3'
    },
    green: {
        defaultMessage: 'Green phlegm',
        id: 'indicator.phlegm.heatmap.legend.4'
    },
    withBlood: {
        defaultMessage: 'Phlegm with blood',
        id: 'indicator.phlegm.heatmap.legend.5'
    }
});
