import React, { ReactElement } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

export type MessageProps = MessageDescriptor & {
    values?: {
        values: Record<string, unknown>;
        tagName: string;
        children: (...formattedMessage: Array<ReactElement>) => ReactElement;
    };
};

export const Message = (props: MessageProps) => {
    return <FormattedMessage {...props} />;
};
