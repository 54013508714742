import { styled } from '../../../../theme/styled';
import { Icon } from 'antd';
import {
    ButtonLarge,
    ButtonLargePrimary
} from '../../../../components/ui/buttons/ButtonLarge';

export const StyledIcon = styled(Icon)`
    font-size: 1.3rem;
    margin-right: 1rem;
    color: #2c8eff;
`;

export const StyledP = styled.p`
    display: flex;
    align-items: center;
    width: 100%;
    height: 3rem;
    padding: 0;
    margin: 0;
    font-size: 1.1rem;
`;

export const StyledButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 2rem;
    text-align: center;
`;

export const StyledEditButton = styled(ButtonLarge)`
    && {
        height: auto;
        padding: 10px 20px;
    }
`;

export const StyledLabel = styled.label`
    display: block;
    margin-top: 1rem;
    font-size: 0.8rem;

    &:first-child {
        margin-top: 0;
    }
`;

export const StyledInfoP = styled.p`
    display: block;
    margin-top: 1rem;
    font-size: 0.8rem;
    font-style: italic;
`;

export const StyledInput = styled.input`
    && {
        display: block;
        width: 100%;
        height: 50px;
        margin-bottom: 0.5rem;
        padding: 5px 10px;
        background: rgb(232, 240, 254);
        border-bottom: 1px solid #23b2f7;
    }

    && {
        :focus,
        :hover {
            border-color: #54aaff;
            border-right-width: 1px !important;
        }
        :focus {
            outline: 0;
            -webkit-box-shadow: 0 0 0 2px rgba(44, 142, 255, 0.2);
            box-shadow: 0 0 0 2px rgba(44, 142, 255, 0.2);
        }
    }
`;

export const StyledError = styled.p`
    color: red;
    font-size: 0.8rem;
`;

export const StyledCancelButton = styled(ButtonLarge)`
    && {
        flex-grow: 1;
        height: auto;
        padding: 10px 20px;
        margin-right: 5px;
    }
`;

export const StyledSaveButton = styled(ButtonLargePrimary)`
    && {
        flex-grow: 1;
        height: auto;
        padding: 10px 20px;
        margin-left: 5px;
    }
`;
