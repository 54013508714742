import { defineMessages } from 'react-intl';

export const general = defineMessages({
    title: {
        defaultMessage: 'Phlegm status',
        id: 'indicator.phlegm.title'
    },
    value: {
        defaultMessage:
            '{status}\n{color, select, 1 {Colorless phlegm} 2 {Yellow phlegm} 3 {Green phlegm} other {}} {blood, select, true {with blood} other {}}',
        id: 'indicator.phlegm.values'
    }
});
