import React, { Fragment } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Empty } from 'antd';
import { styled } from '../../../theme/styled';
import { formatMessage, Strings } from '../../../locale';
import { HistoryListItem } from './HistoryListItem';
import { useCondition } from '../../../hooks/use-condition';
import { useReduxSelector } from '../../../hooks/use-redux-selector';
import { getDayGroups } from './utils';
import { HistoryListDateItem } from './HistoryListDateItem';

export const HistoryList = () => {
    const condition = useCondition();
    const history = useReduxSelector((state) => state.values.history);
    const { values = [] } = history[condition.code] || {};
    const dayGroups = getDayGroups(values);

    return !isEmpty(dayGroups) ? (
        <StyledHistoryList>
            {Object.entries(dayGroups).map(([key, groupedEvents]) => {
                return (
                    <Fragment key={key}>
                        <HistoryListDateItem date={key} />
                        {groupedEvents.map((groupedEvent: any) => (
                            <HistoryListItem
                                event={groupedEvent}
                                key={groupedEvent.id}
                            />
                        ))}
                    </Fragment>
                );
            })}
        </StyledHistoryList>
    ) : (
        <Empty
            style={{ marginTop: '15%' }}
            description={formatMessage(Strings.common.values.noHistory)}
        />
    );
};

const StyledHistoryList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    box-shadow: 0px 1px 2px rgba(128, 128, 128, 0.36);
`;
