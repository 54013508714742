import icon from './images/missing.svg';
import { SliderForm } from '../../../../app/report/forms/slider/SliderForm';
import { Indicator } from '../../../../types/indicator/indicator.type';
import {
    FeedbackType,
    TileType
} from '../../../../types/indicator/report.type';
import messages from './messages';
import indicatorRules from './oxygen-saturation-rules';

export const oxygenSaturation: Indicator = {
    code: 'OXYGEN-SATURATION',
    icon,
    messages,
    hiddenHistory: false,
    hiddenStatistics: false,
    recommendations: {
        limits: {
            enabled: true
        },
        reminder: {
            moreOften: false,
            enabled: true
        },
        info: {
            enabled: false
        }
    },
    rules: indicatorRules,
    tile: {
        report: {
            form: SliderForm,
            type: TileType.Dimming,
            feedback: FeedbackType.Smiley
        }
    }
};
