import { defineMessages } from 'react-intl';

export const recommendation = defineMessages({
    limits: {
        id: 'recommendation.limits.physact.duration',
        defaultMessage: 'Recommended time:  \n{range}'
    },
    reminder: {
        id: 'recommendation.reminder.extra.reportAfterActivity',
        defaultMessage: 'Report on activity'
    }
});
