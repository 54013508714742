import { useIndicator } from './use-indicator';
import { FetchedIndicator } from '../types/indicator/fetched-indicator.type';
import { useSelector } from 'react-redux';
import { getIndicatorsByCode } from '../redux/domains/conditions/conditions.selectors';

export const useFetchedIndicator = (code: string): FetchedIndicator => {
    const indicator = useIndicator(code);
    if (indicator == null) {
        throw new Error(`Indicator ${code} does not exist`);
    }
    const indicatorsByCode = useSelector(getIndicatorsByCode);
    const stateIndicator = indicatorsByCode[indicator.code];
    if (stateIndicator == null) {
        throw new Error(`Indicator ${code} does not exist in state`);
    }
    const fetchedIndicator: FetchedIndicator = {
        ...indicator,
        id: stateIndicator.id,
        category: stateIndicator.category,
        data: {
            jsonSchema: stateIndicator.data.jsonSchema,
            unitOfMeasure: stateIndicator.data.unitOfMeasure
        },
        form: stateIndicator.form
    };

    return fetchedIndicator;
};
