import React from 'react';
import { formatMessage } from '../../../../../locale';
import { styled } from '../../../../../theme/styled';
import {
    FetchedIndicator,
    isFetchedIndicatorProperties
} from '../../../../../types/indicator/fetched-indicator.type';

type Props = {
    indicator: FetchedIndicator;
    property?: string;
    value: number;
};

export const SliderValueText = ({ indicator, property, value }: Props) => {
    const textRange =
        property &&
        isFetchedIndicatorProperties(indicator) &&
        indicator.rules.properties
            ? indicator.rules.properties[property]?.textRanges?.find(
                  (range) => value >= range.min && value <= range.max
              )
            : indicator.rules.textRanges?.find(
                  (range) => value >= range.min && value <= range.max
              );

    if (textRange)
        return (
            <StyledSliderValueText>
                {formatMessage(textRange, { range: '' })}
            </StyledSliderValueText>
        );

    return <></>;
};

const StyledSliderValueText = styled.p`
    margin-bottom: 0;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
`;
