import React, { ReactNode } from 'react';
import { styled } from '../../../../../theme/styled';

type Props = {
    children?: ReactNode;
};

export const SliderValueContainer = ({ children }: Props) => {
    if (children)
        return (
            <StyledSliderValueContainer>{children}</StyledSliderValueContainer>
        );
    return <></>;
};

export const StyledSliderValueContainer = styled.div`
    display: flex;
    margin-top: 25px;
`;
