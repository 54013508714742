import React from 'react';
import { useReduxSelector } from '../../../../hooks/use-redux-selector';
import { styled } from '../../../../theme/styled';
import { ContentLayout } from '../../../../components/layout/ContentLayout';
import { SettingsPageList } from './page-list/SettingsPageList';
import { Header } from '../../../../components/layout/Header';
import { formatMessage } from '../../../../locale';
import { userSettings } from './message-descriptors';
import { routePaths } from '../../../../constants/route-paths';

export const SettingsPage = () => {
    const settings = useReduxSelector((state) => state.user.settings);

    return (
        <>
            <Header
                backButtonPath={routePaths.other}
                title={formatMessage(userSettings.header.title)}
            />
            <StyledUserSettingsContent>
                <SettingsPageList settings={settings} />
            </StyledUserSettingsContent>
        </>
    );
};

const StyledUserSettingsContent = styled(ContentLayout)`
    margin: 0px;
`;
