import { defineMessages } from 'react-intl';

export const choices = defineMessages({
    false: {
        defaultMessage: 'No',
        id: 'indicator.pump-self-test-lvad.choices.false'
    },
    true: {
        defaultMessage: 'Yes',
        id: 'indicator.pump-self-test-lvad.choices.true'
    }
});
