import React from 'react';
import { useSelector } from 'react-redux';
import { formatMessage, Strings } from '../../../../locale';
import { MarkdownFromDescriptor } from '../../../../locale/utils/functions/format-markdown';
import { ReccomendationRow } from '../RecommendationRow';
import { FetchedIndicator } from '../../../../types/indicator/fetched-indicator.type';
import { getSelectedCondition } from '../../../../redux/domains/conditions/conditions.selectors';
import { Reminder } from '../../../../redux/domains/reminders/reminders.types';
import { timeConversion } from '../../../../utils/time-conversion';
import { gradient, StyledText } from './styled';

const RecommendedReportFrequency = (props: {
    indicator: FetchedIndicator;
    reminder?: Reminder;
}): JSX.Element => {
    const conditionState = useSelector(getSelectedCondition);
    const conditionReportConfiguration =
        props.reminder?.conditionReportConfigurations.find(
            (config) => config.conditionId === conditionState.id
        );
    const reminderText = props.indicator.messages.recommendation?.reminder;
    const { enabled = true, moreOften = false } =
        props.indicator.recommendations?.reminder || {};

    if (!enabled) {
        return <></>;
    }
    const { active = false, missedReportInterval = 0 } =
        conditionReportConfiguration || {};
    const formattedTime = timeConversion(missedReportInterval);

    return (
        <ReccomendationRow background={gradient}>
            <StyledText>
                {reminderText ? (
                    <MarkdownFromDescriptor messageDescriptor={reminderText} />
                ) : (
                    formatMessage(
                        Strings.recommendation.reminder[
                            active && missedReportInterval > 0
                                ? moreOften
                                    ? 'reportFrequencyOrMoreOften'
                                    : 'reportFrequency'
                                : 'deactivated'
                        ],
                        { formattedTime }
                    )
                )}
            </StyledText>
        </ReccomendationRow>
    );
};

export default RecommendedReportFrequency;
