import { aoRS } from './aortic-dissection/messages/messages';
import { afRS } from './atrial-fibrillation/messages/messages';
import { copdRS } from './chronic-obstructive-pulmonary-disease/messages/messages';
import { demoDiabetes } from './demo-diabetes/messages/messages';
import { fcRS } from './fake-condition/messages/messages';
import { hsRS } from './hand-surgery/messages/messages';
import { hfRS } from './heart-failure/messages/messages';
import { icMAU } from './intermittent-claudication/messages/messages';
import { kfRS } from './kidney-failure/messages/messages';
import { lvadRS } from './left-ventricular-assist-device/messages/messages';
import { padRS } from './peripheral-artery-disease/messages/messages';

export const conditions = {
    rs: {
        ao: aoRS,
        af: afRS,
        copd: copdRS,
        demoDiabetes: demoDiabetes,
        fc: fcRS,
        hs: hsRS,
        hf: hfRS,
        kf: kfRS,
        lvad: lvadRS,
        pad: padRS
    },
    mau: {
        ic: icMAU
    }
};
