import { defineMessages } from 'react-intl';

export const phlegm = defineMessages({
    title: {
        defaultMessage: 'Phlegm',
        id: 'indicator.cat.phlegm.title'
    },
    maxValueDescription: {
        defaultMessage: 'My chest is completely full of phlegm (mucus)',
        id: 'indicator.cat.phlegm.max-text'
    },
    minValueDescription: {
        defaultMessage: 'I have no phlegm (mucus) in my chest at all',
        id: 'indicator.cat.phlegm.min-text'
    }
});
