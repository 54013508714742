import { defineMessages } from 'react-intl';

const header = defineMessages({
    title: {
        id: 'export.header.title',
        defaultMessage: 'Export my values'
    }
});

const date = defineMessages({
    beginning: {
        defaultMessage: 'Choose start date',
        id: 'export-data.date.beginning'
    },
    end: {
        defaultMessage: 'Choose end date',
        id: 'export-data.date.end'
    }
});

export const exportData = {
    header,
    date
};
