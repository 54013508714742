import { defineMessages } from 'react-intl';

export const info = defineMessages({
    main: {
        id: 'indicator.phlegm.lp_2_0_copd_rs.info.main',
        defaultMessage:
            'Slemmets färg kan avslöja vad en försämring i KOL beror på. ' +
            'Gult slem kan bero på bakterier av en viss sort men kan också ' +
            'bero på inflammationsceller. ' +
            'Grön och brun färg beror på bakterier och om det finns röda ' +
            'inslag kan det bl.a. bero på en lunginflammation. ' +
            'Virusangrepp färgar inte slemmet.'
    }
});
