import { formatMessage } from '../locale';
import messages from '../locale/messages-descriptors/time/time';

const {
    time: {
        recommendationDynamicSec,
        recommendationDynamicMin,
        recommendationDynamicHour,
        recommendationDynamicDay,
        recommendationDynamicMonth
    }
} = messages;

export function timeConversion(millisec = 0) {
    if (millisec === 0) {
        return '';
    }
    const seconds = parseFloat((millisec / 1000).toFixed(1));

    const minutes = parseFloat((millisec / (1000 * 60)).toFixed(1));

    const hours = parseFloat((millisec / (1000 * 60 * 60)).toFixed(1));

    const days = parseFloat((millisec / (1000 * 60 * 60 * 24)).toFixed(1));

    const months = parseFloat(
        (millisec / (1000 * 60 * 60 * 24 * 30)).toFixed(1)
    );

    if (seconds < 60) {
        return formatMessage(recommendationDynamicSec, {
            numeric: Math.trunc(seconds)
        });
    } else if (minutes < 60) {
        return formatMessage(recommendationDynamicMin, {
            numeric: Math.trunc(minutes)
        });
    } else if (hours < 24) {
        return formatMessage(recommendationDynamicHour, {
            numeric: Math.trunc(hours)
        });
    } else if (days < 30) {
        return formatMessage(recommendationDynamicDay, {
            numeric: Math.trunc(days)
        });
    } else {
        return formatMessage(recommendationDynamicMonth, {
            numeric: Math.trunc(months)
        });
    }
}
