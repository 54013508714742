import {
    Condition,
    ConditionsKey,
    isConditionsKey
} from '../../../conditions/condition.type';
import { conditions } from '../../../conditions';
import {
    ConditionCode,
    ConditionsState,
    CondtionByCode,
    Feedback,
    IndicatorCode,
    Indicators,
    Tile
} from './condtions.types';
import { mapValues } from 'lodash';

type ExistingCondition = CondtionByCode & {
    code: ConditionsKey;
};

type OptionalConditionByCode = {
    id?: string;
    code?: ConditionCode;
    indicators?: Indicators;
    tiles?: Record<IndicatorCode, Tile>;
};

type OptionalTile = {
    loading?: boolean;
    feedback?: Feedback;
    dimmedUntil?: number;
    shouldDimWhenReported?: boolean;
};

export const getCondition = (code: ConditionsKey) => {
    const condition: Condition = conditions[code];
    if (condition == null) {
        throw new Error(`Condition ${code} does not exist`);
    }
    return condition;
};

export const isExistingCondition = (
    condition: CondtionByCode
): condition is ExistingCondition => isConditionsKey(condition.code);

export function selectCondition(
    state: ConditionsState,
    conditionCode: string = state.selected
): ConditionsState {
    return {
        ...state,
        selected:
            conditionCode &&
            conditionCode in state.byCode &&
            isConditionsKey(conditionCode)
                ? conditionCode
                : state.allCodes.find(isConditionsKey) || ''
    };
}

export function preserveTilesState(state: ConditionsState, conditions: any) {
    return {
        byCode: conditions.allCodes.reduce(
            (
                acc: Record<ConditionCode, CondtionByCode>,
                curr: ConditionCode
            ) => {
                acc[curr] = {
                    ...conditions.byCode[curr],
                    tiles: {
                        ...conditions.byCode[curr].tiles,
                        ...state.byCode[curr]?.tiles
                    }
                };
                return acc;
            },
            {}
        )
    };
}

export function setConditionByCode(
    state: ConditionsState,
    conditionCode: ConditionCode,
    condition: OptionalConditionByCode
) {
    return {
        ...state,
        byCode: {
            ...state.byCode,
            [conditionCode]: {
                ...state.byCode[conditionCode],
                ...condition
            }
        }
    };
}

export function setSelectedCondition(
    state: ConditionsState,
    condition: OptionalConditionByCode
) {
    return setConditionByCode(state, state.selected, condition);
}

export function setTile(
    state: ConditionsState,
    conditionCode: ConditionCode,
    indicatorCode: IndicatorCode,
    tile: OptionalTile
) {
    return {
        ...setConditionByCode(state, conditionCode, {
            tiles: {
                ...state.byCode[conditionCode].tiles,
                [indicatorCode]: {
                    ...state.byCode[conditionCode].tiles[indicatorCode],
                    ...tile
                }
            }
        })
    };
}

export function setSelectedConditionTile(
    state: ConditionsState,
    indicatorCode: IndicatorCode,
    tile: OptionalTile
) {
    return setTile(state, state.selected, indicatorCode, tile);
}

export function getConditionCodesByIndicatorCode(
    state: ConditionsState,
    indicatorCode: IndicatorCode
) {
    return state.allCodes.filter((code) =>
        state.byCode[code].indicators.allCodes.includes(indicatorCode)
    );
}

function getMatchingTileKeys(
    condition: CondtionByCode,
    tiles: Record<IndicatorCode, OptionalTile>
) {
    return Object.keys(tiles).filter((key) =>
        Object.keys(condition.tiles).includes(key)
    );
}

function setMatchingTiles(
    conditionByCode: CondtionByCode,
    tiles: Record<IndicatorCode, OptionalTile>
) {
    return getMatchingTileKeys(conditionByCode, tiles).reduce(
        (
            tilesByIndicatorCode: Record<IndicatorCode, Tile>,
            indicatorCode: string
        ) => {
            tilesByIndicatorCode[indicatorCode] = {
                ...conditionByCode.tiles[indicatorCode],
                ...tiles[indicatorCode]
            };
            return tilesByIndicatorCode;
        },
        {}
    );
}

function setAllMatchingTiles(
    state: ConditionsState,
    tiles: Record<IndicatorCode, OptionalTile>
) {
    return state.allCodes.reduce(
        (
            byCode: Record<ConditionCode, CondtionByCode>,
            conditionCode: string
        ) => {
            byCode[conditionCode] = {
                ...state.byCode[conditionCode],
                tiles: {
                    ...state.byCode[conditionCode].tiles,
                    ...setMatchingTiles(state.byCode[conditionCode], tiles)
                }
            };
            return byCode;
        },
        {}
    );
}

export function setTiles(
    state: ConditionsState,
    tiles: Record<IndicatorCode, OptionalTile>
) {
    return {
        ...state,
        byCode: {
            ...state.byCode,
            ...setAllMatchingTiles(state, tiles)
        }
    };
}

export function resetTiles(state: ConditionsState) {
    return {
        ...state,
        byCode: {
            ...state.byCode,
            [state.selected]: {
                ...state.byCode[state.selected],
                tiles: mapValues(
                    state.byCode[state.selected].tiles,
                    (value: Tile) => ({
                        ...value,
                        feedback: null,
                        loading: false
                    })
                )
            }
        }
    };
}
