import { normalize, schema } from 'normalizr';

const groupsNormalize = (data: any) => {
    const medicGroupsSchema = new schema.Entity('medicGroups');

    const patientGroupsSchema = new schema.Entity('patientGroups', {
        managedBy: [medicGroupsSchema]
    });

    const normalizedData = normalize(data, [patientGroupsSchema]);

    let { medicGroups = {}, patientGroups = {} } = normalizedData.entities;

    medicGroups = {
        byId: medicGroups,
        allIds: Object.keys(medicGroups)
    };

    patientGroups = {
        byId: patientGroups,
        allIds: Object.keys(patientGroups)
    };

    return { medicGroups, patientGroups };
};

export { groupsNormalize };
