import { IndicatorProperties } from '../../../../types/indicator/indicator.type';
import messages from './messages';
import indicatorRules from './cat-rules';
import icon from './images/cat.svg';
import {
    TileType,
    FeedbackType
} from '../../../../types/indicator/report.type';
import { QuestionnaireForm } from '../../../../app/report/forms/questionnaire/QuestionnaireForm';

const properties = {
    sum: 'sum'
};

export const cat: IndicatorProperties<keyof typeof properties> = {
    code: 'CAT',
    properties,
    icon,
    messages,
    hiddenHistory: false,
    hiddenStatistics: false,
    recommendations: {
        limits: {
            enabled: true
        },
        reminder: {
            moreOften: true,
            enabled: true
        },
        info: {
            enabled: false
        }
    },
    rules: indicatorRules,
    tile: {
        report: {
            form: QuestionnaireForm,
            type: TileType.Dimming,
            feedback: FeedbackType.Checkmark
        }
    }
};
