import React from 'react';
import { Card, Radio } from 'antd';
import { styled } from '../../../../theme/styled';
import { formatMessage } from '../../../../locale';
import { ModalForm } from '../common/ModalForm';
import { Comment } from '../common/Comment';
import { DateTimePicker } from '../common/DateTimePicker';
import { FormProps } from '../../../../types/indicator/report.type';
import { RadioGroupQuestion } from './components/RadioGroupQuestion';
import { StyledRow } from './components/styled';

export const BooleanForm = ({ indicator }: FormProps): JSX.Element => {
    return (
        <ModalForm indicator={indicator}>
            <Card>
                <DateTimePicker />
                <StyledContainer>
                    <RadioGroupQuestion indicator={indicator} />
                    <Radio.Group
                        name={indicator.code}
                        size="large"
                        buttonStyle="solid"
                    >
                        <StyledRow>
                            <Radio.Button value={true}>
                                {formatMessage(
                                    indicator.messages.general.answer ||
                                        indicator.messages.general.value,
                                    { value: true }
                                )}
                            </Radio.Button>
                            <Radio.Button value={false}>
                                {formatMessage(
                                    indicator.messages.general.answer ||
                                        indicator.messages.general.value,
                                    { value: false }
                                )}
                            </Radio.Button>
                        </StyledRow>
                    </Radio.Group>
                </StyledContainer>
                <Comment />
            </Card>
        </ModalForm>
    );
};

const StyledContainer = styled.div`
    margin-top: 5px;
    margin-bottom: 20px;
    text-align: center;
`;
